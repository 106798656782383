import axios from 'axios';
import qs from 'qs';
import lockr from 'lockr';
import { isEmpty, isObject, isUndefined } from 'underscore';
// load mock data if enabled
import './mock';

const {
    API_URL,
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    SHARD_KEY,
} = require('../constants').default;

// ----- Helpers ----- //

// Get Api Url
const getApiUrl = () => API_URL;

// Get hostname url
const getBaseUrl = () => {
    let apiUrl = API_URL;
    const index = apiUrl.lastIndexOf('/api');
    if (index !== -1) {
        apiUrl = apiUrl.substring(0, index);
    }
    return apiUrl;
};

// defaults for axios
const api = axios.create({
    baseURL: API_URL,
});

const clean = (string) => {
    let newString = string.replace(' ', '-');
    newString = newString.replace('/[^A-Za-z0-9\-]/', ''); // Removes special chars.
    return newString;
};

/**
 * @param lat
 * @param lng
 * @param meters
 * This function synchronously returns a street address from a given lat long using OSM
 * @return string
 */
const getAddressFromLatLong = (lat, lng, meters = '10') => {
    const url = `${API_URL}/osm/get-address-from-lat-long`;
    const xmlHttp = new XMLHttpRequest();
    const params = `lat=${lat}&lng=${lng}`;
    xmlHttp.open('GET', `${url}?${params}`, false);
    xmlHttp.send(null);
    const response = xmlHttp.responseText ? JSON.parse(xmlHttp.responseText) : {};
    return response;
};

/**
 * @param lat
 * @param lng
 * @param meters
 * This function asynchronously returns a street address via callback from a given lat long using OSM
 * @return string
 */
const getAddressFromLatLongAsync = (lat, lng, callback) => {
    const url = `${API_URL}/osm/get-address-from-lat-long`;
    const xmlHttp = new XMLHttpRequest();
    const params = `lat=${lat}&lng=${lng}`;
    xmlHttp.open('GET', `${url}?${params}`, true);
    xmlHttp.onload = function () {
        callback(null, xmlHttp.response);
    };
    xmlHttp.onerror = function () {
        callback(xmlHttp.response, null);
    };
    xmlHttp.send(null);
};

// Format params for api call
const formatParams = (payload, key) => {
    let params = payload;
    if (!isObject(payload)) {
        params = {};
        params[key || 'id'] = payload;
    }

    return params;
};

// ----- Auth Key Helpers -----//

/**
 * Get Bearer token from storage
 * @return string
 */
const getAuthKey = () => lockr.get(AUTH_KEY);

/**
 * Get header object for auth token
 * @return object
 */
const getAuthHeaders = () => ({ Authorization: `Bearer ${getAuthKey()}` });

// ----- Api Functions ----- //

const fetchApi = (opts, headers) => {
    const data = (opts.method === 'get' || opts.method === 'GET') ? null : qs.stringify(opts.body);
    const responseType = opts.responseType ? opts.responseType : 'json';
    const shardId = lockr.get(SHARD_KEY) ?? null;
    const newHeaders = { ...headers };
    if (shardId) {
        newHeaders.shardId = shardId;
    }
    const options = {
        method: opts.method,
        url: opts.url,
        params: opts.params,
        data,
        responseType,
    };

    if (opts?.signal) {
        options.signal = opts.signal;
    }

    if (!isEmpty(newHeaders)) {
        options.headers = newHeaders;
    }
    return api(options);
};

const fetchApiAuth = (opts) => new Promise((resolve, reject) => {
    if (isUndefined(getAuthKey())) {
        reject('not-authorised');
    } else {
        const authDate = new Date();
        lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
        resolve(fetchApi(opts, getAuthHeaders()));
    }
});

const fetchApiCustom = (opts, headers) => {
    const data = (opts.method === 'get' || opts.method === 'GET') ? {} : opts.body;
    const options = {
        method: opts.method,
        url: opts.url,
        params: opts.params,
        data,
    };

    if (!isEmpty(headers)) {
        options.headers = headers;
    }
    return api(options);
};

const fetchApiAuthCustom = (opts) => new Promise((resolve, reject) => {
    if (isUndefined(getAuthKey())) {
        reject('not-authorised');
    } else {
        const authDate = new Date();
        lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
        resolve(fetchApiCustom(opts, getAuthHeaders()));
    }
});

const getUserCurrentLocation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
            const center = {};
            center.lat = position.coords.latitude;
            center.lng = position.coords.longitude;
            return center;
        });
    }
};

const getDistanceInMiles = (point1, point2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = (point2.lat - point1.lat) * Math.PI / 180; // deg2rad below
    const dLon = (point2.lng - point1.lng) * Math.PI / 180;
    const a = 0.5 - Math.cos(dLat) / 2
        + Math.cos(point1.lat * Math.PI / 180) * Math.cos(point2.lat * Math.PI / 180)
        * (1 - Math.cos(dLon)) / 2;

    const distanceInKm = R * 2 * Math.asin(Math.sqrt(a));

    return Math.round(distanceInKm / 1.609);
};

export {

    axios,

    getApiUrl,
    getBaseUrl,
    formatParams,
    // -- Auth Key Helpers --//
    getAuthKey,
    getAuthHeaders,

    fetchApi,
    fetchApiAuth,
    fetchApiAuthCustom,

    getDistanceInMiles,
    getUserCurrentLocation,
    getAddressFromLatLong,
    getAddressFromLatLongAsync,
};
