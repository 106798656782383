import React from 'react';
import {
    Button, Checkbox, Col, Row, notification,
} from 'antd';

export default function ({
    title, content, channels, buttonLabel, onClick = () => {}, parent = null, className = '', allowActions = true,
}) {
    const verifySelection = (e) => {
        const { selectedVideoChannels } = parent.state;
        if (channels.length > 0 && selectedVideoChannels.length == 0) {
            notification.error({ message: 'Error', description: 'Please select at least one channel to view stream.' });
            return;
        }
        onClick(e);
    };
    return (
        <div className={`video-view-video-box ${className}`}>
            <div className="video-box-title">{title}</div>
            <div className="video-box-content">{content}</div>
            <div className="video-box-checkbox-group">
                <Checkbox.Group
                    style={{ width: '100%', textAlign: 'left' }}
                    onChange={(checkedValue) => parent.setState({ selectedVideoChannels: checkedValue })}>
                    <Row gutter={[16, 16]}>
                        {channels.map((channel, channelIndex) => (
                            <Col span={8} key={`Channel-${channelIndex}`}>
                                <Checkbox value={channel.channel}>{channel.label != '' ? channel.label : `Channel ${channel.channel}`}</Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </div>
            {allowActions
                ? (
                    <Button
                        onClick={verifySelection}
                        style={{ width: '50%' }}
                        className="btn btn-bordered">
                        {buttonLabel}
                    </Button>
                )
                : null}
        </div>
    );
}
