import React from 'react';
import Layout from '../../components/layout/Layout';
import LoginForm from '../../components/LoginForm';

import './login.scss';

export default function() {
    return (
        <Layout data-test="pages-login" className="login" title="login" showHeader={false} showFooter={false}>
            <LoginForm />
        </Layout>
    );

    // return (
    //     <Layout
    //         className="login"
    //         title={<div>Welcome</div>}
    //         showHeader={false}>
    //         <Header
    //             floating
    //             // beforeHeader={<div>Before header</div>}
    //             // afterHeader={<div style={{ backgroundColor: 'red'}}><Breadcrumb separator=">" navigation={navigation} /></div>}
    //             backLink={<a><Icon type="left" /> BACK</a>}
    //             title={<div>Welcome</div>}
    //             toolbar={toolbar}
    //             menuWidth={350}
    //             menuPlacement="right"
    //             menu={menu} />
    //         <LoginForm />
    //         <div style={{ marginBottom: '140px' }}>Testing floating footer</div>
    //         <Footer floating>
    //             <div style={{ lineHeight: '30px', fontSize: '16px', float: 'right' }}>&copy; copyright 2019</div>
    //         </Footer>
    //     </Layout>
    // );
}
