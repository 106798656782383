/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import { isEmpty } from 'underscore';
import { departmentFilterForm, userFilterForm } from './filterForm';

class TheFilterForm extends React.Component {
    constructor(props) {
        super(props);
        this.view = props.type === 'user' ? userFilterForm : departmentFilterForm;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form, type } = this.props;
        const arr = (type === 'user' ? ['name', 'email', 'status', 'role', 'division_id'] : ['divisions']);
        form.validateFieldsAndScroll(arr, (err, values) => {
            if (!err) {
                const { onSubmit } = this.props;
                onSubmit(values, type);
            }
        });
    };

    componentDidMount() {
        const {
            actions,
        } = this.props;
        // actions.getUserOptionsRequest();
    }

    render() {
        const { type } = this.props;
        this.view = type === 'user' ? userFilterForm : departmentFilterForm;
        return this.view();
    }
}

TheFilterForm.defaultProps = {
    type: 'user',
};

TheFilterForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    type: PropTypes.string,
    searchParams: PropTypes.object.isRequired,
};

const FilterForm = Form.create({
    name: 'usersFilterForm',
    mapPropsToFields(props) {
        const initialData = {};
        if (!isEmpty(props.searchParams)) {
            Object.keys(props.searchParams).map((item) => {
                if (!isEmpty(item)) {
                    // console.log("item: ", item)
                    initialData[item] = Form.createFormField({
                        value: props.searchParams[item],
                    });
                }
            });
        }

        return initialData;
    },
})(TheFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        users: state.user.users,
        departments: state.department.departments,
        divisions: state.asset.divisions,
        searchParams: state.user.userSearchParams,
        userOptions: state.user.options,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({

        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
