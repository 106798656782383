/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Select, Button, Row, Col, Radio } from 'antd';
import { isEmpty, debounce, isArray } from 'underscore';

import '@rc-component/color-picker/assets/index.css';
import ColorPicker from '../../../ColorPicker';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { CloseCircleOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import * as dashboardActions from '../../../../core/dashboard/dashboardActions';
import * as smartWidgetActions from '../../../../core/smartWidget/smartWidgetActions';

import Icon from '../../../elements/Icon';

const { Option } = Select;
const FormItem = Form.Item;

class ColorRule extends Component {

    constructor(props) {
        super(props);

        let mode = (props.mode || 'fixed');
        let ruleIds = [1];

        if (props.data && props.data.length > 0 && mode !== 'fixed') {
            ruleIds = Array(props.data.length).fill(null).map((_, i) => i + 1);
        }

        this.state = {
            colorOption: mode, // fixed, smart-rule
            ruleIds: ruleIds
        };
    }

    componentDidMount() {
    }

    onColorRadioChange = (e) => {
        this.setState({ colorOption: e.target.value });
    };

    appendRow = () => {
        const { ruleIds } = this.state;
        let max = 0;
        if (!isEmpty(ruleIds)) {
            max = Math.max(...ruleIds);
        }

        const newRuleIds = [...ruleIds, max + 1];
        this.setState({
            ruleIds: newRuleIds
        })
    }

    removeRow = (num) => {
        const { ruleIds } = this.state;
        const { onChange } = this.props;

        var index = ruleIds.indexOf(num);
        if (index > -1) {
            ruleIds.splice(index, 1);
        }

        this.setState({
            ruleIds: ruleIds
        });

        onChange();

    }

    setColor = (name, color) => {
        const { form, onChange } = this.props;
        form.setFieldsValue({ [name]: color });
        onChange();
    }

    onInputUpdates = () => {
        const { onChange } = this.props;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            onChange();
        }, 700);
    }

    render() {
        const { form, name, mode, data, excludeConditions } = this.props;
        const { getFieldDecorator } = form;
        const { colorOption, ruleIds } = this.state;

        const operators = [
            {
                name: '=',
                key: '=',
            },
            {
                name: '>',
                key: '>',
            },
            {
                name: '<',
                key: '<',
            },
            {
                name: '<=',
                key: '<=',
            },
            {
                name: '>=',
                key: '>=',
            },
            {
                name: '!=',
                key: '!=',
            },
        ];
        const conditions = [
            {
                name: 'Condition',
                key: 'condition',
            },
            {
                name: 'Range',
                key: 'range',
            },
        ];

        const rules = [];

        if (colorOption !== 'fixed') {
            ruleIds.map((num) => {
                let color = '#36c';
                let condition = 'condition';
                let operator = '=';
                let conditionPlaceholder = '=';
                if (excludeConditions.includes("=")) {
                    conditionPlaceholder = '>';
                    operator = '>';
                }


                let value = "1000";
                let value2 = "1000";
                if (data && data[num - 1]) {
                    color = data[num - 1].color;
                    condition = data[num - 1].condition;
                    operator = data[num - 1]?.operator
                    value = data[num - 1].value;
                    value2 = data[num - 1].value2;

                }

                rules.push(
                    <div key={num}>
                        <div className="condition-box">
                            <Row gutter={[32, 32]}>
                                <Col className="gutter-row" xs={24} sm={12} md={4} lg={1} xl={1}>
                                    <MenuOutlined width={30} height={30} />
                                </Col>
                                <Col className="gutter-row" xs={24} sm={12} md={4} lg={2} xl={2}>
                                    <FormItem>
                                        {getFieldDecorator(`${name}[rules][${num}][color]`, {
                                            initialValue: color
                                        })(
                                            <ColorPicker
                                                color={(form.getFieldValue(`${name}[rules][${num}][color]`) || "#36c")}
                                                onChange={(color) => { this.setColor(`${name}[rules][${num}][color]`, color); }}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <FormItem>
                                        {getFieldDecorator(`${name}[rules][${num}][condition]`, {
                                            initialValue: condition,
                                            rules: [],
                                        })(
                                            <Select
                                                onChange={() => this.props.onChange()}
                                                style={{ width: '100%' }}
                                                suffixIcon={
                                                    <Icon name="triangle-down" />
                                                }
                                                placeholder="Select Condition"
                                            >
                                                {
                                                    conditions.map((v, i) => (
                                                        /* eslint-disable-next-line react/no-array-index-key */
                                                        (<Option key={i} value={v.key}>{v.name}</Option>)
                                                    ))
                                                }
                                            </Select>,
                                        )}
                                    </FormItem>
                                </Col>

                                {this.props?.form?.getFieldValue(`${name}[rules][${num}][condition]`) === 'condition'
                                    ? (
                                        <>
                                            <Col className="gutter-row" xs={24} sm={12} md={3} lg={3} xl={3}>
                                                <FormItem>
                                                    {getFieldDecorator(`${name}[rules][${num}][operator]`, {
                                                        initialValue: operator,
                                                        rules: [],
                                                    })(
                                                        <Select
                                                            onChange={() => this.props.onChange()}
                                                            style={{ width: '100%' }}
                                                            placeholder={conditionPlaceholder}
                                                            suffixIcon={
                                                                <Icon name="triangle-down" />
                                                            }>
                                                            {
                                                                operators.map((v, i) => {

                                                                    if (!excludeConditions.includes(v.name)) {
                                                                        return (
                                                                            /* eslint-disable-next-line react/no-array-index-key */
                                                                            (<Option key={i} value={v.key}>{v.name}</Option>)
                                                                        );
                                                                    }
                                                                })
                                                            }

                                                        </Select>,
                                                    )}

                                                </FormItem>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={12} md={10} lg={10} xl={10}>
                                                <FormItem>
                                                    {getFieldDecorator(`${name}[rules][${num}][value]`, {
                                                        rules: [
                                                            {
                                                                // required: true,
                                                                message: 'Please enter condition value',
                                                            },
                                                        ],
                                                        initialValue: value
                                                    })(
                                                        <Input onChange={() => this.onInputUpdates()} />,
                                                    )}

                                                </FormItem>

                                            </Col>
                                        </>
                                    ) : (
                                        <>
                                            <Col className="gutter-row" xs={5} sm={5} md={4} lg={4} xl={3}>
                                                <span className="condition-box-text">BETWEEN</span>
                                            </Col>
                                            <Col className="gutter-row" xs={8} sm={8} md={6} lg={4} xl={4}>
                                                <FormItem>
                                                    {getFieldDecorator(`${name}[rules][${num}][value]`, {
                                                        rules: [
                                                            {
                                                                // required: true,
                                                                message: 'Please enter condition value',
                                                            },
                                                        ],
                                                        initialValue: value,
                                                    })(
                                                        <Input onChange={() => this.onInputUpdates()} />,
                                                    )}
                                                </FormItem>
                                            </Col>
                                            <Col className="gutter-row" xs={3} sm={3} md={4} lg={4} xl={2}>
                                                <span className="condition-box-text">AND</span>
                                            </Col>
                                            <Col className="gutter-row" xs={8} sm={8} md={6} lg={4} xl={4}>
                                                <FormItem>
                                                    {getFieldDecorator(`${name}[rules][${num}][value2]`, {
                                                        rules: [
                                                            {
                                                                // required: true,
                                                                message: 'Please enter condition value',
                                                            },
                                                        ],
                                                        initialValue: value2,
                                                    })(
                                                        <Input onChange={() => this.onInputUpdates()} />,
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </>
                                    )}

                                <Col className="gutter-row" xs={24} sm={2} md={2} lg={1} xl={1}>
                                    <CloseCircleOutlined className="dynamic-delete-button" onClick={() => this.removeRow(num)} />
                                </Col>
                            </Row>
                        </div>
                        <br />
                    </div>
                );
            })
        }

        return <>
            <FormItem className="icon-position-checkbox" label="Colour">
                {getFieldDecorator(`${name}[color_type]`, {
                    initialValue: (mode || 'fixed'),
                    rules: [],
                })(
                    <Radio.Group onChange={this.onColorRadioChange}>
                        <Radio value="fixed">Fixed Colour</Radio>
                        <Radio value="smart-rule">Smart Rule</Radio>
                    </Radio.Group>,
                )}
            </FormItem>
            <br />

            {colorOption === 'fixed'
                ? (
                    <FormItem className="icon-position-checkbox">
                        {getFieldDecorator(`${name}[fixed_color]`, {
                            rules: [],
                        })(
                            <Row gutter={[32, 32]}>
                                <Col className="gutter-row" xs={24} sm={12} md={4} lg={2} xl={2}>
                                    <FormItem>
                                        {getFieldDecorator(`${name}.color`, {
                                            initialValue: (data?.color || "#36c"),
                                            rules: [],
                                        })(
                                            <ColorPicker
                                                color={(form.getFieldValue(`${name}.color`) || "#36c")}
                                                onChange={(color) => { this.setColor(`${name}[color]`, color); }}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>,
                        )}
                    </FormItem>
                )
                : (
                    <>
                        {rules}

                        <Form.Item style={{ textAlign: 'left' }}>
                            <Button onClick={() => this.appendRow()} shape="circle" size="small" className="add-button">
                                <PlusOutlined />
                            </Button> Add
                        </Form.Item>
                    </>
                )}
        </>;
    }
}

ColorRule.defaultProps = {
    name: 'color',
    form: [],
    excludeConditions: [],
    onChange: () => { },
};

ColorRule.propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func,
    excludeConditions: PropTypes.array,
    // visible: PropTypes.bool,
    // loading: PropTypes.bool,
    // inputData: PropTypes.array,
    // chartType: PropTypes.array,
    // actions: PropTypes.object.isRequired,
    // smartWidget: PropTypes.object.isRequired,
    // newImageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        // inputData: state.smartWidget.inputData,
        // chartType: state.smartWidget.chartType,
        // smartWidget: state.smartWidget
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...dashboardActions,
            ...smartWidgetActions
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ColorRule);
