/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { DatePicker, Input, Select, InputNumber } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { Erase, Calendar } from '@carbon/icons-react';
import * as assetActions from '../../../core/asset/assetActions';

const { Option } = Select;
const FormItem = Form.Item;

let make = null;
let model = null;
class AssetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { actions } = this.props;
    }

    handleMakeChange = (value) => {
        const { actions } = this.props;
        make = parseInt(value, 10);
        model = null;
        if (value) {
            actions.getVehicleMakeModelsRequest({ make_id: value });
        }
    };

    handleModelChange = (value) => {
        model = parseInt(value, 10);
    };

    saveAsset = (e) => {
        const {
            form, actions, updateForm, newAssetImage
        } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                if (!updateForm) {
                    values.picture_id = newAssetImage;
                    actions.createAssetRequest(values);
                    make = null;
                }
            }
        });
    }

    render() {
        const {
            form, divisions, vehicleTypes, vehicleMakes, vehicleModels,
        } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Form id="asset-form" onSubmit={this.saveAsset} layout="vertical">
                <FormItem label="Asset name">
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter a name for the asset',
                            },
                            {
                                min: 1,
                                max: 50,
                                message: 'Asset name must be between 1 and 50 characters',
                            },
                        ],
                    })(
                        <Input placeholder="Enter Name" />,
                    )}
                </FormItem>
                <FormItem label="Asset reg">
                    {getFieldDecorator('registration', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter registration',
                            },
                            {
                                pattern: /^[A-Z0-9\s]*$/i,
                                message: 'Registration must be alphanumeric',
                            },
                            {
                                max: 7,
                                message: 'Registration must be up to 7 characters (excluding spaces)',
                            },
                        ],
                    })(
                        <Input placeholder="Enter Registration" />,
                    )}
                </FormItem>
                <FormItem label="Year vehicle registered">
                    {getFieldDecorator('year_registration', {
                        rules: [
                            {
                                type: 'number',
                                min: 1900,
                                max: moment().year(),
                                message: `Year must be between 1900 and ${moment().year()}`,
                            },
                        ],
                        initialValue: moment().year(),
                    })(
                        <InputNumber min={1900} max={moment().year()} />,
                    )}
                </FormItem>
                <FormItem label="Asset category">
                    {getFieldDecorator('category', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter an asset category',
                            },
                        ],
                    })(
                        <Input placeholder="Enter Category" />,
                    )}
                </FormItem>
                <FormItem label="Date last serviced">
                    {getFieldDecorator('lastService', {
                        rules: [],
                    })(
                        <DatePicker
                            format="DD/MM/YYYY"
                            allowClear={false}
                            suffixIcon={<Calendar />}
                            maxDate={dayjs()}
                        />,
                    )}
                </FormItem>
                <FormItem label="Fuel type">
                    {getFieldDecorator('fuel_type', {
                        rules: [],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Fuel Type"
                        >
                            {[
                                { name: 'Diesel', key: 1 },
                                { name: 'Petrol', key: 2 },
                                { name: 'Electric', key: 4 },
                                { name: 'Hydrogen', key: 5 },
                                { name: 'Hybrid', key: 6 },
                            ].map((v, i) => (
                                <Option key={i} value={v.key}>{v.name}</Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="Asset make">
                    {getFieldDecorator('make_id', {
                        rules: [],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            onChange={this.handleMakeChange}
                            placeholder="Select Asset Make"
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            {vehicleMakes.map((v, i) => (
                                <Option key={i} value={v.id}>{v.title}</Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="Asset model">
                    {getFieldDecorator('model_id', {
                        rules: [],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            onChange={this.handleModelChange}
                            placeholder="Select Asset Model"
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            {vehicleModels.map((v, i) => (
                                <Option key={i} value={v.id}>{v.title}</Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="Division">
                    {getFieldDecorator('division_id', {
                        rules: [],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Division"
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            {divisions.map((v, i) => (
                                <Option key={`${i}`} value={v.id}>{v.name}</Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="Vehicle Type">
                    {getFieldDecorator('vehicle_type_id', {
                        rules: [],
                    })(
                        <Select
                            placeholder="Select Vehicle Type"
                            style={{ width: '100%' }}
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            {vehicleTypes.map((v, i) => (
                                <Option key={`${i}`} value={v.id}>{v.title}</Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>
            </Form>
        );
    }
}

const WrappedAssetForm = Form.create({
    name: 'register',
})(AssetForm);

WrappedAssetForm.defaultProps = {
    updateForm: false,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        divisions: state.asset.divisions,
        vehicleTypes: state.asset.vehicleTypes,
        vehicleMakes: state.asset.vehicleMakes,
        vehicleModels: state.asset.vehicleModels,
        newAssetImage: state.asset.newAssetImage,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WrappedAssetForm);
