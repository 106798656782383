import React from 'react';
import { Input } from 'antd';

interface PasswordInputProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    style?: React.CSSProperties;
    className?: string;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
    value,
    onChange,
    placeholder = 'Enter Password',
    style = {},
    className = '',
}) => {
    return (
        <Input.Password 
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={className}
            style={{
                ...style,
            }}
        />
    );
}

export default PasswordInput;