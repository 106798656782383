/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CloseOutlined, DragOutlined, PushpinOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
    filter, reject, isEmpty,
} from 'underscore';
import Map from '../../../components/Map';
import * as videoActions from '../../../core/video/videoActions';
import VideoPlayer from '../../../components/elements/VideoPlayer';
import app from '../../../core/constants';

const { GOOGLE_MAP_API_KEY } = app;
const ResponsiveGridLayout = WidthProvider(Responsive);

class LiveVideosScreen extends Component {
    constructor(props) {
        super(props);
        this.googleMapAccess = React.createRef();
        this.countdown = null;
        this.state = {
            firstLoad: true,
        };
    }

    componentDidMount() {
        const { selectedCameras, actions } = this.props;
        const ids = selectedCameras.map((selectedCamera) => selectedCamera.id);
        if (!isEmpty(ids)) {
            actions.getVideoCameraChannelsLinkRequest({ ids });
            // getiing video locations
            this.getVideLocations();
        }

        if (this.countdown != null) {
            clearInterval(this.countdown);
            this.countdown = null;
        }
        this.countdown = setInterval(this.getVideLocations.bind(this), 10000); // 10 sec

        setTimeout(() => {
            if (this.googleMapAccess && this.googleMapAccess.current) {
                this.googleMapAccess.current.executeAutoZoom();
            }
        }, 500);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { cameraLocations, selectedCameras, actions } = this.props;
        const { firstLoad } = this.state;

        if (prevProps.selectedCameras && selectedCameras) {
            if (isEmpty(prevProps.selectedCameras) && !isEmpty(selectedCameras)) {
                const ids = selectedCameras.map((selectedCamera) => selectedCamera.id);
                if (!isEmpty(ids)) {
                    actions.getVideoCameraChannelsLinkRequest({ ids });
                    this.getVideLocations();
                }
            }
        }

        if (prevProps.cameraLocations && cameraLocations) {
            if (prevProps.cameraLocations !== cameraLocations) {
                if (cameraLocations[0] && prevProps.cameraLocations[0] && cameraLocations[0].lat !== prevProps.cameraLocations[0].lat) {
                    if (this.googleMapAccess && this.googleMapAccess.current) {
                        if (cameraLocations.length == 1 && !firstLoad) {
                            // don't auto zoom to preseve zoom level if there is only 1 marker
                            // re-center instead
                            const map = this.googleMapAccess.current._googleMap.map_;
                            if (map) {
                                map.setCenter({ lat: parseFloat(cameraLocations[0].lat), lng: parseFloat(cameraLocations[0].lng) });
                            }
                        } else {
                            this.googleMapAccess.current.executeAutoZoom();
                            this.setState({ firstLoad: false });
                        }
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.countdown != null) {
            clearInterval(this.countdown);
            this.countdown = null;
        }
    }

    getVideLocations() {
        const { selectedCameras, actions } = this.props;
        const ids = selectedCameras.map((selectedCamera) => selectedCamera.id);
        if (!isEmpty(ids)) {
            actions.getVideoLiveLocationRequest({ ids });
        } else if (this.countdown != null) {
            clearInterval(this.countdown);
            this.countdown = null;
        }
    }

    reArrangeLayout = (currentLayout) => {
        const { selectedCameras, actions } = this.props;
        const modifiedSelectedCameras = selectedCameras.map((selectedCamera) => {
            const filterCamera = filter(currentLayout, (layout) => layout.i === `camera-sub-${selectedCamera.id}`);
            const newCamera = selectedCamera;
            if (filterCamera) {
                newCamera.data = filterCamera[0];
                newCamera.data.key = selectedCamera.data.i;
            }
            return newCamera;
        });
        actions.updateVideoCameraSelection(modifiedSelectedCameras);
    };

    removeGridBlock = (id) => {
        const { selectedCameras, actions } = this.props;
        const currentVideoList = reject(selectedCameras, (obj) => obj.id == id);
        actions.updateVideoCameraSelection(currentVideoList);
    };

    pinVideoGridBlock = (id) => {
        const { selectedCameras, actions } = this.props;
        const currentBlock = filter(selectedCameras, (selectedCamera) => selectedCamera.id == id)[0] || {};
        const pinnedBlock = filter(selectedCameras, (selectedCamera) => selectedCamera.pinned === true)[0] || {};

        const currentBlockData = (currentBlock && currentBlock.data) || {};
        const pinnedBlockData = (pinnedBlock && pinnedBlock.data) || {};

        pinnedBlock.data.w = 1;
        pinnedBlock.data.x = currentBlockData.x;
        pinnedBlock.data.y = currentBlockData.y;
        pinnedBlock.data.static = false;
        pinnedBlock.pinned = false;

        currentBlock.data.w = 2;
        currentBlock.data.x = 2;
        currentBlock.data.y = 0;
        currentBlock.data.static = true;
        currentBlock.pinned = true;

        return true;
    };

    render() {
        const { selectedCameras, cameraLocations, isFetching } = this.props;

        let center = {
            lat: 52.48759,
            lng: -1.91199,
        };
        const validCameraLocations = cameraLocations.filter((camera) => camera.lat && camera.lng);
        if (validCameraLocations.length > 0) {
            center = {
                lat: parseFloat(validCameraLocations[0].lat),
                lng: parseFloat(validCameraLocations[0].lng),
            };
        }
        let showMap = true;
        if (selectedCameras.length > 17) {
            showMap = false;
        }

        return (
            <Spin spinning={isFetching}>
                <Row
                    gutter={24}
                    style={{
                        paddingTop: 10,
                        paddingBottom: 10
                    }}
                >
                    <Col
                        xs={12}
                        style={{ minHeight: 700 }}
                    >
                        {showMap && (
                            <div
                                className="widget-content"
                                onMouseDown={(a) => a.stopPropagation()}
                            >
                                <div
                                    className="live-video__map"
                                    style={{ minHeight: 700 }}
                                >
                                    <Map
                                        ref={this.googleMapAccess}
                                        center={center}
                                        showToolbar={false}
                                        allowStreetView={false}
                                        showFindCenterButton={false}
                                        apiKey={GOOGLE_MAP_API_KEY}
                                        markers={validCameraLocations}
                                    />
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col xs={12}>
                        <Row gutter={12}>
                            {selectedCameras.map((camera) => {
                                const videoType = camera.video_api === 'Howen' ? 'howen' : 'flv';
                                return (
                                    <Col
                                        className="grid-video"
                                        key={`camera-sub-${camera.id}`}
                                        data-grid={camera.data}
                                        style={{ backgroundColour: '#FF0000', overflow: 'hidden' }}
                                        xs={12}
                                    >
                                        <Spin spinning={isFetching}>
                                            {camera.url
                                                ? (
                                                    <>
                                                        <VideoPlayer minHeight={348} identity={camera.id} url={camera.url} videoServer={camera.video_server} videoType={videoType} />
                                                        <div className="live-video-close" onClick={() => this.removeGridBlock(camera.id)}>
                                                            <CloseOutlined />
                                                        </div>
                                                    </>
                                                ) : null}
                                        </Spin>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

LiveVideosScreen.defaultProps = {
    selectedCameras: [],
    cameraLocations: [],
};

LiveVideosScreen.propTypes = {
    actions: PropTypes.object.isRequired,
    selectedCameras: PropTypes.array,
    cameraLocations: PropTypes.array,
    isFetching: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.video.isFetching,
        selectedCameras: state.video.selectedCameras,
        cameraLocations: state.video.cameraLocations,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...videoActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LiveVideosScreen);
