import React from 'react';
import { Spin } from 'antd';

function Loading() {
    return (
        <div style={{
            position: 'fixed', width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.7)', zIndex: '9999999', textAlign: 'center', top: '0', left: '0', paddingTop: '43vh',
        }}>
            <Spin size="large" />
        </div>
    );
}

export default Loading;
