import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DOM from './twoFactorAuth';
import './twoFactorAuth.scss';
import * as userActions from '../../core/user/userActions';

class TwoFactorAuth extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {};
    }

    render() {
        return this.view();
    }
}

TwoFactorAuth.propTypes = {
    match: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
            },
            dispatch,
        ),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TwoFactorAuth);
