import React from 'react';
import { Slider } from 'antd';
import { secondsToTwentyFourHours } from '../../core/utils/dateUtils';

import './deviceTimelineHeader.scss';

const NINE_AM_AS_SECONDS_PASSED_SINCE_MIDNIGHT = 32400;
const FIVE_PM_AS_SECONDS_PASSED_SINCE_MIDNIGHT = 61200;
const SECONDS_IN_A_DAY = 86400;

function DeviceTimelineHeader(props) {
    if (!props.reportLength) return;
    return (
        (
            <div>
                <div className="device-timeline-header__title">
                    Timeline
                </div>
                <div className="device-timeline-header__spacer">
                    <div className="device-timeline-header__bar-container">
                        <div className="device-timeline-header__bar-marker-text">00:00</div>
                        <div className="device-timeline-header__bar-marker-spacer" />
                        <div className="device-timeline-header__bar-marker-text">06:00</div>
                        <div className="device-timeline-header__bar-marker-spacer" />
                        <div className="device-timeline-header__bar-marker-text">12:00</div>
                        <div className="device-timeline-header__bar-marker-spacer" />
                        <div className="device-timeline-header__bar-marker-text">18:00</div>
                        <div className="device-timeline-header__bar-marker-spacer" />
                        <div className="device-timeline-header__bar-marker-text">23:59</div>
                    </div>
                    <div className="device-timeline-header__bar-marker__first" />
                    <div className="device-timeline-header__bar-marker" />
                    <div className="device-timeline-header__bar-marker" />
                    <div className="device-timeline-header__bar-marker" />
                    <div className="device-timeline-header__ruler-container">
                        <Slider 
                            range 
                            defaultValue={[NINE_AM_AS_SECONDS_PASSED_SINCE_MIDNIGHT, FIVE_PM_AS_SECONDS_PASSED_SINCE_MIDNIGHT]} 
                            step={1}
                            min={0}
                            max={SECONDS_IN_A_DAY}
                            className={`device-timeline-header__ruler__${props.reportLength}-items`}
                            tooltip={{
                                formatter: (value) => secondsToTwentyFourHours(value, true),
                            }} />
                    </div>
                </div>
            </div>
        )
    );
}

export default DeviceTimelineHeader;
