import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constants = keyMirror({
    REHYDRATION_COMPLETED: null,
    SET_VERSION: null,
    SET_BREADCRUMB: null,
    SET_USER_MAP_CENTER: null,
    UPDATE_SLIDER_VALUE: null,
    UPDATE_MAP_DATE: null,
    UPDATE_EVENT_SEARCH: null,
    UPDATE_DATA_EVENT_SEARCH: null,
    UPDATE_VIDEO_SEARCH: null,
    UPDATE_JOURNEY_BACK_LINK: null,
    SET_DATA_EVENT_TO_REPORT_PARAMS: null,
    SET_RETAIN_FILTER: null,
    SET_PREVIOUS_MAP_STATE: null,
    SET_SHARD_ID: null,
});

export const {
    setVersion,
    setBreadcrumb,
    rehydrationCompleted,
    setUserMapCenter,
    updateSliderValue,
    updateMapDate,
    updateEventSearch,
    updateDataEventSearch,
    updateVideoSearch,
    updateJourneyBackLink,
    setDataEventToReportParams,
    setRetainFilter,
    setPreviousMapState,
    setShardId,
} = createActions(
    constants.SET_VERSION,
    constants.SET_BREADCRUMB,
    constants.REHYDRATION_COMPLETED,
    constants.SET_USER_MAP_CENTER,
    constants.UPDATE_SLIDER_VALUE,
    constants.UPDATE_MAP_DATE,
    constants.UPDATE_EVENT_SEARCH,
    constants.UPDATE_DATA_EVENT_SEARCH,
    constants.UPDATE_VIDEO_SEARCH,
    constants.UPDATE_JOURNEY_BACK_LINK,
    constants.SET_DATA_EVENT_TO_REPORT_PARAMS,
    constants.SET_RETAIN_FILTER,
    constants.SET_PREVIOUS_MAP_STATE,
    constants.SET_SHARD_ID,
);
