/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Checkbox, Button, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';
import moment from 'moment';
import DOM from './assets';
import AssetsTab from './tabs/AssetsTab';
import DivisionTab from './tabs/DivisionTab';
import * as AssetActions from '../../core/asset/assetActions';
import * as DivisionActions from '../../core/division/divisionActions';
import * as DeviceActions from '../../core/device/deviceActions';
import LocationFetcher from '../../components/elements/LocationFetcher';
import { View, Edit } from '@carbon/icons-react';

class Assets extends Component {
    constructor(match, props) {
        super(match, props);

        let currentTabKey = '1';
        if (match && match.match && match.match.url) {
            if (match.match.url === '/divisions') {
                currentTabKey = '2';
            }
        }
        this.state = {
            divisionSearchParams: {},
            divisionOrderParams: {},
            currentTabKey: currentTabKey || '1',
            data: [],
            showAddDivisionModal: false,
            newDivisionName: '',
        };
        this.view = DOM;
        this.assetTabContent = AssetsTab;
        this.divisionTabContent = DivisionTab;
    }

    componentDidMount() {
        const {
            actions, asset, searchParams, orderParams,
        } = this.props;

        // TO DO: Replicate functionality from Events page to use saved search params
        // actions.filterAssetsRequest({ page: asset.pagination.currentPage, searchParams, orderParams });
        actions.resetAssetIsFetchings();
        actions.filterAssetsRequest({ page: 1, searchParams, orderParams });
        actions.getDivisionsGridRequest();
        actions.getDivisionsRequest();
        actions.getVehicleTypesRequest();
        actions.saveAssetSearchParamsRequest(searchParams);
    }

    componentWillUnmount() {
        const { actions } = this.props;
        actions.saveAssetSearchParamsRequest({});
    }

    componentDidUpdate(prevProps) {
        const { asset } = this.props;
        if (asset !== prevProps.asset) {
            this.formatData();
        }
    }

    handleTabChange = (tabKey) => {
        if (tabKey === '2') {
            const { actions } = this.props;
            actions.saveAssetSearchParamsRequest({});
        }

        this.setState({ currentTabKey: tabKey });
    };

    formatData() {
        const { asset, user, actions, selectedAssets } = this.props;
        const formattedData = [];
        const NOT_SET_STRING = '-';

        if (!isEmpty(asset.assets)) {
            asset.assets.map((item) => {
                const tempItem = {
                    id: item.id,
                    asset: item.name ? item.name : NOT_SET_STRING,
                    registration: item.registration ? item.registration : NOT_SET_STRING,
                    category: item.category ? item.category : NOT_SET_STRING,
                    last_online: item.last_online ? moment(item.last_online).format('D/M/YY HH:mm') : NOT_SET_STRING,
                    last_location: (
                        <LocationFetcher
                            key={item.id}
                            lat={item.lat}
                            lon={item.lng} 
                        />
                    ),
                    last_driver: item.full_name ? (
                        <div onClick={(e) => { e.stopPropagation(); }}>
                            <Link to={`/drivers/view/${item.user_id}`}>{item.full_name}</Link>
                        </div>
                    ) : NOT_SET_STRING,
                    division: (
                        <div>
                            {item.division ? item.division : NOT_SET_STRING}
                        </div>
                    ),
                    vehicle_type: (
                        <div>
                            {item.vehicle_type ? item.vehicle_type : NOT_SET_STRING}
                        </div>
                    ),
                    actions: (
                        <>
                            <Tooltip title="View Asset">
                                <Link 
                                    to={`/assets/view/${item.id}`} 
                                    className='actions-btn'
                                >
                                    <View />
                                </Link>
                            </Tooltip>
                            {user.permissions.editing_assets && (
                                <>
                                    <Tooltip title="Edit Asset">
                                        <Link 
                                            to={`/assets/edit/${item.id}`} 
                                            className="actions-btn"
                                        >
                                            <Edit />
                                        </Link>
                                    </Tooltip>
                                    <Tooltip title="Select Asset">
                                        <Checkbox
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                actions.toggleAssetSelected(item.id);
                                            }}
                                            checked={selectedAssets.includes(item.id)}
                                            className='actions-btn'
                                        />
                                    </Tooltip>
                                </>
                            )}
                        </>
                    ),
                };
                formattedData.push(tempItem);
            });
            this.setState({ data: formattedData });
        } else {
            this.setState({ data: [] });
        }
    }

    filterAssets(searchParams) {
        const { actions, orderParams } = this.props;
        actions.filterAssetsRequest({ page: 1, searchParams, orderParams });
        actions.saveAssetSearchParamsRequest(searchParams);
    }

    filterDivisions(searchParams) {
        const { actions, orderParams, divisionOrderParams } = this.props;
        // console.log("searchParams: ", searchParams)
        actions.getDivisionsGridRequest({ page: 1, searchParams, divisionOrderParams });
        actions.saveAssetSearchParamsRequest(searchParams);
    }

    paginationChange(page) {
        const {
            actions, asset, searchParams, orderParams,
        } = this.props;

        if (page !== asset.pagination.currentPage) {
            actions.filterAssetsRequest({ page, searchParams, ...orderParams });
        }
    }

    handleTableChange = (pagination, filters, sorter, extra) => {
        const {
            actions, asset, searchParams, orderParams,
        } = this.props;
        // checking when only order changes
        if (pagination && pagination.current == asset.pagination.currentPage) {
            const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}` };
            // orderParams[sorter.field] = sorter.order ? sorter.order : false;
            this.setState({ orderParams: params });
            actions.saveAssetOrderParamsRequest(params);
            actions.filterAssetsRequest({ page: asset.pagination.currentPage, searchParams, ...params });
        }
    };

    render() {
        return this.view();
    }
}

Assets.propTypes = {
    actions: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    searchParams: PropTypes.object.isRequired,
    orderParams: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        asset: state.asset,
        divisions: state.division.divisions,
        divisionsPagination: state.division.divisionsPagination,
        searchParams: state.asset.assetSearchParams,
        orderParams: state.asset.assetOrderParams,
        user: state.user,
        selectedAssets: state.asset.selectedAssets,
        assetSearchIdParams: state.asset.assetSearchIdParams,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...AssetActions,
                ...DivisionActions,
                ...DeviceActions,
            },
            dispatch,
        ),
    };
}
export const AssetsTest = Assets;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Assets);
