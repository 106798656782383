import React from 'react';

function RoutePlanningWithLiveTrafficSVG() {
    return (
        <svg
            width={64}
            height={64}
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1">
            <g stroke="null">
                <path d="M52 16l-8-8-8 8 2.82 2.84L42 15.66V36h4V15.66l3.16 3.16L52 16zM25.18 45.16L22 48.34V28h-4v20.34l-3.16-3.16L12 48l8 8 8-8-2.82-2.84zM4 4h4v56H4zM56 4h4v56h-4zM30 4h4v8h-4zM30 20h4v8h-4zM30 36h4v8h-4zM30 52h4v8h-4z" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default RoutePlanningWithLiveTrafficSVG;
