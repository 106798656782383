/* global window:true */
/* global document:true */
import React from 'react';
import {
    Spin,
} from 'antd';
import DetailView from '../../components/DetailView';
import './videoView.scss';
import FilterSidebar from '../../components/FilterSidebar';

import Layout from '../../components/layout/Layout';
import Icon from '../../components/elements/Icon';

export default function () {
    const { selectedEvent, isFetching, user } = this.props;
    const { sliderValue } = this.state;
    return (
        <Layout
            data-test="pages-video"
            className="video-page"
            title={<div>Video</div>}
            headerTitle={<div>Video Page</div>}
            menuPlacement="right"
            showFooter={false}>
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar registerCloseSidebar={(f) => {}}>{null}</FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <Spin spinning={isFetching}>
                                    <DetailView
                                        shareReportType="video"
                                        selectedEvent={selectedEvent}
                                        sliderValue={sliderValue}
                                        onForward={this.requestEventInfo}
                                        onRewind={this.requestEventInfo}
                                        onIncreaseOptionClick={this.requestEventInfo}
                                        onReset={this.requestEventInfo}
                                        showStreamingSectionOnLoad />
                                </Spin>

                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
