/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { DatePicker, Input, Select, InputNumber, notification } from 'antd';
import { isEmpty, isNull } from 'underscore';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import * as assetActions from '../../../core/asset/assetActions';
import { Erase, Calendar } from '@carbon/icons-react';

const { Option } = Select;
const FormItem = Form.Item;

let make = null;
let model = null;
class AssetUpdateForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { actions, initialValues } = this.props;
        if (initialValues && initialValues.make_id) {
            actions.getVehicleMakeModelsRequest({ make_id: initialValues.make_id });
        }
    }

    handleMakeChange = (value) => {
        const { actions } = this.props;
        make = parseInt(value, 10);
        model = null;
        if (value) {
            actions.getVehicleMakeModelsRequest({ make_id: value });
        }
    };

    handleModelChange = (value) => {
        model = parseInt(value, 10);
    };

    saveAsset = (e) => {
        const {
            form, actions, updateForm, initialValues,
        } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (!err) {
                values.id = initialValues.id;

                // If no division is selected, Just remove it from the request
                if (!values.division_id) {
                    values.division_id = null;
                }

                // Format the Date last serviced field
                if (values.lastService) {
                    values.lastService = values.lastService.format('YYYY-MM-DD');
                }

                actions.updateAssetRequest(values);
                // this.setState({ make: null });
                make = null;
            }
        });
    };

    render() {
        const {
            form, divisions, vehicleTypes, vehicleMakes, vehicleModels, initialValues,
        } = this.props;
        // const { make } = this.state;
        const { getFieldDecorator } = form;

        if (model == null) {
            initialValues.model_id = null;
        }
        return (
            (<Form id="asset-form" onSubmit={this.saveAsset} layout="vertical">
                <FormItem label="Asset name">
                    {getFieldDecorator('name', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter a name for the asset',
                            },
                            {
                                min: 1,
                                max: 50,
                                message: 'Asset name must be between 1 and 50 characters',
                            },
                        ],
                    })(
                        <Input
                            placeholder="Enter Name" />,
                    )}
                </FormItem>
                <FormItem label="Asset reg">
                    {getFieldDecorator('registration', {
                        rules: [
                            {
                                required: true,
                                message: 'Please enter registration',
                            },
                            {
                                validator: (rule, value, callback) => {
                                    const alphanumericPattern = /^[A-Z0-9\s]*$/i;
                                    if (value && (!alphanumericPattern.test(value) || value.replace(/\s/g, '').length > 7)) {
                                        callback('Registration must be alphanumeric and up to 7 characters (excluding spaces)');
                                    } else {
                                        callback();
                                    }
                                },
                            },
                        ],
                    })(
                        <Input placeholder="Enter Reg" />,
                    )}
                </FormItem>

                <FormItem label="Year vehicle registered">
                    {getFieldDecorator('year_registration', {
                        rules: [
                            {
                                type: 'number',
                                min: 1900,
                                max: moment().year(),
                                message: `Year must be between 1900 and ${moment().year()}`,
                            },
                        ],
                    })(
                        <InputNumber
                            min={1900}
                            max={moment().year()}
                        />,
                    )}
                </FormItem>

                <FormItem label="Asset category">
                    {getFieldDecorator('category', {
                        rules: [
                            {
                                required: false,
                                message: 'Please enter a category for the asset',
                            },
                            {
                                min: 1,
                                max: 50,
                                message: 'Category must be between 1 and 50 characters',
                            },
                        ],
                    })(
                        <Input
                            placeholder="Enter Category" />,
                    )}

                </FormItem>
                <FormItem label="Date last serviced">
                    {getFieldDecorator('lastService', {
                        rules: [
                            {
                                type: 'object',
                                required: false,
                                message: 'Please select the last service date',
                            },
                        ],
                    })(
                        <DatePicker
                            format="DD/MM/YYYY"
                            allowClear={false}
                            suffixIcon={<Calendar />}
                            maxDate={dayjs()}
                        />,
                    )}

                </FormItem>
                <FormItem label="Fuel type">
                    {getFieldDecorator('fuel_type', {
                        rules: [
                            {
                                required: false,
                                message: 'Please select a fuel type',
                            },
                        ],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Fuel Type"
                        >
                            {
                                [
                                    {
                                        name: 'Diesel',
                                        key: 1,
                                    },
                                    {
                                        name: 'Petrol',
                                        key: 2,
                                    },
                                    {
                                        name: 'Electric',
                                        key: 4,
                                    },
                                    {
                                        name: 'Hydrogen',
                                        key: 5,
                                    },
                                    {
                                        name: 'Hybrid',
                                        key: 6,
                                    },
                                ].map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    (<Option key={i} value={v.key}>{v.name}</Option>)
                                ))
                            }

                        </Select>,
                    )}

                </FormItem>
                <FormItem label="Asset make">
                    {getFieldDecorator('make_id', {
                        rules: [
                            {
                                required: false,
                                message: 'Please select an asset make',
                            },
                        ],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            onChange={this.handleMakeChange}
                            placeholder="Select Asset Make"
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            {vehicleMakes.map((v, i) => (
                                /* eslint-disable-next-line react/no-array-index-key */
                                (<Option key={i} value={v.id}>{v.title}</Option>)
                            ))}
                        </Select>,
                    )}

                </FormItem>
                <FormItem label="Asset model">
                    {getFieldDecorator('model_id', {
                        rules: [
                            {
                                required: false,
                                message: 'Please select an asset model',
                            },
                        ],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            onChange={this.handleModelChange}
                            placeholder="Select Asset Model"
                            showSearch
                            allowClear={<Erase size={12} />}
                        >
                            {vehicleModels.map((v, i) => (
                                /* eslint-disable-next-line react/no-array-index-key */
                                (<Option key={i} value={v.id}>{v.title}</Option>)
                            ))}
                        </Select>,
                    )}
                </FormItem>
                <FormItem label="Division">
                    {getFieldDecorator('division_id', {
                        rules: [
                            {
                                required: false,
                                message: 'Please select a division',
                            },
                        ],
                    })(
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select Division"
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            {
                                divisions.map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    (<Option key={`${i}`} value={v.id}>{v.name}</Option>)
                                ))
                            }

                        </Select>,
                    )}
                </FormItem>
                <FormItem label="Vehicle Type">
                    {getFieldDecorator('vehicle_type_id', {
                        rules: [
                            {
                                required: false,
                                message: 'Please select a vehicle type',
                            },
                        ],
                    })(
                        <Select
                            placeholder="Select Vehicle Type"
                            style={{ width: '100%' }}
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            {
                                vehicleTypes.map((v, i) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    (<Option key={`${i}`} value={v.id}>{v.title}</Option>)
                                ))
                            }

                        </Select>,
                    )}
                </FormItem>
            </Form>)
        );
    }
}

const WrappedAssetForm = Form.create({
    name: 'register',
    mapPropsToFields(props) {
        const initialData = {};

        if (!isEmpty(props.initialValues)) {
            Object.keys(props.initialValues).map((item) => {
                if (item === 'last_service') {
                    if (isNull(props.initialValues[item])) {
                        initialData.lastService = Form.createFormField({
                            value: null,
                        });
                    } else {
                        initialData.lastService = Form.createFormField({
                            value: dayjs(props.initialValues[item]),
                        });
                    }
                } else if (item === 'year_registration') {
                    initialData.year_registration = Form.createFormField({
                        value: parseInt(props.initialValues[item], 10) || moment()
                            .year(),
                    });
                } else if (item === 'fuel_type') {
                    initialData.fuel_type = Form.createFormField({
                        value: props.initialValues[item] !== null ? parseInt(props.initialValues[item], 10) : undefined,
                    });
                }else if (item === 'vehicle_type_id' || item === 'division_id') {
                    const sanitizedValue = isNull(props.initialValues[item]) ? null : parseInt(props.initialValues[item], 10);
                    if (sanitizedValue !== null) {
                        initialData[item] = Form.createFormField({
                            value: sanitizedValue,
                        });
                    }
                } else if (item === 'make_id') {
                    const sanitizedValue = isNull(props.initialValues[item]) ? null : parseInt(props.initialValues[item], 10);
                    const makeValue = make || sanitizedValue;
                    if (makeValue !== null) {
                        initialData[item] = Form.createFormField({
                            value: makeValue,
                        });
                    }
                } else if (item === 'model_id') {
                    const sanitizedValue = isNull(props.initialValues[item]) ? null : parseInt(props.initialValues[item], 10);
                    const modelValue = model || sanitizedValue;
                    if (modelValue !== null) {
                        initialData[item] = Form.createFormField({
                            value: modelValue,
                        });
                    }
                } else {
                    initialData[item] = Form.createFormField({
                        value: props.initialValues[item],
                    });
                }
            });
        }

        return initialData;
    },
})(AssetUpdateForm);

WrappedAssetForm.defaultProps = {
    updateForm: false,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        divisions: state.asset.divisions,
        vehicleTypes: state.asset.vehicleTypes,
        vehicleMakes: state.asset.vehicleMakes,
        vehicleModels: state.asset.vehicleModels,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WrappedAssetForm);
