/* eslint-disable react/no-this-in-sfc */
import React from 'react';

import {
    ResponsiveContainer,
    BarChart,
    XAxis,
    Tooltip,
    Bar,
    YAxis,
    Cell,
} from 'recharts';

import ChartHeader from '../ChartHeader';
import ChartMultiDropDown from '../ChartMultiDropDown';
import './barChart.scss';

import { convertFromSeconds, getRandomColor } from '../../../../core/utils/functions';

export default function () {
    const {
        data,
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        onAllOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        divisions,
        userDivisions,
        drivers,
        assets,
        heightRatio,
        dotOnClick,
        chartKey,
    } = this.props;

    const { timeframe, colors } = this.state;
    let accessors = [];
    let colorOptions = [];
    let unit = [];
    if (data && data[0]) {
        accessors = Object.keys(data[0]).filter((k) => k !== 'x' && k !== 'colors' && k !== 'rules' && k !== 'unit' && k !== 'custom');
        if (data[0].unit) {
            unit = data[0].unit;
        }
        if (data[0].colors) {
            colorOptions = data[0].colors;
        }
    }

    const xAxisAngle = (['hourly', 'monthly'].includes(timeframe) ? -80 : 0);
    let interval = 0;
    // console.log('#############', data, accessors);
    if (timeframe === 'daily') {
        interval = 'preserveStartEnd';
    }

    let widgetHeight = heightRatio * 200;
    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }

    const formatter = (value, name, props) => ((props.unit == 'h:m:s') ? convertFromSeconds(value) : value.toString());
    const randomKey = Math.random().toString(36).substring(7);
    return (
        <div className="new-chart-widget bar-chart-widget" style={{}}>
            <ChartHeader
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData)}
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />
            <div style={{ width: '100%', height: widgetHeight }} id={`modalContent_${chartKey}`}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        margin={{
                            top: 20, right: 30, left: 20, bottom: 10,
                        }}
                        onClick={(payload) => {
                            if (payload && payload.activePayload && payload.activePayload.length > 0) {
                                const event = payload.activePayload[0];
                                const index = data.indexOf(event.payload);

                                dotOnClick(timeframe, index);
                            }
                        }}
                        data={data}>
                        <XAxis dataKey="x" dy={10} angle={xAxisAngle} interval={interval} />
                        {
                            accessors.map((a, i) => {
                                let color = 'rgba(128,128,128,0.5)';
                                if (colorOptions[i]) {
                                    color = colorOptions[i];
                                } else if (colors[i]) {
                                    color = colors[i];
                                } else {
                                    color = getRandomColor();
                                }

                                const orientation = (i % 2) ? 'right' : 'left';

                                return (
                                    <YAxis key={`y-axis-key-${i}`} yAxisId={a} orientation={orientation} stroke={color} />
                                );
                            })
                        }
                        <Tooltip formatter={(value, name, props) => formatter(value, name, props)} />
                        {
                            accessors.map((a, i) => {
                                let color = 'rgba(128,128,128,0.5)';
                                if (colorOptions[i]) {
                                    color = colorOptions[i];
                                } else if (colors[i]) {
                                    color = colors[i];
                                } else {
                                    color = getRandomColor();
                                }

                                let percentage = 0;

                                return (
                                    <defs key={`gradient-key-${i}`}>
                                        <linearGradient id={`gradient-${randomKey}-${i}`} x1="0%" y1="100%" x2="0%" y2="0%">
                                            {
                                                data[0] && data[0].rules && data[0].rules[a]
                                                    ? data[0].rules[a].map((entry, index) => {
                                                        let barColor = color;
                                                        if (entry.color) {
                                                            barColor = entry.color;
                                                        }

                                                        if (entry.percentage || entry.percentage == 0) {
                                                            percentage = entry.percentage;
                                                        }

                                                        return (
                                                            <stop key={`gradient-${randomKey}-rule-${index}`} offset={`${percentage}%`} stopColor={barColor} />
                                                        );
                                                    })

                                                    : (
                                                        <stop offset={`${0}%`} stopColor={color} />
                                                    )
                                            }
                                        </linearGradient>
                                    </defs>
                                );
                            })
                        }
                        {
                            accessors.map((a, i) => {
                                let color = 'rgba(128,128,128,0.5)';
                                if (colorOptions[i]) {
                                    color = colorOptions[i];
                                } else if (colors[i]) {
                                    color = colors[i];
                                } else {
                                    color = getRandomColor();
                                }

                                const stroke = data[0] && data[0].rules ? `url(#gradient-${randomKey}-${i})` : (data[0].colors && data[0].colors[i] ? data[0].colors[i] : color);
                                return (
                                    <Bar key={`y-axis-2-key-${i}`} yAxisId={a} dataKey={a} fill={stroke} unit={unit[a]} />
                                );
                            })
                        }

                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    divisions={divisions}
                    userDivisions={userDivisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
