import React from 'react';

function AdvancedGPSAccuracySVG() {
    return (
        <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
            <path
                stroke="null"
                d="M55 30.357h-3.37A19.726 19.726 0 0033.644 12.37V9h-3.286v3.37A19.726 19.726 0 0012.37 30.356H9v3.286h3.37A19.726 19.726 0 0030.356 51.63V55h3.286v-3.37A19.726 19.726 0 0051.63 33.644H55v-3.286zM33.643 48.345v-6.488h-3.286v6.488a16.456 16.456 0 01-14.702-14.702h6.488v-3.286h-6.488a16.456 16.456 0 0114.702-14.702v6.488h3.286v-6.488a16.456 16.456 0 0114.702 14.702h-6.488v3.286h6.488a16.456 16.456 0 01-14.702 14.702z"
                className="prefix__cls-1" />
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default AdvancedGPSAccuracySVG;
