/* eslint-disable react/prop-types */
/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Col,
    Row,
} from 'antd';
import Layout from '../../components/layout/Layout';
import Avatar from '../../components/elements/Avatar';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import UserCreateForm from './forms/UserCreateForm';


export default function () {
    const { actions, user } = this.props;

    return (
        <Layout
            data-test="pages-user-create"
            className="assets"
            title={<div>Assets</div>}
            headerTitle={<div>Assets Page</div>}
            showFooter={false}>
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar />
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <div 
                                    className="section__action-bar justify-center"
                                    style={{ marginBottom: '16px' }}
                                >
                                    <span className="asset-name-label">New User</span>
                                </div>
                                <Row
                                    gutter={40}
                                    type="flex"
                                    justify="space-between"
                                    className="data-row">
                                    <Col xl={6}>
                                        <UserCreateForm />
                                    </Col>

                                    <Col xl={11}>
                                        <div className="actions">
                                            <Row
                                                type="flex"
                                                gutter={40}
                                                justify="space-between">
                                                <Col>
                                                    <br />
                                                    <Avatar onChange={(data) => actions.uploadNewUserPictureRequest({ image: data })} />
                                                </Col>
                                                <Col>
                                                    <div className="actions__buttons">
                                                        <Button
                                                            form="asset-form"
                                                            htmlType="submit"
                                                            type="primary"
                                                            className="page-header-button"
                                                            style={{ top: -48, right: 0 }}
                                                        >
                                                            Create
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
