import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import defaultStyles from './styles';
import TimeRangeContext from '../../context';

export function SliderRail({ getRailProps }) {
    const { styles } = useContext(TimeRangeContext);

    return (
        <>
            <div
                className="railOuter"
                style={{ ...defaultStyles.railOuter, ...styles.railOuter }}
                {...getRailProps()} />
            <div
                className="railInner"
                style={{ ...defaultStyles.railInner, ...styles.railInner }} />
        </>
    );
}

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired,
};
SliderRail.defaultProps = {
};

export default SliderRail;
