/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Spin, Row, Col, Card, Tabs,
} from 'antd';
import moment from 'moment';
import {
    Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip as ToolTipChart,
} from 'recharts';
import Map from '../../components/Map';
import { isNull } from 'underscore';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import SingleJourneyTab from './SingleJourneyTab';
import ProgressCircle from '../../components/elements/ProgressCircle';
import journeyScorePNG from '../../assets/images/journey-score.png';
import journeyTimePNG from '../../assets/images/clock.png';
import journeySpeedPNG from '../../assets/images/speed.png';
import averageMpgPNG from '../../assets/images/average-mpg.png';
import cyclearLogoPNG from '../../assets/images/cyclear-logo.png';
import idClearLogoJPG from '../../assets/images/id-clear-no-trademark.jpg';
import reclearLogoPNG from '../../assets/images/reclear-logo.png';
import { secondsToHms } from '../../core/utils/functions';

import './journeysView.scss';

const { GOOGLE_MAP_API_KEY } = require('../../core/constants').default;
const { TabPane } = Tabs;

export default function () {
    const {
        selectedJourneys, isFetching, journeyIds, mapSelectedDeviceLocations, uomPreference, user,
    } = this.props;
    const { centerMap, currentTabKey } = this.state;
    
    const trimmedPath = mapSelectedDeviceLocations && mapSelectedDeviceLocations.length ? mapSelectedDeviceLocations.map((location, i, arr) => {
        const tempLocation = {};
        tempLocation.lat = location.lat;
        tempLocation.lng = location.lng;
        if (i === 0) tempLocation.position = "end";
        else if (i == (arr.length - 1)) tempLocation.position = "start";
        else tempLocation.position = "path";
        return tempLocation;
    }) : [];

    // Calculate combined stats (averaged/totalled/earliest/latest)
    let earliestJourney = null;
    let latestJourney = null;
    let startArea = '';
    let endArea = '';
    let journeyStart = '';
    let journeyEnd = '';
    let score = 0;
    let driverScore = 0;
    let events = 0;
    let timeDriven = 0;
    let averageSpeed = 0;
    let idleTime = 0;
    let trueIdleTime = 0;
    let averageMpg = 0;
    let mpgSuffix = uomPreference === 'british-imperial' ? 'UK mpg' : 'L/100km';
    if (selectedJourneys && selectedJourneys[0]) {
        earliestJourney = selectedJourneys[0];
        latestJourney = selectedJourneys[0];
        let scoreTotal = 0;
        let driverScoreTotal = 0;
        selectedJourneys.forEach(sj => {
            if (sj.startInterval < earliestJourney.startInterval) earliestJourney = sj;
            if (sj.endInterval > latestJourney.endInterval) latestJourney = sj;
            scoreTotal += parseInt(sj.info.score);
            driverScoreTotal += parseInt(sj.driverAverageScore);
            events += parseInt(sj.info.events);
            timeDriven += parseInt(sj.info.time_driven);
            idleTime += parseInt(sj.info.idle_time);
            trueIdleTime += parseInt(sj.info.true_idle_time);
            if (sj?.info?.mpg) {
                if (typeof sj.info.mpg === 'number') {
                    averageMpg += sj.info.mpg;
                } else {
                    averageMpg += parseInt(sj.info.mpg.slice(0, sj.info.mpg.indexOf(mpgSuffix)));
                }
            }
        });
        startArea = earliestJourney.info.start_area;
        endArea = latestJourney.info.end_area;
        journeyStart = earliestJourney.info.start;
        journeyEnd = latestJourney.info.end;
        score = parseInt(scoreTotal / selectedJourneys.length);
        driverScore = parseInt(driverScoreTotal / selectedJourneys.length);
        timeDriven = secondsToHms(timeDriven);
        averageMpg = parseInt(averageMpg / selectedJourneys.length);
        averageMpg = `${averageMpg} ${mpgSuffix}`;
    }

    let profile_picture = '';
    if (selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info) {
        profile_picture = (
            <img
                className='driver-info-image'
                style={{ width: '100%', objectFit: 'cover' }} 
                alt={selectedJourneys[0].info.driver || '-'}
                src={selectedJourneys[0].info.profile_picture || user?.userCompany?.company_image} 
            />
        );
    }
    let asset_picture = '';
    if (selectedJourneys && selectedJourneys[0] && selectedJourneys[0].info) {
        asset_picture = (
            <img
                className="driver-info-image"
                style={{ width: '100%', objectFit: 'cover' }} 
                alt={selectedJourneys[0].info.registration || '-'}
                src={selectedJourneys[0].info.asset_picture || user?.userCompany?.company_image} 
            />
        );
    }
    
    return (
        <Layout
            data-test="pages-journey-view"
            className="events-view"
            title={<div>Journeys View</div>}
            headerTitle={<div>Journeys View</div>}
            showFooter={false}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <div className="content">
                    <Spin spinning={isFetching || isNull(selectedJourneys) || !trimmedPath.length}>
                        <div className="content-inner">
                            <section 
                                className="section"
                                style={{ height: 'calc(100vh - 113.5px)' }}
                            >
                                <br />
                                <br />
                                <Tabs
                                    onTabClick={this.handleTabChange}
                                    defaultActiveKey={currentTabKey}
                                    activeKey={currentTabKey}
                                    animated={false}
                                    className="tabs-restyled"
                                >
                                    <TabPane 
                                        tab="Journeys Summary" 
                                        key="1"
                                    >
                                        <div className="section__body">
                                            <div className='video-detail-view'>
                                                <Row
                                                    gutter={40}
                                                    type="flex"
                                                    justify="space-between"
                                                    className="data-row map-row"
                                                >
                                                    <Col 
                                                        xs={24} 
                                                        style={{ 
                                                            height: 350, 
                                                            width: '100%', 
                                                        }}
                                                    >
                                                        <br />
                                                        <Map
                                                            ref={this.googleMapAccess}
                                                            mapDate={moment().subtract('1', 'days').format('DD/MM/YYYY')}
                                                            apiKey={GOOGLE_MAP_API_KEY}
                                                            showToolbar={false}
                                                            sliderStep={1}
                                                            sliderValue={0}
                                                            sliderMinValue={0}
                                                            sliderMaxValue={0}
                                                            sliderValueUnit="seconds"
                                                            hideDatePicker
                                                            center={centerMap}
                                                            onSliderChange={() => {}}
                                                            allowStreetView
                                                            showFindCenterButton={false}
                                                            path={trimmedPath}
                                                        />
                                                        <br />
                                                        <Row 
                                                            gutter={[16, 16]} 
                                                            className="asset-info-row" 
                                                            ref={this.pdfDocumentRef}
                                                        >
                                                            <Col 
                                                                xs={12} 
                                                                style={{ verticalAlign: 'top' }}
                                                            >
                                                                <div className="video-view-block-title">Asset & Driver Information</div>
                                                                <Row 
                                                                    gutter={[32, 32]} 
                                                                    style={{ minHeight: 120 }}
                                                                >
                                                                    <Col span={12}>
                                                                        {profile_picture}
                                                                        <div className="title">
                                                                            <p>
                                                                                {selectedJourneys && selectedJourneys[0]?.info?.user_id ? (
                                                                                    <Link to={`/drivers/view/${selectedJourneys[0].info.user_id}`}>
                                                                                        {selectedJourneys[0].info.driver || '-'}
                                                                                    </Link>
                                                                                ) : (
                                                                                    selectedJourneys && selectedJourneys[0]?.info?.driver || '-'
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        {asset_picture}
                                                                        <div className="title">
                                                                            {selectedJourneys && selectedJourneys[0]?.info?.asset_id ? (
                                                                                <Link to={`/assets/view/${selectedJourneys[0].info.asset_id}`}>
                                                                                    {selectedJourneys[0].info.registration || '-'}
                                                                                </Link>
                                                                            ) : (
                                                                                selectedJourneys && selectedJourneys[0]?.info?.registration || '-'
                                                                            )}
                                                                        </div>
                                                                        <div className='mt-1'>
                                                                            <span style={{
                                                                                fontSize: '12px',
                                                                                color: 'var(--cool-gray-60)',
                                                                                fontWeight: 600,
                                                                            }}>
                                                                                Last service:
                                                                            </span>
                                                                            &ensp;
                                                                            {selectedJourneys && selectedJourneys[0]?.info?.last_service || '-'}
                                                                        </div>
                                                                        <div className='mt-1'>
                                                                            <span style={{
                                                                                fontSize: '12px',
                                                                                color: 'var(--cool-gray-60)',
                                                                                fontWeight: 600,
                                                                            }}>
                                                                                Registration:
                                                                            </span>
                                                                            &ensp;
                                                                            {selectedJourneys && selectedJourneys[0]?.info?.registration || '-'}
                                                                        </div>
                                                                        <br />
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col 
                                                                xs={12} 
                                                                style={{ verticalAlign: 'top' }}
                                                            >
                                                                <div className="video-view-block-title">Journeys Summary</div>
                                                                <Row className="journey-info-row">
                                                                    <Col xs={12}>
                                                                        <strong>Start Time:</strong>
                                                                        &ensp;
                                                                        {journeyStart || '-'}
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <strong>End Time:</strong>
                                                                        &ensp;
                                                                        {journeyEnd || '-'}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="journey-info-row">
                                                                    <Col xs={12}>
                                                                        <strong>Start Area:</strong>
                                                                        &ensp;
                                                                        {startArea || '-'}
                                                                    </Col>
                                                                    <Col 
                                                                        xs={12} 
                                                                        style={{ wordBreak: 'break-all' }}
                                                                    >   
                                                                        <strong>End Area:</strong>
                                                                        &ensp;
                                                                        {endArea || '-'}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="journey-info-row">
                                                                    <Col xs={12}>
                                                                        <strong>Avg. Journey Score:</strong>
                                                                        &ensp;
                                                                        {score || '-'}
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <strong>Events:</strong>
                                                                        &ensp;
                                                                        {events}
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </TabPane>
                                    {selectedJourneys && selectedJourneys.length && selectedJourneys.map((sj, i) => {
                                        return (
                                            <TabPane 
                                                tab={`${sj.info.start.slice(sj.info.start.indexOf(' ') + 1)} - ${sj.info.end.slice(sj.info.end.indexOf(' ') + 1)}` }
                                                key={`${i + 2}`}
                                            >
                                                <SingleJourneyTab 
                                                    journeyId={sj.info.id} 
                                                    currentTabKey={currentTabKey} 
                                                    tabKey={`${i + 2}`}
                                                />
                                            </TabPane>
                                        );
                                    })}
                                </Tabs>
                            </section>
                        </div>
                    </Spin>
                </div>
            </div>
        </Layout>
    );
}
