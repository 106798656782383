function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
      + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
      * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}

function getMinumumDistanceBetweenArrowsFromZoom(zoom) {
    let distance = 40;
    if (zoom >= 6 && zoom <= 7) {
        distance = 20;
    } else if (zoom <= 8) {
        distance = 15;
    } else if (zoom <= 9) {
        distance = 10;
    } else if (zoom <= 10) {
        distance = 6;
    } else if (zoom <= 11) {
        distance = 3;
    } else if (zoom <= 12) {
        distance = 1.5;
    } else if (zoom <= 14) {
        distance = 1;
    } else {
        distance = 0.5;
    }
    return distance;
}

function getSplitNumberByZoom(zoom) {
    let splitNumber = 350;
    if (zoom >= 6 && zoom <= 7) {
        splitNumber = 250;
    } else if (zoom <= 8) {
        splitNumber = 180;
    } else if (zoom <= 9) {
        splitNumber = 120;
    } else if (zoom <= 10) {
        splitNumber = 60;
    } else if (zoom <= 11) {
        splitNumber = 30;
    } else if (zoom <= 12) {
        splitNumber = 20;
    } else if (zoom <= 16) {
        splitNumber = 10;
    } else {
        splitNumber = 5;
    }

    return splitNumber;
}

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}

export { getDistanceFromLatLonInKm, getSplitNumberByZoom, getMinumumDistanceBetweenArrowsFromZoom };
