import { all } from 'redux-saga/effects';
import userSaga from './user/userSaga';
import deviceSaga from './device/deviceSaga';
import eventsSaga from './events/eventsSaga';
import assetSaga from './asset/assetSaga';
import dashboardSaga from './dashboard/dashboardSaga';
import driverSaga from './driver/driverSaga';
import videoSaga from './video/videoSaga';
import comparisonSaga from './comparison/comparisonSaga';
import departmentSaga from './department/departmentSaga';
import divisionSaga from './division/divisionSaga';
import reportSaga from './report/reportSaga';
import smartWidgetSaga from './smartWidget/smartWidgetSaga';
import companySaga from './company/companySaga';
import shardLogSaga from './shardLog/shardLogSaga';
import recordsSaga from './records/recordsSaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        userSaga(),
        deviceSaga(),
        eventsSaga(),
        assetSaga(),
        dashboardSaga(),
        driverSaga(),
        videoSaga(),
        comparisonSaga(),
        departmentSaga(),
        divisionSaga(),
        reportSaga(),
        smartWidgetSaga(),
        companySaga(),
        shardLogSaga(),
        recordsSaga(),
    ]);
}
