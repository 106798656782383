/* global: localStorage */
import {
    call,
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';
import { message, notification } from 'antd';

import history from '../utils/history';
import { fetchApiAuth } from '../utils/api';

import {
    getDepartmentsGridSuccess,
    getDepartmentsGridFailure,

    getDepartmentUsersGridSuccess,
    getDepartmentUsersGridFailure,

    getDepartmentDetailsSuccess,
    getDepartmentDetailsFailure,

    assignUserSuccess,
    assignUserFailure,

    unassignUserSuccess,
    unassignUserFailure,

    updateDepartmentSuccess,
    updateDepartmentFailure,

    getOtherUsersSuccess,
    getOtherUsersFailure,

    createDepartmentSuccess,
    createDepartmentFailure,

    filterDepartmentsSuccess,
    filterDepartmentsFailure,

} from './departmentActions';

const {
    GET_DEPARTMENTS_GRID_REQUEST,
    GET_DEPARTMENT_USERS_GRID_REQUEST,
    GET_DEPARTMENT_DETAILS_REQUEST,
    ASSIGN_USER_REQUEST,
    UNASSIGN_USER_REQUEST,
    UPDATE_DEPARTMENT_REQUEST,
    GET_OTHER_USERS_REQUEST,
    CREATE_DEPARTMENT_REQUEST,
    FILTER_DEPARTMENT_REQUEST,
} = require('./departmentActions').constants;

function* getDepartmentsGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/department/get-departments',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getDepartmentsGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDepartmentsGridFailure(e));
    }
}

function* getDepartmentUsersGrid(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/department/get-users',
            params: {
                page: ((actions.payload && actions.payload.page) || 1),
            },
            body: actions.payload,
        });

        yield put(getDepartmentUsersGridSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDepartmentUsersGridFailure(e));
    }
}

function* getDepartmentDetails(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/department/get-info',
            body: actions.payload,
        });

        yield put(getDepartmentDetailsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDepartmentDetailsFailure(e));
    }
}

function* assignUser(actions) {
    try {
        yield call(fetchApiAuth, {
            method: 'POST',
            url: '/department/assign-user',
            body: actions.payload,
        });

        notification.success({ message: 'Success', description: 'Succesfully added user!' });
        yield put(assignUserSuccess(actions.payload));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(assignUserFailure(e));
    }
}

function* unassignUser(actions) {
    try {
        yield call(fetchApiAuth, {
            method: 'POST',
            url: '/department/unassign-user',
            body: actions.payload,
        });

        yield put(unassignUserSuccess(actions.payload));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(unassignUserFailure(e));
    }
}

function* updateDepartment(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'department/update-department',
            body: actions.payload,
        });

        notification.success({ message: 'Success', description: 'Succesfully updated department!' });
        yield put(updateDepartmentSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updateDepartmentFailure(e));
    }
}

function* getOtherUsers(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'department/get-other-users',
            body: actions.payload,
        });

        yield put(getOtherUsersSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getOtherUsersFailure(e));
    }
}

function* createDepartment(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'department/create-department',
            body: actions.payload,
        });

        history.push(`/department/view/${response.data.id}`);
        yield put(createDepartmentSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(createDepartmentFailure(e));
    }
}

function* filterDepartments(action) {
    try {
        const url = action.payload && action.payload.page ? `asset/filter-assets?page=${action.payload.page}` : 'asset/filter-assets';
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url,
            body: action.payload,
        });

        yield put(filterDepartmentsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(filterDepartmentsFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* assetSaga() {
    yield all([
        takeEvery(GET_DEPARTMENTS_GRID_REQUEST, getDepartmentsGrid),
        takeEvery(GET_DEPARTMENT_USERS_GRID_REQUEST, getDepartmentUsersGrid),
        takeEvery(GET_DEPARTMENT_DETAILS_REQUEST, getDepartmentDetails),
        takeEvery(ASSIGN_USER_REQUEST, assignUser),
        takeEvery(UNASSIGN_USER_REQUEST, unassignUser),
        takeEvery(UPDATE_DEPARTMENT_REQUEST, updateDepartment),
        takeEvery(GET_OTHER_USERS_REQUEST, getOtherUsers),
        takeEvery(CREATE_DEPARTMENT_REQUEST, createDepartment),
        takeEvery(FILTER_DEPARTMENT_REQUEST, filterDepartments),
    ]);
}
