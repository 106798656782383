function findAndReplace(searchText, replacement, searchNode) {
    if (!searchText || typeof replacement === 'undefined') {
    // Throw error here if you want...
        return;
    }
    const regex = typeof searchText === 'string'
        ? new RegExp(searchText, 'g') : searchText;

    const childNodes = searchNode && searchNode.children ? searchNode.children : [];
    let cnLength = childNodes.length;
    while (cnLength--) {
        const currentNode = childNodes[cnLength];
        if (currentNode.children) {
            return findAndReplace(searchText, replacement, currentNode);
        }
        if (typeof currentNode.data == 'undefined') {
            continue;
        }
        if (typeof replacement == 'string' && typeof currentNode.data == 'string') {
            currentNode.data = currentNode.data.replace(regex, replacement);
            return currentNode;
        }
        if (currentNode.data.search(searchText) !== -1) {
            currentNode.data = replacement;
            return currentNode;
        }
    }
}

export {
    findAndReplace,
};
