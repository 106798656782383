import {
    call, put, takeEvery, all,
} from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import { getListCompaniesSuccess, getListCompaniesFailure } from './companyActions';

const { GET_LIST_COMPANIES_REQUEST } = require('./companyActions').constants;

function* getCompany() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/company/list',
        });

        yield put(getListCompaniesSuccess(response?.data || []));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getListCompaniesFailure(e.response ? e.response.data.message : e));
    }
}

export default function* ListCompaniesSaga() {
    yield all([
        takeEvery(GET_LIST_COMPANIES_REQUEST, getCompany),
    ]);
}
