import { Record } from 'immutable';

const {
    GET_COMPANY_EVENTS_REQUEST,
    GET_COMPANY_EVENTS_SUCCESS,
    GET_COMPANY_EVENTS_FAILURE,
    RESET_COMPANY_EVENTS,

    GET_EVENTS_REQUEST,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_FAILURE,

    UPDATE_COMPANY_EVENTS_REQUEST,
    UPDATE_COMPANY_EVENTS_SUCCESS,
    UPDATE_COMPANY_EVENTS_FAILURE,

    GET_ALL_TRIGGERS_REQUEST,
    GET_ALL_TRIGGERS_SUCCESS,
    GET_ALL_TRIGGERS_FAILURE,

    GET_USER_DIVISIONS_REQUEST,
    GET_USER_DIVISIONS_SUCCESS,
    GET_USER_DIVISIONS_FAILURE,

    GET_SELECTED_EVENT_TRIGGER_REQUEST,
    GET_SELECTED_EVENT_TRIGGER_SUCCESS,
    GET_SELECTED_EVENT_TRIGGER_FAILURE,

    GET_SELECTED_TRIGGER_EVENTS_REQUEST,
    GET_SELECTED_TRIGGER_EVENTS_SUCCESS,
    GET_SELECTED_TRIGGER_EVENTS_FAILURE,

    DELETE_TRIGGER_REQUEST,
    DELETE_TRIGGER_SUCCESS,
    DELETE_TRIGGER_FAILURE,

    GET_EVENT_INFO_REQUEST,
    GET_EVENT_INFO_SUCCESS,
    GET_EVENT_INFO_FAILURE,

    RESET_EVENT_INFO_REQUEST,

    GET_EVENT_INFO_BY_VIDEO_REQUEST,
    GET_EVENT_INFO_BY_VIDEO_SUCCESS,
    GET_EVENT_INFO_BY_VIDEO_FAILURE,

    GET_EVENT_INFO_BY_VIDEO_REFERENCE_REQUEST,
    GET_EVENT_INFO_BY_VIDEO_REFERENCE_SUCCESS,
    GET_EVENT_INFO_BY_VIDEO_REFERENCE_FAILURE,

    SHARE_WEB_REPORT_REQUEST,
    SHARE_WEB_REPORT_SUCCESS,
    SHARE_WEB_REPORT_FAILURE,

    VERIFY_REPORT_CODE_REQUEST,
    VERIFY_REPORT_CODE_SUCCESS,
    VERIFY_REPORT_CODE_FAILURE,

    DOWNLOAD_PDF_REPORT_REQUEST,
    DOWNLOAD_PDF_REPORT_SUCCESS,
    DOWNLOAD_PDF_REPORT_FAILURE,

    UPDATE_TRIGGER_REQUEST,
    UPDATE_TRIGGER_SUCCESS,
    UPDATE_TRIGGER_FAILURE,

    RESET_JOURNEY_INFO_REQUEST,
    GET_JOURNEY_INFO_REQUEST,
    GET_JOURNEY_INFO_SUCCESS,
    GET_JOURNEY_INFO_FAILURE,

    RESET_JOURNEYS_INFO_REQUEST,
    GET_JOURNEYS_INFO_REQUEST,
    GET_JOURNEYS_INFO_SUCCESS,
    GET_JOURNEYS_INFO_FAILURE,

    RESET_JOURNEYS_TO_REQUEST,
    SET_JOURNEYS_TO_REQUEST,

    GET_EVENTS_CSV_REQUEST,
    GET_EVENTS_CSV_SUCCESS,
    GET_EVENTS_CSV_FAILURE,

    RENEW_SHARED_REPORT_REQUEST,
    RENEW_SHARED_REPORT_SUCCESS,
    RENEW_SHARED_REPORT_FAILURE,

    SAVE_EVENTS_SEARCH_PARAMS_REQUEST,
    RESET_UPDATE_MAP_EVENT_DATA_FLAG_REQUEST,
    SET_SELECTED_TRIGGER_FETCHED,

    GET_SIDE_EVENTS_REQUEST,
    GET_SIDE_EVENTS_SUCCESS,
    GET_SIDE_EVENTS_FAILURE,

    SET_EVENT_DEVICE_OFFLINE,

} = require('./eventsActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    companyEvents: [],
    events: [],
    eventsSearchParams: {},
    eventsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    triggers: [],
    triggersPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    divisions: [],
    selectedTrigger: {},
    selectedTriggerFetched: false,
    selectedTriggerEvents: [],
    selectedTriggerEventsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    selectedEvent: null,
    report: null,
    selectedJourney: null,
    selectedJourneys: null,
    journeysToRequest: null,
    updateMapEventData: false,
    eventJourneyFetched: false,
    eventJourneysFetched: false,
    eventInfoFetched: false,
    sideEvents: [],
});

const eventInitialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function eventsReducer(state = eventInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = eventInitialState.mergeDeep(state);
    switch (type) {

        case RESET_COMPANY_EVENTS:
            return state
                .set('companyEvents', []);

    case RESET_JOURNEYS_TO_REQUEST:
        return state
            .set('journeysToRequest', null);
    case SET_JOURNEYS_TO_REQUEST:
        return state
            .set('journeysToRequest', payload);

        // case GET_COMPANY_EVENTS_REQUEST:
    case GET_EVENTS_REQUEST:
    case GET_ALL_TRIGGERS_REQUEST:
    case GET_USER_DIVISIONS_REQUEST:
    case GET_SELECTED_EVENT_TRIGGER_REQUEST:
    case GET_SELECTED_TRIGGER_EVENTS_REQUEST:
    case DELETE_TRIGGER_REQUEST:
    case SHARE_WEB_REPORT_REQUEST:
    case VERIFY_REPORT_CODE_REQUEST:
    case DOWNLOAD_PDF_REPORT_REQUEST:
    case UPDATE_TRIGGER_REQUEST:
    case GET_JOURNEY_INFO_REQUEST:
    case GET_EVENTS_CSV_REQUEST:
    case RENEW_SHARED_REPORT_REQUEST:
        return state
            .set('isFetching', true)
            .set('selectedEvent', null)
            .set('selectedJourney', null)
            .set('eventJourneyFetched', false)
            .set('report', null);

    case GET_JOURNEYS_INFO_REQUEST:
        return state
            .set('isFetching', true)
            .set('selectedEvent', null)
            .set('selectedJourneys', null)
            .set('eventJourneysFetched', false)
            .set('report', null);

    case GET_SIDE_EVENTS_REQUEST:
        return state
            .set('isFetching', true);

        case GET_EVENT_INFO_REQUEST:
        case GET_EVENT_INFO_BY_VIDEO_REQUEST:
        case GET_EVENT_INFO_BY_VIDEO_REFERENCE_REQUEST:
            return state
                .set('selectedEvent', null)
                .set('selectedJourney', null)
                .set('updateMapEventData', false)
                .set('eventInfoFetched', false)
                .set('isFetching', true);
        case UPDATE_COMPANY_EVENTS_REQUEST:
            return state.set('isFetching', true);
        case DELETE_TRIGGER_SUCCESS:
            return state.set('isFetching', false);
        case DOWNLOAD_PDF_REPORT_SUCCESS:
            return state.set('isFetching', false);
        case GET_USER_DIVISIONS_SUCCESS:
            return state.set('isFetching', false)
                .set('divisions', payload.data);
            case GET_COMPANY_EVENTS_REQUEST:
                return state.set('isFetching', true);
        case GET_COMPANY_EVENTS_SUCCESS:
            return state
                .set('isFetching', false)
                .set('companyEvents', payload.data);
        case UPDATE_COMPANY_EVENTS_SUCCESS:
            return state
                .set('companyEvents', payload.data)
                .set('isFetching', false);
        case GET_EVENTS_SUCCESS: {
            const {
                page, count, pageCount, perPage, data,
            } = payload;
            const eventsPagination = {};

        eventsPagination.currentPage = page || 1;
        eventsPagination.totalRecords = count || 0;
        eventsPagination.pageCount = pageCount || 0;
        eventsPagination.perPageCount = perPage || 20;

            return state
                .set('isFetching', false)
                .set('eventsPagination', eventsPagination)
                .set('events', data || []);
        }
        case GET_ALL_TRIGGERS_SUCCESS: {
            const triggersHeaders = payload.headers;
            const triggersPagination = {};
            triggersPagination.currentPage = triggersHeaders['x-pagination-current-page'] || 1;
            triggersPagination.totalRecords = triggersHeaders['x-pagination-total-count'] || 0;
            triggersPagination.pageCount = triggersHeaders['x-pagination-page-count'] || 0;
            triggersPagination.perPageCount = triggersHeaders['x-pagination-per-page'] || 20;
            return state
                .set('isFetching', false)
                .set('triggersPagination', triggersPagination)
                .set('triggers', payload.data);
        }
        case GET_SELECTED_EVENT_TRIGGER_SUCCESS:
        case UPDATE_TRIGGER_SUCCESS:
            const { triggers } = state;

            const triggerIndex = triggers.findIndex((val) => val.id == payload.data.id);
            if (triggerIndex !== -1) {
                triggers[triggerIndex] = payload.data;
            }
            return state.set('isFetching', false)
                .set('triggers', triggers)
                .set('selectedTriggerFetched', true)
                .set('selectedTrigger', payload.data);
        case SET_SELECTED_TRIGGER_FETCHED:
            return state.set('selectedTriggerFetched', false);
        case GET_SELECTED_TRIGGER_EVENTS_SUCCESS: {
            const triggerEventsHeaders = payload.headers;
            const triggerEventsPagination = {};
            triggerEventsPagination.currentPage = triggerEventsHeaders['x-pagination-current-page'] || 1;
            triggerEventsPagination.totalRecords = triggerEventsHeaders['x-pagination-total-count'] || 0;
            triggerEventsPagination.pageCount = triggerEventsHeaders['x-pagination-page-count'] || 0;
            triggerEventsPagination.perPageCount = triggerEventsHeaders['x-pagination-per-page'] || 20;
            return state
                .set('isFetching', false)
                .set('selectedTriggerEventsPagination', triggerEventsPagination)
                .set('selectedTriggerEvents', payload.data);
        }
        case GET_EVENT_INFO_SUCCESS:
        case GET_EVENT_INFO_BY_VIDEO_SUCCESS:
        case GET_EVENT_INFO_BY_VIDEO_REFERENCE_SUCCESS: {
            let sideEvents = [];
            if (payload.data.sideEvents) {
                sideEvents = payload.data.sideEvents;
            }
            return state
                .set('updateMapEventData', true)
                .set('isFetching', false)
                .set('selectedEvent', payload.data)
                .set('eventInfoFetched', true)
                .set('sideEvents', sideEvents);
        }
        case SHARE_WEB_REPORT_SUCCESS:
            return state
                .set('isFetching', false)
                .set('selectedEvent', payload.data);

    case RESET_JOURNEY_INFO_REQUEST:
        return state
            .set('selectedJourney', null);

    case RESET_JOURNEYS_INFO_REQUEST:
        return state
            .set('selectedJourneys', null);

    case RESET_EVENT_INFO_REQUEST:
        return state
            .set('eventInfoFetched', false);
    case GET_JOURNEY_INFO_SUCCESS:
        return state
            .set('isFetching', false)
            .set('eventJourneyFetched', true)
            .set('selectedJourney', payload.data);
    case GET_JOURNEYS_INFO_SUCCESS:
        return state
            .set('isFetching', false)
            .set('eventJourneysFetched', true)
            .set('selectedJourneys', payload.data);
    case VERIFY_REPORT_CODE_SUCCESS:
        return state
            .set('isFetching', false)
            .set('report', payload.data);

    case GET_EVENTS_CSV_SUCCESS:
        return state.set('isFetching', false);

    case RENEW_SHARED_REPORT_SUCCESS:
        return state.set('selectedEvent', payload.data)
            .set('isFetching', false);

    case SAVE_EVENTS_SEARCH_PARAMS_REQUEST: {
        return state.set('eventsSearchParams', payload);
    }
    case RESET_UPDATE_MAP_EVENT_DATA_FLAG_REQUEST:
        return state.set('updateMapEventData', false)
            .set('eventJourneyFetched', false);

    case GET_SIDE_EVENTS_SUCCESS:
        return state.set('isFetching', false)
            .set('sideEvents', payload.data);

    case GET_COMPANY_EVENTS_FAILURE:
    case UPDATE_COMPANY_EVENTS_FAILURE:
    case GET_EVENTS_FAILURE:
    case GET_ALL_TRIGGERS_FAILURE:
    case GET_USER_DIVISIONS_FAILURE:
    case GET_SELECTED_EVENT_TRIGGER_FAILURE:
    case GET_SELECTED_TRIGGER_EVENTS_FAILURE:
    case DELETE_TRIGGER_FAILURE:
    case SHARE_WEB_REPORT_FAILURE:
    case VERIFY_REPORT_CODE_FAILURE:
    case DOWNLOAD_PDF_REPORT_FAILURE:
    case UPDATE_TRIGGER_FAILURE:
    case GET_JOURNEY_INFO_FAILURE:
    case GET_JOURNEYS_INFO_FAILURE:
    case GET_EVENTS_CSV_FAILURE:
    case RENEW_SHARED_REPORT_FAILURE:
    case GET_SIDE_EVENTS_FAILURE:
        return state
            .set('isFetching', false)
            .set('error', payload);

    case GET_EVENT_INFO_FAILURE:
    case GET_EVENT_INFO_BY_VIDEO_FAILURE:
    case GET_EVENT_INFO_BY_VIDEO_REFERENCE_FAILURE:
        return state
            .set('updateMapEventData', false)
            .set('eventInfoFetched', false)
            .set('isFetching', false)
            .set('error', payload);

    case SET_EVENT_DEVICE_OFFLINE: {
        const selectedEvent = state.get('selectedEvent');
        if (selectedEvent?.dvrDetails) {
            selectedEvent.dvrDetails.is_online = 0;
        }
        return state.set('selectedEvent', selectedEvent);
    }

    default:
        return state;
    }
}

export {
    eventsReducer,
    eventInitialState,
};
