import React, { useEffect, useRef, useState } from 'react';
import ColorPicker from '@rc-component/color-picker';
import '@rc-component/color-picker/assets/index.css';
import { rgbToHex } from '../../core/utils/functions';
import './colorPicker.scss';

enum ColorPickerMode {
    Preview = 'preview',
    Select = 'select',
}

interface ColorPickerProps {
    color: string;
    onChange: (color: string) => void;
    style: React.CSSProperties;
}

const ColorPickerComponent: React.FC<ColorPickerProps> = ({ 
    color = '#ff6900', 
    onChange = (color: string) => {},
    style = {},
}) => {
    const [mode, setMode] = useState<string>(ColorPickerMode.Preview);

    // const ref = useRef(null);  
    const ref = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (mode === ColorPickerMode.Preview && ref.current && !ref.current.contains(event.target)) {
                setMode(ColorPickerMode.Preview);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);


    if (mode === ColorPickerMode.Preview) {
        return (
            <div 
                ref={ref}
                style={{
                    width: '30px',
                    height: '30px',
                    padding: '2px',
                    border: '1px solid #999',
                    cursor: 'pointer',
                    ...style,
                }}
                onClick={() => setMode(ColorPickerMode.Select)}
            >
                <div style={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: color,
                }} />
            </div>
        );
    } else {
        return (
            <div className="color-picker-container" ref={ref}>
                <ColorPicker 
                    defaultValue={color}
                    onChangeComplete={(val: { r: number, g: number, b: number }) => {
                        onChange(rgbToHex(val.r, val.g, val.b));
                    }} />
            </div>
        );
    }
}

export default ColorPickerComponent;
