import React from 'react';
import Layout from '../../components/layout/Layout';
import ResetPassswordForm from '../../components/ResetPassswordForm';
import { Redirect } from 'react-router-dom';
import './resetPassword.scss';

export default function () {
    const { modalOpen } = this.state;
    const { match } = this.props;
    const { isValidResetPassword, isSucessResetPassword, isFetching } = this.props;
    const { token } = match.params;
    return (
        <Layout className="login" title="reset-password" showHeader={false} showFooter={false}>
            <ResetPassswordForm isFetching={isFetching} token={token} isValidResetPassword={isValidResetPassword} isSucessResetPassword={isSucessResetPassword} />
        </Layout>
    );


}
