import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_ASSETS_VIDEO_LIST_REQUEST: null,
    GET_ASSETS_VIDEO_LIST_SUCCESS: null,
    GET_ASSETS_VIDEO_LIST_FAILURE: null,

    RESET_HISTORIC_VIDEOS_REQUEST: null,
    GET_HISTORIC_VIDEOS_REQUEST: null,
    GET_HISTORIC_VIDEOS_SUCCESS: null,
    GET_HISTORIC_VIDEOS_FAILURE: null,

    GET_REQUESTED_VIDEOS_REQUEST: null,
    GET_REQUESTED_VIDEOS_SUCCESS: null,
    GET_REQUESTED_VIDEOS_FAILURE: null,

    GET_VIDEO_URL_REQUEST: null,
    GET_VIDEO_URL_SUCCESS: null,
    GET_VIDEO_URL_FAILURE: null,

    TOGGLE_VIDEO_SELECTION_SCREEN: null,

    UPDATE_VIDEO_CAMERA_SELECTION: null,

    UPDATE_VIDEO_REFERENCE_REQUEST: null,
    UPDATE_VIDEO_REFERENCE_SUCCESS: null,
    UPDATE_VIDEO_REFERENCE_FAILURE: null,

    GET_VIDEO_CAMERA_CHANNELS_LINK_REQUEST: null,
    GET_VIDEO_CAMERA_CHANNELS_LINK_SUCCESS: null,
    GET_VIDEO_CAMERA_CHANNELS_LINK_FAILURE: null,

    GET_VIDEO_LIVE_LOCATION_REQUEST: null,
    GET_VIDEO_LIVE_LOCATION_SUCCESS: null,
    GET_VIDEO_LIVE_LOCATION_FAILURE: null,

    GET_VIDEO_LIVE_TAB_REQUEST: null,
    GET_VIDEO_LIVE_TAB_SUCCESS: null,
    GET_VIDEO_LIVE_TAB_FAILURE: null,

    QUEUE_VIDEO_BY_ID_REQUEST: null,
    QUEUE_VIDEO_BY_ID_SUCCESS: null,
    QUEUE_VIDEO_BY_ID_FAILURE: null,

    EXTEND_VIDEO_BY_ID_REQUEST: null,
    EXTEND_VIDEO_BY_ID_SUCCESS: null,
    EXTEND_VIDEO_BY_ID_FAILURE: null,

    EXTEND_STITCHED_VIDEO_BY_ID_REQUEST: null,
    EXTEND_STITCHED_VIDEO_BY_ID_SUCCESS: null,
    EXTEND_STITCHED_VIDEO_BY_ID_FAILURE: null,

    GET_VIDEOS_AROUND_EVENT_REQUEST: null,
    GET_VIDEOS_AROUND_EVENT_SUCCESS: null,
    GET_VIDEOS_AROUND_EVENT_FAILURE: null,

    GET_STITCHED_VIDEO_REQUEST: null,
    GET_STITCHED_VIDEO_SUCCESS: null,
    GET_STITCHED_VIDEO_FAILURE: null,

    SET_SINGLE_VIDEO_REQUEST: null,
    RESET_GO_PREVIOUS_PAGE_REQUEST: null,

    CHECK_DVR_ONLINE_STATUS_REQUEST: null,
    CHECK_DVR_ONLINE_STATUS_SUCCESS: null,
    CHECK_DVR_ONLINE_STATUS_FAILURE: null,

    RESET_SURROUNDING_VIDEOS_REQUEST: null,

    SEARCH_HISTORIC_VIDEOS_REQUEST: null,
    SEARCH_HISTORIC_VIDEOS_SUCCESS: null,
    SEARCH_HISTORIC_VIDEOS_FAILURE: null,
    RESET_SEARCH_HISTORIC_VIDEOS_REQUEST: null,

    SELECT_SINGLE_SEARCHED_VIDEO_REQUEST: null,

    GET_CHANNELS_AND_VIDEOS_REQUEST: null,
    GET_CHANNELS_AND_VIDEOS_SUCCESS: null,
    GET_CHANNELS_AND_VIDEOS_FAILURE: null,

    CACHE_VIDEOS_REQUEST: null,
    CACHE_VIDEOS_SUCCESS: null,
    CACHE_VIDEOS_FAILURE: null,

    QUEUE_SEARCHED_VIDEO_REQUEST: null,
    QUEUE_SEARCHED_VIDEO_SUCCESS: null,
    QUEUE_SEARCHED_VIDEO_FAILURE: null,

    GET_STITCHED_VIDEOS_REQUEST: null,
    GET_STITCHED_VIDEOS_SUCCESS: null,
    GET_STITCHED_VIDEOS_FAILURE: null,

    TRIM_AND_CACHE_VIDEO_REQUEST: null,
    TRIM_AND_CACHE_VIDEO_SUCCESS: null,
    TRIM_AND_CACHE_VIDEO_FAILURE: null,

    RESET_TRIM_AND_CACHE_VIDEO_REQUEST: null,
    RESET_TRIM_AND_CACHE_VIDEO_SUCCESS: null,
    RESET_TRIM_AND_CACHE_VIDEO_FAILURE: null,

    POST_FORCE_DOWNLOAD_REQUEST: null,
    POST_FORCE_DOWNLOAD_SUCCESS: null,
    POST_FORCE_DOWNLOAD_FAILURE: null,

    SET_MAP_SEARCH_LOCATION_BOUNDARY: null,

    DELETE_CACHED_VIDEO_REQUEST: null,
    DELETE_CACHED_VIDEO_SUCCESS: null,
    DELETE_CACHED_VIDEO_FAILURE: null,
});

export const {
    getAssetsVideoListRequest,
    getAssetsVideoListSuccess,
    getAssetsVideoListFailure,
} = createActions(
    constants.GET_ASSETS_VIDEO_LIST_REQUEST,
    constants.GET_ASSETS_VIDEO_LIST_SUCCESS,
    constants.GET_ASSETS_VIDEO_LIST_FAILURE,
);

export const {
    resetHistoricVideosRequest,
    getHistoricVideosRequest,
    getHistoricVideosSuccess,
    getHistoricVideosFailure,
} = createActions(
    constants.RESET_HISTORIC_VIDEOS_REQUEST,
    constants.GET_HISTORIC_VIDEOS_REQUEST,
    constants.GET_HISTORIC_VIDEOS_SUCCESS,
    constants.GET_HISTORIC_VIDEOS_FAILURE,
);

export const {
    getRequestedVideosRequest,
    getRequestedVideosSuccess,
    getRequestedVideosFailure,
} = createActions(
    constants.GET_REQUESTED_VIDEOS_REQUEST,
    constants.GET_REQUESTED_VIDEOS_SUCCESS,
    constants.GET_REQUESTED_VIDEOS_FAILURE,
);

export const {
    getVideoUrlRequest,
    getVideoUrlSuccess,
    getVideoUrlFailure,
} = createActions(
    constants.GET_VIDEO_URL_REQUEST,
    constants.GET_VIDEO_URL_SUCCESS,
    constants.GET_VIDEO_URL_FAILURE,
);

export const {
    toggleVideoSelectionScreen,
    updateVideoCameraSelection,
} = createActions(
    constants.TOGGLE_VIDEO_SELECTION_SCREEN,
    constants.UPDATE_VIDEO_CAMERA_SELECTION,
);

export const {
    updateVideoReferenceRequest,
    updateVideoReferenceSuccess,
    updateVideoReferenceFailure,
} = createActions(
    constants.UPDATE_VIDEO_REFERENCE_REQUEST,
    constants.UPDATE_VIDEO_REFERENCE_SUCCESS,
    constants.UPDATE_VIDEO_REFERENCE_FAILURE,
);

export const {
    getVideoCameraChannelsLinkRequest,
    getVideoCameraChannelsLinkSuccess,
    getVideoCameraChannelsLinkFailure,
} = createActions(
    constants.GET_VIDEO_CAMERA_CHANNELS_LINK_REQUEST,
    constants.GET_VIDEO_CAMERA_CHANNELS_LINK_SUCCESS,
    constants.GET_VIDEO_CAMERA_CHANNELS_LINK_FAILURE,
);

export const {
    getVideoLiveLocationRequest,
    getVideoLiveLocationSuccess,
    getVideoLiveLocationFailure,
} = createActions(
    constants.GET_VIDEO_LIVE_LOCATION_REQUEST,
    constants.GET_VIDEO_LIVE_LOCATION_SUCCESS,
    constants.GET_VIDEO_LIVE_LOCATION_FAILURE,
);

export const {
    getVideoLiveTabRequest,
    getVideoLiveTabSuccess,
    getVideoLiveTabFailure,
} = createActions(
    constants.GET_VIDEO_LIVE_TAB_REQUEST,
    constants.GET_VIDEO_LIVE_TAB_SUCCESS,
    constants.GET_VIDEO_LIVE_TAB_FAILURE,
);

export const {
    queueVideoByIdRequest,
    queueVideoByIdSuccess,
    queueVideoByIdFailure,
} = createActions(
    constants.QUEUE_VIDEO_BY_ID_REQUEST,
    constants.QUEUE_VIDEO_BY_ID_SUCCESS,
    constants.QUEUE_VIDEO_BY_ID_FAILURE,
);

export const {
    extendVideoByIdRequest,
    extendVideoByIdSuccess,
    extendVideoByIdFailure,
} = createActions(
    constants.EXTEND_VIDEO_BY_ID_REQUEST,
    constants.EXTEND_VIDEO_BY_ID_SUCCESS,
    constants.EXTEND_VIDEO_BY_ID_FAILURE,
);

export const {
    extendStitchedVideoByIdRequest,
    extendStitchedVideoByIdSuccess,
    extendStitchedVideoByIdFailure,
} = createActions(
    constants.EXTEND_STITCHED_VIDEO_BY_ID_REQUEST,
    constants.EXTEND_STITCHED_VIDEO_BY_ID_SUCCESS,
    constants.EXTEND_STITCHED_VIDEO_BY_ID_FAILURE,
);

export const {
    getVideosAroundEventRequest,
    getVideosAroundEventSuccess,
    getVideosAroundEventFailure,
} = createActions(
    constants.GET_VIDEOS_AROUND_EVENT_REQUEST,
    constants.GET_VIDEOS_AROUND_EVENT_SUCCESS,
    constants.GET_VIDEOS_AROUND_EVENT_FAILURE,
);

export const {
    getStitchedVideoRequest,
    getStitchedVideoSuccess,
    getStitchedVideoFailure,
} = createActions(
    constants.GET_STITCHED_VIDEO_REQUEST,
    constants.GET_STITCHED_VIDEO_SUCCESS,
    constants.GET_STITCHED_VIDEO_FAILURE,
);

export const {
    setSingleVideoRequest,
    resetGoPreviousPageRequest,
} = createActions(
    constants.SET_SINGLE_VIDEO_REQUEST,
    constants.RESET_GO_PREVIOUS_PAGE_REQUEST,
);

export const {
    checkDvrOnlineStatusRequest,
    checkDvrOnlineStatusSuccess,
    checkDvrOnlineStatusFailure,
} = createActions(
    constants.CHECK_DVR_ONLINE_STATUS_REQUEST,
    constants.CHECK_DVR_ONLINE_STATUS_SUCCESS,
    constants.CHECK_DVR_ONLINE_STATUS_FAILURE,
);

export const {
    resetSurroundingVideosRequest,
} = createActions(
    constants.RESET_SURROUNDING_VIDEOS_REQUEST,
);

export const {
    searchHistoricVideosRequest,
    searchHistoricVideosSuccess,
    searchHistoricVideosFailure,
    resetSearchHistoricVideosRequest,
} = createActions(
    constants.SEARCH_HISTORIC_VIDEOS_REQUEST,
    constants.SEARCH_HISTORIC_VIDEOS_SUCCESS,
    constants.SEARCH_HISTORIC_VIDEOS_FAILURE,
    constants.RESET_SEARCH_HISTORIC_VIDEOS_REQUEST,
);

export const {
    selectSingleSearchedVideoRequest,
} = createActions(
    constants.SELECT_SINGLE_SEARCHED_VIDEO_REQUEST,
);

export const {
    getChannelsAndVideosRequest,
    getChannelsAndVideosSuccess,
    getChannelsAndVideosFailure,
} = createActions(
    constants.GET_CHANNELS_AND_VIDEOS_REQUEST,
    constants.GET_CHANNELS_AND_VIDEOS_SUCCESS,
    constants.GET_CHANNELS_AND_VIDEOS_FAILURE,
);

export const {
    cacheVideosRequest,
    cacheVideosSuccess,
    cacheVideosFailure,
} = createActions(
    constants.CACHE_VIDEOS_REQUEST,
    constants.CACHE_VIDEOS_SUCCESS,
    constants.CACHE_VIDEOS_FAILURE,
);

export const {
    queueSearchedVideoRequest,
    queueSearchedVideoSuccess,
    queueSearchedVideoFailure,
} = createActions(
    constants.QUEUE_SEARCHED_VIDEO_REQUEST,
    constants.QUEUE_SEARCHED_VIDEO_SUCCESS,
    constants.QUEUE_SEARCHED_VIDEO_FAILURE,
);

export const {
    getStitchedVideosRequest,
    getStitchedVideosSuccess,
    getStitchedVideosFailure,
} = createActions(
    constants.GET_STITCHED_VIDEOS_REQUEST,
    constants.GET_STITCHED_VIDEOS_SUCCESS,
    constants.GET_STITCHED_VIDEOS_FAILURE,
);

export const {
    trimAndCacheVideoRequest,
    trimAndCacheVideoSuccess,
    trimAndCacheVideoFailure,
} = createActions(
    constants.TRIM_AND_CACHE_VIDEO_REQUEST,
    constants.TRIM_AND_CACHE_VIDEO_SUCCESS,
    constants.TRIM_AND_CACHE_VIDEO_FAILURE,
);

export const {
    resetTrimAndCacheVideoRequest,
    resetTrimAndCacheVideoSuccess,
    resetTrimAndCacheVideoFailure,
} = createActions(
    constants.RESET_TRIM_AND_CACHE_VIDEO_REQUEST,
    constants.RESET_TRIM_AND_CACHE_VIDEO_SUCCESS,
    constants.RESET_TRIM_AND_CACHE_VIDEO_FAILURE,
);

export const {
    postForceDownloadRequest,
    postForceDownloadSuccess,
    postForceDownloadFailure,
} = createActions(
    constants.POST_FORCE_DOWNLOAD_REQUEST,
    constants.POST_FORCE_DOWNLOAD_SUCCESS,
    constants.POST_FORCE_DOWNLOAD_FAILURE,
);

export const {
    setMapSearchLocationBoundary,
} = createActions(
    constants.SET_MAP_SEARCH_LOCATION_BOUNDARY,
);

export const {
    deleteCachedVideoRequest,
    deleteCachedVideoSuccess,
    deleteCachedVideoFailure,
} = createActions(
    constants.DELETE_CACHED_VIDEO_REQUEST,
    constants.DELETE_CACHED_VIDEO_SUCCESS,
    constants.DELETE_CACHED_VIDEO_FAILURE,
);
