import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { getAddressFromLatLongAsync } from '../../../core/utils/api';
import Icon from '../Icon';
import { Location } from '@carbon/icons-react';

class LocationFetcher extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            location: null,
            error: false,
        };
    }

    componentDidMount() {
        const { lat, lon, onFetchedLocation } = this.props;
        const self = this;

        if (!isEmpty(lat) && !isEmpty(lon)) {
            getAddressFromLatLongAsync(lat, lon, (error, data) => {
                if (error) {
                    self.setState({ error: true });
                } else {
                    let location = data;
                    if (!location) location = '-';
                    location = location.replace(/"/g, '');
                    self.setState({ location });
                    if (onFetchedLocation) onFetchedLocation(location);
                }
            });
        } else {
            this.setState({ error: true });
        }
    }

    render() {
        const { location, error } = this.state;
        const { key, lat, lon } = this.props;

        const icon = (<Location />);
        const emptyValuePlaceholder = (
            <>
                {icon}
                &ensp;
                -
            </>
        );

        let content = null;
        if (isEmpty(lat) && isEmpty(lon)) {
            content = (<div>{emptyValuePlaceholder}</div>);
        } else if (location && location !== '""') {
            content = (<div>{icon}&ensp;{location.replace(/"/g, '')}</div>);
        } else if (error) {
            content = (<div>{icon}</div>);
        } else if (location === null) {
            content = (
                <div>
                    &ensp;&ensp;&ensp;
                    <Spin size="small" />
                </div>
            );
        } else {
            content = (<div>{emptyValuePlaceholder}</div>);
        }

        return (
            <div key={key}>
                {content}
            </div>
        );
    }
}

LocationFetcher.defaultProps = {
    key: '',
    lat: null,
    lon: null,
    onFetchedLocation: () => {},
};

LocationFetcher.propTypes = {
    key: PropTypes.string.isRequired,
    lat: PropTypes.string,
    lon: PropTypes.string,
    onFetchedLocation: PropTypes.func,
};

export default LocationFetcher;
