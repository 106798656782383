import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';
import { Erase } from '@carbon/icons-react';
import {
    Button, Col, Input, Radio, Row, Select, 
} from 'antd';

import { isEmpty } from 'underscore';

class GeoFence extends Component {
    constructor(props) {
        super(props);
        const { selectedDivisions, initializeValues } = props;

        const selectDivision = !isEmpty(selectedDivisions) || (initializeValues && initializeValues.divisions && !isEmpty(initializeValues.divisions));

        this.state = {
            selectDivision,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.initializeValues !== prevProps.initializeValues) {
            const { initializeValues } = this.props;
            const selectDivision = initializeValues && initializeValues.divisions && !isEmpty(initializeValues.divisions);
            this.setState({
                selectDivision,
            });
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const {
            divisions, getDivisions, redrawGeofence, form, selectedDivisions, initializeValues, deleteGeofence, hideSelection,
        } = this.props;

        const { selectDivision } = this.state;
        const { getFieldDecorator } = form;
        let defaultDivisions = selectedDivisions ? selectedDivisions.map((selectedDivision) => selectedDivision.name) : [];
        if (initializeValues && initializeValues.divisions && !isEmpty(initializeValues.divisions)) {
            defaultDivisions = initializeValues.divisions.map((division) => division.name);
        }

        return (
            <>
                <h5 className="h-5">Save Geo-fence</h5>
                <Form onSubmit={this.submitForm}>
                    <Row 
                        className='mt-2' 
                        style={{ width: '100%' }}
                    >
                        <Col span={24}>
                            <Form.Item label={null}>
                                {getFieldDecorator('trigger_name', {
                                    initialValue: (initializeValues && initializeValues.name) || null,
                                })(
                                    <Input 
                                        type="text" 
                                        placeholder="Enter Name" 
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <strong>Actions:</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={1} />
                        {isEmpty(initializeValues) ? (
                            <Col span={5}>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redrawGeofence();
                                    }}
                                    style={{ 
                                        display: 'inline-block',
                                        width: '48px',
                                    }}
                                >
                                    <div style={{ textAlign: 'left' }}>Redraw</div>
                                </a>
                            </Col>
                        ) : (
                            <Col span={5}>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        deleteGeofence(initializeValues);
                                    }}
                                    style={{ 
                                        display: 'inline-block',
                                        width: '48px',
                                    }}
                                >
                                    <div style={{ textAlign: 'left' }}>Delete</div>
                                </a>
                            </Col>
                        )}
                    </Row>
                    {!hideSelection && (
                        <>
                            <Row style={{ marginTop: '20px' }}>
                                <Col>
                                    <strong>Selection:</strong>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={1} />
                                <Col span={23}>
                                    <Form.Item label={null}>
                                        {getFieldDecorator('selection', {
                                            initialValue: selectDivision ? 2 : 1,
                                        })(
                                            <Radio.Group
                                                onChange={(e) => {
                                                    if (e.target.value === 1) {
                                                        this.setState({ selectDivision: false });
                                                        return;
                                                    }
                                                    getDivisions();
                                                    this.setState({ selectDivision: true });
                                                }}>
                                                <Radio value={1}>Any asset</Radio>
                                                <Radio value={2}>Selected Divisions</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {selectDivision && (
                                <Row style={{ marginTop: '10px' }}>
                                    <Col span={1} />
                                    <Col span={23}>
                                        <Form.Item label={null}>
                                            {getFieldDecorator('divisions', {
                                                initialValue: defaultDivisions,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select divisions!',
                                                    },
                                                ],
                                            })(
                                                <Select 
                                                    mode="multiple" 
                                                    style={{ width: '100%' }} 
                                                    placeholder="Select Division" 
                                                    showSearch 
                                                    allowClear={<Erase size={12} />}
                                                >
                                                    {divisions && divisions.map((division, divisionIndex) => (
                                                        <Select.Option 
                                                            key={`index-${divisionIndex}`} 
                                                            value={division.name}
                                                        >
                                                            {division.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            htmlType="submit"
                            type="primary"
                            className="mt-3"
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </>
        );
    }
}

GeoFence.propTypes = {
    divisions: PropTypes.array,
    getDivisions: PropTypes.func,
    redrawGeofence: PropTypes.func,
    deleteGeofence: PropTypes.func,
    initializeValues: PropTypes.object,
};

GeoFence.defaulProps = {
    divisions: [],
    getDivisions: () => {},
    redrawGeofence: () => {},
};

const GeoFenceForm = Form.create({ name: 'event_filter_form' })(GeoFence);

export default GeoFenceForm;
