import React from 'react';
import { Form } from '@ant-design/compatible';
import { Button, Spin } from 'antd';
import PasswordInput from '../PasswordInput';
import { Redirect, Link } from 'react-router-dom';
import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';
import { Email } from '@carbon/icons-react';

import './resetPassswordForm.scss';

const FormItem = Form.Item;

export default function () {
    const {
        user, form, isValidResetPassword, isSucessResetPassword, isFetching,
    } = this.props;
    const { getFieldDecorator } = form;

    // if (!isValidResetPassword && !this.state.isValidResetPassword) return <Redirect to='/' />;
    if (isSucessResetPassword && isValidResetPassword) return <Redirect to="/" />;
    return (
        (<Spin spinning={isFetching}>
            <div className="loginform-wrap">
                <div className="loginform-container">
                    <div className="d-flex dir-column align-center text-center">
                        <img 
                            src={srcFleetclearLogo} 
                            className="login__logo-fleet" 
                            alt="Fleetclear" 
                            style={{ width: '80%' }} 
                        />
                        <br />
                        <br />
                    </div>
                    <Form onSubmit={this.handleSubmit} layout="vertical" className="form mt-3">
                        <FormItem>
                            {getFieldDecorator('new_password', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please enter new password!',
                                    },
                                    {
                                        min: 8,
                                        message: 'A new password must be at least 8 characters length!\n\r',
                                    },
                                    {
                                        pattern: /\d+/,
                                        message: 'Must contain at least 1 digit\n\r',
                                    },
                                    {
                                        pattern: /[A-Z]+/,
                                        message: 'Must contain at least 1 uppercase character\n\r',
                                    },
                                    {
                                        pattern: /[a-z]+/,
                                        message: 'Must contain at least 1 lowercase character\n\r',
                                    },
                                    {
                                        pattern: /[!#$@%\-_=+<>\'"`£^&¬\.*\(\)\[\]\?:;\/,{}]/,
                                        message: 'Must contain at least 1 special character',
                                    },

                                ],
                            })(
                                <PasswordInput 
                                    placeholder="Enter New Password" 
                                    className="login-password" 
                                />
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('new_password_confirm', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please confirm new password!',
                                    },
                                    {
                                        min: 8,
                                        message: 'A new password must be at least 8 characters length!\n\r',
                                    },
                                    {
                                        pattern: /\d+/,
                                        message: 'Must contain at least 1 digit\n\r',
                                    },
                                    {
                                        pattern: /[A-Z]+/,
                                        message: 'Must contain at least 1 uppercase character\n\r',
                                    },
                                    {
                                        pattern: /[a-z]+/,
                                        message: 'Must contain at least 1 lowercase character\n\r',
                                    },
                                    {
                                        pattern: /[!#$@%\-_=+<>\'"`£^&¬\.*\(\)\[\]\?:;\/,{}]/,
                                        message: 'Must contain at least 1 special character',
                                    },
                                ],
                            })(
                                <PasswordInput 
                                    placeholder="Enter Again" 
                                    className="login-password" 
                                />)}
                        </FormItem>
                        <div className="text-center mt-2">
                            <Button 
                                type="primary" 
                                htmlType="submit"
                                style={{ width: '70%' }}
                            >
                                Reset Password
                            </Button>

                        </div>
                        <br />
                        <div>
                            <Link to="/">
                                <span className="link-green btn-empty underlined center-align">
                                    Cancel
                                </span>
                            </Link>
                        </div>
                    </Form>
                </div>
            </div>
        </Spin>)
    );
}
