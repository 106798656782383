/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import Icon from '@ant-design/icons';
import ChartHeader from '../ChartHeader';
import ChartMultiDropDown from '../ChartMultiDropDown';
import './multipleIconsChart.scss';

import srcFleetclearLogo from '../../../../assets/images/fleetclear-logo.svg';
import { getChartIconList } from '../../../../core/utils/functions';

import LowFuelSVG from '../../../../assets/icons/LowFuelSVG';
import CarbonReportSVG from '../../../../assets/icons/CarbonReportSVG';

const LowFuelIcon = (props) => <Icon component={LowFuelSVG} {...props} />;

export default function () {
    const {
        data,
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        onAllOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        divisions,
        userDivisions,
        drivers,
        assets,
        heightRatio,
        dotOnClick,
        chartKey,
    } = this.props;

    const { timeframe } = this.state;

    let widgetHeight = heightRatio * 200;
    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }

    const mappedIcons = getChartIconList();

    const renderIconItem = (item, key) => {
        let DisplayIcon = null;
        let displayColor = 'grey';
        let iconHoverText = null;

        if (item.icon_id && mappedIcons[item.icon_id]) {
            DisplayIcon = mappedIcons[item.icon_id];
        }

        if (item.color) {
            displayColor = item.color;
        }

        if (item.icon_id > 16) {
            const fill = (item.color ? item.color : '#FFFFFF');
            DisplayIcon = <Icon component={DisplayIcon} style={{ fill, fontSize: '46px' }} />;
        }

        if (item.icon) {
            DisplayIcon = item.icon;
        }

        if (item.icon_hover_text) {
            iconHoverText = item.icon_hover_text;
        }

        return (
            <div
                key={`ixon-item-${key}`}
                style={{ color: displayColor, cursor: 'pointer', fill: displayColor }}
                title={iconHoverText}
                onClick={() => { dotOnClick(timeframe, key); }}
                className={item.active === true ? 'single-icon-item active' : 'single-icon-item'}>
                {DisplayIcon}
            </div>
        );
    };

    let primaryIcon = null;
    let logo = null;
    let mainLabelText = null;

    if (data[0]) {
        if (data[0].main_label_text) {
            mainLabelText = data[0].main_label_text;
        }

        if (data[0].primary_icon_id && mappedIcons[data[0].primary_icon_id]) {
            primaryIcon = mappedIcons[data[0].primary_icon_id];
        }

        if (data[0].logo) {
            logo = <img src={data[0].logo} alt="Fleetclear" style={{ height: 30 }} />;
        }
    }

    return (
        <div className="new-chart-widget multiple-icons-chart" style={{}}>
            <ChartHeader
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData)}
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />
            <div className="multiple-icons-chart-content" style={{ width: '100%', height: widgetHeight, overflow: 'hidden' }} id={`modalContent_${chartKey}`}>
                <div className="top-content-row">
                    <div className="title-block">
                        <div key="ixon-item" className="single-icon-item" style={{ color: 'black' }}>
                            {primaryIcon}
                        </div>
                        <div className="title">{mainLabelText}</div>
                    </div>
                </div>
                <div className="icons-row">
                    <div className="logo-block">
                        {logo}
                    </div>
                    <div className="icons-block">
                        {data.map((item, key) => renderIconItem(item, key))}
                    </div>
                </div>
            </div>

            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    divisions={divisions}
                    userDivisions={userDivisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
