import colours from '../../colours';

export default {
    track: {
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        height: '32px',
        cursor: 'pointer',
        transition: 'background-color .15s ease-in-out, border-color .15s ease',
        backgroundColor: 'rgba(20, 20, 20, 0.08)',
        zIndex: 3,
    },
    trackDisabled: {
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25px',
        backgroundColor: colours.trackDisabled,
        borderRadius: '20px',
        zIndex: 1,
        cursor: 'pointer',
        transition: 'background-color .15s ease-in-out, border-color .15s ease',
    },
};
