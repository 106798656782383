/* global window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { notification } from 'antd';
import * as videoActions from '../../core/video/videoActions';
import * as eventsActions from '../../core/events/eventsActions';
import * as assetActions from '../../core/asset/assetActions';
import * as driverActions from '../../core/driver/driverActions';
import * as globalActions from '../../core/global/globalActions';
import * as deviceActions from '../../core/device/deviceActions';
import history from '../../core/utils/history';
import DOM from './video';
import moment from 'moment';
import { fetchApiAuth } from '../../core/utils/api';
import { VideoRequestLength } from './tabs/VideoSearchTab';
import { timeToSeconds } from '../../core/utils/dateUtils';

class Video extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.googleMapAccess = React.createRef();
        this.loadingDvrsRequest = null;

        this.state = {
            // eslint-disable-next-line react/no-unused-state
            selectedTabKey: 'video-search',
            locationSearch: false,
            mapOpen: false,
            filterSidebarIsVisible: true,
            videoSearchIsFetching: false,
            videoSearchData: null,
            selectedDate: null,
            latitude: null,
            longitude: null,
            geometry: null,
            openSidebar: true,
            showFilterSidebar: true,
            videoStreamingSidebarIsVisible: false,
            videoSearchSidebarIsVisible: false,
            videoRequestLength: VideoRequestLength.PlusMinusFifteenSeconds,
        };
        this.onFilterCallback = () => null;
        this.onRequestFilterCallback = () => null;
        this.onLocationSearchCallback = () => null;
    }

    checkDvrStatus() {
        if (!this.loadingDvrsRequest) {
            const opts = {
                method: 'POST',
                url: '/video/get-dvr-online-status',
            };
            this.loadingDvrsRequest = fetchApiAuth(opts);
        }
        return this.loadingDvrsRequest;
    }

    componentWillUnmount() {
        this.loadingDvrsRequest = null;
    }

    async componentDidMount() {
        const {
            actions, match,
        } = this.props;
        const { params } = match;
        const selectedTabKey = params?.selectedTab;
        if (selectedTabKey) {
            this.setState({ selectedTabKey });

        }
        await this.checkDvrStatus();
        actions.getAssetOptionsRequest();
        actions.getDriverListRequest();
        actions.getDivisionListRequest();
        actions.getVehicleTypesRequest();
        actions.setRetainFilter(false);
    }

    componentDidUpdate() {
        window.onpopstate = (e) => {
            const path = window.location.pathname;

            if (path.includes('video-search')) {
                this.setState({ selectedTabKey: 'video-search' });
            } else if (path.includes('cached-videos')) {
                this.setState({ selectedTabKey: 'cached-videos' });
            } else if (path.includes('live-videos')) {
                this.setState({ selectedTabKey: 'live-videos' });
            }

            const { actions } = this.props;

            actions.updateVideoCameraSelection([]);
            actions.toggleVideoSelectionScreen(true);
        };
    }

    changeTab(selectedTabKey, filterSidebarIsVisible = null) {
        this.setState({ selectedTabKey });

        if (filterSidebarIsVisible !== null) {
            this.setState({ filterSidebarIsVisible });
        }
    }

    closeSidebar() {
        this.setState({ filterSidebarIsVisible: false });
    }

    setLocationSearch = (locationSearch) => {
        this.setState({ 
            locationSearch, 
            mapOpen: locationSearch 
        });
    };

    setMapOpen = (mapOpen) => {
        this.setState({ mapOpen });
    };

    registerOnFilterCallback = (fn) => {
        this.onFilterCallback = fn;
    };

    registerOnRequestFilterCallback = (fn) => {
        this.onRequestFilterCallback = fn;
    };

    registerOnLocationSearchCallback = (fn) => {
        this.onLocationSearchCallback = fn;
    };

    setShouldShowSidebar = (shouldShow) => {
        this.setState({ 
            filterSidebarIsVisible: shouldShow, 
            openSidebar: shouldShow 
        });
    };

    liveVideoFilterSubmit = (values) => {
        const { actions } = this.props;

        actions.getAssetsVideoListRequest(values);
    };

    handleHistoricFilter = (a) => this.onFilterCallback(a);

    onChangeSelectedDivisionId = (selectedDivisionId) => {
        this.setState({ 
            selectedDivisionId,
            selectedAssetImei: null,
        });
    }

    onChangeSelectedAssetImei = (selectedAssetImei) => {
        this.setState({ selectedAssetImei });
    }

    onChangeSelectedDate = (selectedDate) => {
        this.setState({ selectedDate });
    }

    handleVideoSearchFilter = (params) => {
        this.setState({ 
            videoSearchIsFetching: true,
            videoSearchSelectedDate: params.date.format('YYYY-MM-DD HH:mm:ss'),
            videoStreamingSidebarIsVisible: false,
        });
        this.closeSidebar();
        const imei = params.imei;
        const assetId = params.assetId;
        const videoSearchDataPromise = new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'GET',
                url: `/device/${imei}`,
                params: {
                    imei,
                    date: params.date.format('YYYY-MM-DD HH:mm:ss'),
                },
            })
                .then((res) => {
                    this.setState({ 
                        videoSearchData: res?.data,
                        selectedDate: params.date.format('YYYY-MM-DD HH:mm:ss'),
                    });
                    resolve();
                    
                })
                .catch((err) => {
                    notification.error({
                        message: 'Error',
                        description: 'Unable to fetch device data',
                    });
                    reject(err);
                });
        });
        const timelineFetchPromise = new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'POST',
                url: '/device/timeline',
                body: {
                    imei,
                    date: params.date.format('YYYY-MM-DD'),
                },
            })
                .then((res) => {
                    this.setState({ timelineSegments: res?.data });
                    resolve();
                })
                .catch((err) => {
                    notification.error({
                        message: 'Error',
                        description: 'Unable to fetch device data',
                    });
                    reject(err);
                });
        });
        const geofenceTimelineFetchPromise = new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'POST',
                url: '/device/geofence-timeline',
                body: {
                    imei,
                    date: params.date.format('YYYY-MM-DD'),
                },
            })
                .then((res) => {
                    this.setState({ geofenceTimelineSegments: res?.data });
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
        const channelListFetchPromise = new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'GET',
                url: '/video/get-company-dvrs',
            })
                .then((res) => {
                    if (res?.data?.data?.length) {
                        const selectedDvr = res.data.data.filter(dvr => dvr.asset_id === assetId);
                        if (selectedDvr && selectedDvr.length) {
                            this.setState({ 
                                channelList: selectedDvr[0].available_channels,
                                selectedChannels: selectedDvr[0].available_channels.map(channel => ({ channel: channel.channel, selected: true })),
                                selectedDvrIsOnline: selectedDvr[0].status,
                                selectedDvrId: selectedDvr[0].device_id,
                            });
                        }
                    }
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
        Promise.all([ videoSearchDataPromise, timelineFetchPromise, geofenceTimelineFetchPromise, channelListFetchPromise ]).then(() => {
            this.setState({ videoSearchIsFetching: false });
            setInterval(() => {
                fetchApiAuth({
                    method: 'GET',
                    url: '/video/get-company-dvrs',
                })
                    .then((res) => {
                        if (res?.data?.data?.length) {
                            const selectedDvr = res.data.data.filter(dvr => dvr.asset_id === assetId);
                            if (selectedDvr && selectedDvr.length) {
                                this.setState({ selectedDvrIsOnline: selectedDvr[0].status });
                            }
                        }
                    })
                    .catch(() => {
                        notification.error({
                            message: 'Error',
                            description: 'Failed to fetch DVR status',
                        });
                    });
            }, 30000);
        })
        .catch(() => {
            this.setState({ videoSearchIsFetching: false });
            notification.error({
                message: 'Error',
                description: 'Failed to fetch DVR data',
            });
        });
    };

    toggleSelectedChannel = (channel) => {
        const { selectedChannels } = this.state;

        const newSelectedChannels = selectedChannels.map((sc) => {
            if (sc.channel === channel) {
                return { channel, selected: !sc.selected };
            }
            return sc;
        })
        this.setState({ selectedChannels: newSelectedChannels });
    }

    onChangeVideoRequestLength = (videoRequestLength) => {
        const { videoSearchSelectedDate, sliderMiddleValue } = this.state;
        if (videoRequestLength && videoSearchSelectedDate && sliderMiddleValue !== undefined) {
            const gap = this.calculateVideoRequestLengthSeconds(videoRequestLength);
            const upperGap = Math.floor(gap / 2);
            const lowerGap = Math.ceil(gap / 2);
            this.setState({ 
                videoRequestLength,
                videoSearchDateFrom: moment(videoSearchSelectedDate).startOf('day').add((sliderMiddleValue - lowerGap), 'seconds').format('YYYY-MM-DD HH:mm:ss'),
                videoSearchDateTo: moment(videoSearchSelectedDate).startOf('day').add((sliderMiddleValue + upperGap), 'seconds').format('YYYY-MM-DD HH:mm:ss'),
            });
        }
        
    }

    onChangeSliderValue = (sliderValue) => {
        const { videoRequestLength, videoSearchSelectedDate } = this.state;
        
        if (videoRequestLength && videoSearchSelectedDate && sliderValue?.length && sliderValue.length === 3) {
            const gap = this.calculateVideoRequestLengthSeconds(videoRequestLength);
            const upperGap = Math.floor(gap / 2);
            const lowerGap = Math.ceil(gap / 2);
            this.setState({ 
                sliderMiddleValue: sliderValue[1],
                videoSearchDateFrom: moment(videoSearchSelectedDate).startOf('day').add((sliderValue[1] - lowerGap), 'seconds').format('YYYY-MM-DD HH:mm:ss'),
                videoSearchDateTo: moment(videoSearchSelectedDate).startOf('day').add((sliderValue[1] + upperGap), 'seconds').format('YYYY-MM-DD HH:mm:ss'),
            });
        }
    }

    calculateVideoRequestLengthSeconds = (videoRequestLength) => {
        const { videoSearchSelectedDate } = this.state;
        
        if (videoRequestLength && videoSearchSelectedDate) {
            let gap = 0;
            switch (videoRequestLength) {
                case VideoRequestLength.PlusMinusFifteenSeconds:
                    return 30;
                case VideoRequestLength.PlusMinusThirtySeconds:
                    return 60;
                case VideoRequestLength.PlusMinusOneMinute:
                    return 120;
                case VideoRequestLength.PlusMinusTwoMinutes:
                    return 240;
                case VideoRequestLength.PlusMinusFiveMinutes:
                    return 600;
                default:
                    return 30;
            }
        }
    }

    handleRequestedFilter = (a) => this.onRequestFilterCallback(a);

    handleLocationSearchFilter = (a) => this.onLocationSearchCallback(a);

    handleTabClick = (key) => {
        const { selectedTabKey } = this.state;
        if (selectedTabKey !== key) {
            this.setState({ selectedTabKey: key });
            history.push(`/video/${key}`);
            const { actions } = this.props;
            actions.updateVideoCameraSelection([]);
            actions.toggleVideoSelectionScreen(true);
            actions.updateVideoSearch({ assetReg: '' });
            this.setShouldShowSidebar(true);
        }
    };

    showVideoStreamingSidebar = () => {
        this.setState({ videoStreamingSidebarIsVisible: true });
    }

    toggleVideoSearchSidebar = () => {
        const { videoSearchSidebarIsVisible } = this.state;
        this.setState({ videoSearchSidebarIsVisible: !videoSearchSidebarIsVisible });
    }

    queueCachedVideo = () => {
        const { selectedDvrId, videoSearchSelectedDate, channelList, videoSearchDateFrom, videoSearchDateTo } = this.state;

        fetchApiAuth({
            method: 'POST',
            url: '/video/trim-and-cache',
            body: {
                deviceId: selectedDvrId,
                videoDate: videoSearchSelectedDate,
                selectedVideoChannels: channelList.map(channel => channel.channel),
                sliderRangeMinValue: timeToSeconds(moment(videoSearchDateFrom).format('HH:mm:ss'), true),
                sliderRangeMaxValue: timeToSeconds(moment(videoSearchDateTo).format('HH:mm:ss'), true),
                location: '',
            }
        })
            .then((res) => {
                if (res && res.data) {
                    notification.success({
                        message: 'Success',
                        description: 'Your video has been queued for saving. Once finished we will notify you via email.',
                    });
                }
            })
            .catch(() => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to queue video for saving.',
                });
            });
    }

    render() {
        return this.view();
    }
}

Video.defaultProps = {
    assetId: 0,
};

Video.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    assetId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

function mapStateToProps(state, ownProps) {
    const assetId = ownProps.match.params && ownProps.match.params.assetId ? ownProps.match.params.assetId : 0;
    return {
        ...ownProps,
        showSelectionScreen: state.video.showSelectionScreen,
        user: state.user,
        assetReg: state.global.videoSearchFilters.assetReg,
        retainFilter: state.global.retainFilter,
        assetId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...videoActions,
                ...eventsActions,
                ...assetActions,
                ...driverActions,
                ...globalActions,
                ...deviceActions,
            },
            dispatch,
        ),
    };
}
export const VideoTest = Video;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Video);
