/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import DOM from './multipleIconsChart';

class MultipleIconsChart extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            timeframe: props.timeframe,
        };
    }

    onTimeframeChange(timeframe) {
        const { onTimeframeChange } = this.props;

        this.setState({
            timeframe,
        });

        onTimeframeChange(timeframe);
    }

    render() {
        return this.view();
    }
}

MultipleIconsChart.defaultProps = {
    onTimeframeChange: () => null,
    rightMenuOnClick: () => null,
    rightMenuItems: [],
    tooltipText: null,
    onFirstOptionChange: () => null,
    onSecondOptionChange: () => null,
    onThirdOptionChange: () => null,
    onFourOptionChange: () => null,
    defaultFirstOptionValue: 'All',
    defaultSecondOptionValue: 'All',
    defaultThirdOptionValue: 'All',
    defaultFourOptionValue: 'All',
    divisions: [],
    userDivisions: [],
    drivers: [],
    assets: [],
    data: [],
    unit: 'Miles',
    subtitle: '',
    heightRatio: 1,
    imageUrl: '',
    chartTitle: '',
    chartIcon: '',
    dotOnClick: () => null,
};

MultipleIconsChart.propTypes = {
    timeframe: PropTypes.oneOf(['hourly', 'daily', 'weekly', 'monthly', 'yearly']).isRequired,
    data: PropTypes.array,
    unit: PropTypes.string,
    subtitle: PropTypes.string,
    rightMenuItems: PropTypes.array,
    onTimeframeChange: PropTypes.func,
    rightMenuOnClick: PropTypes.func,
    tooltipText: PropTypes.string,
    onFirstOptionChange: PropTypes.func,
    onSecondOptionChange: PropTypes.func,
    onThirdOptionChange: PropTypes.func,
    onFourOptionChange: PropTypes.func,
    defaultFirstOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultSecondOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultThirdOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultFourOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    divisions: PropTypes.array,
    userDivisions: PropTypes.array,
    drivers: PropTypes.array,
    assets: PropTypes.array,
    heightRatio: PropTypes.oneOf([1, 2]),
    imageUrl: PropTypes.string,
    chartTitle: PropTypes.string,
    dotOnClick: PropTypes.func,
};

export default MultipleIconsChart;
