import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_ALL_WIDGETS_REQUEST: null,
    GET_ALL_WIDGETS_SUCCESS: null,
    GET_ALL_WIDGETS_FAILURE: null,

    UPDATE_OR_CREATE_REQUEST: null,
    UPDATE_OR_CREATE_SUCCESS: null,
    UPDATE_OR_CREATE_FAILURE: null,

    UPDATE_WIDGET_POSITIONS_REQUEST: null,
    UPDATE_WIDGET_POSITIONS_SUCCESS: null,
    UPDATE_WIDGET_POSITIONS_FAILURE: null,

    DELETE_WIDGET_REQUEST: null,
    DELETE_WIDGET_SUCCESS: null,
    DELETE_WIDGET_FAILURE: null,

    GET_WIDGET_EVENT_TYPES_REQUEST: null,
    GET_WIDGET_EVENT_TYPES_SUCCESS: null,
    GET_WIDGET_EVENT_TYPES_FAILURE: null,

    GET_USER_DIVISIONS_REQUEST: null,
    GET_USER_DIVISIONS_SUCCESS: null,
    GET_USER_DIVISIONS_FAILURE: null,

    GET_USER_DRIVERS_REQUEST: null,
    GET_USER_DRIVERS_SUCCESS: null,
    GET_USER_DRIVERS_FAILURE: null,

    GET_SINGLE_WIDGET_REQUEST: null,
    GET_SINGLE_WIDGET_SUCCESS: null,
    GET_SINGLE_WIDGET_FAILURE: null,

});

export const {
    getAllWidgetsRequest,
    getAllWidgetsSuccess,
    getAllWidgetsFailure,
} = createActions(
    constants.GET_ALL_WIDGETS_REQUEST,
    constants.GET_ALL_WIDGETS_SUCCESS,
    constants.GET_ALL_WIDGETS_FAILURE,
);

export const {
    updateOrCreateRequest,
    updateOrCreateSuccess,
    updateOrCreateFailure,
} = createActions(
    constants.UPDATE_OR_CREATE_REQUEST,
    constants.UPDATE_OR_CREATE_SUCCESS,
    constants.UPDATE_OR_CREATE_FAILURE,
);

export const {
    updateWidgetPositionsRequest,
    updateWidgetPositionsSuccess,
    updateWidgetPositionsFailure,
} = createActions(
    constants.UPDATE_WIDGET_POSITIONS_REQUEST,
    constants.UPDATE_WIDGET_POSITIONS_SUCCESS,
    constants.UPDATE_WIDGET_POSITIONS_FAILURE,
);

export const {
    deleteWidgetRequest,
    deleteWidgetSuccess,
    deleteWidgetFailure,
} = createActions(
    constants.DELETE_WIDGET_REQUEST,
    constants.DELETE_WIDGET_SUCCESS,
    constants.DELETE_WIDGET_FAILURE,
);

export const {
    getWidgetEventTypesRequest,
    getWidgetEventTypesSuccess,
    getWidgetEventTypesFailure,
} = createActions(
    constants.GET_WIDGET_EVENT_TYPES_REQUEST,
    constants.GET_WIDGET_EVENT_TYPES_SUCCESS,
    constants.GET_WIDGET_EVENT_TYPES_FAILURE,
);

export const {
    getUserDivisionsRequest,
    getUserDivisionsSuccess,
    getUserDivisionsFailure,
} = createActions(
    constants.GET_USER_DIVISIONS_REQUEST,
    constants.GET_USER_DIVISIONS_SUCCESS,
    constants.GET_USER_DIVISIONS_FAILURE,
);

export const {
    getUserDriversRequest,
    getUserDriversSuccess,
    getUserDriversFailure,
} = createActions(
    constants.GET_USER_DRIVERS_REQUEST,
    constants.GET_USER_DRIVERS_SUCCESS,
    constants.GET_USER_DRIVERS_FAILURE,
);

export const {
    getSingleWidgetRequest,
    getSingleWidgetSuccess,
    getSingleWidgetFailure,
} = createActions(
    constants.GET_SINGLE_WIDGET_REQUEST,
    constants.GET_SINGLE_WIDGET_SUCCESS,
    constants.GET_SINGLE_WIDGET_FAILURE,
);
