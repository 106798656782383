import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { emptyCache } from '../../core/utils/functions';
import DOM from './login';
import * as globalActions from '../../core/global/globalActions';

class Login extends PureComponent {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    componentDidMount() {
        const { user } = this.props;
        if (user && (user.isLoggedIn || user.loginUser != '')) {
            emptyCache();
        }
    }

    render() {
        return this.view();
    }
}

Login.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
            },
            dispatch,
        ),
    };
}
export const LoginTest = Login;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
