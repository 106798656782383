import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_UNRESOLVED_LOGS_REQUEST: null,
    GET_UNRESOLVED_LOGS_SUCCESS: null,
    GET_UNRESOLVED_LOGS_FAILURE: null,
});

export const {
    getUnresolvedLogsRequest,
    getUnresolvedLogsSuccess,
    getUnresolvedLogsFailure,
} = createActions(
    constants.GET_UNRESOLVED_LOGS_REQUEST,
    constants.GET_UNRESOLVED_LOGS_SUCCESS,
    constants.GET_UNRESOLVED_LOGS_FAILURE,
);
