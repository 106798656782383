import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import defaultStyles from './styles';
import PolygonIcon from '../../icons/PolygonIcon';
import TimeRangeContext from '../../context';
import defaultColors from '../../colours';

function Handle({
    error,
    domain: [min, max],
    handle: { id, value, percent = 0 },
    disabled,
    getHandleProps,
    time,
    index,
}) {
    const { styles, colours } = useContext(TimeRangeContext);

    const leftPosition = `${percent}%`;
    const applyStylesContainer = disabled ? defaultStyles.handleContainerDisabled : defaultStyles.handleContainer;
    const receivedApplyStylesContainer = disabled ? styles.handleContainerDisabled : styles.handleContainer;
    const applyStylesMarker = error ? defaultStyles.handleMarkerError : defaultStyles.handleMarker;
    const receivedApplyStylesMarker = error ? styles.handleMarkerError : styles.handleMarker;

    const hour = time.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2 });
    const minute = time.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2 });
    const second = time.getSeconds().toLocaleString('en-US', { minimumIntegerDigits: 2 });

    const defaultLineDiv = index === 0 ? defaultStyles.firstLineDiv : defaultStyles.lastLineDiv;
    const receivedLineDiv = index === 0 ? styles.firstLineDiv : styles.lastLineDiv;
    const defaultTimeText = index === 0 ? defaultStyles.firstTimeText : defaultStyles.lastTimeText;
    const receivedTimeText = index === 0 ? styles.firstTimeText : styles.lastTimeText;

    return (
        <>
            {index === 1 ? (
                <div
                    className="handleWrapper"
                    style={{ ...defaultStyles.handleWrapper, left: leftPosition, ...styles.handleWrapper }}
                    {...getHandleProps(id)}>
                    <p style={{ ...defaultStyles.timePrimary, ...styles.timePrimary }}>
                        {`${hour}:${minute}:`}
                        <span style={{ ...defaultStyles.timeTextSecondary, ...styles.timeTextSecondary }}>
                            {second}
                        </span>
                    </p>
                    <div style={{ ...defaultStyles.polygon, ...styles.polygon }}>
                        <PolygonIcon color={colours.primaryColor || defaultColors.primaryColor} />
                    </div>
                </div>
            ) : (
                <>
                    <div
                        className="handleWrapper"
                        style={{ ...defaultStyles.handleWrapper, left: leftPosition, ...styles.handleWrapper }}
                        {...getHandleProps(id)}>
                        <div style={{ ...defaultLineDiv, ...receivedLineDiv }} />
                        <p style={{ ...defaultTimeText, ...receivedTimeText }}>
                            {`${hour}:${minute}:`}
                            <span style={{ ...defaultStyles.timeTextSecondary, ...styles.timeTextSecondary }}>
                                {second}
                            </span>
                        </p>
                    </div>
                    <div
                        role="slider"
                        aria-valuemin={min}
                        aria-valuemax={max}
                        aria-valuenow={value}
                        className="handleContainer"
                        style={{ ...applyStylesContainer, left: leftPosition, ...receivedApplyStylesContainer }}>
                        <div
                            className="handleMarker"
                            style={{ ...applyStylesMarker, ...receivedApplyStylesMarker }} />
                    </div>
                </>
            )}

        </>
    );
}

Handle.propTypes = {
    error: PropTypes.bool,
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    time: PropTypes.object,
    index: PropTypes.number,
};

Handle.defaultProps = {
    disabled: false,
    index: 0,
};

export default Handle;
