import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_SMART_WIDGET_PRESETS_REQUEST: null,
    GET_SMART_WIDGET_PRESETS_SUCCESS: null,
    GET_SMART_WIDGET_PRESETS_FAILURE: null,

    UPDATE_PRESET_REQUEST: null,
    UPDATE_PRESET_SUCCESS: null,
    UPDATE_PRESET_FAILURE: null,

    DELETE_PRESET_REQUEST: null,
    DELETE_PRESET_SUCCESS: null,
    DELETE_PRESET_FAILURE: null,

    CREATE_WIDGET_FROM_PRESET_REQUEST: null,
    CREATE_WIDGET_FROM_PRESET_SUCCESS: null,
    CREATE_WIDGET_FROM_PRESET_FAILURE: null,

    CREATE_SMART_WIDGET_REQUEST: null,
    CREATE_SMART_WIDGET_SUCCESS: null,
    CREATE_SMART_WIDGET_FAILURE: null,

    UPDATE_SMART_WIDGET_REQUEST: null,
    UPDATE_SMART_WIDGET_SUCCESS: null,
    UPDATE_SMART_WIDGET_FAILURE: null,

    GET_SMART_WIDGET_INPUT_DATA_REQUEST: null,
    GET_SMART_WIDGET_INPUT_DATA_SUCCESS: null,
    GET_SMART_WIDGET_INPUT_DATA_FAILURE: null,

    UPLOAD_WIDGET_IMAGE_REQUEST: null,
    UPLOAD_WIDGET_IMAGE_SUCCESS: null,
    UPLOAD_WIDGET_IMAGE_FAILURE: null,

    UPLOAD_WIDGET_IMAGE_ATTR_REQUEST: null,
    UPLOAD_WIDGET_IMAGE_ATTR_SUCCESS: null,
    UPLOAD_WIDGET_IMAGE_ATTR_FAILURE: null,

    GET_CHART_DATA_REQUEST: null,
    GET_CHART_DATA_SUCCESS: null,
    GET_CHART_DATA_FAILURE: null,

    GET_VOR_CHART_DATA_REQUEST: null,
    GET_VOR_CHART_DATA_SUCCESS: null,
    GET_VOR_CHART_DATA_FAILURE: null,

    GET_BEST_DRIVER_CHART_DATA_REQUEST: null,
    GET_BEST_DRIVER_CHART_DATA_SUCCESS: null,
    GET_BEST_DRIVER_CHART_DATA_FAILURE: null,

    GET_SMART_WIDGETS_REQUEST: null,
    GET_SMART_WIDGETS_SUCCESS: null,
    GET_SMART_WIDGETS_FAILURE: null,

    GET_SMART_WIDGET_DATA_REQUEST: null,
    GET_SMART_WIDGET_DATA_SUCCESS: null,
    GET_SMART_WIDGET_DATA_FAILURE: null,

    DELETE_SMART_WIDGET_REQUEST: null,
    DELETE_SMART_WIDGET_SUCCESS: null,
    DELETE_SMART_WIDGET_FAILURE: null,

    UPDATE_SMART_WIDGET_POSITIONS_REQUEST: null,
    UPDATE_SMART_WIDGET_POSITIONS_SUCCESS: null,
    UPDATE_SMART_WIDGET_POSITIONS_FAILURE: null,

    GET_PRESET_WIDGET_REQUEST: null,
    GET_PRESET_WIDGET_SUCCESS: null,
    GET_PRESET_WIDGET_FAILURE: null,

    RESET_PRESET_WIDGET_REQUEST: null,
    RESET_PRESET_CHECK_BOX_REQUEST: null,
    RESET_FETCH_PREVIEW_REQUEST: null,
    RESET_CLEAR_CREATED_FORM_REQUEST: null,
    RESET_BASE64_NEW_IMAGE_ID_REQUEST: null,

    GET_USER_WIDGET_REQUEST: null,
    GET_USER_WIDGET_SUCCESS: null,
    GET_USER_WIDGET_FAILURE: null,

    RESET_CHART_DATA_REQUEST: null,
});

export const {
    getSmartWidgetPresetsRequest,
    getSmartWidgetPresetsSuccess,
    getSmartWidgetPresetsFailure,
} = createActions(
    constants.GET_SMART_WIDGET_PRESETS_REQUEST,
    constants.GET_SMART_WIDGET_PRESETS_SUCCESS,
    constants.GET_SMART_WIDGET_PRESETS_FAILURE,
);

export const {
    updatePresetRequest,
    updatePresetSuccess,
    updatePresetFailure,
} = createActions(
    constants.UPDATE_PRESET_REQUEST,
    constants.UPDATE_PRESET_SUCCESS,
    constants.UPDATE_PRESET_FAILURE,
);

export const {
    deletePresetRequest,
    deletePresetSuccess,
    deletePresetFailure,
} = createActions(
    constants.DELETE_PRESET_REQUEST,
    constants.DELETE_PRESET_SUCCESS,
    constants.DELETE_PRESET_FAILURE,
);

export const {
    createWidgetFromPresetRequest,
    createWidgetFromPresetSuccess,
    createWidgetFromPresetFailure,
} = createActions(
    constants.CREATE_WIDGET_FROM_PRESET_REQUEST,
    constants.CREATE_WIDGET_FROM_PRESET_SUCCESS,
    constants.CREATE_WIDGET_FROM_PRESET_FAILURE,
);

export const {
    createSmartWidgetRequest,
    createSmartWidgetSuccess,
    createSmartWidgetFailure,
} = createActions(
    constants.CREATE_SMART_WIDGET_REQUEST,
    constants.CREATE_SMART_WIDGET_SUCCESS,
    constants.CREATE_SMART_WIDGET_FAILURE,
);

export const {
    updateSmartWidgetRequest,
    updateSmartWidgetSuccess,
    updateSmartWidgetFailure,
} = createActions(
    constants.UPDATE_SMART_WIDGET_REQUEST,
    constants.UPDATE_SMART_WIDGET_SUCCESS,
    constants.UPDATE_SMART_WIDGET_FAILURE,
);

export const {
    getSmartWidgetInputDataRequest,
    getSmartWidgetInputDataSuccess,
    getSmartWidgetInputDataFailure,
} = createActions(
    constants.GET_SMART_WIDGET_INPUT_DATA_REQUEST,
    constants.GET_SMART_WIDGET_INPUT_DATA_SUCCESS,
    constants.GET_SMART_WIDGET_INPUT_DATA_FAILURE,
);

export const {
    uploadWidgetImageRequest,
    uploadWidgetImageSuccess,
    uploadWidgetImageFailure,
} = createActions(
    constants.UPLOAD_WIDGET_IMAGE_REQUEST,
    constants.UPLOAD_WIDGET_IMAGE_SUCCESS,
    constants.UPLOAD_WIDGET_IMAGE_FAILURE,
);

// remember to add initial state in smartWidgetReducer when use this request with different attr
export const {
    uploadWidgetImageAttrRequest,
    uploadWidgetImageAttrSuccess,
    uploadWidgetImageAttrFailure,
} = createActions(
    constants.UPLOAD_WIDGET_IMAGE_ATTR_REQUEST,
    constants.UPLOAD_WIDGET_IMAGE_ATTR_SUCCESS,
    constants.UPLOAD_WIDGET_IMAGE_ATTR_FAILURE,
);

export const {
    getChartDataRequest,
    getChartDataSuccess,
    getChartDataFailure,
} = createActions(
    constants.GET_CHART_DATA_REQUEST,
    constants.GET_CHART_DATA_SUCCESS,
    constants.GET_CHART_DATA_FAILURE,
);

export const {
    getVorChartDataRequest,
    getVorChartDataSuccess,
    getVorChartDataFailure,
} = createActions(
    constants.GET_VOR_CHART_DATA_REQUEST,
    constants.GET_VOR_CHART_DATA_SUCCESS,
    constants.GET_VOR_CHART_DATA_FAILURE,
);

export const {
    getBestDriverChartDataRequest,
    getBestDriverChartDataSuccess,
    getBestDriverChartDataFailure,
} = createActions(
    constants.GET_BEST_DRIVER_CHART_DATA_REQUEST,
    constants.GET_BEST_DRIVER_CHART_DATA_SUCCESS,
    constants.GET_BEST_DRIVER_CHART_DATA_FAILURE,
);

export const {
    getSmartWidgetsRequest,
    getSmartWidgetsSuccess,
    getSmartWidgetsFailure,
} = createActions(
    constants.GET_SMART_WIDGETS_REQUEST,
    constants.GET_SMART_WIDGETS_SUCCESS,
    constants.GET_SMART_WIDGETS_FAILURE,
);

export const {
    getSmartWidgetDataRequest,
    getSmartWidgetDataSuccess,
    getSmartWidgetDataFailure,
} = createActions(
    constants.GET_SMART_WIDGET_DATA_REQUEST,
    constants.GET_SMART_WIDGET_DATA_SUCCESS,
    constants.GET_SMART_WIDGET_DATA_FAILURE,
);

export const {
    deleteSmartWidgetRequest,
    deleteSmartWidgetSuccess,
    deleteSmartWidgetFailure,
} = createActions(
    constants.DELETE_SMART_WIDGET_REQUEST,
    constants.DELETE_SMART_WIDGET_SUCCESS,
    constants.DELETE_SMART_WIDGET_FAILURE,
);

export const {
    updateSmartWidgetPositionsRequest,
    updateSmartWidgetPositionsSuccess,
    updateSmartWidgetPositionsFailure,
} = createActions(
    constants.UPDATE_SMART_WIDGET_POSITIONS_REQUEST,
    constants.UPDATE_SMART_WIDGET_POSITIONS_SUCCESS,
    constants.UPDATE_SMART_WIDGET_POSITIONS_FAILURE,
);

export const {
    getPresetWidgetRequest,
    getPresetWidgetSuccess,
    getPresetWidgetFailure,
} = createActions(
    constants.GET_PRESET_WIDGET_REQUEST,
    constants.GET_PRESET_WIDGET_SUCCESS,
    constants.GET_PRESET_WIDGET_FAILURE,
);

export const {
    resetPresetWidgetRequest,
    resetPresetCheckBoxRequest,
    resetFetchPreviewRequest,
    resetClearCreatedFormRequest,
    resetBase64NewImageIdRequest,
} = createActions(
    constants.RESET_PRESET_WIDGET_REQUEST,
    constants.RESET_PRESET_CHECK_BOX_REQUEST,
    constants.RESET_FETCH_PREVIEW_REQUEST,
    constants.RESET_CLEAR_CREATED_FORM_REQUEST,
    constants.RESET_BASE64_NEW_IMAGE_ID_REQUEST,
);

export const {
    getUserWidgetRequest,
    getUserWidgetSuccess,
    getUserWidgetFailure,
} = createActions(
    constants.GET_USER_WIDGET_REQUEST,
    constants.GET_USER_WIDGET_SUCCESS,
    constants.GET_USER_WIDGET_FAILURE,
);

export const {
    resetChartDataRequest,
} = createActions(
    constants.RESET_CHART_DATA_REQUEST,
);
