import React from 'react';

function LowFuelSVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 396.9 396.9"
            width="1em"
            height="1em"
            xmlSpace="preserve">
            <path d="M298.2,265.8l-5.8-123.5c-0.3-5.4-2.6-10.5-6.5-14.3l-27.4-26.4c-3.6-3.5-9.4-3.4-12.9,0.2
	c-3.5,3.6-3.4,9.4,0.2,12.9l14.3,13.7c0,0.2,0,0.3,0,0.5l1,20.7c0.3,5.4,2.9,10.3,7.3,13.4l6.9,4.9l4.6,98.7c0.3,7-3,9.6-5.9,9.7
	c-3.8,0.3-6.4-3.3-6.7-8.9l-3.5-77c-0.3-5.6-2.8-11-6.9-14.8c-4.2-3.8-9.8-5.8-15.3-5.5l-2.5,0.1V92.7c0-8.4-6.8-15.2-15.2-15.2
	H118.6c-8.4,0-15.2,6.8-15.2,15.2v218.4h-4.3c-1.7,0-3,1.4-3,3v15.5h150.4v-15.5c0-1.7-1.4-3-3-3h-4.3V188.6l3.4-0.2
	c0.8,0,1.6,0.2,2.2,0.8c0.6,0.5,1,1.3,1,2.1l3.5,77c0.9,17.4,12.9,26.3,24.6,26.3c0.4,0,0.8,0,1.2,0
	C287.2,294,299.1,283.9,298.2,265.8z M216.8,180.5c0,4.2-3.4,7.6-7.6,7.6h-75.9c-4.2,0-7.6-3.4-7.6-7.6v-73.8c0-4.2,3.4-7.6,7.6-7.6
	h75.9c4.2,0,7.6,3.4,7.6,7.6V180.5z" />
            <g>
                <path d="M179.8,111.1c-0.4-1.2-1-2.2-1.8-3.2c-0.8-0.9-1.8-1.7-3-2.2c-1.2-0.5-2.4-0.8-3.7-0.8c-1.3,0-2.5,0.3-3.7,0.8
		c-1.1,0.5-2.1,1.2-2.9,2.2c-0.8,0.9-1.5,2-1.8,3.1c-0.4,1.2-0.6,2.5-0.4,3.8l4.2,41.4c0.1,1.2,0.7,2.2,1.5,3c0.9,0.8,2,1.2,3.1,1.2
		c2.4,0,4.4-1.8,4.6-4.2l4.4-41.3C180.3,113.6,180.2,112.3,179.8,111.1z" />
                <path d="M171,165.5c-2.2,0-4.3,0.9-5.9,2.4c-1.6,1.6-2.4,3.7-2.4,5.9v0.1c0,2.2,0.9,4.3,2.4,5.9
		c1.6,1.6,3.7,2.4,5.9,2.4c2.2,0,4.3-0.9,5.9-2.4c1.6-1.6,2.4-3.7,2.4-5.9v-0.1c0-2.2-0.9-4.3-2.4-5.9S173.2,165.5,171,165.5z" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default LowFuelSVG;
