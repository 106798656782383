/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import './progressCircle.scss';

function ProgressCircle({
    percent, type, width, status, strokeColor, strokeWidth, className,
}) {
    return (
        <div style={{ textAlign: 'center' }}>
            <Progress
                className={className}
                type={type}
                percent={percent}
                width={width}
                strokeWidth={strokeWidth}
                status={status}
                strokeColor={strokeColor}
                strokeLinecap="square" />
        </div>
    );
}

ProgressCircle.defaultProps = {
    percent: 0,
    type: 'circle',
    width: 80,
    status: 'normal',
    strokeColor: 'blue',
    strokeWidth: 15,
    className: '',
};

ProgressCircle.propTypes = {
    percent: PropTypes.number,
    type: PropTypes.string,
    width: PropTypes.number,
    status: PropTypes.string,
    strokeColor: PropTypes.string,
    strokeWidth: PropTypes.number,
    className: PropTypes.string,
};

export default ProgressCircle;
