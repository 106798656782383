/* global: localStorage */
import {
    call, put, takeEvery, all, takeLatest, cancelled,
} from 'redux-saga/effects';
import { message, notification } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    mapDeviceListSuccess,
    mapDeviceListFailure,

    mapDevicesLastLocationSuccess,
    mapDevicesLastLocationFailure,

    mapDeviceListByLocationSuccess,
    mapDeviceListByLocationFailure,

    mapDeviceLocationsSuccess,
    mapDeviceLocationsFailure,

    getDeviceLocationDetailedInfoSuccess,
    getDeviceLocationDetailedInfoFailure,

    getMapEventsHeatMapSuccess,
    getMapEventsHeatMapFailure,

    getDeviceHistoricVideosSuccess,
    getDeviceHistoricVideosFailure,
    getCompanyAllTriggersSuccess,
    getCompanyAllTriggersFailure,

    createTriggerSuccess,
    createTriggerFailure,

    mapAssetSearchSuccess,
    mapAssetSearchFailure,

    mapGetLiveAssetsLocationSuccess,
    mapGetLiveAssetsLocationFailure,

    deleteGeofenceSuccess,
    deleteGeofenceFailure,

    updateDeviceTriggerSuccess,
    updateDeviceTriggerFailure,

    getTripInformationSuccess,
    getTripInformationFailure,

    getDeviceTimelineSuccess,
    getDeviceTimelineFailure,

    getDeviceGeofenceTimelineSuccess,
    getDeviceGeofenceTimelineFailure,

} from './deviceActions';

const {
    MAP_DEVICE_LIST_REQUEST,
    MAP_DEVICES_LAST_LOCATION_REQUEST,
    MAP_DEVICE_LIST_BY_LOCATION_REQUEST,
    MAP_DEVICE_LOCATIONS_REQUEST,
    GET_DEVICE_LOCATION_DETAILED_INFO_REQUEST,
    GET_MAP_EVENTS_HEAT_MAP_REQUEST,
    GET_DEVICE_HISTORIC_VIDEOS_REQUEST,
    GET_COMPANY_ALL_TRIGGERS_REQUEST,
    CREATE_TRIGGER_REQUEST,
    MAP_ASSET_SEARCH_REQUEST,
    MAP_GET_LIVE_ASSETS_LOCATION_REQUEST,
    DELETE_GEOFENCE_REQUEST,
    UPDATE_DEVICE_TRIGGER_REQUEST,
    GET_TRIP_INFORMATION_REQUEST,
    GET_DEVICE_TIMELINE_REQUEST,
} = require('./deviceActions').constants;

function* getDeviceListRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/devices',
            body: action.payload,
        });
        yield put(mapDeviceListSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(mapDeviceListFailure(e));
    }
}

function* mapDevicesLastLocation({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/devices/last-location',
            body: payload,
        });

        yield put(mapDevicesLastLocationSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(mapDevicesLastLocationFailure(e));
    }
}

function* mapDeviceListByLocation({ payload }) {
    const {
        startDate, endDate, lat, lng,
    } = payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/devices-location',
            params: {
                start_date: startDate,
                end_date: endDate,
                lat,
                lng,
            },
        });

        yield put(mapDeviceListByLocationSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(mapDeviceListByLocationFailure(e));
    }
}

function* getDeviceLocationsRequest(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/device/${action.payload.imei}`,
            params: action.payload,
        });
        yield put(mapDeviceLocationsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(mapDeviceLocationsFailure(e));
    }
}

function* getDeviceLocationDetailedInfo({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'device/location-detailed-info',
            params: payload,
        });

        yield put(getDeviceLocationDetailedInfoSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getDeviceLocationDetailedInfoFailure(e));
    }
}

function* getDeviceHeatMapRequest({ payload }) {
    const abortController = new AbortController();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/device/heat-map',
            params: payload,
            signal: abortController.signal,
        });
        yield put(getMapEventsHeatMapSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getMapEventsHeatMapFailure(e));
    } finally {
        if (yield (cancelled())) {
            abortController.abort();
        }
    }
}

function* getHistoricVideos(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/historic-video/get-videos',
            params: {
                page: (action.payload.page || 1),
                device_id: action.payload.device_id,
            },
            body: action.payload,
        });

        yield put(getDeviceHistoricVideosSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDeviceHistoricVideosFailure(e));
    }
}

function* getAllTriggers(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/get-all-triggers',
        });
        yield put(getCompanyAllTriggersSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getCompanyAllTriggersFailure(e));
    }
}

function* createTrigger(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/trigger/create',
            body: action.payload,
        });
        yield put(createTriggerSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(createTriggerFailure(e));
    }
}

function* mapAssetSearch({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/asset/map-search-assets',
            body: payload,
            params: payload,
        });
        yield put(mapAssetSearchSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(mapAssetSearchFailure(e));
    }
}

function* mapAssetsLive(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/devices/live',
            params: action.payload,
        });
        yield put(mapGetLiveAssetsLocationSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(mapGetLiveAssetsLocationFailure(e));
    }
}

function* deleteGeofence(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'device/delete-geofence',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'You have succesfully deleted Geofence' });
        yield put(deleteGeofenceSuccess(action.payload));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(deleteGeofenceFailure(e));
    }
}

function* updateDeviceTrigger(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/trigger/${action.payload.id}/update`,
            body: action.payload,
        });

        yield put(updateDeviceTriggerSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updateDeviceTriggerFailure(e));
    }
}

function* getTripInformation(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/device/get-trip-information',
            body: action.payload,
        });

        yield put(getTripInformationSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getTripInformationFailure(e));
    }
}

function* getDeviceTimeline(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/device/timeline',
            body: action.payload,
        });

        yield put(getDeviceTimelineSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getDeviceTimelineFailure(e));
    }
}

function* getDeviceGeofenceTimeline(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/device/geofence-timeline',
            body: action.payload,
        });

        yield put(getDeviceGeofenceTimelineSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getDeviceGeofenceTimelineFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* deviceSaga() {
    yield all([
        takeEvery(MAP_DEVICE_LIST_REQUEST, getDeviceListRequest),
        takeEvery(MAP_DEVICES_LAST_LOCATION_REQUEST, mapDevicesLastLocation),
        takeEvery(MAP_DEVICE_LIST_BY_LOCATION_REQUEST, mapDeviceListByLocation),
        takeEvery(MAP_DEVICE_LOCATIONS_REQUEST, getDeviceLocationsRequest),
        takeEvery(GET_DEVICE_LOCATION_DETAILED_INFO_REQUEST, getDeviceLocationDetailedInfo),
        takeLatest(GET_MAP_EVENTS_HEAT_MAP_REQUEST, getDeviceHeatMapRequest),
        takeEvery(GET_DEVICE_HISTORIC_VIDEOS_REQUEST, getHistoricVideos),
        takeEvery(GET_COMPANY_ALL_TRIGGERS_REQUEST, getAllTriggers),
        takeEvery(CREATE_TRIGGER_REQUEST, createTrigger),
        takeEvery(MAP_ASSET_SEARCH_REQUEST, mapAssetSearch),
        takeEvery(MAP_GET_LIVE_ASSETS_LOCATION_REQUEST, mapAssetsLive),
        takeEvery(DELETE_GEOFENCE_REQUEST, deleteGeofence),
        takeEvery(UPDATE_DEVICE_TRIGGER_REQUEST, updateDeviceTrigger),
        takeEvery(GET_TRIP_INFORMATION_REQUEST, getTripInformation),
        takeEvery(GET_DEVICE_TIMELINE_REQUEST, getDeviceTimeline),
        takeEvery(GET_DEVICE_TIMELINE_REQUEST, getDeviceGeofenceTimeline),
    ]);
}
