/* global window document */
/* global document: true */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FlagFilled } from '@ant-design/icons';
import { notification } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'underscore';
import moment from 'moment';
import { debounce } from 'lodash';

import DOM from './detailView';
import MapAssetPopup from '../elements/MapAssetPopup';
import {
    convertFromSeconds, openStreetView, secondsToHms,
} from '../../core/utils/functions';
import * as videoActions from '../../core/video/videoActions';
import * as eventsActions from '../../core/events/eventsActions';
import * as deviceActions from '../../core/device/deviceActions';
import {
    calculateSpeedAndUnit, getMapAssetPopupContent, getMapAssetPopupHead,
    setMarkerPopoverToLoading, setMarkerPopoverDetails,
} from '../../core/utils/mapUtils';
import { fetchApiAuth } from '../../core/utils/api';

const {
    MapAssetPopupContent,
    MapAssetPopupHead,
} = MapAssetPopup;

class DetailView extends Component {
    constructor(props) {
        super(props);
        this.pdfDocumentRef = React.createRef();
        this.pdfRef = React.createRef();
        this.view = DOM;
        this.googleMapAccess = React.createRef();
        this.cachedVideosRefs = [];
        this.centerMap = {};
        this.eventsInTrip = [];
        this.state = {
            sliderValue: props?.params?.slider_value || props?.selectedEvent?.interval || props?.selectedEvent?.startInterval,
            sliderRangeMinValue: props?.selectedEvent?.startInterval,
            sliderRangeMaxValue: props?.selectedEvent?.endInterval,
            selectedVideoChannels: [],
            cachedVideos: [],
            videoMode: 'streaming',
            streamingVideos: false,
            showResetBtn: false,
            setVideoTab: true,
            markerPopover: null,
            videoStreamsReady: false,
            exportedCacheVideo: null,
        };
        this.streamingVideosRefs = [];
        this.loadedStreamingVideos = [];

        this.debouncedGetUpdatedTimeVideosStream = debounce(this.getUpdatedTimeVideosStream, 500);
    }

    componentDidMount() {
        const {
            selectedEvent, actions, eventInfoFetched, showShareBlock, user,
        } = this.props;

        const canViewLive = user?.permissions?.live_video;
        this.loadedStreamingVideos = [];

        setTimeout(() => {
            if (selectedEvent) {
                if (eventInfoFetched) {
                    actions.resetEventInfoRequest();
                    setTimeout(() => {
                        if (this.googleMapAccess && this.googleMapAccess.current) {
                            // console.log('######## EXECUTING THIS');
                            // this.googleMapAccess.current.refreshMap();
                            this.googleMapAccess.current.setState({ sliderMaxValue: selectedEvent.endInterval });
                            this.googleMapAccess.current.executeAutoZoom();
                        }
                    }, 500);
                }
            }
        }, 2000);

        if (!showShareBlock) {
            setTimeout(() => {
                if (this.googleMapAccess && this.googleMapAccess.current) {
                    this.googleMapAccess.current.refreshMap();
                    this.googleMapAccess.current.executeAutoZoom();
                }
            }, 2000);
        }

        if (!canViewLive) {
            this.setState({ setVideoTab: false, videoMode: 'cached' });
        }

        setMarkerPopoverToLoading(this);
    }

    onRemoved(channel) {
        if (this.loadedStreamingVideos.includes(channel)) {
            this.loadedStreamingVideos = this.loadedStreamingVideos.filter((item) => item !== channel);
            const videoStreamsReady = (this.loadedStreamingVideos?.length == this.streamingVideosRefs?.length);
            this.setState({ videoStreamsReady });
        }
    }

    onReady(channel) {
        if (!this.loadedStreamingVideos.includes(channel)) {
            this.loadedStreamingVideos.push(channel);
            const videoStreamsReady = (this.loadedStreamingVideos?.length == this.streamingVideosRefs?.length);
            this.setState({ videoStreamsReady });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            selectedEvent, updateMapEventData, actions, eventInfoFetched, videoUrlsError, isFetchingVideoUrls,
            sliderValue, deviceDetailedLocationFetching, deviceDetailedLocationInfo,
        } = this.props;
        const {
            setVideoTab, videoMode, videoStreamsReady, exportedCacheVideo,
        } = this.state;
        const cachedVideos = selectedEvent?.dvrDetails?.cached_videos || [];
        if (!exportedCacheVideo && selectedEvent?.dvrDetails?.exported_cache_video) {
            const exportedCacheVideoValue = selectedEvent?.dvrDetails?.exported_cache_video;
            this.setState({ exportedCacheVideo: exportedCacheVideoValue });
        }

        if (setVideoTab && cachedVideos.length > 0) {
            this.setState({ setVideoTab: false, videoMode: 'cached' });
        }

        if (updateMapEventData === true) {
            actions.resetUpdateMapEventDataFlagRequest();
            setTimeout(() => {
                if (this.googleMapAccess && this.googleMapAccess.current) {
                    const midSliderValue = Math.floor((selectedEvent.endInterval - selectedEvent.startInterval) / 6);
                    this.googleMapAccess.current.setState({
                        sliderValue: sliderValue || (selectedEvent.startInterval + midSliderValue),
                        sliderMinValue: selectedEvent.startInterval,
                        sliderMaxValue: selectedEvent.endInterval,
                        sliderRangeMinValue: selectedEvent.startInterval,
                        sliderRangeMaxValue: selectedEvent.endInterval,
                    });
                    this.googleMapAccess.current.executeAutoZoom();
                    // the value is updated but not trigger re-render, update the state to re-render with latest data
                    this.setState({
                        sliderValue: sliderValue || (selectedEvent.startInterval + midSliderValue),
                        sliderMinValue: selectedEvent.startInterval,
                        sliderMaxValue: selectedEvent.endInterval,
                        sliderRangeMinValue: selectedEvent.startInterval,
                        sliderRangeMaxValue: selectedEvent.endInterval,
                    });
                }
            }, 250);
        }

        if (prevProps.selectedEvent && selectedEvent) {
            if (prevProps.selectedEvent.startInterval !== selectedEvent.startInterval) {
                this.setState({ sliderValue: selectedEvent.startInterval });
            }
            if (eventInfoFetched) {
                actions.resetEventInfoRequest();
                setTimeout(() => {
                    if (this.googleMapAccess && this.googleMapAccess.current) {
                        this.googleMapAccess.current.setState({ sliderMaxValue: selectedEvent.endInterval });
                        this.googleMapAccess.current.executeAutoZoom();
                    }
                }, 500);
            }
        }

        if (videoUrlsError && prevProps.videoUrlsError !== videoUrlsError) {
            this.setState({ streamingVideos: false });
        }

        if (!isFetchingVideoUrls && prevProps.isFetchingVideoUrls !== isFetchingVideoUrls) {
            if (this.googleMapAccess?.current && videoMode !== 'streaming') {
                this.googleMapAccess.current.togglePlayPause();
            }
        }

        if (videoMode == 'streaming' && videoStreamsReady && prevState.videoStreamsReady !== videoStreamsReady) {
            this.googleMapAccess.current.togglePlayPause();
        }

        if (!this.props.eventsIsFetching && prevProps.eventsIsFetching) {
            setTimeout(() => {
                if (this.googleMapAccess && this.googleMapAccess.current) {
                    this.googleMapAccess.current.refreshMap();
                    this.googleMapAccess.current.executeAutoZoom();
                }
            }, 2000);
        }

        if (deviceDetailedLocationFetching === false && deviceDetailedLocationInfo !== prevProps.deviceDetailedLocationInfo) {
            const { showLive } = this.googleMapAccess.current.state;
            setMarkerPopoverDetails(this, showLive);
        }
    }

    renderPdf(toPdf) {
        window.scrollTo(0, 0);
        setTimeout(() => {
            toPdf();
            this.setState({
                parentWidth: parseInt(window?.frameElement?.getAttribute('data-parent-width'), 10) || window.innerWidth,
                parentHeight: parseInt(window?.frameElement?.getAttribute('data-parent-height'), 10) || window.innerHeight,
            });
        }, 2500);
    }

    getSelectedVideosUrl = () => {
        const { actions, selectedEvent } = this.props;
        const { selectedVideoChannels, sliderValue } = this.state;
        const searchChannels = {};
        searchChannels.date_from = moment(selectedEvent?.startTimeBST).startOf('day').seconds(sliderValue).format('YYYY-MM-DD HH:mm:ss');
        searchChannels.date_to = selectedEvent?.endTimeBST;
        searchChannels.channels = selectedVideoChannels ? selectedVideoChannels.join(',') : 0;
        searchChannels.device_id = selectedEvent?.dvrDetails?.id || null;
        actions.getChannelsAndVideosRequest(searchChannels);
        this.setState({ streamingVideos: true });
    };

    requestCacheAgain = () => {
        const { actions, selectedEvent, shardId } = this.props;
        const failedVideos = [];
        Object.keys(selectedEvent.dvrDetails.discovered_videos).map((key) => {
            const video = selectedEvent.dvrDetails.discovered_videos[key][0];
            if (video.status == 'not-available') {
                failedVideos.push(video.id);
            }
        });
        if (!isEmpty(failedVideos)) {
            actions.resetTrimAndCacheVideoRequest({ failedVideos });

            setTimeout(() => {
                const params = {};
                params.videoReference = selectedEvent.reference;
                params.shardId = shardId || '1';
                actions.getEventInfoByVideoReferenceRequest(params);
            }, 2000);
        }
    };

    mapDevicePath = (selectedEvent) => {
        const { user, company } = this.props;
        const eventsInTrip = [];
        let foundFirstEvent = false;
        const info = (selectedEvent && selectedEvent.info) || {};
        const path = selectedEvent && selectedEvent.path ? selectedEvent.path.map((event, eventIndex) => {
            let { speed } = event;
            let unit = 'kph';
            if (user && user.profile && user.profile.unit_of_measurement_preference === 'british-imperial') {
                unit = 'mph';
                speed = Math.floor(speed * 0.62);
            }

            const videoFunc = null;
            // if (!isNull(event.id)) {
            //     videoFunc = () => { goToEventPage(event.id, event.device_id); };
            // }

            const tempEvent = {};
            tempEvent.showLoading = false;
            tempEvent.showNoData = false;
            tempEvent.time_interval = event.time_interval;
            tempEvent.time = event.time;
            tempEvent.lat = event.lat;
            tempEvent.lng = event.lng;
            tempEvent.record_id = event.id;
            tempEvent.angle = event.angle;
            tempEvent.speed = speed;
            tempEvent.position = 'path';
            tempEvent.title = selectedEvent.info.asset_name;
            tempEvent.registration = selectedEvent.info.registration;
            if ((event.priority === 1 && foundFirstEvent === false) || eventIndex === 0) {
                this.centerMap.lat = parseFloat(event.lat);
                this.centerMap.lng = parseFloat(event.lng);
                if (eventIndex > 0) {
                    foundFirstEvent = true;
                }
            }
            if (event.show_in_events === 1) {
                tempEvent.is_event = true;
                this.centerMap.lat = parseFloat(event.lat);
                this.centerMap.lng = parseFloat(event.lng);
                const iconStyle = {};
                iconStyle.fontSize = '16px';
                iconStyle.color = '#CF2B28';

                tempEvent.position = 'event';
                tempEvent.currentMarker = {};
                let eventTitle = event.event_name;

                if (event.event_type == 253) {
                    if (event.green_driving_type == 0) {
                        eventTitle = 'Eco-friendly driving';
                    } else if (event.green_driving_type == 1) {
                        eventTitle = 'Harsh accelerating';
                    } else if (event.green_driving_type == 2) {
                        eventTitle = 'Harsh braking';
                    } else if (event.green_driving_type == 3) {
                        eventTitle = 'Harsh cornering';
                    }
                }
                eventsInTrip.push({ title: eventTitle, value: moment(event.time).format('DD/MM/YYYY hh:mm:ss') });

                if (event.icon && event.icon !== '') {
                    tempEvent.icon = <LegacyIcon style={iconStyle} type={event.icon} theme="filled" />;
                } else {
                    tempEvent.icon = <FlagFilled style={iconStyle} />;
                }

                tempEvent.title = (
                    <MapAssetPopupHead
                        speed={tempEvent.speed}
                        heading={eventTitle}
                        registration={tempEvent.registration}
                    />
                );
                tempEvent.content = (
                    <MapAssetPopupContent
                        showLoading={false}
                        showPicture
                        assetPicture={info.asset_picture}
                        userPicture={info.profile_picture}
                        defaultImage={user?.userCompany?.company_image}
                        event_key={eventTitle}
                        // videoFunc={videoFunc}
                        online={event.online}
                        online_status={event.online_status}
                        dateTime={event.time}
                        driver={info.driver}
                        speed={speed}
                        speedUnit={unit}
                        streetViewIconClicked={openStreetView}
                        googleMapAccessRef={this.googleMapAccess}
                        lat={event.lat}
                        lng={event.lng}
                        location_data={event.location_data}
                        user={user}
                        angle={event.angle}
                        showTimeToDestinationBtn={company && company.features && company.features.find(feature => feature.id === 'time-to-destination')}
                    />
                );
                tempEvent.markerPopover = tempEvent.content;

                // tempEvent.currentMarker.icon = event.icon && event.icon !== ''
                //     ? (
                //         <Tooltip title={event.event_name}><AntIcon
                //             type={event.icon}
                //             theme="filled"
                //             style={iconStyle} />
                //         </Tooltip>
                //     )
                //     : <AntIcon type="flag" theme="filled" />;
            }
            return tempEvent;
        })
            : [];
        this.eventsInTrip = eventsInTrip;
        return path;
    };

    getAssetCurrentPin = (mappedDevicePath) => {
        const { sliderValue } = this.state;
        const { selectedEvent, user } = this.props;
        const assets = mappedDevicePath.filter((path) => path.time_interval == sliderValue);

        if (assets && assets[0]) {
            const { speed, unit } = calculateSpeedAndUnit(assets[0], user);
            const title = getMapAssetPopupHead(assets[0]);
            const content = getMapAssetPopupContent(this, assets[0], speed, unit, user?.userCompany?.company_image);

            assets[0].arrowIcon = true;
            assets[0].hasEvents = true;
            assets[0].imei = '';
            assets[0].online = 0;
            assets[0].registration = '';
            assets[0].markers = [];
            assets[0].currentMarker = {};
            assets[0].currentMarker.lat = parseFloat(assets[0].lat);
            assets[0].currentMarker.lng = parseFloat(assets[0].lng);
            assets[0].currentMarker.angle = assets[0].angle;
            assets[0].currentMarker.imei = '';
            assets[0].currentMarker.showArrowIcon = true;
            assets[0].currentMarker.speed = speed;
            assets[0].currentMarker.time_interval = assets[0].time_interval;
            assets[0].currentMarker.title = '';
            assets[0].markers[assets[0].time_interval] = {};
            assets[0].markers[assets[0].time_interval].lat = parseFloat(assets[0].lat);
            assets[0].markers[assets[0].time_interval].lng = parseFloat(assets[0].lng);
            assets[0].markers[assets[0].time_interval].record_id = assets[0].record_id;
            assets[0].markers[assets[0].time_interval].angle = assets[0].angle;
            assets[0].markers[assets[0].time_interval].imei = '';
            assets[0].markers[assets[0].time_interval].showArrowIcon = true;
            assets[0].markers[assets[0].time_interval].speed = speed;
            assets[0].markers[assets[0].time_interval].time_interval = assets[0].time_interval;
            assets[0].markers[assets[0].time_interval].title = title;
            assets[0].markers[assets[0].time_interval].content = content;
        }
        return assets;
    };

    getUpdatedTimeVideosStream = (value) => {
        const selectedTime = convertFromSeconds(value).padStart(8, '0');
        const { actions, selectedEvent } = this.props;
        const { selectedVideoChannels } = this.state;
        if (selectedVideoChannels.length > 0) {
            const searchChannels = {};
            searchChannels.date_from = `${moment(selectedEvent?.startTimeUTC).format('YYYY-MM-DD')} ${selectedTime}`;
            searchChannels.date_to = selectedEvent?.endTimeBST;
            searchChannels.channels = selectedVideoChannels.length > 0 ? selectedVideoChannels.join(',') : '0';
            searchChannels.device_id = selectedEvent?.dvrDetails?.id || null;
            actions.getChannelsAndVideosRequest(searchChannels);
        }
    };

    onSliderRangeChange = (value, formattedValue, newSliderRangeMinValue, newSliderRangeMaxValue, changedByPlay) => {
        const processStartTime = new Date();
        const {
            sliderValue, sliderRangeMinValue, sliderRangeMaxValue, videoMode,
        } = this.state;
        const { selectedEvent } = this.props;
        // console.log('<><><><><><><>', sliderValue);
        if (sliderRangeMinValue != newSliderRangeMinValue || sliderRangeMaxValue != newSliderRangeMaxValue) {
            this.setState({
                sliderRangeMinValue: newSliderRangeMinValue,
                sliderRangeMaxValue: newSliderRangeMaxValue,
                sliderValue: value,
            });
        } else {
            this.setState({ sliderValue: value });
        }
        if (videoMode == 'streaming' && !changedByPlay) {
            this.debouncedGetUpdatedTimeVideosStream(value);
            if (this.googleMapAccess?.current?.state?.showPlayPause) {
                this.googleMapAccess.current.togglePlayPause();
            }
        } else {
            if (changedByPlay) {
                // eslint-disable-next-line no-param-reassign
                value += 1;
            }
            if (videoMode == 'cached') {
                let sliderUpdated = false; // need to only update the slider once
                (this.cachedVideosRefs).forEach((video, videoIndex) => {
                    const videoParts = (video.key).split('#');
                    const startTime = parseInt(videoParts[1], 10);
                    const endTime = startTime + parseInt(videoParts[2], 10);
                    if (video && video.element && video.element.play) {
                        let currentTime = (value - startTime);
                        let playerMaxed = (currentTime > video.element.duration);

                        if (this.googleMapAccess?.current?.state?.showPlayPause && !playerMaxed) {
                            video.element.play();
                        }

                        if (value >= startTime && value <= endTime) {
                            const sliderCurrentTime = currentTime;

                            let currentVideoOffset = 0;
                            if (video.element.currentTime && changedByPlay) {
                                // adding previous video time offset
                                currentVideoOffset += Math.floor(((video.element.currentTime) - currentTime) * 100) / 100;

                                if (currentVideoOffset > 0) {
                                    currentTime += currentVideoOffset;
                                }
                            }
                            /**
                             * adding current process time offset
                             * by the time the function was called from the map, to now some milliseconds have passed
                             * we need to add this time when setting the new video time
                             */
                            if (changedByPlay) {
                                const processCurrentTime = new Date();
                                const processTimeDif = (processCurrentTime - processStartTime) / 1000;
                                currentTime = Math.floor((currentTime + processTimeDif) * 100) / 100;
                            }

                            // need to update slider value because video player plays faster than slider
                            const sliderVideoDiff = currentTime - sliderCurrentTime;
                            if (!sliderUpdated) {
                                if (sliderVideoDiff >= 3) {
                                    const newSliderValue = (value + sliderVideoDiff);
                                    sliderUpdated = true;
                                    this.setState({ sliderValue: newSliderValue });
                                } else {
                                    sliderUpdated = true;
                                    this.setState({ sliderValue: value });
                                }
                            }
                            video.element.currentTime = currentTime;
                        }
                        if (playerMaxed) {
                            video.element.currentTime = video.element.duration;
                            return;
                        }
                        if (value < startTime) {
                            video.element.currentTime = startTime - startTime;
                        }
                        if (value > endTime) {
                            video.element.currentTime = endTime - startTime;
                        }
                    }
                });
            } else if (videoMode == 'streaming') {
                if (this.streamingVideosRefs.length) {
                    const video = this.streamingVideosRefs[0];

                    if (video && video.element && video.element.play) {
                        const times = this.streamingVideosRefs.map((i) => i.element.currentTime);
                        const lowest = Math.min(...times);
                        const highest = Math.max(...times);
                        if (changedByPlay) {
                            if (highest - lowest > 1) {
                                (this.streamingVideosRefs).forEach((video, videoIndex) => {
                                    video.element.currentTime = parseInt(lowest);
                                });
                            }
                            // lets use the first to set the slider now
                            const videoParts = (video.key).split('#');
                            const startTime = parseInt(videoParts[1], 10);
                            this.setState({ sliderValue: startTime + lowest });
                        }
                    } else {
                        this.setState({ sliderValue: value });
                    }
                }
            }
        }
    };

    handlePause = () => {
        const { videoMode } = this.state;
        if (videoMode == 'cached') {
            (this.cachedVideosRefs).forEach((video) => {
                if (video && video.element && video.element.pause) {
                    video.element.pause();
                }
            });
        }
        if (videoMode == 'streaming') {
            (this.streamingVideosRefs).forEach((video) => {
                if (video && video.element && video.element.pause) {
                    video.element.pause();
                }
            });
        }
    };

    handleResume = () => {
        const { videoMode } = this.state;

        if (videoMode == 'streaming') {
            (this.streamingVideosRefs).forEach((video) => {
                if (video && video.element && video.element.play) {
                    video.element.play();
                }
            });
        }
    };

    requestCacheAndTrimVideos = () => {
        const { sliderRangeMinValue, sliderRangeMaxValue, selectedVideoChannels } = this.state;
        const { selectedEvent, actions } = this.props;

        const deviceId = selectedEvent?.dvrDetails?.id || null;
        const assetId = selectedEvent?.info?.asset_id || null;
        const userid = selectedEvent?.info?.user_id || null;
        const videoDate = selectedEvent?.startTimeUTC || null;
        const location = selectedEvent?.location || null;

        actions.trimAndCacheVideoRequest({
            deviceId,
            assetId,
            videoDate,
            selectedVideoChannels,
            sliderRangeMinValue,
            sliderRangeMaxValue,
            location,
        });
    };

    requestExportCacheVideo = () => {
        const { selectedEvent } = this.props;
        const opts = {
            method: 'POST',
            url: `/video/${selectedEvent.reference}/export-cache-video`,
        };
        return fetchApiAuth(opts)
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error(res.status);
                }

                notification.success({ message: 'Success', description: 'Started exporting of cached video.' });
            })
            .catch((err) => {
                notification.error({ message: 'Error', description: 'Failed to export video.' });
            });
    };

    reprocessCacheVideo = () => {
        const { selectedEvent } = this.props;
        const opts = {
            method: 'POST',
            url: `/download-queue/reprocess-videos/${selectedEvent.reference}`,
        };
        return fetchApiAuth(opts)
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error(res.status);
                }

                notification.success({ message: 'Success', description: 'Started reprocessing of cached video.' });
            })
            .catch((err) => {
                notification.error({ message: 'Error', description: 'Failed to reprocess cache video.' });
            });
    };

    requestExportCacheVideoStatus = () => {
        const { selectedEvent } = this.props;
        const opts = {
            method: 'GET',
            url: `/video/${selectedEvent.reference}/export-cache-video`,
        };
        return fetchApiAuth(opts)
            .then((res) => {
                const exportedCacheVideo = res.data;
                if (exportedCacheVideo?.status != this.state.exportedCacheVideo?.status) {
                    return this.setState({ exportedCacheVideo });
                }
                return exportedCacheVideo;
            })
            .then(() => this.state.exportedCacheVideo)
            .catch((err) => {
                console.error('error requestExportCacheVideoStatus:', err);
                throw err;
            });
    };

    handleForward = (option) => {
        const { selectedEvent, maxAllowedTimeline, onForward } = this.props;
        let sliderMinValue = selectedEvent?.startInterval;
        let sliderMaxValue = selectedEvent?.endInterval;
        sliderMaxValue += option.value;

        const timelineDuration = sliderMaxValue - sliderMinValue;
        if (timelineDuration > maxAllowedTimeline) {
            const timelineDiff = timelineDuration - maxAllowedTimeline;
            sliderMinValue += timelineDiff;
        }

        const startTime = moment(selectedEvent?.startTimeBST).startOf('day').add(sliderMinValue, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        const endTime = moment(selectedEvent?.startTimeBST).startOf('day').add(sliderMaxValue, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        this.setState({ showResetBtn: true });
        onForward(startTime, endTime);
    };

    handleRewind = (option) => {
        const { selectedEvent, maxAllowedTimeline, onRewind } = this.props;
        let sliderMinValue = selectedEvent?.startInterval;
        let sliderMaxValue = selectedEvent?.endInterval;
        sliderMinValue -= option.value;

        const timelineDuration = sliderMaxValue - sliderMinValue;
        if (timelineDuration > maxAllowedTimeline) {
            const timelineDiff = timelineDuration - maxAllowedTimeline;
            sliderMaxValue -= timelineDiff;
        }

        const startTime = moment(selectedEvent?.startTimeBST).startOf('day').add(sliderMinValue, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        const endTime = moment(selectedEvent?.startTimeBST).startOf('day').add(sliderMaxValue, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        this.setState({ showResetBtn: true });
        onRewind(startTime, endTime);
    };

    handleIncreaseOptionCallback = (option) => {
        const { selectedEvent, onIncreaseOptionClick } = this.props;
        const { sliderValue } = this.state;
        let sliderMinValue = sliderValue - (option.value / 2);
        const sliderMaxValue = sliderValue + (option.value / 2);

        if (sliderMinValue < 0) {
            sliderMinValue = 0;
        }

        if (sliderMaxValue >= 86399) {
            sliderMinValue = 86399;
        }

        const startTime = moment(selectedEvent?.startTimeBST).startOf('day').add(sliderMinValue, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        const endTime = moment(selectedEvent?.startTimeBST).startOf('day').add(sliderMaxValue, 'seconds').format('YYYY-MM-DD HH:mm:ss');

        this.setState({ showResetBtn: true });
        onIncreaseOptionClick(startTime, endTime, sliderValue);
    };

    handleReset = () => {
        const { onReset } = this.props;
        this.setState({ showResetBtn: false });
        onReset();
    };

    render() {
        return this.view();
    }
}

DetailView.defaultProps = {
    selectedEvent: {},
    googleMapApi: null,
    shareReportType: 'event',
    eventsData: [],
    allowActions: true,
    onForward: () => { },
    onRewind: () => { },
    onIncreaseOptionClick: () => { },
    onReset: () => { },
    showStreamingSectionOnLoad: false,
    maxAllowedTimeline: 60 * 30,
    sliderValue: null,
    showDownloadIframe: true,
};

DetailView.propTypes = {
    selectedEvent: PropTypes.object,
    googleMapApi: PropTypes.object,
    shareReportType: PropTypes.string,
    eventsData: PropTypes.array,
    allowActions: PropTypes.bool,
    onForward: PropTypes.func,
    onRewind: PropTypes.func,
    onReset: PropTypes.func,
    onIncreaseOptionClick: PropTypes.func,
    showStreamingSectionOnLoad: PropTypes.bool,
    maxAllowedTimeline: PropTypes.number,
    sliderValue: PropTypes.number,
    showDownloadIframe: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
        searchedSingleVideoChannels: state.video.searchedSingleVideoChannels,
        isFetchingVideoUrls: state.video.isFetchingVideoUrls,
        videoUrlsError: state.video.error,
        updateMapEventData: state.events.updateMapEventData,
        eventInfoFetched: state.events.eventInfoFetched,
        eventsIsFetching: state.events.isFetching,
        deviceDetailedLocationFetching: state.device.deviceDetailedLocationFetching,
        deviceDetailedLocationInfo: state.device.deviceDetailedLocationInfo,
        shardId: state.global.shardId,
        company: state.user.userCompany,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...videoActions,
                ...eventsActions,
                ...deviceActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DetailView);
