import React from 'react';

function CyclearLogoGreySVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1179.1 396.9"
            width="1em"
            height="1em"
            xmlSpace="preserve">

            <g>
                <g>
                    <path d="M548.4,226.9h-45c-1.1,12-4.5,20-10,24H472l-3.3-2.3c-0.4-2-0.3-4.9,0.3-8.7l11.7-53.4
			c3.6-2.9,10.7-4.2,21.4-4c10.7,0.2,13.3,7.7,8,22.4h43c3.4-14.5,4.8-25.5,4-33c0,0,0.1,0,0.1,0c0,0,0,0-0.1,0
			c-0.7-7-3.3-10.9-7.7-11.7l-28.3-0.1c-23.3-10.4-51.9-29.1-66.7-61.2c-11.9-43.4-35.6-33-35.6-33s-17,6.8-7.2,25.7
			c0,0,10.5,45.8,66.5,68.3l-21.5-0.1c-7.1,2.5-12.2,6.3-15.3,11.7c-2.2,3.8-3.7,7.2-4.3,10.3l-14.7,69.7c-0.2,2.9-0.2,5.3,0,7.3
			c0.2,2,0.4,3.6,0.7,4.7c3.6,6.9,15.3,10.1,35.4,9.7h62.4C534.2,272.4,543.5,256.9,548.4,226.9z" />
                </g>
                <g>
                    <path d="M559,260c-0.9,3.8-3.1,8.1-6.7,13h48.4c-0.7-7.6-0.1-15.2,1.7-23l24.4-115.4H581l0.3,2.3
			c1.8,3.8,2.3,8.1,1.7,13L559,260z" />
                </g>
                <g>
                    <path d="M740.5,159.2h-84.1c-5.8,2.2-10.6,5.2-14.3,9c-2.9,4.2-4.8,7.8-5.7,10.7l-15.3,72.7l-0.3,8
			c-0.9,5.8,2.3,10.2,9.7,13.3l96.7,0.3c0.7-3.1,1.2-4.9,1.7-5.3l1-3.7l1.7-5.3c1.1-2.7,1.9-5.1,2.5-7.2c0.6-2.1,1.1-4.1,1.5-5.8
			c-12.7,4.7-31.5,6.3-56.4,5c-4.7,0.4-8.3,0.1-11-1c-2.2-6.9-2-13.7,0.7-20.3l3.7-0.7h63.4l4.3-0.7l10.3-49.4l0.3-4l-2-7.3
			C746.8,163.5,744.1,160.8,740.5,159.2z M708.5,205.6h-34.7l3.3-17c0.9-5.8,7.1-8.6,18.7-8.3c11.1-0.7,16.6,2.1,16.3,8.3
			L708.5,205.6z" />
                </g>
                <g>
                    <path d="M865.3,161.9l-15.7-2h-83.1l-2.7,12.3l-3,14.7l10.7-5h62.1c4.2,0.9,5.7,3.5,4.3,7.7c-0.7,4.2-3.9,6.9-9.7,8
			h-31c-14.9,1.1-25.5,4.1-31.7,9c-6,4.9-10.1,14.3-12.3,28.4c-2.9,12.5-2.6,24.1,1,35l6,3.3h49.4l4.3-2c3.6-2.9,5.8-5.9,6.7-9
			l0.3,1.3l-0.3,9.7h43.7l0.3-2c-0.9-4.9-0.9-9.5,0-13.7l0.3-4l14-66.4C879.8,171.4,875.3,163,865.3,161.9z M824.6,236.9l-1,5
			c-0.4,1.8-0.8,3-1,3.7c-10.5,6.7-19.5,7.7-27,3l-0.3-4.7l3.7-17c0.7-6.2,3-9.4,7-9.7h13c5.1-0.4,8.3-0.2,9.7,0.7L824.6,236.9z" />
                </g>
                <g>
                    <path d="M983.4,159.2h-20c-10.5,0.2-17.3,4.1-20.7,11.7l2.7-12h-44.7l1.7,9.7l-19.7,91.7c-1.8,5.6-4.1,9.8-7,12.7h49
			l-1.7-6v-4l14.3-68.1l2.3-9c10.7-5.8,19.6-6.2,26.7-1.3l-0.3,3.3h29.4C998.3,170.3,994.3,160.8,983.4,159.2z" />
                </g>
                <g>
                    <path d="M286.4,159.5l-92.7-0.3c-7.1,2.5-12.2,6.3-15.3,11.7c-2.2,3.8-3.7,7.2-4.3,10.3l-14.7,69.7
			c-0.2,2.9-0.2,5.3,0,7.3c0.2,2,0.4,3.6,0.7,4.7c3.6,6.9,15.3,10.1,35.4,9.7h62.4c13.6-0.9,22.8-16.3,27.7-46.4h-45
			c-1.1,12-4.5,20-10,24H209l-3.3-2.3c-0.4-2-0.3-4.9,0.3-8.7l11.7-53.4c3.6-2.9,10.7-4.2,21.4-4c10.7,0.2,13.3,7.7,8,22.4h43
			C296.7,176,295.5,161.1,286.4,159.5z" />
                </g>
                <g>
                    <polygon points="410.6,295.6 415.4,295.6 415.4,317.5 410.6,317.5 410.6,320.3 423.1,320.3 423.1,317.5 418.6,317.5
			418.6,295.6 423.1,295.6 423.1,292.8 410.6,292.8 		" />
                </g>
                <g>
                    <polygon points="453.3,314.3 443.4,292.8 439.9,292.8 439.9,320.3 443.1,320.3 443.1,298.6 452.9,320.3 456.5,320.3
			456.5,292.8 453.3,292.8 		" />
                </g>
                <g>
                    <polygon points="484.5,314.3 474.6,292.8 471,292.8 471,320.3 474.2,320.3 474.2,298.6 484.1,320.3 487.6,320.3
			487.6,292.8 484.5,292.8 		" />
                </g>
                <g>
                    <path d="M510.4,292.3c-3.1,0-5.4,1.2-7,3.7c-1.6,2.4-2.4,6-2.4,10.6s0.8,8.2,2.4,10.6c1.6,2.4,4,3.7,7,3.7
			c3.1,0,5.4-1.2,7.1-3.7c1.6-2.4,2.4-6,2.4-10.6c0-4.7-0.8-8.2-2.4-10.6C515.9,293.5,513.5,292.3,510.4,292.3z M510.4,318
			c-2,0-3.5-1-4.5-2.9c-1-2-1.5-4.8-1.5-8.5c0-3.7,0.5-6.5,1.6-8.5c1-2,2.5-3,4.5-3c4.1,0,6.1,3.8,6.1,11.5
			C516.5,314.2,514.5,318,510.4,318z" />
                </g>
                <g>
                    <polygon points="541.6,316.6 534,292.8 530.7,292.8 539.7,320.3 543.5,320.3 552.5,292.8 549.2,292.8 		" />
                </g>
                <g>
                    <path d="M570.9,292.8l-9,27.5h3.3l3.1-9.6h9l3,9.6h3.4l-9-27.5H570.9z M569.2,307.9l3.6-11.4l3.6,11.4H569.2z" />
                </g>
                <g>
                    <polygon points="593.9,295.4 602.4,295.4 602.4,320.3 605.6,320.3 605.6,295.4 614.1,295.4 614.1,292.8 593.9,292.8
					" />
                </g>
                <g>
                    <polygon points="628.7,295.6 633.5,295.6 633.5,317.5 628.7,317.5 628.7,320.3 641.2,320.3 641.2,317.5 636.7,317.5
			636.7,295.6 641.2,295.6 641.2,292.8 628.7,292.8 		" />
                </g>
                <g>
                    <polygon points="666.2,316.6 658.7,292.8 655.3,292.8 664.3,320.3 668.1,320.3 677.1,292.8 673.8,292.8 		" />
                </g>
                <g>
                    <polygon points="693.6,306.9 704.3,306.9 704.3,304.1 693.6,304.1 693.6,295.6 704.7,295.6 704.7,292.8 690.4,292.8
			690.4,320.3 704.8,320.3 704.8,317.5 693.6,317.5 		" />
                </g>
                <g>
                    <path d="M765.4,307.2c-0.7-0.6-1.3-1.1-2-1.5c-0.7-0.4-2-1-4-1.9c-2.7-1.3-4.1-2.7-4.1-4.4c0-1.4,0.5-2.5,1.6-3.2
			c1-0.8,2.4-1.1,4.1-1.1c1.5,0,3,0.3,4.7,0.8l0.7-2.7c-1.8-0.6-3.6-0.8-5.5-0.8c-2.5,0-4.6,0.6-6.2,1.9c-1.6,1.3-2.5,3-2.5,5.3
			c0,0.9,0.2,1.7,0.5,2.4c0.3,0.7,0.8,1.4,1.3,2c0.6,0.6,1.1,1,1.7,1.4c0.6,0.3,1.9,1,3.8,1.9c1.9,0.9,3.2,1.8,4,2.6
			c0.7,0.9,1.1,1.9,1.1,3.1c0,1.7-0.5,2.9-1.6,3.9c-1.1,0.9-2.6,1.4-4.4,1.4c-1.6,0-3.4-0.5-5.5-1.4l-0.7,2.8
			c1.8,0.9,3.9,1.4,6.2,1.4c2.4,0,4.6-0.7,6.4-2.2c1.8-1.5,2.7-3.6,2.7-6.2c0-1.1-0.2-2-0.6-2.9
			C766.7,308.6,766.1,307.9,765.4,307.2z" />
                </g>
                <g>
                    <path d="M789,292.8l-9,27.5h3.3l3.1-9.6h9l3,9.6h3.4l-9-27.5H789z M787.3,307.9l3.6-11.4l3.6,11.4H787.3z" />
                </g>
                <g>
                    <polygon points="815.1,320.3 818.3,320.3 818.3,307.4 828.6,307.4 828.6,304.6 818.3,304.6 818.3,295.6 829.6,295.6
			829.6,292.8 815.1,292.8 		" />
                </g>
                <g>
                    <polygon points="849.4,306.9 860,306.9 860,304.1 849.4,304.1 849.4,295.6 860.5,295.6 860.5,292.8 846.2,292.8
			846.2,320.3 860.6,320.3 860.6,317.5 849.4,317.5 		" />
                </g>
                <g>
                    <polygon points="874.3,295.4 882.8,295.4 882.8,320.3 886,320.3 886,295.4 894.5,295.4 894.5,292.8 874.3,292.8 		" />
                </g>
                <g>
                    <polygon points="915.5,305.2 909,292.8 905.4,292.8 914,308.5 914,320.3 917.1,320.3 917.1,308.5 925.7,292.8
			922.1,292.8 		" />
                </g>
                <g>
                    <path d="M1014.7,149.5c-0.3-0.2-0.6-0.5-1-0.6c1-0.1,1.9-0.4,2.5-1.1c0.6-0.7,0.9-1.4,0.9-2.3c0-0.6-0.2-1.2-0.6-1.8
			c-0.4-0.6-0.9-0.9-1.5-1.2c-0.6-0.2-1.7-0.3-3.1-0.3h-4.1v12.1h1.9v-5.1h1.1c0.7,0,1.2,0.1,1.6,0.4c0.5,0.4,1.2,1.3,2,2.8l1.1,1.9
			h2.4l-1.5-2.4C1015.8,150.8,1015.2,150,1014.7,149.5z M1012.2,147.5h-2.4v-3.6h2.2c1,0,1.6,0.1,2,0.2c0.4,0.1,0.6,0.4,0.8,0.6
			c0.2,0.3,0.3,0.6,0.3,1c0,0.6-0.2,1-0.6,1.3C1014.1,147.3,1013.3,147.5,1012.2,147.5z" />
                </g>
                <g>
                    <path d="M1022.5,142.5c-1-1.8-2.4-3.2-4.2-4.2c-1.8-1-3.6-1.4-5.5-1.4s-3.7,0.5-5.5,1.4c-1.8,1-3.2,2.3-4.2,4.2
			c-1,1.8-1.5,3.7-1.5,5.6c0,1.9,0.5,3.8,1.5,5.6c1,1.8,2.4,3.2,4.2,4.2c1.8,1,3.6,1.5,5.6,1.5c1.9,0,3.8-0.5,5.6-1.5
			c1.8-1,3.2-2.4,4.1-4.2c1-1.8,1.5-3.6,1.5-5.6C1024,146.2,1023.5,144.3,1022.5,142.5z M1020.9,152.8c-0.8,1.5-2,2.6-3.5,3.5
			c-1.5,0.8-3,1.2-4.7,1.2s-3.2-0.4-4.7-1.2c-1.5-0.8-2.6-2-3.5-3.5c-0.8-1.5-1.2-3-1.2-4.6c0-1.6,0.4-3.2,1.3-4.7
			c0.8-1.5,2-2.7,3.5-3.5c1.5-0.8,3-1.2,4.6-1.2c1.6,0,3.1,0.4,4.6,1.2c1.5,0.8,2.7,2,3.5,3.5c0.8,1.5,1.3,3.1,1.3,4.7
			C1022.1,149.7,1021.7,151.3,1020.9,152.8z" />
                </g>
                <g>
                    <path d="M417.2,217.5c9.2-15.2,17.9-40.1,2.1-47.7c-15.8-7.6-27.6,8.1-39.6,30.3c-3,5.6-10,20.4-18.7,39.2
			c-2.7-21.2-8-48.2-18.8-73.1c-4.3-9.9-10.7-16.1-21.1-17.5c-12-1.6-24.4,3.7-21.5,14.7c3.7,14,28.8,75.1,49.7,101.4
			c-24.6,54-54.4,121-54.4,121S408,232.6,417.2,217.5z" />
                </g>
                <g>
                    <path d="M351,141.8c10.1-12.3,53.1-66.7,53.1-66.7s-62.8,24.7-76.8,45.2C313.4,140.8,340.9,154.1,351,141.8z" />
                </g>
                <g>
                    <path d="M509.3,98.5c2.7-0.9,7.2-4.6,7.2-4.6s-20.9-5.4-53.7-37.5c0,0,3.5,37.2,9.8,48.7c6.3,11.5,12.1,14.3,18.9,8.7
			c6.8-5.6,5.7-6.6,10.2-6.5c4.5,0.1,8.5,2.7,8-1.2C509.2,102.4,506.6,99.4,509.3,98.5z" />
                </g>
                <g>
                    <path d="M509.6,84.4c15.1,7.9,18.4,9,23-0.9c4.7-9.9,9.8-31-14.8-50.9c-24.6-20-48-21.5-48-21.5s9.3,6.5,9.9,16.8
			l-11.5-0.6c0,0,2.3,5.7,2.3,9.1l-10.2,1.1C460.3,37.5,480.1,68.9,509.6,84.4z" />
                </g>
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default CyclearLogoGreySVG;
