import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { Drawer, Button } from 'antd';
import { map, isNull } from 'underscore';
import Icon from '../../elements/Icon';

import './menu.scss';

export default function () {
    const {
        items, logo, placement, width,
    } = this.props;
    const { menuOpen } = this.state;
    return (
        (<div className="menu">
            <Button className="menu-button" onClick={this.openDrawer}>
                <MenuOutlined />
            </Button>
            <Drawer
                open={menuOpen}
                onClose={this.closeDrawer}
                placement={placement}
                width={width}>
                <div rootClassName="menu__header mt-3">
                    {!isNull(logo) ? (
                        <Link to="/">
                            <img
                                rootClassName="github"
                                width={160}
                                src={logo}
                                alt="" />
                        </Link>
                    ) : null}
                </div>
                <div rootClassName="menu__content">
                    <ul rootClassName="menu__nav">
                        {map(items, (item, itemIndex) => (
                            <li key={item.key || `menu-item-${itemIndex}`}>
                                <NavLink
                                    to={item.link}
                                    activeClassName="is-active">
                                    {item.iconName ? (
                                        <div rootClassName="menu__nav-icon">
                                            <Icon name={item.iconName} />
                                        </div>
                                    ) : null}
                                    <span>{item.label}</span>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </Drawer>
        </div>)
    );
}
