/* eslint-disable no-case-declarations */

import { Record } from 'immutable';
import {
    updateWidgets,
    removeWidget,
    updatePositions,
    setLoading,
} from './helpers';

const {
    GET_ALL_WIDGETS_REQUEST,
    GET_ALL_WIDGETS_SUCCESS,
    GET_ALL_WIDGETS_FAILURE,

    UPDATE_OR_CREATE_REQUEST,
    UPDATE_OR_CREATE_SUCCESS,
    UPDATE_OR_CREATE_FAILURE,

    UPDATE_WIDGET_POSITIONS_REQUEST,
    UPDATE_WIDGET_POSITIONS_SUCCESS,
    UPDATE_WIDGET_POSITIONS_FAILURE,

    DELETE_WIDGET_REQUEST,
    DELETE_WIDGET_SUCCESS,
    DELETE_WIDGET_FAILURE,

    GET_WIDGET_EVENT_TYPES_REQUEST,
    GET_WIDGET_EVENT_TYPES_SUCCESS,
    GET_WIDGET_EVENT_TYPES_FAILURE,

    GET_USER_DIVISIONS_REQUEST,
    GET_USER_DIVISIONS_SUCCESS,
    GET_USER_DIVISIONS_FAILURE,

    GET_USER_DRIVERS_REQUEST,
    GET_USER_DRIVERS_SUCCESS,
    GET_USER_DRIVERS_FAILURE,

    GET_SINGLE_WIDGET_REQUEST,
    GET_SINGLE_WIDGET_SUCCESS,
    GET_SINGLE_WIDGET_FAILURE,

} = require('./dashboardActions').constants;

const {
    LOGOUT_REQUEST,
} = require('../user/userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isLoading: false,
    gettingModalInfo: false,
    initialWidgetList: [],
    widgets: [],
    eventTypes: null,
    userDivision: null,
    userDrivers: null,
});

const dashboardInitialState = new InitialState();

/**
 * ## dashboardReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function dashboardReducer(state = dashboardInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = dashboardInitialState.mergeDeep(state);

    switch (type) {
    case GET_ALL_WIDGETS_REQUEST:
        return state.set('isLoading', true)
            .set('error', null);
    case UPDATE_OR_CREATE_REQUEST:
        return state.set('widgets', setLoading(state.widgets, payload && payload.widget && payload.widget.id))
            .set('isFetching', true)
            .set('error', null);
    case UPDATE_WIDGET_POSITIONS_REQUEST:
        return state.set('positioning', true)
            .set('error', null);
    case DELETE_WIDGET_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case GET_WIDGET_EVENT_TYPES_REQUEST:
        return state.set('eventType', [])
            .set('gettingModalInfo', true)
            .set('error', null);
    case GET_USER_DIVISIONS_REQUEST:
        return state.set('userDivision', [])
            .set('gettingModalInfo', true)
            .set('error', null);
    case GET_USER_DRIVERS_REQUEST:
        return state.set('userDrivers', [])
            .set('gettingModalInfo', true)
            .set('error', null);
    case GET_SINGLE_WIDGET_REQUEST:
        return state.set('widgets', setLoading(state.widgets, payload && payload.widget_id))
            .set('error', null);

    case LOGOUT_REQUEST:
        return state.set('widgets', []);

    case GET_ALL_WIDGETS_SUCCESS:
        return state.set('initialWidgetList', payload.data)
            .set('isLoading', false);
    case UPDATE_OR_CREATE_SUCCESS:
        return state.set('isFetching', false)
            .set('widgets', updateWidgets(state.widgets, payload.data));
    case UPDATE_WIDGET_POSITIONS_SUCCESS:
        return state.set('widgets', updatePositions(state.widgets, payload.data))
            .set('positioning', false);
    case DELETE_WIDGET_SUCCESS:
        return state.set('widgets', removeWidget(state.widgets, payload.data))
            .set('isFetching', false);
    case GET_WIDGET_EVENT_TYPES_SUCCESS:
        return state.set('eventTypes', payload.data)
            .set('gettingModalInfo', false);
    case GET_USER_DIVISIONS_SUCCESS:
        return state.set('userDivision', payload.data)
            .set('gettingModalInfo', false);
    case GET_USER_DRIVERS_SUCCESS:
        return state.set('userDrivers', payload.data)
            .set('gettingModalInfo', false);
    case GET_SINGLE_WIDGET_SUCCESS:
        return state.set('widgets', updateWidgets(state.widgets, payload.data));
    case GET_ALL_WIDGETS_FAILURE:
        return state.set('isLoading', false)
            .set('error', payload);
    case UPDATE_OR_CREATE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    case UPDATE_WIDGET_POSITIONS_FAILURE:
        return state.set('positioning', false)
            .set('error', payload);
    case DELETE_WIDGET_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    case GET_WIDGET_EVENT_TYPES_FAILURE:
        return state.set('gettingModalInfo', false)
            .set('error', payload);
    case GET_USER_DIVISIONS_FAILURE:
        return state.set('gettingModalInfo', false)
            .set('error', payload);
    case GET_USER_DRIVERS_FAILURE:
        return state.set('gettingModalInfo', false)
            .set('error', payload);
    case GET_SINGLE_WIDGET_FAILURE:
        return state.set('error', payload);

    default:
        return state;
    }
}

export {
    dashboardReducer,
    dashboardInitialState,
};
