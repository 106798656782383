import React from 'react';
import Layout from '../../components/layout/Layout';
import TwoFactorAuthForm from '../../components/TwoFactorAuthForm';

export default function () {
    const { match } = this.props;
    return (
        <Layout className="twoFactorAuthPage" title="Two Factor Auth" showHeader={false} showFooter={false}>
            <TwoFactorAuthForm userRef={match?.params?.ref} />
        </Layout>
    );
}
