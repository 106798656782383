import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Switch, Input, DatePicker, Button, Checkbox } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { isEmpty } from 'underscore';
import { Form } from '@ant-design/compatible';
import * as userActions from '../../../core/user/userActions';
import { Calendar } from '@carbon/icons-react';

const FormItem = Form.Item;

class LeftSide extends React.Component {
    componentDidMount() {
        const { registerSubmitHandler } = this.props;
        registerSubmitHandler(this.submitDetails.bind(this));
    }

    submitDetails() {
        const { actions, form, user } = this.props;

        form.validateFieldsAndScroll((err, vals) => {
            if (err) {
                return false;
            }

            const values = {
                ...vals,
                date_of_birth: vals.date_of_birth_moment.format('YYYY-MM-DD'),
                unit_of_measurement_preference: vals.unit_of_measurement_preference ? 'metric' : 'british-imperial',
                show_info_preference: +vals.show_info_preference,
            };
            actions.updateUserProfileRequest(values);

            return true;
        });
    }

    render() {
        const {
            form, openPasswordChangeModal, user,
        } = this.props;
        const { getFieldDecorator } = form;
        const { unit_of_measurement_preference, show_info_preference } = user?.profile || {};

        const isMetric = unit_of_measurement_preference !== 'british-imperial';
        const showInfoChecked = !!show_info_preference;

        return (
            <div>
                <div className="main-info-wrapper">
                    <div className="left-side">
                        <div>
                            <span className='label'>Role</span>
                            <div style={{ height: '8px' }} />
                            <span className='value'>{user.role}</span>
                        </div>
                        <div style={{ marginTop: '8px' }}>
                            <span className='label'>Password</span>
                            <div style={{ height: '8px' }} />
                            <span className='value'>
                                ********
                                <Button 
                                    type="link" 
                                    onClick={openPasswordChangeModal}
                                    style={{ marginTop: '-8px' }}
                                >
                                    change
                                </Button>
                            </span>
                        </div>
                    </div>
                    <br />
                    <div className="right-side">
                        <div>
                            <span className='label'>Email</span>
                            <div style={{ height: '8px' }} />
                            <span className='value'>{user.loginUser}</span>
                        </div>
                        <div style={{ marginTop: '8px' }}>
                            <span className='label'>Division</span>
                            <div style={{ height: '8px' }} />
                            <span className='value'>{user.userDetail.division_name === '-' ? '-' : user.userDetail.division_name}</span>
                        </div>
                    </div>
                </div>
                <br />
                <Form>
                    <ul className="list left-side-user-list">
                        <li 
                            className="list__item"
                            style={{ marginTop: 0 }}
                        >
                            <div 
                                className="list__item-value"
                                style={{ marginTop: '4px' }}
                            >
                                <FormItem 
                                    label='Name' 
                                    style={{ display: 'block' }}
                                >
                                    {getFieldDecorator('full_name', {
                                        initialValue: user.profile.full_name,
                                        rules: [
                                            { transform: (value) => value.trim() },
                                            {
                                                required: true,
                                                message: 'Please enter your name',
                                            },
                                            { max: 255, message: 'Maximum 255 characters' }
                                        ],
                                    })(<Input placeholder="Enter Name" />)}
                                </FormItem>
                            </div>
                        </li>
                        <li 
                            className="list__item"
                            style={{ marginTop: 0 }}
                        >
                            <div 
                                className="list__item-value"
                                style={{ marginTop: '4px' }}
                            >
                                <FormItem 
                                    label='Contact Number'
                                    style={{ display: 'block' }}
                                >
                                    {getFieldDecorator('phone_number', {
                                        initialValue: user.profile.phone_number,
                                        rules: [
                                            { transform: (value) => value.trim() },
                                            {
                                                required: true,
                                                message: 'Please input your phone number',
                                            },
                                            { max: 255, message: 'Maximum 255 characters' }
                                        ],
                                    })(<Input placeholder="Enter Phone Number" />)}
                                </FormItem>
                            </div>
                        </li>
                        <li 
                            className="list__item"
                            style={{ marginTop: 0 }}
                        >
                            <div 
                                className="list__item-value"
                                style={{ marginTop: '4px' }}
                            >
                                <FormItem 
                                    label='Date of Birth'
                                    style={{ display: 'block' }}
                                >
                                    {getFieldDecorator('date_of_birth_moment', {
                                        initialValue: !isEmpty(user.profile.date_of_birth) ? dayjs(user.profile.date_of_birth, 'YYYYMMDD') : dayjs(),
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please select date',
                                            },
                                        ],
                                    })(
                                        <DatePicker 
                                            format="DD/MM/YYYY" 
                                            placeholder="Date of Birth" 
                                            style={{ width: '100%' }} 
                                            allowClear={false}
                                            suffixIcon={<Calendar />}
                                        />,
                                    )}
                                </FormItem>
                            </div>
                        </li>
                        <br />
                        <li>
                            <div 
                                className="list__item-value" 
                                style={{ marginBottom: '8px' }}
                            >
                                <FormItem style={{ marginLeft: '8px' }}>
                                    {getFieldDecorator('unit_of_measurement_preference', {
                                        valuePropName: 'checked',
                                        initialValue: isMetric,
                                    })(<Checkbox />)}
                                    <span className='asset-name-label'>&ensp; Show Units in Metric?</span>
                                </FormItem>
                            </div>
                        </li>
                        <li>
                            <div 
                                className="list__item-value" 
                                style={{ marginBottom: '8px' }}
                            >
                                <FormItem style={{ marginLeft: '8px' }}>
                                    {getFieldDecorator('show_info_preference', {
                                        valuePropName: 'checked',
                                        initialValue: showInfoChecked,
                                    })(<Checkbox />)}
                                    <span className='asset-name-label'>&ensp; Show Asset Names on Map?</span>
                                </FormItem>
                            </div>
                        </li>
                    </ul>
                </Form>
            </div>
        );
    }
}

LeftSide.defaultProps = {
    openPasswordChangeModal: () => null,
    registerSubmitHandler: () => null,
    getFieldDecorator: () => null,
};

LeftSide.propTypes = {
    openPasswordChangeModal: PropTypes.func,
    registerSubmitHandler: PropTypes.func,
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    getFieldDecorator: PropTypes.func,
};

const ProfileMainForm = Form.create()(LeftSide);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...userActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfileMainForm);
