import React from 'react';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import MapNew, { Channel, MarkerData, MarkerType, PathSegment, PopupType } from '../../../components/MapNew';
import { AvlKey } from '../../../components/MapNew/elements/Popup/AssetPopupContent';

interface VideoSearchResponse {
    info: {
        id: number;
        device_status: string;
        lat: string;
        lng: string;
        angle: number;
        name: string;
        division_color: string;
        cached_avl_info: string;
        speed: string;
        user_id: number;
        full_name: string;
    };
    locations: {
        lat: string;
        lng: string;
        event_type: string;
        icon: string;
        time: string;
        event_key: string;
        speed: number;
        show_in_events: boolean | null;
        location_data: AvlKey[];
    }[];
}

export enum VideoRequestLength {
    PlusMinusFifteenSeconds = '+/-15secs',
    PlusMinusThirtySeconds = '+/-30secs',
    PlusMinusOneMinute = '+/-1min',
    PlusMinusTwoMinutes = '+/-2mins',
    PlusMinusFiveMinutes = '+/-5mins',
}

// TODO: move these to timeline files after merged to master
export interface TimelineData {
    timelineSegments: TimelineSegment[];
    geofenceTimelineSegments: GeofenceTimelineSegment[];
}
export interface TimelineSegment {
    start: number;
    start_time: string;
    end: number;
    end_time: string;
    location: string;
    status: string;
}
export interface GeofenceTimelineSegment {
    start: number;
    start_time: string;
    end: number;
    end_time: string;
    geofence_names: string[];
}

interface VideoSearchTabProps {
    videoSearchData?: VideoSearchResponse | null;
    videoSearchIsFetching?: boolean;
    selectedDate?: string;
    timelineSegments?: TimelineSegment[] | null;
    geofenceTimelineSegments?: GeofenceTimelineSegment[] | null;
    channelList?: Channel[] | null;
    selectedDvrIsOnline?: boolean;
    showVideoStreamingSidebar?: () => void;
    selectedChannels?: { channel: string, selected: boolean }[] | null;
    toggleSelectedChannel?: (channel: string) => void;
    onChangeSliderValue?: (value: [number, number, number]) => void;
    videoRequestLength?: VideoRequestLength;
    onChangeVideoRequestLength?: (value: string) => void;
    videoStreamingSidebarIsVisible?: boolean;
    videoSearchSidebarIsVisible?: boolean;
}

const VideoSearchTab: React.FC<VideoSearchTabProps> = ({
    videoSearchData = null,
    videoSearchIsFetching = false,
    selectedDate = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    timelineSegments = null,
    geofenceTimelineSegments = null,
    channelList = null,
    selectedDvrIsOnline = false,
    showVideoStreamingSidebar = () => {},
    selectedChannels = null,
    toggleSelectedChannel = (channel) => {},
    onChangeSliderValue = (value) => {},
    videoRequestLength = VideoRequestLength.PlusMinusFifteenSeconds,
    onChangeVideoRequestLength = (value) => {},
    videoStreamingSidebarIsVisible = false,
    videoSearchSidebarIsVisible = false,
}) => {
    if (videoSearchIsFetching) {
        return (
            <div className='spin-container'>
                <Spin spinning />
            </div>
        );
    }
    
    if (videoSearchData && videoSearchData.info && videoSearchData.locations) {
        const markers: MarkerData[] = [{
            type: MarkerType.Asset,
            location: {
                lat: parseFloat(videoSearchData.info.lat),
                lng: parseFloat(videoSearchData.info.lng),
            },
            angle: videoSearchData.info.angle,
            label: videoSearchData.info.name,
            divisionColor: videoSearchData.info.division_color,
            deviceStatus: videoSearchData.info.device_status,
            cachedAvlInfo: videoSearchData.info.cached_avl_info || '',
            speed: videoSearchData.info.speed || '',
            popupData: {
                type: PopupType.Asset,
                assetId: String(videoSearchData.info.id),
                date: selectedDate,
            },
        }];
        const path: PathSegment[] = videoSearchData.locations.map((location) => ({
            location: {
                lat: parseFloat(location.lat),
                lng: parseFloat(location.lng),
            },
            eventType: parseInt(location.event_type),
            icon: location.icon,
            datetime: location.time,
            popupData: location.show_in_events ? {
                type: PopupType.Event,
                eventName: location.event_key,
                speed: location.speed,
                time: location.time,
                driverId: videoSearchData.info.user_id,
                driverName: videoSearchData.info.full_name,
                location: {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lng),
                    address: '',
                },
                avlKeys: location.location_data,
            } : null,
        }));
        return (
            <MapNew
                containerStyle={{ 
                    width: videoStreamingSidebarIsVisible ? 'calc(100% - 320px)' : '100%', 
                    height: '600px',
                }} 
                markers={markers}
                path={path}
                showSlider={true}
                timelineData={{
                    timelineSegments,
                    geofenceTimelineSegments,
                }}
                channelList={channelList}
                selectedDvrIsOnline={selectedDvrIsOnline}
                showVideoStreamingSidebar={showVideoStreamingSidebar}
                selectedChannels={selectedChannels}
                toggleSelectedChannel={toggleSelectedChannel}
                onChangeSliderValue={onChangeSliderValue}
                videoRequestLength={videoRequestLength}
                onChangeVideoRequestLength={onChangeVideoRequestLength}
                selectedDate={selectedDate}
                videoSearchSidebarIsVisible={videoSearchSidebarIsVisible}
            />
        );
    } 
            
    return (
        <div className='video-search-tab-info'>
            Use the filters in the sidebar to search for videos for a particular asset.
        </div>
    );
}

export default VideoSearchTab;
