import React from 'react';
import { Spin } from 'antd';
import Layout from '../../components/layout/Layout';
import PasswordScreen from '../EventShareView/partials/passwordScreen';
import EventScreen from './partials/eventScreen';
import './eventShareCustomView.scss';

import srcISSLogo from '../../assets/images/iss-logo-silver-black.png';
import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';

export default function () {
    const { report, match, isFetching, user } = this.props;
    const { params } = match;
    const showPasswordScreen = report && report.isValid && report.isValid === true && !report.eventId && !report.deviceId;
    const showEventScreen = report && report.isValid && report.isValid === true && report.eventId && report.deviceId;

    return (
        <Layout
            data-test="pages-events-share-view"
            className="events-view"
            title="Event View"
            showHeader={false}
            headerTitle={<div>AssetsView Page</div>}
            showFooter={false}>
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            {showPasswordScreen
                                ? <PasswordScreen params={params} />
                                : null}
                            {showEventScreen
                                ? <EventScreen params={params} />
                                : null}

                            {!showPasswordScreen && !showEventScreen
                                ? (
                                    <div className="loginform-wrap">
                                        <div className="loginform-container">
                                            <div className="d-flex dir-column align-center text-center">
                                                <img src={srcFleetclearLogo} className="login__logo-fleet" alt="Fleetclear" />
                                                { isFetching ? <Spin style={{ marginTop: 30 }} /> : <h1 className="login__heading">Invalid link!</h1>}
                                            </div>
                                        </div>
                                    </div>
                                )
                                : null}
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
