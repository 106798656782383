/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import DOM from './simpleScatterChart';

class SimpleScatterChart extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            timeframe: props.timeframe,
            colors: [
                '#FCB525',
                '#82C4FD',
                '#7AC943',
                '#F77B00',
                '#ED1C24',
                '#8158B9',
                '#E062D3',
                '#176C89',
                '#8C28A3',
                '#B31617',
                '#54D193',
                '#17A92F',
                '#C270E0',
                '#0DDF3A',
                '#DAE63B',
                '#501CCA',
                '#FF5365',
                '#D8E849',
                '#F27AC2',
                '#E6F9F4',
                '#660787',
            ],
        };
    }

    onTimeframeChange(timeframe) {
        const { onTimeframeChange } = this.props;

        this.setState({
            timeframe,
        });

        onTimeframeChange(timeframe);
    }

    render() {
        return this.view();
    }
}

SimpleScatterChart.defaultProps = {
    onTimeframeChange: () => null,
    rightMenuOnClick: () => null,
    rightMenuItems: [],
    tooltipText: null,
    onFirstOptionChange: () => null,
    onSecondOptionChange: () => null,
    onThirdOptionChange: () => null,
    onFourOptionChange: () => null,
    onAllOptionChange: () => null,
    defaultFirstOptionValue: 'All',
    defaultSecondOptionValue: 'All',
    defaultThirdOptionValue: 'All',
    defaultFourOptionValue: 'All',
    divisions: [],
    drivers: [],
    userDivisions: [],
    assets: [],
    data: [],
    heightRatio: 1,
    dotOnClick: () => null,
};

SimpleScatterChart.propTypes = {
    timeframe: PropTypes.oneOf(['hourly', 'daily', 'weekly', 'monthly', 'yearly']).isRequired,
    data: PropTypes.array,
    rightMenuItems: PropTypes.array,
    onTimeframeChange: PropTypes.func,
    rightMenuOnClick: PropTypes.func,
    tooltipText: PropTypes.string,
    onFirstOptionChange: PropTypes.func,
    onSecondOptionChange: PropTypes.func,
    onThirdOptionChange: PropTypes.func,
    onFourOptionChange: PropTypes.func,
    onAllOptionChange: PropTypes.func,
    defaultFirstOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultSecondOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultThirdOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultFourOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    divisions: PropTypes.array,
    userDivisions: PropTypes.array,
    drivers: PropTypes.array,
    assets: PropTypes.array,
    heightRatio: PropTypes.oneOf([1, 2]),
    dotOnClick: PropTypes.func,
};

export default SimpleScatterChart;
