import React from 'react';

function FuelEfficiencySVG() {
    return (
        <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
            <g stroke="null">
                <path
                    className="prefix__cls-1"
                    d="M18.111 21.813h12.963v3.704H18.111z" />
                <path
                    d="M55.915 18.11l-9.26-9.26-2.618 2.62 5.556 5.555v8.492a3.708 3.708 0 003.703 3.704v19.444a2.778 2.778 0 01-5.555 0v-15.74a1.852 1.852 0 00-1.852-1.852H38.48V12.554a1.852 1.852 0 00-1.851-1.852H12.556a1.852 1.852 0 00-1.852 1.852v38.889H7v3.704h35.185v-3.704h-3.704V34.776h5.556V48.48a6.6 6.6 0 005.121 6.528A6.493 6.493 0 0057 48.665V20.73a3.704 3.704 0 00-1.085-2.62zM34.778 51.443h-20.37V14.406h20.37v37.037z"
                    className="prefix__cls-1" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default FuelEfficiencySVG;
