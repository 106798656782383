import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchApiAuth } from '../../../../../core/utils/api';
import { AxiosResponse } from 'axios';
import { Spin, Row, Col } from 'antd';
import { PopupType } from '../../..';
import Icon from '../../../../elements/Icon';
import SpeedSign from '../../../../SpeedSign';

import './assetPopupContent.scss';

// TODO: move these types after merge to master
export enum UnitOfMeasurementPreference {
    Metric = 'metric',
    BritishImperial = 'british-imperial',
}
export enum DeviceStatus {
    Online = 'In Transit',
    Idling = 'Idling',
    Stopped = 'Stopped',
    Offline = 'Offline',
}
export interface AvlKey {
    description: string;
    value: string;
}

interface AssetPopupResponse {
    asset_picture?: string | null;
    driver: string;
    driver_score: number;
    marker: {
        data: AvlKey[];
        location: string;
        lat: string;
        lng: string;
        speed: number;
    };
    online_status: DeviceStatus;
    title: string;
    user_id: number;
    user_picture?: string | null;
}

const getStatusIconColor = (deviceStatus: DeviceStatus): string => {
    switch (deviceStatus) {
        case DeviceStatus.Online:
            return 'green';
        case DeviceStatus.Idling:
            return 'yellow';
        case DeviceStatus.Stopped:
            return 'red';
        case DeviceStatus.Offline:
            return '#ddd';
        default:
            return '#ddd';
    }
}

export interface AssetPopupContentProps {
    type: PopupType.Asset;
    assetId?: string | null;
    date?: string | null;
}

/**
 * Renders the content of an asset popup.
 */
const AssetPopupContent: React.FC<AssetPopupContentProps> = ({
    assetId = null,
    date = null,
}) => {
    const [ data, setData ] = useState<AssetPopupResponse | null>(null);
    const [ isFetching, setIsFetching ] = useState<boolean>(false);
    const [ error, setError ] = useState<string | null>(null);
    const user = useSelector((state: any) => state.user);

    useEffect(() => {
        if (assetId && date) {
            setIsFetching(true);
            fetchApiAuth({
                method: 'GET',
                url: 'device/location-detailed-info',
                params: {
                    asset_id: assetId, 
                    date_to: date,
                },
            })
                .then((res: AxiosResponse<AssetPopupResponse>) => {
                    setData(res?.data?.marker ? res.data : null);
                    setIsFetching(false);
                })
                .catch(() => {
                    setError('Failed to fetch device data');
                    setIsFetching(false);
                });
        }
    }, []);

    if (error) {
        return (
            <div className='asset-popup-error'>
                {error}
            </div>
        );
    }

    if (isFetching) {
        return (
            <Spin spinning={isFetching} >
                <div className='asset-popup-loading'>
                    &ensp;
                </div>
            </Spin>
        );
    } 
    
    if (data) {
        return (
            <div className='asset-popup-loaded'>
                <Row>
                    <Col xs={3}>
                        <SpeedSign 
                            speed={data.marker.speed.toFixed(0)} 
                            isMetric={user?.profile?.unit_of_measurement_preference === UnitOfMeasurementPreference.Metric || false}
                        />
                    </Col>
                    <Col xs={21}>
                        <h3 className='title'>{data.title}</h3>
                    </Col>
                    <Col xs={20}>
                        <div className='metadata'>
                            <div className='status'>
                                <div 
                                    className='status-icon' 
                                    style={{ backgroundColor: getStatusIconColor(data.online_status) }}
                                />
                                &ensp;
                                {data.online_status}
                            </div>
                            <div className='driver-name'>
                                <Icon name='person' />
                                &ensp;
                                <a 
                                    href={`/drivers/view/${data.user_id}`} 
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data.driver}
                                </a>
                            </div>
                            <div className='driver-score'>
                                <span className='fake-icon-percentage'>%</span>
                                &ensp;
                                Driver Score:
                                &thinsp;
                                <span>{data.driver_score?.toFixed(0) || 0}</span>
                            </div>
                            <div className='lat-lng'>
                                <Icon name='location' />
                                &ensp;
                                <div>{data.marker.lat}, {data.marker.lng}</div>
                            </div>
                            <div className='address'>
                                <Icon name='road' />
                                &ensp;
                                {data.marker.location}
                            </div>
                        </div>
                    </Col>
                    <Col xs={4}>
                        {data.asset_picture && (
                            <img 
                                src={data.asset_picture} 
                                alt='asset-img'
                            />
                        )}
                        {data.asset_picture && data.user_picture && (
                            <br />
                        )}
                        {data.user_picture && (
                            <img 
                                src={data.user_picture} 
                                alt='driver-img'
                            />
                        )}
                    </Col>
                    <Col xs={24}>
                        <div className='avl-table-container'>
                            <table>
                                <tbody>
                                    {data.marker.data.map((avlKey: AvlKey) => (
                                        <tr key={avlKey.value}>
                                            <td>&ensp;{avlKey.description}</td>
                                            <td>{avlKey.value}&ensp;</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
    
    return (
        <div className='asset-popup-error'>
            Unable to fetch device data
        </div>
    );
}

export default AssetPopupContent;