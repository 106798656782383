import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_MILEAGE_REPORT_REQUEST: null,
    GET_MILEAGE_REPORT_SUCCESS: null,
    GET_MILEAGE_REPORT_FAILURE: null,

    GET_MPG_REPORT_REQUEST: null,
    GET_MPG_REPORT_SUCCESS: null,
    GET_MPG_REPORT_FAILURE: null,

    GET_DRIVING_TIME_REPORT_REQUEST: null,
    GET_DRIVING_TIME_REPORT_SUCCESS: null,
    GET_DRIVING_TIME_REPORT_FAILURE: null,

    GET_OUTSIDE_WORKING_HOURS_REPORT_REQUEST: null,
    GET_OUTSIDE_WORKING_HOURS_REPORT_SUCCESS: null,
    GET_OUTSIDE_WORKING_HOURS_REPORT_FAILURE: null,

    GET_NOT_MOVED_REPORT_REQUEST: null,
    GET_NOT_MOVED_REPORT_SUCCESS: null,
    GET_NOT_MOVED_REPORT_FAILURE: null,

    GET_IDLE_REPORT_REQUEST: null,
    GET_IDLE_REPORT_SUCCESS: null,
    GET_IDLE_REPORT_FAILURE: null,

    GET_PTO_REPORT_REQUEST: null,
    GET_PTO_REPORT_SUCCESS: null,
    GET_PTO_REPORT_FAILURE: null,

    GET_TRUE_IDLE_REPORT_REQUEST: null,
    GET_TRUE_IDLE_REPORT_SUCCESS: null,
    GET_TRUE_IDLE_REPORT_FAILURE: null,

    GET_SPEED_REPORT_REQUEST: null,
    GET_SPEED_REPORT_SUCCESS: null,
    GET_SPEED_REPORT_FAILURE: null,

    GET_CARBON_REPORT_REQUEST: null,
    GET_CARBON_REPORT_SUCCESS: null,
    GET_CARBON_REPORT_FAILURE: null,

    GET_SPEEDING_REPORT_REQUEST: null,
    GET_SPEEDING_REPORT_SUCCESS: null,
    GET_SPEEDING_REPORT_FAILURE: null,

    GET_UNAUTHORISED_JOURNEY_REPORT_REQUEST: null,
    GET_UNAUTHORISED_JOURNEY_REPORT_SUCCESS: null,
    GET_UNAUTHORISED_JOURNEY_REPORT_FAILURE: null,

    GET_ECO_SCORE_REPORT_REQUEST: null,
    GET_ECO_SCORE_REPORT_SUCCESS: null,
    GET_ECO_SCORE_REPORT_FAILURE: null,

    GET_TRUE_ODOMETER_REPORT_REQUEST: null,
    GET_TRUE_ODOMETER_REPORT_SUCCESS: null,
    GET_TRUE_ODOMETER_REPORT_FAILURE: null,

    GET_TIMESHEET_REPORT_REQUEST: null,
    GET_TIMESHEET_REPORT_SUCCESS: null,
    GET_TIMESHEET_REPORT_FAILURE: null,

    GET_TIMESHEET_REPORT_CSV_REQUEST: null,
    GET_TIMESHEET_REPORT_CSV_SUCCESS: null,
    GET_TIMESHEET_REPORT_CSV_FAILURE: null,

    GET_UTILISATION_REPORT_REQUEST: null,
    GET_UTILISATION_REPORT_SUCCESS: null,
    GET_UTILISATION_REPORT_FAILURE: null,

    GET_SPEEDING_REPORT_CSV_REQUEST: null,
    GET_SPEEDING_REPORT_CSV_SUCCESS: null,
    GET_SPEEDING_REPORT_CSV_FAILURE: null,

    GET_NEW_PTO_IDLE_REPORT_REQUEST: null,
    GET_NEW_PTO_IDLE_REPORT_SUCCESS: null,
    GET_NEW_PTO_IDLE_REPORT_FAILURE: null,

    GET_EVENT_REPORT_REQUEST: null,
    GET_EVENT_REPORT_SUCCESS: null,
    GET_EVENT_REPORT_FAILURE: null,

    GET_GEOFENCE_REPORT_REQUEST: null,
    GET_GEOFENCE_REPORT_SUCCESS: null,
    GET_GEOFENCE_REPORT_FAILURE: null,

    GET_GEOFENCE_REPORT_CSV_REQUEST: null,
    GET_GEOFENCE_REPORT_CSV_SUCCESS: null,
    GET_GEOFENCE_REPORT_CSV_FAILURE: null,

    GET_EVENT_REPORT_CSV_REQUEST: null,
    GET_EVENT_REPORT_CSV_SUCCESS: null,
    GET_EVENT_REPORT_CSV_FAILURE: null,

    GET_NEW_PTO_IDLE_REPORT_CSV_REQUEST: null,
    GET_NEW_PTO_IDLE_REPORT_CSV_SUCCESS: null,
    GET_NEW_PTO_IDLE_REPORT_CSV_FAILURE: null,

    RESET_REPORTS_REQUEST: null,
});

export const {
    getMileageReportRequest,
    getMileageReportSuccess,
    getMileageReportFailure,
} = createActions(
    constants.GET_MILEAGE_REPORT_REQUEST,
    constants.GET_MILEAGE_REPORT_SUCCESS,
    constants.GET_MILEAGE_REPORT_FAILURE,
);

export const {
    getMpgReportRequest,
    getMpgReportSuccess,
    getMpgReportFailure,
} = createActions(
    constants.GET_MPG_REPORT_REQUEST,
    constants.GET_MPG_REPORT_SUCCESS,
    constants.GET_MPG_REPORT_FAILURE,
);

export const {
    getDrivingTimeReportRequest,
    getDrivingTimeReportSuccess,
    getDrivingTimeReportFailure,
} = createActions(
    constants.GET_DRIVING_TIME_REPORT_REQUEST,
    constants.GET_DRIVING_TIME_REPORT_SUCCESS,
    constants.GET_DRIVING_TIME_REPORT_FAILURE,
);

export const {
    getOutsideWorkingHoursReportRequest,
    getOutsideWorkingHoursReportSuccess,
    getOutsideWorkingHoursReportFailure,
} = createActions(
    constants.GET_OUTSIDE_WORKING_HOURS_REPORT_REQUEST,
    constants.GET_OUTSIDE_WORKING_HOURS_REPORT_SUCCESS,
    constants.GET_OUTSIDE_WORKING_HOURS_REPORT_FAILURE,
);

export const {
    getNotMovedReportRequest,
    getNotMovedReportSuccess,
    getNotMovedReportFailure,
} = createActions(
    constants.GET_NOT_MOVED_REPORT_REQUEST,
    constants.GET_NOT_MOVED_REPORT_SUCCESS,
    constants.GET_NOT_MOVED_REPORT_FAILURE,
);

export const {
    getIdleReportRequest,
    getIdleReportSuccess,
    getIdleReportFailure,
} = createActions(
    constants.GET_IDLE_REPORT_REQUEST,
    constants.GET_IDLE_REPORT_SUCCESS,
    constants.GET_IDLE_REPORT_FAILURE,
);

export const {
    getPtoReportRequest,
    getPtoReportSuccess,
    getPtoReportFailure,
} = createActions(
    constants.GET_PTO_REPORT_REQUEST,
    constants.GET_PTO_REPORT_SUCCESS,
    constants.GET_PTO_REPORT_FAILURE,
);

export const {
    getTrueIdleReportRequest,
    getTrueIdleReportSuccess,
    getTrueIdleReportFailure,
} = createActions(
    constants.GET_TRUE_IDLE_REPORT_REQUEST,
    constants.GET_TRUE_IDLE_REPORT_SUCCESS,
    constants.GET_TRUE_IDLE_REPORT_FAILURE,
);

export const {
    getSpeedReportRequest,
    getSpeedReportSuccess,
    getSpeedReportFailure,
} = createActions(
    constants.GET_SPEED_REPORT_REQUEST,
    constants.GET_SPEED_REPORT_SUCCESS,
    constants.GET_SPEED_REPORT_FAILURE,
);

export const {
    getCarbonReportRequest,
    getCarbonReportSuccess,
    getCarbonReportFailure,
} = createActions(
    constants.GET_CARBON_REPORT_REQUEST,
    constants.GET_CARBON_REPORT_SUCCESS,
    constants.GET_CARBON_REPORT_FAILURE,
);

export const {
    resetReportsRequest,
} = createActions(
    constants.RESET_REPORTS_REQUEST,
);

export const {
    getSpeedingReportRequest,
    getSpeedingReportSuccess,
    getSpeedingReportFailure,
} = createActions(
    constants.GET_SPEEDING_REPORT_REQUEST,
    constants.GET_SPEEDING_REPORT_SUCCESS,
    constants.GET_SPEEDING_REPORT_FAILURE,
);

export const {
    getUnauthorisedJourneyReportRequest,
    getUnauthorisedJourneyReportSuccess,
    getUnauthorisedJourneyReportFailure,
} = createActions(
    constants.GET_UNAUTHORISED_JOURNEY_REPORT_REQUEST,
    constants.GET_UNAUTHORISED_JOURNEY_REPORT_SUCCESS,
    constants.GET_UNAUTHORISED_JOURNEY_REPORT_FAILURE,
);

export const {
    getEcoScoreReportRequest,
    getEcoScoreReportSuccess,
    getEcoScoreReportFailure,
} = createActions(
    constants.GET_ECO_SCORE_REPORT_REQUEST,
    constants.GET_ECO_SCORE_REPORT_SUCCESS,
    constants.GET_ECO_SCORE_REPORT_FAILURE,
);

export const {
    getTrueOdometerReportRequest,
    getTrueOdometerReportSuccess,
    getTrueOdometerReportFailure,
} = createActions(
    constants.GET_TRUE_ODOMETER_REPORT_REQUEST,
    constants.GET_TRUE_ODOMETER_REPORT_SUCCESS,
    constants.GET_TRUE_ODOMETER_REPORT_FAILURE,
);

export const {
    getTimesheetReportRequest,
    getTimesheetReportSuccess,
    getTimesheetReportFailure,
} = createActions(
    constants.GET_TIMESHEET_REPORT_REQUEST,
    constants.GET_TIMESHEET_REPORT_SUCCESS,
    constants.GET_TIMESHEET_REPORT_FAILURE,
);

export const {
    getTimesheetReportCsvRequest,
    getTimesheetReportCsvSuccess,
    getTimesheetReportCsvFailure,
} = createActions(
    constants.GET_TIMESHEET_REPORT_CSV_REQUEST,
    constants.GET_TIMESHEET_REPORT_CSV_SUCCESS,
    constants.GET_TIMESHEET_REPORT_CSV_FAILURE,
);

export const {
    getUtilisationReportRequest,
    getUtilisationReportSuccess,
    getUtilisationReportFailure,
} = createActions(
    constants.GET_UTILISATION_REPORT_REQUEST,
    constants.GET_UTILISATION_REPORT_SUCCESS,
    constants.GET_UTILISATION_REPORT_FAILURE,
);

export const {
    getSpeedingReportCsvRequest,
    getSpeedingReportCsvSuccess,
    getSpeedingReportCsvFailure,
} = createActions(
    constants.GET_SPEEDING_REPORT_CSV_REQUEST,
    constants.GET_SPEEDING_REPORT_CSV_SUCCESS,
    constants.GET_SPEEDING_REPORT_CSV_FAILURE,
);

export const {
    getNewPtoIdleReportRequest,
    getNewPtoIdleReportSuccess,
    getNewPtoIdleReportFailure,

} = createActions(
    constants.GET_NEW_PTO_IDLE_REPORT_REQUEST,
    constants.GET_NEW_PTO_IDLE_REPORT_SUCCESS,
    constants.GET_NEW_PTO_IDLE_REPORT_FAILURE,
);

export const {
    getEventReportRequest,
    getEventReportSuccess,
    getEventReportFailure,
} = createActions(
    constants.GET_EVENT_REPORT_REQUEST,
    constants.GET_EVENT_REPORT_SUCCESS,
    constants.GET_EVENT_REPORT_FAILURE,
);

export const {
    getNewPtoIdleReportCsvRequest,
    getNewPtoIdleReportCsvSuccess,
    getNewPtoIdleReportCsvFailure,
} = createActions(
    constants.GET_NEW_PTO_IDLE_REPORT_CSV_REQUEST,
    constants.GET_NEW_PTO_IDLE_REPORT_CSV_SUCCESS,
    constants.GET_NEW_PTO_IDLE_REPORT_CSV_FAILURE,
);

export const {
    getEventReportCsvRequest,
    getEventReportCsvSuccess,
    getEventReportCsvFailure,
} = createActions(
    constants.GET_EVENT_REPORT_CSV_REQUEST,
    constants.GET_EVENT_REPORT_CSV_SUCCESS,
    constants.GET_EVENT_REPORT_CSV_FAILURE,
);

export const {
    getGeofenceReportRequest,
    getGeofenceReportSuccess,
    getGeofenceReportFailure,
} = createActions(
    constants.GET_GEOFENCE_REPORT_REQUEST,
    constants.GET_GEOFENCE_REPORT_SUCCESS,
    constants.GET_GEOFENCE_REPORT_FAILURE,
);

export const {
    getGeofenceReportCsvRequest,
    getGeofenceReportCsvSuccess,
    getGeofenceReportCsvFailure,
} = createActions(
    constants.GET_GEOFENCE_REPORT_CSV_REQUEST,
    constants.GET_GEOFENCE_REPORT_CSV_SUCCESS,
    constants.GET_GEOFENCE_REPORT_CSV_FAILURE,
);
