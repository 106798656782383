import React from 'react';

function MultiAssetActivitySideBarSVG() {
    return (
        <svg width={64} height={61} xmlns="http://www.w3.org/2000/svg">
            <g stroke="null">
                <path d="M57 41.214H32V39.43h-3.571v1.785H7V51.93h21.429v1.785H32V51.93h25V41.214zm-46.429 7.143v-3.571H28.43v3.571H10.57zm42.858 0H32v-3.571h21.429v3.571zM57 25.143H46.286v-1.786h-3.572v1.786H7v10.714h35.714v1.786h3.572v-1.786H57V25.143zm-46.429 7.143v-3.572h32.143v3.572H10.571zm42.858 0h-7.143v-3.572h7.143v3.572zM57 9.071H21.286V7.286h-3.572V9.07H7v10.715h10.714v1.785h3.572v-1.785H57V9.07zm-46.429 7.143v-3.571h7.143v3.571h-7.143zm42.858 0H21.286v-3.571h32.143v3.571z" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default MultiAssetActivitySideBarSVG;
