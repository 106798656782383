/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import * as DivisionActions from '../../../core/division/divisionActions';
import { Input, Select, Button } from 'antd';
import ColorPicker from '../../../components/ColorPicker';
import { Erase } from '@carbon/icons-react';

const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;

class DivisionsLeftSide extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            color: props.division.divisionDetail.color,
        };
    }

    componentDidMount() {
        const { registerSubmitHandler } = this.props;
        registerSubmitHandler(this.submitDetails.bind(this));
    }

    componentDidUpdate(prevProps) {
        const { color } = this.state;
        const { division } = this.props;

        if (division.divisionDetail.color !== prevProps.division.divisionDetail.color) {
            this.setState({ color: division.divisionDetail.color });
        }
    }

    submitDetails() {
        const { actions, form, division } = this.props;
        form.validateFieldsAndScroll((err, v) => {
            if (!err) {
                const values = {
                    ...v,
                    division_id: division.divisionDetail.id,
                };
                actions.updateDivisionRequest(values);
                return true;
            }
            return false;
        });
    }

    setColor(color) {
        if (color) {
            this.props.form.setFieldsValue({ color });
            this.setState({ color });
        }
    }

    render() {
        const { form, division, divisionOptions, nonDivisionAssets, assignAsset } = this.props;
        const { color } = this.state;
        const { getFieldDecorator } = form;

        return (
            <>
                <label>
                    Name
                </label>
                <FormItem style={{ color: 'red' }}>
                    {getFieldDecorator('name', {
                        initialValue: division.divisionDetail.name,
                        rules: [
                            {
                                required: true,
                                message: 'Please enter division name',
                            },
                            {
                                max: 50,
                                message: 'Name cannot be longer than 50 characters',
                            },
                        ],
                    })(<Input placeholder="Enter Division Name" maxLength={50} />)}
                </FormItem>
                <br />
                <label>
                    Description
                </label>
                <FormItem>
                    {getFieldDecorator('description', {
                        initialValue: division.divisionDetail.description,
                    })(
                        <TextArea
                            rows={4}
                            style={{ height: 68 }}
                        />
                    )}
                </FormItem>
                <br />
                <label>
                    Color
                </label>
                <FormItem>
                    {getFieldDecorator('color', {
                        initialValue: color,
                    })(
                        <ColorPicker
                            color={color}
                            onChange={(val) => { this.setColor(val); }}
                            style={{ marginLeft: '8px' }}
                        />
                    )}
                </FormItem>
                <br />
                <label>
                    Division status
                </label>
                <FormItem>
                    {getFieldDecorator('status', {
                        initialValue: division.divisionDetail.status,
                        rules: [
                            {
                                required: true,
                                message: 'Please select status',
                            },
                        ],
                    })(
                        <Select style={{ width: '100%' }}>
                            <Option value={1}>Active</Option>
                            <Option value={0}>Inactive</Option>
                        </Select>,
                    )}
                </FormItem>
                <br />
                <h3 className="assignment-header">Assign Assets</h3>
                <Select
                    value=""
                    className="assignment-select"
                    showSearch
                    filterOption={divisionOptions}
                    onChange={() => null}
                    allowClear={<Erase size={12} />}
                    style={{ width: '100%' }}
                >
                    {nonDivisionAssets.map((item) => (
                        <Option key={`${item.id}-${item.name}-${item.asset_reg}`} value={item.id}>
                            <div className="button-option">
                                {
                                    // eslint-disable-next-line no-nested-ternary
                                    item.name === null || item.name === ''
                                        ? item.asset_reg === null || item.asset_reg === ''
                                            ? 'Unknown asset'
                                            : item.asset_reg
                                        : `${item.name}`
                                }
                                {
                                    item.name === item.asset_reg || item.asset_reg === null || item.asset_reg === ''
                                        ? ''
                                        : `(${item.asset_reg})`
                                }
                                <Button onClick={() => assignAsset(item.id)}>Add</Button>
                            </div>
                        </Option>
                    ))}
                </Select>
            </>
        );
    }
}

DivisionsLeftSide.defaultProps = {
    registerSubmitHandler: () => null,
};

DivisionsLeftSide.propTypes = {
    registerSubmitHandler: PropTypes.func,
    division: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
};

const DivisionMainForm = Form.create()(DivisionsLeftSide);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        division: state.division,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...DivisionActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DivisionMainForm);
