import React from 'react';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Input, Button, Spin } from 'antd';

import srcFleetclearLogo from '../../assets/images/fleetclear-logo.svg';
import './twoFactorAuthForm.css';
import history from '../../core/utils/history';
import Icon from '../elements/Icon';

const FormItem = Form.Item;

export default function () {
    const { form, isFetching } = this.props;
    const { getFieldDecorator } = form;
    return (
        <Spin spinning={isFetching}>
            <div className="twoFactorAuth-wrap">
                <div className="twoFactorAuth-container">
                    <div className="d-flex dir-column align-center text-center">
                        <img src={srcFleetclearLogo} className="login__logo-fleet" alt="Fleetclear" />
                        <h1 className="login__heading">Two-Factor Authentication</h1>
                    </div>
                    <br />
                    <Form onSubmit={this.handleSubmit} layout="vertical">
                        <FormItem>
                            {getFieldDecorator('verification_code', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input your verification code!',
                                    },
                                ],
                            })(<Input placeholder="Verification Code" />)}
                        </FormItem>
                        <Button 
                            htmlType="submit" 
                            type='primary'
                        >
                            Verify
                        </Button>
                        <br />
                        <div style={{ marginTop: '10px' }}>
                            <Button className="btn btn-empty btn-link" onClick={() => history.push('/map')}>
                                Return to login
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Spin>

    );
}
