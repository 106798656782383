/* global: localStorage */
import {
    call,
    put,
    takeEvery,
    all,
    delay,
} from 'redux-saga/effects';
import { message, notification } from 'antd';
import screenfull from 'screenfull';
import html2canvas from 'html2canvas';
import $ from 'jquery';
import moment from 'moment';
import { isEmpty } from 'underscore';
import { fetchApiAuth } from '../utils/api';

import {
    getLeftWidgetDataSuccess,
    getLeftWidgetDataFailure,

    getRightWidgetDataSuccess,
    getRightWidgetDataFailure,

    getCompareTargetsSuccess,
    getCompareTargetsFailure,

    getPrintPdfCompareSuccess,
    getPrintPdfCompareFailure,

} from './comparisonActions';

const {
    GET_LEFT_WIDGET_DATA_REQUEST,
    GET_RIGHT_WIDGET_DATA_REQUEST,
    GET_COMPARE_TARGETS_REQUEST,
    GET_PRINT_PDF_COMPARE_REQUEST,
} = require('./comparisonActions').constants;

function* getLeftWidgetData(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'compare/get-compare-data',
            body: action.payload,
        });
        yield put(getLeftWidgetDataSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getLeftWidgetDataFailure(e));
    }
}

function* getRightWidgetData(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'compare/get-compare-data',
            body: action.payload,
        });
        yield put(getRightWidgetDataSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getRightWidgetDataFailure(e));
    }
}

function* getCompareTargets(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'compare/get-options',
        });
        yield put(getCompareTargetsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getCompareTargetsFailure(e));
    }
}

function* getPrintPdfCompare(action) {
    try {
        let error = false;
        if (isEmpty(action.payload.widgets[0])) {
            error = true;
            notification.warning({ message: 'Warning', description: 'Please add a widget to get started' });
        }
        if (error === false) {
            if (screenfull.isEnabled) {
                screenfull.request();
            }
            yield delay(300);
            const wHeight = window.innerHeight * 3;
            const wWidth = window.innerWidth * 2;
            html2canvas($('.compare-section-wrapper').get(0), {
                width: wWidth, height: wHeight, scale: 2.5, dpi: 800, letterRendering: true,
            }).then((canvas) => {
                const myImage = canvas.toDataURL('image/jpeg');
                const doc = new window.jsPDF('1', 'pt', [wWidth, wHeight]);
                doc.addImage(myImage, 'jpeg', 5, 5);
                doc.save(`driver_asset_report_${moment().format('YYYY_MM_DD_HH_MM_SS')}.pdf`);
            });
            yield delay(300);
            screenfull.toggle();
        }
        yield put(getPrintPdfCompareSuccess());
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getPrintPdfCompareFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* assetSaga() {
    yield all([
        takeEvery(GET_LEFT_WIDGET_DATA_REQUEST, getLeftWidgetData),
        takeEvery(GET_RIGHT_WIDGET_DATA_REQUEST, getRightWidgetData),
        takeEvery(GET_COMPARE_TARGETS_REQUEST, getCompareTargets),
        takeEvery(GET_PRINT_PDF_COMPARE_REQUEST, getPrintPdfCompare),
    ]);
}
