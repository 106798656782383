/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spin, Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isEmpty, size } from 'underscore';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import CustomEmpty, { CustomEmptyType } from '../../../../../components/CustomEmpty';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            unit: false,
        };
    }

    componentDidMount() {
        const { profile } = this.props;
        const { unit_of_measurement_preference } = profile;

        if (unit_of_measurement_preference === 'british-imperial') {
            this.setState({ unit: true });
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    setUnit = (e) => {
        this.setState({ unit: e?.key === 'checked' });
    };

    render() {
        const {
            mpgReport,
            isFetching,
            toggleFilterSidebar,
            reportType,
        } = this.props;

        const { unit } = this.state;
        let unitChecked = unit;

        let switchDisabled = false;
        let checkedChildren = 'Miles per Gallon';
        let unCheckedChildren = 'Litres per 100km';
        if (reportType !== 'mpg') {
            checkedChildren = null;
            unCheckedChildren = null;
        }

        let convertedMpgReport = [];

        if (reportType == 'mpg') {
            mpgReport.forEach((report) => {
                const newReport = {};
                const keys = Object.keys(report);

                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    const value = report[key];

                    if (unit && value != null && key != 'name' && key != 'id') {
                        // convert l/100km to mpg
                        if (!Number.isNaN(+value) && value != 0) {
                            let mpg = 282.481 / value;
                            mpg = mpg.toFixed(2).toString();
                            newReport[key] = mpg;
                        } else {
                            newReport[key] = value.toString();
                        }
                    } else if (key == 'id') {
                        newReport[key] = Number(value).toFixed(0).toString();
                    } else {
                        newReport[key] = value != null ? value.toString() : value;
                    }
                }
                convertedMpgReport.push(newReport);
            });
        } else {
            switchDisabled = true;
            unitChecked = true;
            convertedMpgReport = mpgReport;
        }

        const listReports = convertedMpgReport.map((report) => {
            const trimmedReport = {
                ...report,
                name: (
                    <Link
                        to={`/assets/view/${report.id}`}
                        className="btn btn-empty">
                        {report.name}
                    </Link>
                ),
            };
            delete trimmedReport.id;
            return trimmedReport;
        });
        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        return (
            <Spin spinning={isFetching}>
                <div className="report-table-with-totals">
                    {(isEmpty(listReports) && !isFetching) ? <CustomEmpty type={CustomEmptyType.Report} />
                        : (
                            <>
                                {size(listReports) === 1
                                    ? <CustomEmpty />
                                    : (
                                        <>
                                            <h4 className='report-title'>Fuel Report</h4>
                                            <br />
                                            {checkedChildren && unCheckedChildren && (
                                                <Dropdown overlay={(
                                                    <Menu onClick={this.setUnit}>
                                                        <Menu.Item key="checked">{checkedChildren}</Menu.Item>
                                                        <Menu.Item key="unchecked">{unCheckedChildren}</Menu.Item>
                                                    </Menu>
                                                )}>
                                                    <Button
                                                        type='primary'
                                                        className='export-dropdown-button'
                                                        style={{
                                                            right: 96,
                                                            top: -14
                                                        }}
                                                    >
                                                        {unitChecked ? checkedChildren : unCheckedChildren}
                                                        <DownOutlined />
                                                    </Button>
                                                </Dropdown>
                                            )}
                                            {!isFetching && (
                                                <GridView
                                                    showExportButton
                                                    exportableColumns={exportableColumns}
                                                    data={listReports}
                                                    onChange={this.onTableChange}
                                                    disablePagination
                                                />
                                            )}
                                        </>
                                    )}
                            </>
                        )}
                </div>
            </Spin>
        );
    }
}

ReportTab.defaultProps = {
    mpgReport: [],
    onPageChange: () => null,
    onTableChange: () => null,
};

ReportTab.propTypes = {
    mpgReport: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
    reportType: PropTypes.string.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        mpgReport: state.report.mpgReport,
        isFetching: state.report.isFetching,
        profile: state.user.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
