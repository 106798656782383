import { Record } from 'immutable';
import moment from 'moment';
import { getMinutesSliderValueFromDate } from '../utils/functions';

const { MAP_SLIDER_INTERVAL } = require('../constants').default;
const {
    REHYDRATION_COMPLETED,
    SET_VERSION,
    SET_BREADCRUMB,
    SET_USER_MAP_CENTER,
    UPDATE_SLIDER_VALUE,
    UPDATE_MAP_DATE,
    UPDATE_EVENT_SEARCH,
    UPDATE_DATA_EVENT_SEARCH,
    UPDATE_VIDEO_SEARCH,
    UPDATE_JOURNEY_BACK_LINK,
    SET_DATA_EVENT_TO_REPORT_PARAMS,
    SET_RETAIN_FILTER,
    SET_PREVIOUS_MAP_STATE,
    SET_SHARD_ID,
} = require('./globalActions').constants;

const interval = getMinutesSliderValueFromDate();

const InitialState = Record({
    version: null,
    breadcrumb: [],
    rehydrationCompleted: false,
    isFetching: false,
    selectedSliderValue: interval,
    mapDate: moment().format('YYYY-MM-DD'),
    previousMapStatusLive: true,
    userMapCenter: {
        lat: 52.48759,
        lng: -1.91199,
    },
    eventSearchFilters: {
        assetId: '',
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
        eventType: [],
        drivers: [],
        divisions: [],
    },
    dataEventSearchFilters: {
        assetId: '',
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
        inputData: [],
        drivers: [],
        divisions: [],
    },
    videoSearchFilters: {
        assetReg: '',
    },
    journeyBackLink: {
        link: '',
        text: '',
    },
    activeTimestamp: Date.now(),
    dataEventToReportParams: null,
    retainFilter: false,
    shardId: null,
});

const globalInitialState = new InitialState();
/**
 * ## globalReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function globalReducer(state = globalInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = globalInitialState.merge(state);
    switch (type) {
    case SET_VERSION:
        return state.set('version', payload);
    case SET_BREADCRUMB:
        return state.set('breadcrumb', payload);
    case REHYDRATION_COMPLETED:
        return state.set('rehydrationCompleted', payload);
    case SET_USER_MAP_CENTER:
        return state.set('userMapCenter', payload);
    case UPDATE_SLIDER_VALUE:
        return state.set('selectedSliderValue', payload);
    case UPDATE_MAP_DATE:
        return state.set('mapDate', payload);
    case UPDATE_EVENT_SEARCH:
        return state.set('eventSearchFilters', payload);
    case UPDATE_DATA_EVENT_SEARCH:
        return state.set('dataEventSearchFilters', payload);
    case UPDATE_VIDEO_SEARCH:
        return state.set('videoSearchFilters', payload);
    case UPDATE_JOURNEY_BACK_LINK:
        return state.set('journeyBackLink', payload);
    case SET_DATA_EVENT_TO_REPORT_PARAMS:
        return state.set('dataEventToReportParams', payload);
    case SET_RETAIN_FILTER:
        return state.set('retainFilter', payload);
    case SET_PREVIOUS_MAP_STATE:
        return state.set('previousMapStatusLive', payload);
    case SET_SHARD_ID:
        return state.set('shardId', payload);

    default:
        return state;
    }
}

export {
    globalReducer,
    globalInitialState,
};
