import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_DRIVERS_REQUEST: null,
    GET_DRIVERS_SUCCESS: null,
    GET_DRIVERS_FAILURE: null,

    GET_GENERAL_DATA_REQUEST: null,
    GET_GENERAL_DATA_SUCCESS: null,
    GET_GENERAL_DATA_FAILURE: null,

    GET_SINGLE_DRIVER_REQUEST: null,
    GET_SINGLE_DRIVER_SUCCESS: null,
    GET_SINGLE_DRIVER_FAILURE: null,

    UPDATE_DRIVER_REQUEST: null,
    UPDATE_DRIVER_SUCCESS: null,
    UPDATE_DRIVER_FAILURE: null,

    UPLOAD_IMAGE_REQUEST: null,
    UPLOAD_IMAGE_SUCCESS: null,
    UPLOAD_IMAGE_FAILURE: null,

    ASSIGN_ASSET_REQUEST: null,
    ASSIGN_ASSET_SUCCESS: null,
    ASSIGN_ASSET_FAILURE: null,

    GET_DRIVER_JOURNEY_REQUEST: null,
    GET_DRIVER_JOURNEY_SUCCESS: null,
    GET_DRIVER_JOURNEY_FAILURE: null,

    GET_DRIVER_EVENTS_REQUEST: null,
    GET_DRIVER_EVENTS_SUCCESS: null,
    GET_DRIVER_EVENTS_FAILURE: null,

    GET_DRIVER_SERVICE_ALERTS_REQUEST: null,
    GET_DRIVER_SERVICE_ALERTS_SUCCESS: null,
    GET_DRIVER_SERVICE_ALERTS_FAILURE: null,

    REQUEST_TAG_REQUEST: null,
    REQUEST_TAG_SUCCESS: null,
    REQUEST_TAG_FAILURE: null,

    TAG_LIMIT_REACHED_REQUEST: null,
    TAG_LIMIT_REACHED_SUCCESS: null,
    TAG_LIMIT_REACHED_FAILURE: null,

    CREATE_DRIVER_SERVICE_ALERT_REQUEST: null,
    CREATE_DRIVER_SERVICE_ALERT_SUCCESS: null,
    CREATE_DRIVER_SERVICE_ALERT_FAILURE: null,

    GET_DRIVER_LIST_REQUEST: null,
    GET_DRIVER_LIST_SUCCESS: null,
    GET_DRIVER_LIST_FAILURE: null,

    GET_DRIVER_LIST_STRICT_REQUEST: null,
    GET_DRIVER_LIST_STRICT_SUCCESS: null,
    GET_DRIVER_LIST_STRICT_FAILURE: null,

    DELETE_DRIVER_SERVICE_ALERT_REQUEST: null,
    DELETE_DRIVER_SERVICE_ALERT_SUCCESS: null,
    DELETE_DRIVER_SERVICE_ALERT_FAILURE: null,

    UPDATE_JOURNEY_LOCATION_REQUEST: null,
    UPDATE_JOURNEY_LOCATION_SUCCESS: null,
    UPDATE_JOURNEY_LOCATION_FAILURE: null,

    DELETE_DRIVER_DATA_REQUEST: null,
    DELETE_DRIVER_DATA_SUCCESS: null,
    DELETE_DRIVER_DATA_FAILURE: null,

    SET_DRIVER_JOURNEY_FILTERS: null,
});

export const {
    getDriversRequest,
    getDriversSuccess,
    getDriversFailure,
} = createActions(
    constants.GET_DRIVERS_REQUEST,
    constants.GET_DRIVERS_SUCCESS,
    constants.GET_DRIVERS_FAILURE,
);

export const {
    getGeneralDataRequest,
    getGeneralDataSuccess,
    getGeneralDataFailure,
} = createActions(
    constants.GET_GENERAL_DATA_REQUEST,
    constants.GET_GENERAL_DATA_SUCCESS,
    constants.GET_GENERAL_DATA_FAILURE,
);

export const {
    getSingleDriverRequest,
    getSingleDriverSuccess,
    getSingleDriverFailure,
} = createActions(
    constants.GET_SINGLE_DRIVER_REQUEST,
    constants.GET_SINGLE_DRIVER_SUCCESS,
    constants.GET_SINGLE_DRIVER_FAILURE,
);

export const {
    updateDriverRequest,
    updateDriverSuccess,
    updateDriverFailure,
} = createActions(
    constants.UPDATE_DRIVER_REQUEST,
    constants.UPDATE_DRIVER_SUCCESS,
    constants.UPDATE_DRIVER_FAILURE,
);

export const {
    uploadImageRequest,
    uploadImageSuccess,
    uploadImageFailure,
} = createActions(
    constants.UPLOAD_IMAGE_REQUEST,
    constants.UPLOAD_IMAGE_SUCCESS,
    constants.UPLOAD_IMAGE_FAILURE,
);

export const {
    assignAssetRequest,
    assignAssetSuccess,
    assignAssetFailure,
} = createActions(
    constants.ASSIGN_ASSET_REQUEST,
    constants.ASSIGN_ASSET_SUCCESS,
    constants.ASSIGN_ASSET_FAILURE,
);

export const {
    getDriverJourneyRequest,
    getDriverJourneySuccess,
    getDriverJourneyFailure,
} = createActions(
    constants.GET_DRIVER_JOURNEY_REQUEST,
    constants.GET_DRIVER_JOURNEY_SUCCESS,
    constants.GET_DRIVER_JOURNEY_FAILURE,
);

export const {
    getDriverEventsRequest,
    getDriverEventsSuccess,
    getDriverEventsFailure,
} = createActions(
    constants.GET_DRIVER_EVENTS_REQUEST,
    constants.GET_DRIVER_EVENTS_SUCCESS,
    constants.GET_DRIVER_EVENTS_FAILURE,
);

export const {
    getDriverServiceAlertsRequest,
    getDriverServiceAlertsSuccess,
    getDriverServiceAlertsFailure,
} = createActions(
    constants.GET_DRIVER_SERVICE_ALERTS_REQUEST,
    constants.GET_DRIVER_SERVICE_ALERTS_SUCCESS,
    constants.GET_DRIVER_SERVICE_ALERTS_FAILURE,
);

export const {
    createDriverServiceAlertRequest,
    createDriverServiceAlertSuccess,
    createDriverServiceAlertFailure,
} = createActions(
    constants.CREATE_DRIVER_SERVICE_ALERT_REQUEST,
    constants.CREATE_DRIVER_SERVICE_ALERT_SUCCESS,
    constants.CREATE_DRIVER_SERVICE_ALERT_FAILURE,
);

export const {
    requestTagRequest,
    requestTagSuccess,
    requestTagFailure,
} = createActions(
    constants.REQUEST_TAG_REQUEST,
    constants.REQUEST_TAG_SUCCESS,
    constants.REQUEST_TAG_FAILURE,
);

export const {
    tagLimitReachedRequest,
    tagLimitReachedSuccess,
    tagLimitReachedFailure,
} = createActions(
    constants.TAG_LIMIT_REACHED_REQUEST,
    constants.TAG_LIMIT_REACHED_SUCCESS,
    constants.TAG_LIMIT_REACHED_FAILURE,
);

export const {
    getDriverListRequest,
    getDriverListSuccess,
    getDriverListFailure,
} = createActions(
    constants.GET_DRIVER_LIST_REQUEST,
    constants.GET_DRIVER_LIST_SUCCESS,
    constants.GET_DRIVER_LIST_FAILURE,
);

export const {
    getDriverListStrictRequest,
    getDriverListStrictSuccess,
    getDriverListStrictFailure,
} = createActions(
    constants.GET_DRIVER_LIST_STRICT_REQUEST,
    constants.GET_DRIVER_LIST_STRICT_SUCCESS,
    constants.GET_DRIVER_LIST_STRICT_FAILURE,
);

export const {
    deleteDriverServiceAlertRequest,
    deleteDriverServiceAlertSuccess,
    deleteDriverServiceAlertFailure,
} = createActions(
    constants.DELETE_DRIVER_SERVICE_ALERT_REQUEST,
    constants.DELETE_DRIVER_SERVICE_ALERT_SUCCESS,
    constants.DELETE_DRIVER_SERVICE_ALERT_FAILURE,
);

export const {
    updateJourneyLocationRequest,
    updateJourneyLocationSuccess,
    updateJourneyLocationFailure,
} = createActions(
    constants.UPDATE_JOURNEY_LOCATION_REQUEST,
    constants.UPDATE_JOURNEY_LOCATION_SUCCESS,
    constants.UPDATE_JOURNEY_LOCATION_FAILURE,
);

export const {
    deleteDriverDataRequest,
    deleteDriverDataSuccess,
    deleteDriverDataFailure,
} = createActions(
    constants.DELETE_DRIVER_DATA_REQUEST,
    constants.DELETE_DRIVER_DATA_SUCCESS,
    constants.DELETE_DRIVER_DATA_FAILURE,
);

export const {
    setDriverJourneyFilters,
} = createActions(
    constants.SET_DRIVER_JOURNEY_FILTERS,
);
