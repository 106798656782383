import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_DEPARTMENTS_GRID_REQUEST: null,
    GET_DEPARTMENTS_GRID_SUCCESS: null,
    GET_DEPARTMENTS_GRID_FAILURE: null,

    GET_DEPARTMENT_USERS_GRID_REQUEST: null,
    GET_DEPARTMENT_USERS_GRID_SUCCESS: null,
    GET_DEPARTMENT_USERS_GRID_FAILURE: null,

    GET_DEPARTMENT_DETAILS_REQUEST: null,
    GET_DEPARTMENT_DETAILS_SUCCESS: null,
    GET_DEPARTMENT_DETAILS_FAILURE: null,

    ASSIGN_USER_REQUEST: null,
    ASSIGN_USER_SUCCESS: null,
    ASSIGN_USER_FAILURE: null,

    UNASSIGN_USER_REQUEST: null,
    UNASSIGN_USER_SUCCESS: null,
    UNASSIGN_USER_FAILURE: null,

    UPDATE_DEPARTMENT_REQUEST: null,
    UPDATE_DEPARTMENT_SUCCESS: null,
    UPDATE_DEPARTMENT_FAILURE: null,

    GET_OTHER_USERS_REQUEST: null,
    GET_OTHER_USERS_SUCCESS: null,
    GET_OTHER_USERS_FAILURE: null,

    CREATE_DEPARTMENT_REQUEST: null,
    CREATE_DEPARTMENT_SUCCESS: null,
    CREATE_DEPARTMENT_FAILURE: null,

    FILTER_DEPARTMENT_REQUEST: null,
    FILTER_DEPARTMENT_SUCCESS: null,
    FILTER_DEPARTMENT_FAILURE: null,
    SAVE_DEPARTMENT_SEARCH_PARAMS_REQUEST: null,

});

export const {
    filterDepartmentsRequest,
    filterDepartmentsSuccess,
    filterDepartmentsFailure,
} = createActions(
    constants.FILTER_DEPARTMENT_REQUEST,
    constants.FILTER_DEPARTMENT_SUCCESS,
    constants.FILTER_DEPARTMENT_FAILURE,
);

export const {
    saveDepartmentSearchParamsRequest,
} = createActions(
    constants.SAVE_DEPARTMENT_SEARCH_PARAMS_REQUEST,
);

export const {
    getDepartmentsGridRequest,
    getDepartmentsGridSuccess,
    getDepartmentsGridFailure,
} = createActions(
    constants.GET_DEPARTMENTS_GRID_REQUEST,
    constants.GET_DEPARTMENTS_GRID_SUCCESS,
    constants.GET_DEPARTMENTS_GRID_FAILURE,
);

export const {
    getDepartmentUsersGridRequest,
    getDepartmentUsersGridSuccess,
    getDepartmentUsersGridFailure,
} = createActions(
    constants.GET_DEPARTMENT_USERS_GRID_REQUEST,
    constants.GET_DEPARTMENT_USERS_GRID_SUCCESS,
    constants.GET_DEPARTMENT_USERS_GRID_FAILURE,
);

export const {
    getDepartmentDetailsRequest,
    getDepartmentDetailsSuccess,
    getDepartmentDetailsFailure,
} = createActions(
    constants.GET_DEPARTMENT_DETAILS_REQUEST,
    constants.GET_DEPARTMENT_DETAILS_SUCCESS,
    constants.GET_DEPARTMENT_DETAILS_FAILURE,
);

export const {
    assignUserRequest,
    assignUserSuccess,
    assignUserFailure,
} = createActions(
    constants.ASSIGN_USER_REQUEST,
    constants.ASSIGN_USER_SUCCESS,
    constants.ASSIGN_USER_FAILURE,
);

export const {
    unassignUserRequest,
    unassignUserSuccess,
    unassignUserFailure,
} = createActions(
    constants.UNASSIGN_USER_REQUEST,
    constants.UNASSIGN_USER_SUCCESS,
    constants.UNASSIGN_USER_FAILURE,
);

export const {
    updateDepartmentRequest,
    updateDepartmentSuccess,
    updateDepartmentFailure,
} = createActions(
    constants.UPDATE_DEPARTMENT_REQUEST,
    constants.UPDATE_DEPARTMENT_SUCCESS,
    constants.UPDATE_DEPARTMENT_FAILURE,
);

export const {
    getOtherUsersRequest,
    getOtherUsersSuccess,
    getOtherUsersFailure,
} = createActions(
    constants.GET_OTHER_USERS_REQUEST,
    constants.GET_OTHER_USERS_SUCCESS,
    constants.GET_OTHER_USERS_FAILURE,
);

export const {
    createDepartmentRequest,
    createDepartmentSuccess,
    createDepartmentFailure,
} = createActions(
    constants.CREATE_DEPARTMENT_REQUEST,
    constants.CREATE_DEPARTMENT_SUCCESS,
    constants.CREATE_DEPARTMENT_FAILURE,
);
