/* global window */
/* global localStorage */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    Spin, Empty, Button, Row, Col, Modal,
    Tooltip,
} from 'antd';
import { isEmpty } from 'underscore';
import { Link } from 'react-router-dom';
import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import Icon from '../../../../../components/elements/Icon';
import Map from '../../../../../components/Map';
import GeoFenceForm from '../../../../Map/GeoFenceForm';
import CustomEmpty, { CustomEmptyType } from '../../../../../components/CustomEmpty';
import { Information } from '@carbon/icons-react';
import dayjs from 'dayjs';
import { View } from '@carbon/icons-react';

const { GOOGLE_MAP_API_KEY, MAP_SLIDER_INTERVAL } = require('../../../../../core/constants').default;

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);

        this.mapToolBarButtons = [
            { key: 'side-bar', visible: false },
            { key: 'info-box', visible: false },
            { key: 'traffic', visible: false },
            { key: 'geo-fence', visible: false, onClick: this.props.toggleDrawGeoFence.bind(this) },
            { key: 'center-map', visible: false },
            { key: 'satellite', visible: false },
            { key: 'heat-map', visible: false },
            { key: 'live-map', visible: false },
            { key: 'video-search', visible: false },
        ];
    }

    componentDidMount() {
        this.props.handleMapRef(this.googleMapRef);
    }

    componentDidUpdate(prevProps) {
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    render() {
        const {
            geofenceReport,
            isFetching,
            geofenceReportPagination,
            reportHasBeenFetched,
            googleMapRef,
            newGeoFencePath,
            divisions,
            showSaveGeofence,
            filters,
        } = this.props;
        let listReports = [];
        if (geofenceReport && geofenceReport.length > 0) {
            listReports = geofenceReport.map((report) => {
                const tempReport = {
                    ...report,
                };

                let url = `/records/detailed-view/${report.record_id}`;
                if (newGeoFencePath?.[0]?.coordinates?.length > 0) {
                    const geopoints = newGeoFencePath[0].coordinates.map((point) => JSON.stringify(point)).join('&');
                    const hash = (Math.random() + 1).toString(36).substring(2);
                    localStorage.setItem(hash, geopoints);
                    url += `?hash=${hash}`;
                }

                if (tempReport.entry_time === "-") {
                    const tooltipMsg = "This Asset entered the Geo-fence before " + dayjs(filters?.dateRange?.[0]).format("DD/MM/YYYY h:mm:ss A")
                    tempReport.entry_time = (
                        <Tooltip title={tooltipMsg}>
                            <Button
                                className='btn btn-empty'
                                style={{ verticalAlign: 'middle' }}>
                                <Information />
                            </Button>
                        </Tooltip>
                    );
                }

                if (tempReport.exit_time === "-") {
                    const tooltipMsg = "This Asset did not exit the Geo-fence before " + dayjs(filters?.dateRange?.[1]).format("DD/MM/YYYY h:mm:ss A")
                    tempReport.exit_time = (
                        <Tooltip title={tooltipMsg}>
                            <Button
                                className='btn btn-empty'
                                style={{ verticalAlign: 'middle' }}>
                                <Information />
                            </Button>
                        </Tooltip>
                    );
                }

                if (tempReport.duration === "-") {
                    const tooltipMsg = "To calculate the duration, the Asset must have entered and exited the geo-fence during the searched timeframe"
                    tempReport.duration = (
                        <Tooltip title={tooltipMsg}>
                            <Button
                                className='btn btn-empty'
                                style={{ verticalAlign: 'middle' }}>
                                <Information />
                            </Button>
                        </Tooltip>
                    );
                }

                tempReport.actions = (
                    <Tooltip title="View Record">
                        <Button 
                            onClick={() => { window.open(url, '_blank').focus() }}
                            className='btn btn-empty actions-btn'
                        >
                            <View />
                        </Button>
                    </Tooltip>
                );
                return tempReport;
            });
        }
        const exportableColumns = listReports && listReports[0] ? (Object.keys(listReports[0]).filter((oK) => oK.toLowerCase() != 'actions')) : [];
        
        return (
            (
                <Spin spinning={isFetching}>
                    <div className="report-table">
                        <h4 className='report-title'>Geo-fence Entry/Exit Report</h4>
                        {isEmpty(listReports) ? null : (
                            <Button
                                type="primary"
                                className='export-csv-button'
                                disabled={this.props.isExportingGeofenceReportCsv}
                                onClick={() => { this.props.onExportCSV(); }}
                            >
                                <Spin 
                                    size="small" 
                                    spinning={this.props.isExportingGeofenceReportCsv}
                                >
                                    Export
                                </Spin>
                            </Button>
                        )}
                        <br />
                        {isEmpty(listReports)
                            ? reportHasBeenFetched
                                ? <CustomEmpty />
                                : <CustomEmpty type={CustomEmptyType.Report} />
                            : (
                                <>
                                    <GridView
                                        exportableColumns={exportableColumns}
                                        hiddenColumns={['record_id', 'asset_id']}
                                        data={listReports}
                                        onChange={this.onTableChange}
                                        pagination={{
                                            total: geofenceReportPagination.totalRecords,
                                            pageSize: geofenceReportPagination.perPageCount,
                                            showSizeChanger: false,
                                            onChange: (page) => this.onPageChange(page),
                                        }} 
                                    />
                                </>
                            )}
                        <Row>
                            <Col span={24}>
                                <div style={{
                                    marginBottom: '10px',
                                    display: 'flex',
                                    height: '50vh',
                                    flexDirection: 'row',
                                }}>
                                    <Map
                                        ref={(el) => {
                                            this.googleMapRef = el;
                                        }}
                                        mapToolBarButtons={this.mapToolBarButtons}
                                        markerOnMouseLeave={() => { }}
                                        geFenceCompleted={this.props.geFenceCompleted}
                                        hideDatePicker
                                        hideSlider
                                        showToolbar
                                        allowStreetView={false}
                                        showFindCenterButton={false}
                                        apiKey={GOOGLE_MAP_API_KEY}
                                        polygonBoundary={newGeoFencePath}
                                        noLimit
                                        onMapLoaded={this.props.onMapMounted}
                                        zoom={15}
                                        autoZoom />
                                </div>
                                <Modal
                                    open={showSaveGeofence}
                                    onCancel={() => this.props.cancelGeoFence(false)}
                                    cancelText={null}
                                    footer={false}
                                    width={388}
                                >
                                    <div className="d-flex dir-column align-center text-center">
                                        {showSaveGeofence ? (
                                            <>
                                                <GeoFenceForm
                                                    onSubmit={this.props.createNewTrigger}
                                                    redrawGeofence={this.props.toggleDrawGeoFence}
                                                    getDivisions={this.props.getDivisions}
                                                    hideSelection
                                                    divisions={divisions} 
                                                />
                                            </>
                                        )
                                            : <p>Processing...</p>}
                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </div>
                </Spin>
            )
        );
    }
}

ReportTab.defaultProps = {
    geofenceReport: [],
    geofenceReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    onTableChange: () => null,
    onMapMounted: () => { },
};

ReportTab.propTypes = {
    geofenceReport: PropTypes.array,
    geofenceReportPagination: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    onMapMounted: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        geofenceReport: state.report.geofenceReport,
        geofenceReportPagination: state.report.geofenceReportPagination,
        isFetching: state.report.isFetching,
        isExportingGeofenceReportCsv: state.report.isExportingGeofenceReportCsv,
        divisions: state.events.divisions,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
