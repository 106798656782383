import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';

import { isEmpty } from 'underscore';
import DOM from './filterForm';

class TheFilterForm extends React.Component {
    constructor(props) {
        super(props);
        this.view = DOM;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(['driver', 'division'], (err, values) => {
            if (!err) {
                const { onSubmit } = this.props;
                onSubmit(values);
            }
        });
    };

    render() {
        return this.view();
    }
}

TheFilterForm.defaultProps = {
    departments: [],
    dicisions: [],
};

TheFilterForm.propTypes = {
    form: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    departments: PropTypes.array,
    divisions: PropTypes.array,
};

const FilterForm = Form.create({
    name: 'driversFilterForm',
    mapPropsToFields(props) {
        const initialData = {};

        if (!isEmpty(props.searchParams)) {
            Object.keys(props.searchParams).map((item) => {
                if (!isEmpty(item)) {
                    // console.log("item: ", item)
                    initialData[item] = Form.createFormField({
                        value: props.searchParams[item],
                    });
                }
            });
        }

        return initialData;
    },
})(TheFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        driverList: state.driver.driverList,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({

        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm);
