import React, { ReactNode, useState, useEffect } from 'react';
import { APIProvider, Map as ReactGoogleMap, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday'
import { useSelector } from 'react-redux';
import AssetMarker, { AssetMarkerStatus } from './elements/Marker/AssetMarkerContent';
import PathMarker, { PathMarkerPosition } from './elements/Marker/PathMarkerContent';
import EventMarker from './elements/Marker/EventMarkerContent';
import Marker from './elements/Marker';
import ThreeHandleSlider from '../ThreeHandleSlider';
import VideoToolbar from './elements/Toolbar/VideoToolbar';
import DeviceTimeline from '../DeviceTimeline';
import { timeToSeconds, secondsToTime } from '../../core/utils/dateUtils.js';
import { TimelineSegment, VideoRequestLength, TimelineData } from '../../pages/VideoNew/tabs/VideoSearchTab';
import { AssetPopupContentProps } from './elements/Popup/AssetPopupContent';
import { EventPopupContentProps } from './elements/Popup/EventPopupContent';

import './map.scss';

dayjs.extend(isToday);
const { 
    GOOGLE_MAP_API_KEY, 
    DEFAULT_MAP_CENTER,
    DEFAULT_MAP_ZOOM, 
    ZERO_INDEXED_SECONDS_IN_A_DAY,
} = require('../../core/constants').default;

const MAP_ID: string = '6225007a6dfb173e';
let existingPathRef: google.maps.Polyline | null = null;

export interface Location {
    lat: number;
    lng: number;
    address?: string;
}

export enum MarkerType {
    Asset = 'asset',
    PathStart = 'path_start',
    PathEnd = 'path_end',
    Event = 'event',
}

export enum PopupType {
    Asset = 'asset',
    Event = 'event',
}

export interface MarkerData {
    type: MarkerType;
    location: Location;
    divisionColor?: string;
    angle?: number;
    label?: string;
    deviceStatus?: string;
    cachedAvlInfo?: string;
    speed?: string;
    icon?: string;
    popupData?: AssetPopupContentProps | EventPopupContentProps | null;
}

export interface PathSegment {
    location: Location;
    eventType: number;
    icon: string;
    datetime: string;
    popupData?: EventPopupContentProps | null;
}

// TODO: move these types to relevant files once master merged and can convert target files to .tsx
enum TimelineStatus {
    Idle = 'idle',
    Moving = 'moving',
    Stopped = 'stopped',
}
export interface Channel {
    channel: string;
    label: string;
}

/**
 * Verify the given data constitutes a valid path.
 * @param path array of PathSegment objects
 * @param map the map object, provided by useMap() hook
 * @param mapsLib the mapsLib object, provided by the useMapsLibrary("maps") hook
 * @returns boolean
 */
const verifyPathIsValid = (
    path: PathSegment[], 
    map: google.maps.Map, 
    mapsLib: google.maps.MapsLibrary,
): boolean => {
    if (path && path.length && map && mapsLib) {
        // Ensure not to render paths with one single (different) segment or less
        //   Since it's possible to have path data consisting of multiple of the same point only (if the vehicle didn't move on the selected date)
        let differentSegments: PathSegment[] = [];
        path.forEach(segment => {
            if (differentSegments.length > 1) return;
            if (differentSegments.length === 0) {
                differentSegments.push(segment);
            } else {
                differentSegments.forEach(differentSegment => {
                    if (
                        differentSegment.location.lat !== segment.location.lat || 
                        differentSegment.location.lng !== segment.location.lng
                    ) {
                        differentSegments.push(segment);
                    }
                });
            }
        });
        return differentSegments.length > 1;
    }
    return false;
}

/**
 * Returns a filtered array of PathSegment objects based on the provided slider values.
 * @param path array of PathSegment objects
 * @param sliderValue the current value of the slider
 * @returns PathSegment[] | null
 */
const filterPath = (
    path: PathSegment[] | null,
    sliderValue: [number, number, number],
): PathSegment[] | null => {
    if (path) {
        return path.filter((segment) => {
            const segmentTime: string = dayjs(segment.datetime).format('HH:mm:ss');
            const seconds: number = timeToSeconds(segmentTime);
            return seconds >= sliderValue[0] && seconds <= sliderValue[2];
        });
    }
}

/**
 * Returns an array of rendered markers from the given marker and path data.
 * @param markers array of Marker objects
 * @param path array of PathSegment objects
 * @param pathIsValid whether path consitutes a valid path, provided by verifyPathIsValid()
 * @param sliderValue the current value of the slider
 * @param showAssetLabels whether to render asset labels for asset markers
 * @returns ReactNode[]
 */
const renderMarkers = (
    markers: MarkerData[], 
    path: PathSegment[] | null, 
    pathIsValid: boolean,
    sliderValue: [number, number, number],
    showAssetLabels: boolean,
): ReactNode[] => {
    const markersWithExtra: MarkerData[] = [ ...markers ];
    if (pathIsValid) {
        const sliderFilteredPath: PathSegment[] = filterPath(path, sliderValue);
        markersWithExtra.push({
            type: MarkerType.PathStart,
            location: {
                lat: sliderFilteredPath[sliderFilteredPath.length - 1].location.lat,
                lng: sliderFilteredPath[sliderFilteredPath.length - 1].location.lng,
            },
        });
        markersWithExtra.push({
            type: MarkerType.PathEnd,
            location: {
                lat: sliderFilteredPath[0].location.lat,
                lng: sliderFilteredPath[0].location.lng,
            },
        });
        sliderFilteredPath.forEach(segment => {
            if (segment.eventType > 0 && segment.popupData) {
                markersWithExtra.push({
                    type: MarkerType.Event,
                    location: {
                        lat: segment.location.lat,
                        lng: segment.location.lng,
                    },
                    icon: segment.icon,
                    popupData: segment.popupData ? {
                        type: PopupType.Event,
                        speed: segment.popupData.speed,
                        eventName: segment.popupData.eventName,
                        time: segment.popupData.time,
                        driverId: segment.popupData.driverId,
                        driverName: segment.popupData.driverName,
                        location: segment.popupData.location,
                        avlKeys: segment.popupData.avlKeys,
                    } : null,
                });
            }
        });
    }
    return markersWithExtra.map((marker, index) => {
        let markerContent: ReactNode;
        let zIndex: number;
        switch (marker.type) {
            case MarkerType.Asset:
                let status: AssetMarkerStatus = AssetMarkerStatus.Offline;
                if (parseInt(marker.deviceStatus) > 0) {
                    status = AssetMarkerStatus.Online;
                } else {
                    if (marker.cachedAvlInfo) {
                        const parsedAvls = JSON.parse(marker.cachedAvlInfo);
                        if (parsedAvls && typeof parsedAvls === 'object' && parsedAvls['engine_rpm'] !== undefined) {
                            if (marker.speed && parseInt(marker.speed) === 0 && parseInt(parsedAvls['engine_rpm']) > 0) {
                                status = AssetMarkerStatus.Idle;
                            }
                        }
                    }
                }
                markerContent = (
                    <AssetMarker
                        divisionColor={marker.divisionColor}
                        status={status}
                        angle={marker.angle}
                        label={showAssetLabels ? marker.label : ''}
                    />
                );
                zIndex = 1000;
                break;
            case MarkerType.Event:
                markerContent = (
                    <EventMarker icon={marker.icon} />
                );
                zIndex = 998;
                break;
            case MarkerType.PathStart:
                markerContent = (
                    <PathMarker position={PathMarkerPosition.Start} />
                );
                zIndex = 999;
                break;
            case MarkerType.PathEnd:
                markerContent = (
                    <PathMarker position={PathMarkerPosition.End} />
                );
                zIndex = 999;
                break;
            
            default:
                markerContent = null;
                zIndex = 0;
                break;
        }
        if (markerContent) {
            let lat: number = marker.location.lat;
            let lng: number = marker.location.lng;
            if (pathIsValid && marker.type === MarkerType.Asset) {
                let nearestSegment: PathSegment | null = null;
                path.forEach(segment => {
                    if (!nearestSegment) {
                        const segmentDatetime = dayjs(segment.datetime);
                        const sliderValueDateTime = dayjs(segment.datetime).startOf('day').add(sliderValue[1], 'seconds');
                        if (segmentDatetime.isBefore(sliderValueDateTime)) {  
                            nearestSegment = segment;
                        }
                    }
                });
                if (nearestSegment) {
                    lat = nearestSegment.location.lat;
                    lng = nearestSegment.location.lng;
                }
            }
            return (
                <Marker
                    location={{ 
                        lat, 
                        lng,
                    }}
                    zIndex={zIndex}
                    markerContent={markerContent}
                    popupData={marker.popupData}
                />
            );
        }
    });
}

/**
 * Draws a Polyline path on the map based on the given path data.
 * @param path array of PathSegment objects
 * @param map the map object, provided by useMap() hook
 * @param mapsLib the mapsLib object, provided by the useMapsLibrary("maps") hook
 * @param sliderValue the current value of the slider
 * @returns google.maps.Polyline | null
 */
const renderPath = (
    path: PathSegment[], 
    map: google.maps.Map, 
    mapsLib: google.maps.MapsLibrary, 
    sliderValue: [number, number, number], 
    existingPathRef: google.maps.Polyline | null = null,
): google.maps.Polyline | null => {
    const sliderFilteredPath: PathSegment[] = filterPath(path, sliderValue);
    if (sliderFilteredPath.length) {
        if (existingPathRef) {
            existingPathRef.setMap(null);
            existingPathRef.setVisible(false);
            
        }
        // We have to use maps lib directly as vis.gl/react-google-maps is yet to implement geometry components, e.g. <Polyline />
        //   Can be replaced if a future update introduces geometry native components
        /* eslint-disable no-new */
        const pathRef = new mapsLib.Polyline({
            path: sliderFilteredPath.map((segment) => ({
                lat: segment.location.lat,
                lng: segment.location.lng,
            })),
            map, // passing this sets it to the map, we don't need to pass it into the component via JSX
            clickable: false,
            draggable: false,
            editable: false,
            geodesic: false, // relative to earth's angle
            strokeColor: "#1890ff",
            strokeOpacity: 1,
            strokeWeight: 5,
        });
        return pathRef;
    }
}

/**
 * Center and zoom the map based on the given markers and path data.
 * @param markers array of Marker objects
 * @param map the map object, provided by useMap() hook
 * @param coreLib the coreLib object, provided by the useMapsLibrary("core") hook
 * @param path array of PathSegment objects
 * @param pathIsValid whether path consitutes a valid path, provided by verifyPathIsValid()
 * @returns void
 */
const performAutoZoom = (
    markers: MarkerData[] | null, 
    map: google.maps.Map, 
    coreLib: google.maps.CoreLibrary, 
    path: PathSegment[] | null, 
    pathIsValid: boolean = false
): void => {
    let bounds: Location[] | null;
    if (markers && markers.length) {
        if (pathIsValid) {
            bounds = path.map((segment) => {
                return {
                    lat: segment.location.lat, 
                    lng: segment.location.lng,
                };
            });
        } else {
            if (markers.length === 1) {
                bounds = [{
                    lat: markers[0].location.lat,
                    lng: markers[0].location.lng,
                }];
            } else {
                bounds = markers.map((marker) => {
                    return {
                        lat: marker.location.lat, 
                        lng: marker.location.lng,
                    };
                });
            }
        }
    }
    if (!bounds || !bounds.length) {
        bounds = [ DEFAULT_MAP_CENTER ];
    }
    const latLngBounds = new coreLib.LatLngBounds();
    bounds.forEach((bound) => {
        latLngBounds.extend(bound);
    });
    if (!pathIsValid && markers && markers.length === 1) {
        // we do this because fitBounds doesn't work well with single markers as it zooms in too far
        map.setOptions({ 
            maxZoom: 16, 
            zoom: 16 
        });
        map.fitBounds(latLngBounds);
    } else {
        map.fitBounds(latLngBounds);
    }
}


/**
 * Returns a rendered tooltip for the slider based on the given value and data.
 * @param sliderValue the current value of the slider
 * @param timelineData a valid TimelineData object
 * @returns ReactNode
 */
const formatSliderTooltip = (sliderValue: number, timelineData: TimelineData): ReactNode => {
    if (timelineData?.timelineSegments) {
        let matches: any[] = [];
        let journeyIndex: number = 0;
        for (let i = 0; i < timelineData.timelineSegments.length; i++) {
            const segment: TimelineSegment = timelineData.timelineSegments[i];
            const isMoving = segment.status === TimelineStatus.Moving;
            if (isMoving) {
                journeyIndex += 1;
            }
            if (sliderValue >= segment.start && sliderValue <= segment.end) {
                matches.push({
                    status: segment.status,
                    location: segment.location,
                    journeyIndex: isMoving ? journeyIndex : null,
                });
            }
        }
        let highestPriority: any = null;
        let idleOverlappingMovingPrefix: any = null;
        for (let j = 0; j < matches.length; j++) {
            if (matches[j].status === TimelineStatus.Idle) {
                idleOverlappingMovingPrefix = {
                    location: matches[j].location,
                };
            }
            if (!highestPriority) {
                highestPriority = matches[j];
            } else {
                if (matches[j].status === TimelineStatus.Moving) {
                    highestPriority = matches[j];
                } else if (matches[j].status === TimelineStatus.Idle && highestPriority.status === TimelineStatus.Stopped) {
                    highestPriority = matches[j];
                }
            }
        }
        if (!highestPriority) {
            highestPriority = {
                status: TimelineStatus.Stopped,
                location: null,
                journeyIndex: null,
            };
        }
        const statusText: string = highestPriority.status === TimelineStatus.Moving 
            ? `Journey ${journeyIndex}` 
            : highestPriority.status === TimelineStatus.Idle
                ? "Idle"
                : "Stopped"; 
        let geofences: string = '';
        for (let k = 0; k < timelineData.geofenceTimelineSegments.length; k++) {
            const segment = timelineData.geofenceTimelineSegments[k];
            if (sliderValue >= segment.start && sliderValue <= segment.end) {
                geofences = segment.geofence_names.join(', ');
            }
        }
        return (
            <div style={{ textAlign: 'center' }}>
                <span style={{ whiteSpace: 'nowrap' }}>{statusText}</span>
                <br />
                {secondsToTime(sliderValue)}
                <br />
                {idleOverlappingMovingPrefix && highestPriority.status === TimelineStatus.Moving && (
                    <>
                        <span>Idle</span>
                        <br />
                        <span>{idleOverlappingMovingPrefix.location}</span>
                        <br />
                    </>
                )}
                {geofences !== '' ? (
                    <>
                        <span style={{ whiteSpace: 'nowrap' }}>
                            In Geo-fence{geofences.split(',').length > 1 ? 's' : ''}:
                            <br />
                            {geofences}
                        </span>
                    </>
                ) : highestPriority.status !== TimelineStatus.Moving && highestPriority.location ? (
                    <>
                        <span>{highestPriority.location}</span>
                    </>
                ) : null}
            </div>
        );
    } else {
        return (
            <div>{secondsToTime(sliderValue)}</div>
        );
    }
}

interface MapProps {
    markers?: MarkerData[];
    path?: PathSegment[];
    showSlider?: boolean;
    timelineData?: TimelineData | null;
    channelList?: Channel[] | null;
    selectedDvrIsOnline?: boolean;
    showVideoStreamingSidebar?: () => void;
    selectedChannels?: { channel: string, selected: boolean }[] | null;
    toggleSelectedChannel?: (channel: string) => void;
    onChangeSliderValue?: (sliderValue: [number, number, number]) => void;
    videoRequestLength?: VideoRequestLength;
    onChangeVideoRequestLength?: (videoRequestLength: VideoRequestLength) => void;
    selectedDate?: string | null;
    videoSearchSidebarIsVisible?: boolean;
}

const Map: React.FC<MapProps> = ({ 
    markers = [],
    path = [],
    showSlider = false,
    timelineData = null,
    channelList = null,
    selectedDvrIsOnline = false,
    showVideoStreamingSidebar = () => {},
    selectedChannels = null,
    toggleSelectedChannel = (channel) => {},
    onChangeSliderValue = (sliderValue) => {},
    videoRequestLength = VideoRequestLength.PlusMinusFifteenSeconds,
    onChangeVideoRequestLength = (videoRequestLength) => {},
    selectedDate = null,
    videoSearchSidebarIsVisible = false,
}) => {
    const map: google.maps.Map = useMap(); //  provides a reference to the map that we can interact with
    const mapsLib: google.maps.MapsLibrary = useMapsLibrary("maps"); // provides a reference to the maps sub-lib
    const coreLib: google.maps.CoreLibrary = useMapsLibrary("core"); // provides a reference to the core sub-lib
    const user = useSelector((state: any) => state.user);
    let initialMiddleSliderValue = Math.round(ZERO_INDEXED_SECONDS_IN_A_DAY / 2);
    if (selectedDate && dayjs(selectedDate).isToday()) {
        initialMiddleSliderValue = timeToSeconds(dayjs(selectedDate).format('HH:mm:ss'));
    }
    const [ sliderValue, setSliderValue ] = useState<[number, number, number]>([0, initialMiddleSliderValue, ZERO_INDEXED_SECONDS_IN_A_DAY]);
    const [ sliderTextValue, setSliderTextValue ] = useState<string>(secondsToTime(initialMiddleSliderValue, true)); // used to keep text input separate from sliderValue until a valid input is detected
    const [ showAssetLabels, setShowAssetLabels ] = React.useState<boolean>(user.profile.show_info_preference > 0);
    const [ timelineEl, setTimelineEl ] = React.useState<Element | null>(null);
    
    const pathIsValid = verifyPathIsValid(path, map, mapsLib);
    const renderedMarkers = renderMarkers(markers, path, pathIsValid, sliderValue, showAssetLabels);
    if (pathIsValid) {
        const newPathRef = renderPath(path, map, mapsLib, sliderValue, existingPathRef);
        if (newPathRef) {
            existingPathRef = newPathRef;
        }
    }
    const sliderWidth = timelineEl ? timelineEl.getBoundingClientRect().width : 1;
    const sliderScaleX = sliderWidth / ZERO_INDEXED_SECONDS_IN_A_DAY;

    useEffect(() => {
        if (coreLib && map) {
            performAutoZoom(markers, map, coreLib, path, pathIsValid);
        }
    }, [coreLib, map]); // only once, and after coreLib and map have finished loading
    useEffect(() => {
        if (
            sliderTextValue?.length && 
            sliderTextValue?.length === 8 && 
            sliderTextValue?.match(/[0-9][0-9]:[0-9][0-9]:[0-9][0-9]/g)?.length
        ) {
            const newSliderValue: [number, number, number] = [sliderValue[0], timeToSeconds(sliderTextValue), sliderValue[2]];
            setSliderValue(newSliderValue);
            if (onChangeSliderValue) onChangeSliderValue(newSliderValue)

        }
    }, [sliderTextValue])
    useEffect(() => {
        setTimelineEl(document.getElementsByClassName('ant-slider-rail')[0]);
        window.addEventListener('resize', () => setTimelineEl(null));
    }, []);
    useEffect(() => {
        setTimelineEl(null);
    }, [videoSearchSidebarIsVisible])
    useEffect(() => {
        setTimelineEl(document.getElementsByClassName('ant-slider-rail')[0]);
    }, [timelineEl]);
    useEffect(() => () => {
        window.removeEventListener('resize', () => setTimelineEl(null))
    }, []);

    return (
        <>
            {showSlider && (
                <>
                    <ThreeHandleSlider 
                        min={0}
                        max={ZERO_INDEXED_SECONDS_IN_A_DAY}
                        value={sliderValue}
                        defaultValue={sliderValue}
                        step={1}
                        onChange={(value: [number, number, number]) => {
                            setSliderValue(value);
                            setSliderTextValue(secondsToTime(value[1]));
                            if (onChangeSliderValue) onChangeSliderValue(value)
                        }}
                        tooltipFormatter={(value) => formatSliderTooltip(value, timelineData)} 
                        showTimingLabels
                        sliderWidth={sliderWidth}
                        style={{ zIndex: 1000 }}
                    />
                    {timelineData && (
                        <DeviceTimeline
                            timelineWidth={ZERO_INDEXED_SECONDS_IN_A_DAY}
                            scaleX={sliderScaleX}
                            data={timelineData.timelineSegments} 
                            geofenceData={timelineData.geofenceTimelineSegments}
                            style={{ 
                                top: '30px',
                                marginLeft: '15px',
                                borderRadius: '0px',
                            }}
                        />
                    )}
                    <VideoToolbar 
                        channelList={channelList}
                        toggleAssetLabels={() => setShowAssetLabels(!showAssetLabels) }
                        sliderTextValue={sliderTextValue}
                        setSliderTextValue={(value: string) => setSliderTextValue(value)}
                        videoRequestLength={videoRequestLength}
                        onChangeVideoRequestLength={onChangeVideoRequestLength}
                        selectedDvrIsOnline={selectedDvrIsOnline}
                        showVideoStreamingSidebar={showVideoStreamingSidebar}
                        selectedChannels={selectedChannels}
                        toggleSelectedChannel={toggleSelectedChannel}
                    />
                </>
            )}
            <ReactGoogleMap
                mapId={MAP_ID} // used with reuseMaps for caching
                reuseMaps // cache map where possible to improve perf
                defaultCenter={DEFAULT_MAP_CENTER} // defined in case coreLib is undefined for some reason
                defaultZoom={DEFAULT_MAP_ZOOM} // defined in case coreLib is undefined for some reason
                gestureHandling='greedy' // enables zoom via mouse wheel
                disableDoubleClickZoom
            >
                {renderedMarkers || null}
            </ReactGoogleMap>
        </>
    );
};

interface MapWithApiProps extends MapProps {
    containerStyle?: React.CSSProperties;
}

const MapWithApi: React.FC<MapWithApiProps> = ({ 
    markers = [],
    path = [],
    showSlider = false,
    timelineData = null,
    containerStyle = {},
    channelList = null,
    selectedDvrIsOnline = false,
    showVideoStreamingSidebar = () => {},
    selectedChannels = null,
    toggleSelectedChannel = (channel) => {},
    onChangeSliderValue = (sliderValue) => {},
    videoRequestLength = VideoRequestLength.PlusMinusFifteenSeconds,
    onChangeVideoRequestLength = (videoRequestLength) => {},
    selectedDate = null,
    videoSearchSidebarIsVisible = false,
}) => {
    return (
        <div 
            id='map-container'
            style={{
                ...containerStyle,
                paddingTop: showSlider ? '8px' : '16px',
                paddingBottom: '96px',
            }}
        >
            <APIProvider
                apiKey={GOOGLE_MAP_API_KEY}
                libraries={['marker', "maps"]}
            >
                <Map
                    markers={markers}
                    path={path}
                    showSlider={showSlider}
                    timelineData={timelineData}
                    channelList={channelList}
                    selectedDvrIsOnline={selectedDvrIsOnline}
                    showVideoStreamingSidebar={showVideoStreamingSidebar}
                    selectedChannels={selectedChannels}
                    toggleSelectedChannel={toggleSelectedChannel}
                    onChangeSliderValue={onChangeSliderValue}
                    videoRequestLength={videoRequestLength}
                    onChangeVideoRequestLength={onChangeVideoRequestLength}
                    selectedDate={selectedDate}
                    videoSearchSidebarIsVisible={videoSearchSidebarIsVisible}
                />
            </APIProvider>
        </div>
    );
}

export default React.memo(MapWithApi);

// TODO: rename parent dir after merged to master