import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import lockr from 'lockr';
import DOM from './eventShareView';
import * as eventsActions from '../../core/events/eventsActions';
import * as globalActions from '../../core/global/globalActions';

const { SHARD_KEY } = require('../../core/constants').default;

class EventShareView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedEvent: {},
            report: {},

        };
        this.view = DOM;
    }

    componentDidMount() {
        const { report, actions, match } = this.props;
        let { shardId } = this.props;
        const {
            isValid, eventId, deviceId, reference, historicVideoId,
        } = report || {};
        const { code } = match.params;

        const showPasswordScreen = isValid === true && !eventId && !deviceId;
        const showEventScreen = isValid === true
            && (eventId || reference || historicVideoId)
            && report.deviceId;

        if (!showPasswordScreen && !showEventScreen) {
            if (code && code.includes('_')) {
                shardId = code.split('_')[0];
                lockr.set(SHARD_KEY, shardId);
                actions.setShardId(shardId);
            }
            actions.verifyReportCodeRequest({
                code,
                shardId: shardId || '1',
            });
        }
    }

    render() {
        return this.view();
    }
}

EventShareView.propTypes = {
    actions: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        report: state.events.report,
        selectedEvent: state.events.selectedEvent,
        isFetching: state.events.isFetching,
        shardId: state.global.shardId,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...eventsActions,
                ...globalActions,
            },
            dispatch,
        ),
    };
}
export const EventShareViewTest = EventShareView;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventShareView);
