/* global document: true */
/* global window: true */
import React, { useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import {
    Button, message, Col, Input, Row, 
} from 'antd';
import moment from 'moment';
import Icon from '../../elements/Icon';
import { convertFromSeconds } from '../../../core/utils/functions';
import { getFormattedDatetime } from '../../../core/utils/dateUtils';

const { DEBUG } = require('../../../core/constants').default;

const linkText = React.createRef();

export default function ({
    sliderRangeMinValue, sliderRangeMaxValue, selectedEvent, props,
}) {
    const [isFetching, setIsFetching] = useState(false);
    const share = selectedEvent?.share;
    let isSharedAndValid = false;
    let isSharedAndExpired = false;
    const sharePort = DEBUG || window.location.hostname === 'localhost' ? `:${window.location.port}` : '';
    let shareLink = null;
    if (share && share.unique_code) {
        isSharedAndValid = true;
        shareLink = `${window.location.protocol}//${window.location.hostname}${sharePort}/event/${share.unique_code}`;
        if (share.expire == 0) {
            isSharedAndExpired = true;
        }
    }

    const generateEventReport = () => {
        if (!isSharedAndExpired && !isSharedAndValid) {
            const { actions } = props;

            actions.shareWebReportRequest({
                eventId: selectedEvent?.info?.event_id,
                deviceId: selectedEvent?.info?.device_id,
                before_time: selectedEvent?.startThreshold,
                after_time: selectedEvent?.endThreshold,
                sliderValue: selectedEvent?.sliderValue || selectedEvent?.startInterval,
                sliderRangeMinValue,
                sliderRangeMaxValue,
            });
        } else if (isSharedAndExpired) {
            // renew the web report, add 30 days to generated_on
            const { actions } = props;
            actions.renewSharedReportRequest({
                eventId: selectedEvent?.info?.event_id,
                deviceId: selectedEvent?.info?.device_id,
                before_time: selectedEvent?.startThreshold,
                after_time: selectedEvent?.endThreshold,
                uniqueCode: selectedEvent?.share?.unique_code,
            });
        }
    };

    const generateVideoReport = () => {
        const { actions } = props;
        if (!isSharedAndExpired && !isSharedAndValid) {
            const end_time = getFormattedDatetime(moment(selectedEvent?.info?.record_time)
                .add(selectedEvent?.info?.duration, 'seconds'));
            const payload = {
                historicVideoId: selectedEvent?.info?.historic_video_id,
                deviceId: selectedEvent?.info?.device_id,
                start_time: selectedEvent?.info?.record_time,
                end_time,
                sliderValue: selectedEvent?.sliderValue || selectedEvent?.startInterval,
                sliderRangeMinValue,
                sliderRangeMaxValue,
            };
            actions.shareWebReportRequest(payload);
        }
    };

    const generateCacheReport = () => {
        const { actions } = props;

        if (!isSharedAndExpired && !isSharedAndValid) {
            const startTime = convertFromSeconds(selectedEvent?.info?.start_time);
            const startDateTime = `${selectedEvent?.info?.date} ${startTime}`;
            const endTime = convertFromSeconds(selectedEvent?.info?.end_time);
            const endDateTime = `${selectedEvent?.info?.date} ${endTime}`;
            const payload = {
                reference: selectedEvent?.reference,
                deviceId: selectedEvent?.info?.device_id,
                start_time: startDateTime,
                end_time: endDateTime,
                sliderValue: selectedEvent?.sliderValue || selectedEvent?.startInterval,
                sliderRangeMinValue,
                sliderRangeMaxValue,
            };
            actions.shareWebReportRequest(payload);
        }
    };

    const onClick = () => {
        const { shareReportType } = props;

        if (shareReportType === 'cache') {
            generateCacheReport();
        } else if (shareReportType === 'video') {
            generateVideoReport();
        } else {
            generateEventReport();
        }
    };

    return (
        <div className="share-report-section">
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        type="primary"
                        className="btn"
                        onClick={onClick}
                    >
                        {isSharedAndValid ? <span>{`shared, expires in ${share?.expires} days`}</span> : null}
                        {isSharedAndExpired ? <span>Renew web report link</span> : null}
                        {!isSharedAndExpired && !isSharedAndValid ? <span>Share</span> : null}
                    </Button>
                </Col>
            </Row>
            {shareLink
                ? (
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={14}>
                            <Input
                                ref={linkText}
                                readOnly
                                value={shareLink}
                                addonAfter={(
                                    <CopyOutlined
                                        onClick={(e) => {
                                            linkText.current.select();
                                            document.execCommand('copy');
                                            message.success('Copied to clipboard');
                                        }} />
                                )} />
                        </Col>
                        <Col xs={24} md={4} style={{ paddingTop: '16px' }}>
                            <strong>Password:</strong>
                        </Col>
                        <Col xs={24} md={6} style={{ paddingTop: '16px' }}>
                            {share?.password}
                        </Col>
                    </Row>
                )
                : null}
        </div>
    );
}
