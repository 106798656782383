import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as eventsActions from '../../../core/events/eventsActions';
import * as assetActions from '../../../core/asset/assetActions';
import Icon from '../../../components/elements/Icon';
import GridView from '../../../components/elements/GridView/index';
import { Button, Col, Modal, Row, Spin, Tooltip } from 'antd';
import MapComponent from '../../../components/Map';
import app from '../../../core/constants';
import { fetchApiAuth } from '../../../core/utils/api';
import GeoFenceForm from '../forms/GeoFenceForm';
import { isEmpty } from 'underscore';
import moment from 'moment';
import { sprintf } from 'underscore.string';
import { Edit, Location, Search, TrashCan } from '@carbon/icons-react';
const { confirm } = Modal;


const { GOOGLE_MAP_API_KEY } = app;


class TriggersTab extends Component {
    constructor(props) {
        super(props);
        window.sss = this;
        this.googleMapRef = React.createRef();
        this.state = {
            newGeoFencePath: [],
            focusedTrigger: null,
            fetchingMap: false,
            deletingTrigger: false,
            showEditTrigger: false,
        }

        this.centerMap = {
            lat: 52.48759,
            lng: -1.91199,
        };

        this.mapToolBarButtons = [
            { key: 'side-bar', visible: false },
            { key: 'info-box', visible: false },
            { key: 'traffic', visible: false },
            { key: 'geo-fence', visible: false },
            { key: 'center-map', visible: false },
            { key: 'satellite', visible: false },
            { key: 'heat-map', visible: false },
            { key: 'live-map', visible: false },
            { key: 'video-search', visible: false },
        ];
    }

    componentDidMount() {
        const { actions, setLoadedState, triggersTabLoaded } = this.props;
        if (!triggersTabLoaded) {
            this.getAllTriggers(1);

            setLoadedState();
        }
    }

    getDivisions = () => {
        const { actions } = this.props;
        actions.getUserDivisionsRequest();
    }

    onPageChange = (page, pageSize) => {
        const { actions, triggersPagination } = this.props;
        if (page !== triggersPagination.currentPage) {
            this.getAllTriggers(page);
        }
    };

    getAllTriggers(page) {
        const { actions } = this.props;
        actions.getAllTriggersRequest({
            page,
        });
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange, triggersPagination } = this.props;
        if (pagination && pagination.current == triggersPagination.currentPage) {
            this.setState({
                newGeoFencePath: [],
                focusedTrigger: null
            });
            onTableChange(pagination, filters, sorter, extra);
        }
    };

    deleteSpecificTrigger(trigger) {
        trigger.deleted = true;
        this.props.actions.deleteTriggerRequest({
            triggerId: trigger.id
        });
    }

    saveTrigger = (values) => {
        const { actions } = this.props;
        const { focusedTrigger } = this.state;
        
        if (!focusedTrigger?.id) {
            return;
        }

        const days_active = moment.weekdays().reduce((carry, day) => {
            carry[day] = values.days_active.includes(day) ? 1 : 0;
            return carry;
        }, {});
        let emails = [];
        if (values?.emails?.length) {
            emails = String(values.emails).split(',').map(i => i.trim());
        }
        actions.updateTriggerRequest({
            id: focusedTrigger.id,
            trigger_name: values.trigger_name,
            selection: values.selection,
            divisions: values.divisions,
            assets: values.assets,
            emails,
            filter_by: values.filter_by,
            days_active: days_active,
            active_inside: values.active_inside,
            time_active_start: values.time_active_start.format("HH:mm:ss"),
            time_active_end: values.time_active_end.format("HH:mm:ss"),
        });
        this.setState({ focusedTrigger: null });
    }

    render() {
        const { triggers, triggersPagination, divisions, isFetching, assets } = this.props;
        const { newGeoFencePath, fetchingMap, focusedTrigger } = this.state;
        const listTriggers = triggers.filter(i => !i.deleted).map((trigger, triggerIndex) => {
            const onClick = () => {
                this.googleMapRef.clearGeofence();
                this.googleMapRef.showGeoFenceOnMap(true);
                this.setState({
                    newGeoFencePath: [],
                    fetchingMap: true
                });
                const response = fetchApiAuth({
                    method: 'GET',
                    url: `/trigger/${trigger.id}/details`
                })
                    .then(response => {
                        if (response?.data?.trigger_points?.length) {
                            const points = response.data.trigger_points.map((point) => ({ lng: parseFloat(point.lng), lat: parseFloat(point.lat) }));
                            const triggers = [{
                                coordinates: points,
                                id: trigger.id,
                            }];

                            this.setState({
                                newGeoFencePath: triggers,
                                fetchingMap: false,
                            }, () => {
                                this.googleMapRef.refreshMap(true);
                                this.googleMapRef.executeAutoZoom();

                            });
                        }
                        setTimeout(() => {
                            document.getElementById('zoom-hook')?.scrollIntoView();
                        }, 500);
                    }).catch(() => {
                        this.setState({
                            newGeoFencePath: [],
                            fetchingMap: false
                        });
                        setTimeout(() => {
                            document.getElementById('zoom-hook')?.scrollIntoView();
                        }, 500);
                    });
            }

            const deleteTrigger = (event) => {
                event.stopPropagation();
                if (!this.state.deletingTrigger) {
                    confirm({
                        title: (
                            <div style={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#697077',
                            }}>
                                Confirm Delete Geofence
                            </div>
                        ),
                        content: (
                            <div style={{
                                marginBottom: '16px',
                                fontSize: '12px',
                                fontWeight: 'normal',
                                color: '#343a3f',
                            }}>
                                Geo-fence data will be permanently lost after deletion
                            </div>
                        ),
                        onOk: () => {
                            this.deleteSpecificTrigger(trigger)
                        },
                        okText: 'Delete',
                        onCancel: () => { },
                        cancelButtonProps: { className: 'btn ant-btn ant-btn-primary' },
                        icon: null,
                        closable: true,
                    });
                }
            }

            const editMoal = () => {
                this.setState({
                    focusedTrigger: trigger,
                });
            }

            const tempTrigger = {};
            tempTrigger.id = trigger.id;
            tempTrigger.name = trigger.name;
            let daysActive = '';
            if (Object.keys(trigger.days_active).every(day => trigger.days_active[day] == 1)) {
                daysActive = "everyday";
            } else {
                daysActive = "on " + Object.keys(trigger.days_active).filter(day => trigger.days_active[day] == 1).join(', ');
            }
            const insideText = trigger.active_inside ? "Between" : "Outside of";
            tempTrigger.alerts_will_be_sent = sprintf(" %s %s - %s %s", insideText, trigger.time_active_start, trigger.time_active_end, daysActive)
            const emailsArray = (trigger.emails ?? '').split(',').filter(i => i);
            tempTrigger.emails = emailsArray.length ? "Email(s): " + emailsArray.length : 'Email(s): -';
            // tempTrigger.divisions = trigger.divisions;
            tempTrigger.affects = 'All Assets'; //(trigger.divisions ? trigger.divisions : 'All Assets');
            if (trigger.filter_by == "asset" && !isEmpty(trigger.assets)) {
                tempTrigger.affects = "Asset(s): " + trigger.assets.length;
            } else if (trigger.filter_by == "division" && !isEmpty(trigger.divisions)) {
                tempTrigger.affects = "Division(s): " + trigger.divisions.length;
            }
            tempTrigger.actions = (
                <>
                    <Tooltip title="View on Map">
                        <Button
                            className='btn btn-empty actions-btn'
                            onClick={onClick}
                        >
                            <Location />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Edit Geo-fence">
                        <Button
                            className='btn btn-empty actions-btn'
                            onClick={editMoal}
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Delete Geo-fence">
                        <Button
                            className='btn btn-empty actions-btn'
                            onClick={deleteTrigger}
                        >
                            <TrashCan />
                        </Button>
                    </Tooltip>
                </>
            );
            return tempTrigger;
        });
        return (
            <>
                <Spin spinning={isFetching}>
                    <div className="trigger-table">
                        {!isFetching && (
                            <GridView
                                data={listTriggers}
                                onChange={this.onTableChange}
                                pagination={{
                                    total: parseInt(triggersPagination.totalRecords, 10),
                                    current: parseInt(triggersPagination.currentPage, 10),
                                    showSizeChanger: false,
                                    pageSize: parseInt(triggersPagination.perPageCount, 10),
                                    onChange: this.onPageChange,
                                }} />
                        )}
                    </div>
                </Spin>
                <Spin spinning={fetchingMap}>
                    <Row>
                        <Col span={24}>
                            <div style={{
                                marginBottom: '10px',
                                display: 'flex',
                                height: '50vh',
                                flexDirection: 'row',
                                marginTop: '10px',
                            }}>
                                <MapComponent
                                    ref={(el) => {
                                        this.googleMapRef = el;
                                    }}
                                    showToolbar={false}
                                    allowStreetView={false}
                                    showFindCenterButton={false}
                                    apiKey={GOOGLE_MAP_API_KEY}
                                    mapToolBarButtons={this.mapToolBarButtons}
                                    // mapShapeOnClick={this.onMapShapeClick}
                                    polygonBoundary={newGeoFencePath}
                                    zoom={9}
                                    autoZoom
                                    noLimit
                                />
                            </div>
                        </Col>
                    </Row>
                </Spin>
                <div
                    id='zoom-hook'
                    style={{
                        width: '100%',
                        height: '1px'
                    }}
                />
                <Modal
                    visible={!!focusedTrigger}
                    onCancel={() => this.setState({ focusedTrigger: null })}
                    footer={false}
                    width={388}
                    style={{ top: 16 }}
                >
                    <div className="d-flex dir-column align-center text-center">
                        {focusedTrigger ? (
                            <>
                                <GeoFenceForm
                                    onSubmit={this.saveTrigger}
                                    initializeValues={focusedTrigger}
                                    divisions={divisions}
                                    assets={assets}
                                    deleteGeofence={() => {
                                        this.deleteSpecificTrigger(focusedTrigger)
                                    }}
                                    getDivisions={this.getDivisions}
                                    daysActive={focusedTrigger.days_active}
                                    selectedDivisions={focusedTrigger.divisions}
                                />
                            </>
                        ) : null}
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        triggers: state.events.triggers,
        triggersPagination: state.events.triggersPagination,
        divisions: state.events.divisions,
        assets: state.asset.options,
        isFetching: state.events.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TriggersTab);
