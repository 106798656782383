import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({

    SELECT_MAP_DEVICE: null,

    FLUSH_MAP_DEVICE: null,

    UPDATE_MAP_DEVICE_REQUEST_FLAG: null,
    UPDATE_MAP_HEAT_MAP_REQUEST_FLAG: null,

    MAP_DEVICE_LIST_REQUEST: null,
    MAP_DEVICE_LIST_SUCCESS: null,
    MAP_DEVICE_LIST_FAILURE: null,

    MAP_DEVICES_LAST_LOCATION_REQUEST: null,
    MAP_DEVICES_LAST_LOCATION_SUCCESS: null,
    MAP_DEVICES_LAST_LOCATION_FAILURE: null,

    MAP_DEVICE_LIST_BY_LOCATION_REQUEST: null,
    MAP_DEVICE_LIST_BY_LOCATION_SUCCESS: null,
    MAP_DEVICE_LIST_BY_LOCATION_FAILURE: null,

    MAP_DEVICE_LOCATIONS_REQUEST: null,
    MAP_DEVICE_LOCATIONS_SUCCESS: null,
    MAP_DEVICE_LOCATIONS_FAILURE: null,

    GET_DEVICE_LOCATION_DETAILED_INFO_REQUEST: null,
    GET_DEVICE_LOCATION_DETAILED_INFO_SUCCESS: null,
    GET_DEVICE_LOCATION_DETAILED_INFO_FAILURE: null,

    GET_MAP_EVENTS_HEAT_MAP_REQUEST: null,
    GET_MAP_EVENTS_HEAT_MAP_SUCCESS: null,
    GET_MAP_EVENTS_HEAT_MAP_FAILURE: null,

    GET_COMPANY_ALL_TRIGGERS_REQUEST: null,
    GET_COMPANY_ALL_TRIGGERS_SUCCESS: null,
    GET_COMPANY_ALL_TRIGGERS_FAILURE: null,

    CREATE_TRIGGER_REQUEST: null,
    CREATE_TRIGGER_SUCCESS: null,
    CREATE_TRIGGER_FAILURE: null,

    GET_DEVICE_HISTORIC_VIDEOS_REQUEST: null,
    GET_DEVICE_HISTORIC_VIDEOS_SUCCESS: null,
    GET_DEVICE_HISTORIC_VIDEOS_FAILURE: null,

    MAP_ASSET_SEARCH_REQUEST: null,
    MAP_ASSET_SEARCH_SUCCESS: null,
    MAP_ASSET_SEARCH_FAILURE: null,

    MAP_GET_LIVE_ASSETS_LOCATION_REQUEST: null,
    MAP_GET_LIVE_ASSETS_LOCATION_SUCCESS: null,
    MAP_GET_LIVE_ASSETS_LOCATION_FAILURE: null,

    DELETE_GEOFENCE_REQUEST: null,
    DELETE_GEOFENCE_SUCCESS: null,
    DELETE_GEOFENCE_FAILURE: null,

    UPDATE_DEVICE_TRIGGER_REQUEST: null,
    UPDATE_DEVICE_TRIGGER_SUCCESS: null,
    UPDATE_DEVICE_TRIGGER_FAILURE: null,

    RESET_MAP_LIVE_ASSETS_REQUEST: null,
    RESET_MAP_DATA_REQUEST: null,
    SET_HEAT_MAP_DATA_UPDATED: null,
    RESET_HEAT_MAP_DATA: null,

    UPDATE_MAP_DEVICE_LOADED: null,

    GET_TRIP_INFORMATION_REQUEST: null,
    GET_TRIP_INFORMATION_SUCCESS: null,
    GET_TRIP_INFORMATION_FAILURE: null,

    SET_MAP_DEVICES: null,
    SET_CHECKED_ASSETS: null,
    SET_SELECTED_ASSET: null,

    GET_DEVICE_TIMELINE_REQUEST: null,
    GET_DEVICE_TIMELINE_SUCCESS: null,
    GET_DEVICE_TIMELINE_FAILURE: null,

    GET_DEVICE_GEOFENCE_TIMELINE_REQUEST: null,
    GET_DEVICE_GEOFENCE_TIMELINE_SUCCESS: null,
    GET_DEVICE_GEOFENCE_TIMELINE_FAILURE: null,

    RESET_FETCHINGS: null,
});

export const {
    selectMapDevice,
    flushMapDevice,
    updateMapDeviceRequestFlag,
    updateMapHeatMapRequestFlag,
    updateMapDeviceLoaded,
} = createActions(
    constants.SELECT_MAP_DEVICE,
    constants.FLUSH_MAP_DEVICE,
    constants.UPDATE_MAP_DEVICE_REQUEST_FLAG,
    constants.UPDATE_MAP_HEAT_MAP_REQUEST_FLAG,
    constants.UPDATE_MAP_DEVICE_LOADED,
);

export const {
    mapDeviceListRequest,
    mapDeviceListSuccess,
    mapDeviceListFailure,
} = createActions(
    constants.MAP_DEVICE_LIST_REQUEST,
    constants.MAP_DEVICE_LIST_SUCCESS,
    constants.MAP_DEVICE_LIST_FAILURE,
);

export const {
    mapDevicesLastLocationRequest,
    mapDevicesLastLocationSuccess,
    mapDevicesLastLocationFailure,
} = createActions(
    constants.MAP_DEVICES_LAST_LOCATION_REQUEST,
    constants.MAP_DEVICES_LAST_LOCATION_SUCCESS,
    constants.MAP_DEVICES_LAST_LOCATION_FAILURE,
);

export const {
    mapDeviceListByLocationRequest,
    mapDeviceListByLocationSuccess,
    mapDeviceListByLocationFailure,
} = createActions(
    constants.MAP_DEVICE_LIST_BY_LOCATION_REQUEST,
    constants.MAP_DEVICE_LIST_BY_LOCATION_SUCCESS,
    constants.MAP_DEVICE_LIST_BY_LOCATION_FAILURE,
);

export const {
    mapDeviceLocationsRequest,
    mapDeviceLocationsSuccess,
    mapDeviceLocationsFailure,
} = createActions(
    constants.MAP_DEVICE_LOCATIONS_REQUEST,
    constants.MAP_DEVICE_LOCATIONS_SUCCESS,
    constants.MAP_DEVICE_LOCATIONS_FAILURE,
);

export const {
    getDeviceLocationDetailedInfoRequest,
    getDeviceLocationDetailedInfoSuccess,
    getDeviceLocationDetailedInfoFailure,
} = createActions(
    constants.GET_DEVICE_LOCATION_DETAILED_INFO_REQUEST,
    constants.GET_DEVICE_LOCATION_DETAILED_INFO_SUCCESS,
    constants.GET_DEVICE_LOCATION_DETAILED_INFO_FAILURE,
);

export const {
    getMapEventsHeatMapRequest,
    getMapEventsHeatMapSuccess,
    getMapEventsHeatMapFailure,
} = createActions(
    constants.GET_MAP_EVENTS_HEAT_MAP_REQUEST,
    constants.GET_MAP_EVENTS_HEAT_MAP_SUCCESS,
    constants.GET_MAP_EVENTS_HEAT_MAP_FAILURE,
);

export const {
    getDeviceHistoricVideosRequest,
    getDeviceHistoricVideosSuccess,
    getDeviceHistoricVideosFailure,
} = createActions(
    constants.GET_DEVICE_HISTORIC_VIDEOS_REQUEST,
    constants.GET_DEVICE_HISTORIC_VIDEOS_SUCCESS,
    constants.GET_DEVICE_HISTORIC_VIDEOS_FAILURE,
);

export const {
    getCompanyAllTriggersRequest,
    getCompanyAllTriggersSuccess,
    getCompanyAllTriggersFailure,
} = createActions(
    constants.GET_COMPANY_ALL_TRIGGERS_REQUEST,
    constants.GET_COMPANY_ALL_TRIGGERS_SUCCESS,
    constants.GET_COMPANY_ALL_TRIGGERS_FAILURE,
);

export const {
    createTriggerRequest,
    createTriggerSuccess,
    createTriggerFailure,
} = createActions(
    constants.CREATE_TRIGGER_REQUEST,
    constants.CREATE_TRIGGER_SUCCESS,
    constants.CREATE_TRIGGER_FAILURE,
);

export const {
    mapAssetSearchRequest,
    mapAssetSearchSuccess,
    mapAssetSearchFailure,
} = createActions(
    constants.MAP_ASSET_SEARCH_REQUEST,
    constants.MAP_ASSET_SEARCH_SUCCESS,
    constants.MAP_ASSET_SEARCH_FAILURE,
);

export const {
    mapGetLiveAssetsLocationRequest,
    mapGetLiveAssetsLocationSuccess,
    mapGetLiveAssetsLocationFailure,
} = createActions(
    constants.MAP_GET_LIVE_ASSETS_LOCATION_REQUEST,
    constants.MAP_GET_LIVE_ASSETS_LOCATION_SUCCESS,
    constants.MAP_GET_LIVE_ASSETS_LOCATION_FAILURE,
);

export const {
    deleteGeofenceRequest,
    deleteGeofenceSuccess,
    deleteGeofenceFailure,
} = createActions(
    constants.DELETE_GEOFENCE_REQUEST,
    constants.DELETE_GEOFENCE_SUCCESS,
    constants.DELETE_GEOFENCE_FAILURE,
);

export const {
    updateDeviceTriggerRequest,
    updateDeviceTriggerSuccess,
    updateDeviceTriggerFailure,
} = createActions(
    constants.UPDATE_DEVICE_TRIGGER_REQUEST,
    constants.UPDATE_DEVICE_TRIGGER_SUCCESS,
    constants.UPDATE_DEVICE_TRIGGER_FAILURE,
);

export const {
    resetMapLiveAssetsRequest,
} = createActions(
    constants.RESET_MAP_LIVE_ASSETS_REQUEST,
);

export const {
    resetMapDataRequest,
    setHeatMapDataUpdated,
    resetHeatMapData,
} = createActions(
    constants.RESET_MAP_DATA_REQUEST,
    constants.SET_HEAT_MAP_DATA_UPDATED,
    constants.RESET_HEAT_MAP_DATA,
);

export const {
    getTripInformationRequest,
    getTripInformationSuccess,
    getTripInformationFailure,
} = createActions(
    constants.GET_TRIP_INFORMATION_REQUEST,
    constants.GET_TRIP_INFORMATION_SUCCESS,
    constants.GET_TRIP_INFORMATION_FAILURE,
);

export const {
    setMapDevices,
    setCheckedAssets,
    setSelectedAsset,
} = createActions(
    constants.SET_MAP_DEVICES,
    constants.SET_CHECKED_ASSETS,
    constants.SET_SELECTED_ASSET,
);

export const {
    getDeviceTimelineRequest,
    getDeviceTimelineSuccess,
    getDeviceTimelineFailure,
} = createActions(
    constants.GET_DEVICE_TIMELINE_REQUEST,
    constants.GET_DEVICE_TIMELINE_SUCCESS,
    constants.GET_DEVICE_TIMELINE_FAILURE,
);

export const {
    getDeviceGeofenceTimelineRequest,
    getDeviceGeofenceTimelineSuccess,
    getDeviceGeofenceTimelineFailure,
} = createActions(
    constants.GET_DEVICE_GEOFENCE_TIMELINE_REQUEST,
    constants.GET_DEVICE_GEOFENCE_TIMELINE_SUCCESS,
    constants.GET_DEVICE_GEOFENCE_TIMELINE_FAILURE,
);

export const {
    resetFetchings,
} = createActions(
    constants.RESET_FETCHINGS,
);