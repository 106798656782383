/* global: localStorage */
import {
    call,
    put,
    takeLatest,
    takeEvery,
    all,
} from 'redux-saga/effects';
import { message, notification } from 'antd';
import { saveAs } from 'file-saver';
import { fetchApiAuth, fetchApiAuthCustom } from '../utils/api';
import history from '../utils/history';

import {
    assetSearchSuccess,
    assetSearchFailure,

    filterAssetsSuccess,
    filterAssetsFailure,

    createAssetSuccess,
    createAssetFailure,

    getAssetInfoSuccess,
    getAssetInfoFailure,

    deleteAssetFailure,
    deleteAssetSuccess,

    getAssetInfoByImeiAndDateSuccess,
    getAssetInfoByImeiAndDateFailure,

    getEventTypesSuccess,
    getEventTypesFailure,

    getAssetJourneySuccess,
    getAssetJourneyFailure,

    getAssetListSuccess,
    getAssetListFailure,

    getBasicAssetListSuccess,
    getBasicAssetListFailure,

    getAssetEventHistorySuccess,
    getAssetEventHistoryFailure,

    getAssetServiceAlertsSuccess,
    getAssetServiceAlertsFailure,

    createServiceAlertSuccess,
    createServiceAlertFailure,

    uploadAssetImageSuccess,
    uploadAssetImageFailure,

    uploadNewAssetImageSuccess,
    uploadNewAssetImageFailure,

    getAssetLiveVideoUrlSuccess,
    getAssetLiveVideoUrlFailure,

    queueByDeviceIdSuccess,
    queueByDeviceIdFailure,

    getAssetHistoricVideoSuccess,
    getAssetHistoricVideoFailure,

    updateAssetSuccess,
    updateAssetFailure,

    getDivisionsSuccess,
    getDivisionsFailure,

    getAllAssetOptionsSuccess,
    getAllAssetOptionsFailure,

    getAssetOptionsSuccess,
    getAssetOptionsFailure,

    getAssetOptionsStrictSuccess,
    getAssetOptionsStrictFailure,

    getDivisionListSuccess,
    getDivisionListFailure,

    deleteServiceAlertSuccess,
    deleteServiceAlertFailure,

    getVehicleTypesSuccess,
    getVehicleTypesFailure,

    updateAssetJourneyLocationSuccess,
    updateAssetJourneyLocationFailure,

    getVehicleMakesSuccess,
    getVehicleMakesFailure,

    getVehicleMakeModelsSuccess,
    getVehicleMakeModelsFailure,

    deleteAssetDataSuccess,
    deleteAssetDataFailure,

    getCsvAssetRecordsByDaySuccess,
    getCsvAssetRecordsByDayFailure,
} from './assetActions';

const {
    ASSET_SEARCH_REQUEST,
    FILTER_ASSETS_REQUEST,
    FILTER_ASSETS_DIVISION_REQUEST,
    CREATE_ASSET_REQUEST,
    GET_ASSET_INFO_REQUEST,
    DELETE_ASSET_REQUEST,
    GET_ASSET_INFO_BY_IMEI_AND_DATE_REQUEST,
    GET_EVENT_TYPES_REQUEST,
    GET_ASSET_JOURNEY_REQUEST,
    GET_ASSET_LIST_REQUEST,
    GET_BASIC_ASSET_LIST_REQUEST,
    GET_ASSET_EVENT_HISTORY_REQUEST,
    GET_ASSET_HISTORIC_VIDEO_REQUEST,
    GET_ASSET_SERVICE_ALERTS_REQUEST,
    CREATE_SERVICE_ALERT_REQUEST,
    UPLOAD_ASSET_IMAGE_REQUEST,
    UPLOAD_NEW_ASSET_IMAGE_REQUEST,
    GET_ASSET_LIVE_VIDEO_URL_REQUEST,
    QUEUE_BY_DEVICE_ID_REQUEST,
    UPDATE_ASSET_REQUEST,
    GET_DIVISIONS_REQUEST,
    GET_ALL_ASSET_OPTIONS_REQUEST,
    GET_ASSET_OPTIONS_REQUEST,
    GET_ASSET_OPTIONS_STRICT_REQUEST,
    GET_DIVISION_LIST_REQUEST,
    DELETE_SERVICE_ALERT_REQUEST,
    GET_VEHICLE_TYPES_REQUEST,
    UPDATE_ASSET_JOURNEY_LOCATION_REQUEST,
    GET_VEHICLE_MAKES_REQUEST,
    GET_VEHICLE_MAKE_MODELS_REQUEST,
    DELETE_ASSET_DATA_REQUEST,
    GET_CSV_ASSET_RECORDS_BY_DAY_REQUEST,
} = require('./assetActions').constants;

function* getAssetSearch(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/asset/search',
            body: action.payload,
        });
        yield put(assetSearchSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(assetSearchFailure(e));
    }
}

function* filterAssets(action) {
    try {
        const url = action.payload && action.payload.page ? `asset/filter-assets?page=${action.payload.page}` : 'asset/filter-assets';
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url,
            body: action.payload,
        });

        yield put(filterAssetsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(filterAssetsFailure(e));
    }
}

function* createAsset(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/asset/new',
            body: action.payload,
        });
        notification.success({
            message: 'Success',
            description: 'Asset created successfully',
        });
        yield put(createAssetSuccess(response));
        history.push(`/assets/edit/${response.data.id}`);
    } catch (e) {
        /* eslint-disable-next-line no-console */

        yield put(createAssetFailure(e));
    }
}

function* getAssetInfo(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/asset/${action.payload}`,
        });
        yield put(getAssetInfoSuccess(response));
    } catch (e) {
        /* eslint-disable-next-line no-console */
        notification.error({
            message: 'Error',
            description: e.response ? e.response.data.message : e,
        });
        yield put(getAssetInfoFailure(e));
    }
}

function* deleteAsset(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/asset/${action.payload}/delete`,
        });
        notification.success({
            message: 'Success',
            description: 'Asset deleted successfully',
        });
        history.push('/assets');
        yield put(deleteAssetSuccess(response));
    } catch (e) {
        /* eslint-disable-next-line no-console */

        yield put(deleteAssetFailure(e));
    }
}

function* getAssetInfoByImeiAndDate(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/asset/${action.payload.imei}/info`,
            body: action.payload,
        });
        yield put(getAssetInfoByImeiAndDateSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getAssetInfoByImeiAndDateFailure(e));
    }
}

function* getEventTypeList(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/asset/event_types',
            params: action.payload,
        });
        yield put(getEventTypesSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getEventTypesFailure(e));
    }
}

function* getAssetJourney(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/asset/${action.payload.assetId}/journey`,
            params: {
                page: (action.payload.page || 1),
                assetId: action.payload.assetId,
            },
            body: action.payload,
        });
        yield put(getAssetJourneySuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getAssetJourneyFailure(e));
    }
}

function* getAssetHistoricVideo(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'historic-video/get-videos',
            params: {
                page: (action.payload.page || 1),
                asset_id: action.payload.assetId,
            },
            body: action.payload,
        });

        yield put(getAssetHistoricVideoSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getAssetHistoricVideoFailure(e));
    }
}

function* getAssetEvents(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/asset/${action.payload.assetId}/events`,
            params: {
                page: (action.payload.page || 1),
                assetId: action.payload.assetId,
            },
            body: action.payload,
        });
        yield put(getAssetEventHistorySuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getAssetEventHistoryFailure(e));
    }
}

function* getServiceAlerts(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/asset/${action.payload.assetId}/service-alerts`,
            params: {
                page: (action.payload.page || 1),
                assetId: action.payload.assetId,
            },
            body: action.payload,
        });
        yield put(getAssetServiceAlertsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getAssetServiceAlertsFailure(e));
    }
}

function* getAssetList(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/asset/get-asset-list',
            body: action.payload,
        });

        yield put(getAssetListSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getAssetListFailure(e));
    }
}

function* getBasicAssetList(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/asset/get-basic-asset-list',
            body: action.payload,
        });
        yield put(getBasicAssetListSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getBasicAssetListFailure(e));
    }
}

function* createAssetServiceAlert(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/asset/${action.payload.assetId}/create-service-alert`,
            body: action.payload,
        });

        yield put(createServiceAlertSuccess(response));
        notification.success({ message: 'Success', description: 'You\'ve Successfully created service alert' });
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(createServiceAlertFailure(e));
    }
}

function* uploadAssetImage(action) {
    try {
        const body = new FormData();
        body.append('image', action.payload.image);
        const response = yield call(fetchApiAuthCustom, {
            method: 'POST',
            url: `/asset/${action.payload.assetId}/upload-image`,
            body,
        });
        notification.success({
            message: 'Success',
            description: 'Asset image uploaded successfully',
        });
        yield put(uploadAssetImageSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(uploadAssetImageFailure(e));
    }
}

function* uploadNewAssetImage(action) {
    try {
        const body = new FormData();
        body.append('image', action.payload.image);
        const response = yield call(fetchApiAuthCustom, {
            method: 'POST',
            url: `/asset/${action.payload.assetId}/upload-new-image`,
            body,
        });
        notification.success({
            message: 'Success',
            description: 'Asset image uploaded successfully',
        });
        yield put(uploadNewAssetImageSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(uploadNewAssetImageFailure(e));
    }
}

function* getAssetLiveVideoUrls(action) {
    try {
        const response = yield call(fetchApiAuthCustom, {
            method: 'GET',
            url: `/asset/${action.payload.imei}/live-urls`,
        });
        const { data } = response;
        if (!data || data.length === 0) {
            notification.info({ message: 'Live Video Information', description: 'Live video is not available for this asset.' });
            if (!action.payload.redirectToFeed || (action.payload.redirectToFeed && action.payload.redirectToFeed !== true)) history.push('/map');
        } else if (action.payload.redirectToFeed && action.payload.redirectToFeed === true) {
            history.push(`/map/${action.payload.imei}/live-video`);
        }
        yield put(getAssetLiveVideoUrlSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getAssetLiveVideoUrlFailure(e));
    }
}

function* queueByDeviceId(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/download-queue/queue-device',
            body: action.payload,
        });

        notification.success({
            message: 'Success',
            description: 'Video queue process has started',
        });

        yield put(queueByDeviceIdSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(queueByDeviceIdFailure(e));
    }
}

function* updateAsset(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'asset/update-asset',
            body: action.payload,
        });
        yield put(updateAssetSuccess(response));
        notification.success({ message: 'Success', description: 'Asset updated successfully.' });
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(updateAssetFailure(e));
    }
}

function* getDivisions() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'asset/get-divisions',
        });

        yield put(getDivisionsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(getDivisionsFailure(e));
    }
}

function* getAllAssetOptions(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'asset/get-all-options-list',
            params: action.payload,
        });

        yield put(getAllAssetOptionsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getAllAssetOptionsFailure(e));
    }
}

function* getAssetOptions(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'asset/get-options-list',
            params: action.payload,
        });

        yield put(getAssetOptionsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getAssetOptionsFailure(e));
    }
}

function* getAssetOptionsStrict({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'asset/get-options-list-strict',
            params: payload,
        });

        yield put(getAssetOptionsStrictSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getAssetOptionsStrictFailure(e));
    }
}

function* getDivisionList() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'asset/get-division-list',
        });

        yield put(getDivisionListSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getDivisionListFailure(e));
    }
}

function* deleteServieAlert(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/asset/${action.payload.assetId}/delete-service-alert`,
            body: { alertId: action.payload.alertId },
        });
        notification.success({ message: 'Success', description: 'Service alert deleted successfully.' });
        yield put(deleteServiceAlertSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(deleteServiceAlertFailure(e));
    }
}

function* getVehicleTypes() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'asset/get-vehicle-types',
        });

        yield put(getVehicleTypesSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(getVehicleTypesFailure(e));
    }
}

function* updateAssetJourneyLocation(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'driver/update-journey-location',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'The location has been successfully updated' });

        yield put(updateAssetJourneyLocationSuccess({ payload: action.payload, response }));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(updateAssetJourneyLocationFailure(e));
    }
}

function* getVehicleMakes() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/asset/get-vehicle-makes',
        });

        yield put(getVehicleMakesSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(getVehicleMakesFailure(e));
    }
}

function* getVehicleMakeModels(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/asset/get-vehicle-models/${action.payload.make_id}`,
        });

        yield put(getVehicleMakeModelsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(getVehicleMakeModelsFailure(e));
    }
}

function* deleteAssetData(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/asset/${action.payload.assetId}/soft-delete-asset-data`,
        });

        notification.success({ message: 'Success', description: 'Asset data successfully deleted' });
        history.push('/assets');
        yield put(deleteAssetDataSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(deleteAssetDataFailure(e));
    }
}

function* getCsvAssetRecordsByDay(action) {
    const params = action.payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/asset/${action.payload.assetId}/get-record-csv-by-day`,
            params,
            // responseType: 'blob',
        });
        const blob = new Blob([response.data], { type: 'application/csv' });
        saveAs(blob, 'RecordsForAssetByDay.csv');
        notification.success({
            message: 'Success',
            description: 'File downloaded successfully.',
        });
        yield put(getCsvAssetRecordsByDaySuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getCsvAssetRecordsByDayFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* assetSaga() {
    yield all([
        takeEvery(ASSET_SEARCH_REQUEST, getAssetSearch),
        takeEvery(FILTER_ASSETS_REQUEST, filterAssets),
        takeEvery(FILTER_ASSETS_DIVISION_REQUEST, filterAssets),
        takeEvery(ASSET_SEARCH_REQUEST, getAssetSearch),
        takeEvery(CREATE_ASSET_REQUEST, createAsset),
        takeEvery(GET_ASSET_INFO_REQUEST, getAssetInfo),
        takeEvery(DELETE_ASSET_REQUEST, deleteAsset),
        takeEvery(GET_ASSET_INFO_BY_IMEI_AND_DATE_REQUEST, getAssetInfoByImeiAndDate),
        takeEvery(GET_EVENT_TYPES_REQUEST, getEventTypeList),
        takeEvery(GET_ASSET_JOURNEY_REQUEST, getAssetJourney),
        takeEvery(GET_ASSET_LIST_REQUEST, getAssetList),
        takeEvery(GET_BASIC_ASSET_LIST_REQUEST, getBasicAssetList),
        takeEvery(GET_ASSET_EVENT_HISTORY_REQUEST, getAssetEvents),
        takeEvery(GET_ASSET_HISTORIC_VIDEO_REQUEST, getAssetHistoricVideo),
        takeEvery(GET_ASSET_SERVICE_ALERTS_REQUEST, getServiceAlerts),
        takeLatest(CREATE_SERVICE_ALERT_REQUEST, createAssetServiceAlert),
        takeLatest(UPLOAD_ASSET_IMAGE_REQUEST, uploadAssetImage),
        takeLatest(UPLOAD_NEW_ASSET_IMAGE_REQUEST, uploadNewAssetImage),
        takeEvery(GET_ASSET_LIVE_VIDEO_URL_REQUEST, getAssetLiveVideoUrls),
        takeEvery(QUEUE_BY_DEVICE_ID_REQUEST, queueByDeviceId),
        takeEvery(UPDATE_ASSET_REQUEST, updateAsset),
        takeEvery(GET_DIVISIONS_REQUEST, getDivisions),
        takeEvery(GET_ALL_ASSET_OPTIONS_REQUEST, getAllAssetOptions),
        takeEvery(GET_ASSET_OPTIONS_REQUEST, getAssetOptions),
        takeEvery(GET_ASSET_OPTIONS_STRICT_REQUEST, getAssetOptionsStrict),
        takeEvery(GET_DIVISION_LIST_REQUEST, getDivisionList),
        takeEvery(DELETE_SERVICE_ALERT_REQUEST, deleteServieAlert),
        takeEvery(GET_VEHICLE_TYPES_REQUEST, getVehicleTypes),
        takeEvery(UPDATE_ASSET_JOURNEY_LOCATION_REQUEST, updateAssetJourneyLocation),
        takeEvery(GET_VEHICLE_MAKES_REQUEST, getVehicleMakes),
        takeEvery(GET_VEHICLE_MAKE_MODELS_REQUEST, getVehicleMakeModels),
        takeEvery(DELETE_ASSET_DATA_REQUEST, deleteAssetData),
        takeEvery(GET_CSV_ASSET_RECORDS_BY_DAY_REQUEST, getCsvAssetRecordsByDay),
    ]);
}
