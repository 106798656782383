import React from 'react';

function DriverIdentificationSVG() {
    return (
        <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
            <g stroke="null">
                <path d="M53.429 14.143v35.714H10.57V14.143H53.43m0-3.572H10.57A3.571 3.571 0 007 14.143v35.714a3.571 3.571 0 003.571 3.572H53.43A3.571 3.571 0 0057 49.857V14.143a3.571 3.571 0 00-3.571-3.572z" />
                <path d="M14.143 21.286h12.5v3.571h-12.5zM14.143 28.429h7.143V32h-7.143zM44.5 35.571H33.786a5.357 5.357 0 00-5.357 5.358V44.5H32v-3.571a1.786 1.786 0 011.786-1.786H44.5a1.786 1.786 0 011.786 1.786V44.5h3.571v-3.571A5.357 5.357 0 0044.5 35.57zM39.143 33.786A7.143 7.143 0 1032 26.643a7.143 7.143 0 007.143 7.143zm0-10.715a3.571 3.571 0 11-3.572 3.572 3.571 3.571 0 013.572-3.572z" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default DriverIdentificationSVG;
