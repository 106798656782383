import React, { useEffect } from 'react';
import { Button, Row, Col, notification, Spin } from 'antd';
import Icon from '../elements/Icon';
import { fetchApiAuth } from '../../core/utils/api';
import { Channel } from '../MapNew';
import VideoPlayer from '../elements/VideoPlayer';
import dayjs from 'dayjs';

import './videoStreamingSidebar.scss';


// TODO: move these types after merge to master
interface ChannelLink {
    channel: number;
    device: string;
    duration: number;
    end_time: string;
    id: string;
    start_time: string;
    url: string;
    video_api: string;
}

interface VideoStreamingSidebarProps {
    dvrId?: string | null;
    channelList?: Channel[] | null;
    isVisible?: boolean;
    closeSidebar?: () => void;
    selectedChannels?: { channel: string, selected: boolean }[] | null;
    dateFrom?: string | null;
    dateTo?: string | null;
    queueCachedVideo?: () => void;
}

const VideoStreamingSidebar: React.FC<VideoStreamingSidebarProps> = ({
    dvrId = null,
    channelList = null,
    isVisible = false,
    closeSidebar = () => {},
    selectedChannels = null,
    dateFrom = null,
    dateTo = null,
    queueCachedVideo = () => {},
}) => {
    const [ channelLinksIsFetching, setChannelLinksIsFetching ] = React.useState<boolean>(false);
    const [ channelLinks, setChannelLinks ] = React.useState<ChannelLink[] | null>(null);

    useEffect(() => {
        if (isVisible && !channelLinksIsFetching && dvrId && channelList?.length && selectedChannels?.length) {
            setChannelLinksIsFetching(true);
            fetchApiAuth({
                method: 'POST',
                url: '/historic-video/get-channels-and-videos',
                body: {
                    date_from: dateFrom,
                    date_to: dateTo,
                    channels: selectedChannels.map((channel) => channel.channel).join(','),
                    device_id: dvrId,
                },
            })
                .then((res) => {
                    setChannelLinksIsFetching(false);
                    if (res?.data?.length) {
                        setChannelLinks(res.data.map(d => d[0]) as ChannelLink[]);
                    } else {
                        setChannelLinks([]);
                    }
                })
                .catch(() => {
                    setChannelLinksIsFetching(false);
                    notification.error({
                        message: 'Error',
                        description: 'Failed to get camera links',
                    });
                });
        }
    }, [isVisible]);

    if (isVisible) {
        return (
            <div className='video-streaming-sidebar'>
                <div className='inner'>
                    <div className='button-container'>
                        <Button
                            className="btn btn-empty"
                            onClick={closeSidebar}
                        >
                            <Icon name="close" />
                        </Button>
                        <Button
                            type='primary'
                            onClick={queueCachedVideo}
                        >
                            <Icon name='video' />
                            Save
                        </Button>
                    </div>
                    {channelLinksIsFetching && (
                        <div className='spin-container'>
                            <Spin spinning />
                        </div>
                    )}
                    {channelLinks && !channelLinksIsFetching && (
                        <Row gutter={24}>
                            {channelLinks.length > 0 ? channelLinks.map((channelLink) => {
                                return (
                                    <Col
                                        key={`camera-${channelLink.id}`}
                                        className="grid-video"
                                        xs={24}
                                    >
                                        {channelLink.url && (
                                            <VideoPlayer 
                                                minHeight={300}
                                                identity={channelLink.id} 
                                                url={channelLink.url} 
                                                // videoServer={channelLink.video_server} 
                                                videoType={channelLink.video_api === 'Howen' ? 'howen' : 'flv'} 
                                            />
                                        )}
                                    </Col>
                                );
                            }) : (<div className='no-videos-info'>No available videos.</div>)}
                        </Row>
                    )}
                </div>
            </div>
        );
    }

    return null;
}

export default VideoStreamingSidebar;
