import { each } from 'underscore';
import moment from 'moment';
import { convertMinsIntoHoursAndMins, convertSecondsIntoDatetime } from '../utils/functions';

const { MAP_SLIDER_INTERVAL } = require('../constants').default;

const populateMapDevices = (mapDevices, intervalParam, selectedAsset = null) => {
    let interval = (MAP_SLIDER_INTERVAL || 10);
    if (intervalParam) interval = intervalParam;
    const upperLimit = selectedAsset ? (86400 / interval) : (1440 / interval);
    const lowerLimit = 0;

    const tempDevices = mapDevices;
    each(mapDevices, (asset, assetIndex) => {
        for (let i = upperLimit; i >= lowerLimit; i -= 1) {
            // Find the nearest non-null marker to copy from
            if (asset && asset.markers && asset.markers[i] == null) {
                let findKey = i;

                if (i === 0) {
                    findKey += 1;
                    while (asset.markers[findKey] == null && findKey <= upperLimit) {
                        findKey += 1;
                    }
                } else {
                    findKey -= 1;
                    while (asset.markers[findKey] == null && findKey > 0) {
                        findKey -= 1;
                    }
                }

                if (findKey <= 0) {
                    while (asset.markers[findKey] == null && findKey <= upperLimit) {
                        findKey += 1;
                    }
                }

                // Copy found record
                if (findKey > 0) {
                    let time;
                    if (selectedAsset) time = convertSecondsIntoDatetime(i, interval);
                    else time = convertMinsIntoHoursAndMins(i, interval);

                    if (tempDevices[assetIndex] && tempDevices[assetIndex].markers && tempDevices[assetIndex].markers[i] == null) {
                        tempDevices[assetIndex].markers[i] = {};
                    }
                    if (tempDevices[assetIndex] && tempDevices[assetIndex].markers && tempDevices[assetIndex].markers[i] && asset.markers[findKey]) {
                        tempDevices[assetIndex].markers[i].record_id = asset.markers[findKey].record_id;
                        tempDevices[assetIndex].markers[i].date = `${moment(asset.markers[findKey].date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')} ${time}`;
                        tempDevices[assetIndex].markers[i].copy_date = asset.markers[findKey].date;
                        tempDevices[assetIndex].markers[i].time = time;
                        tempDevices[assetIndex].markers[i].time_interval = i.toString();
                        tempDevices[assetIndex].markers[i].lat = asset.markers[findKey].lat;
                        tempDevices[assetIndex].markers[i].lng = asset.markers[findKey].lng;
                        tempDevices[assetIndex].markers[i].angle = asset.markers[findKey].angle;
                        tempDevices[assetIndex].markers[i].speed = asset.markers[findKey].speed;
                        tempDevices[assetIndex].markers[i].priority = asset.markers[findKey].priority;
                        tempDevices[assetIndex].markers[i].event_key = asset.markers[findKey].event_key;
                        tempDevices[assetIndex].markers[i].copy = `copy of ${findKey}`;
                        tempDevices[assetIndex].markers[i].data = asset.markers[findKey].data;
                        tempDevices[assetIndex].markers[i].asset_id = asset.asset_id;
                        tempDevices[assetIndex].markers[i].division_id = asset.division_id;
                    }
                }
            }
        }
    });

    return tempDevices;
};

export default populateMapDevices;
