// APP CONSTATNS
const app = {
    DEBUG: false,
    MOCK: false,
    APP_NAME: process.env.REACT_APP_APP_NAME,
    API_URL: process.env.REACT_APP_API_URL,
    OSM_URL: process.env.REACT_APP_OSM_URL,
    AUTH_KEY: 'iss-key',
    API_LOGIN_KEY: 'iss-api-key',
    SHARD_KEY: 'shard-id',
    LOCALSTORAGE_EXPIRES_KEY: 'expire-auth',
    GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    DEFAULT_MAP_CENTER: { // Center of UK
        lat: 52.48759,
        lng: -1.91199,
    },
    DEFAULT_MAP_ZOOM: 6,
    LOCALSTORAGE_EXPIRES: '30', // minutes
    BUGSNAG_KEY: '719ed98fd8d631b89b17e4b188a1f4d8',
    MAP_SLIDER_INTERVAL: parseInt(process.env.REACT_APP_MAP_SLIDER_INTERVAL, 10),
    MAP_LIVE_ASSET_DELAY: parseInt(process.env.REACT_APP_MAP_LIVE_ASSET_DELAY, 10),
    GEOFENCE_REPORT_FEATURE: 'geofence-report',

    SECONDS_IN_A_DAY: 86400,
    ZERO_INDEXED_SECONDS_IN_A_DAY: 86399,
};

export default app;
