/* global: localStorage */
import {
    call,
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getUnresolvedLogsSuccess,
    getUnresolvedLogsFailure,
} from './shardLogActions';

const {
    GET_UNRESOLVED_LOGS_REQUEST,
} = require('./shardLogActions').constants;

function* getUnresolvedLogs(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'shard-log/get-unresolved',
            body: action.payload,
        });
        yield put(getUnresolvedLogsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getUnresolvedLogsFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* shardLogSaga() {
    yield all([
        takeEvery(GET_UNRESOLVED_LOGS_REQUEST, getUnresolvedLogs),
    ]);
}
