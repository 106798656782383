/* eslint-disable import/prefer-default-export */

/**
 * Updates a list of widgets from state with a new widget from backend
 *
 * @param oldWidgets
 * @param newWidget
 */
function updateWidgets(oldWidgets, newWidget) {
    if (oldWidgets.some((w) => w.id === newWidget.id)) {
        const newWidgets = oldWidgets.map((widget) => {
            if (widget.id === newWidget.id) {
                // console.log("newWidget: ", newWidget);
                return newWidget;
            }
            return widget;
        });
        // console.log("newWidgets: ", newWidgets);
        return newWidgets;
    }
    return [...oldWidgets, newWidget];
}

function checkLoadedWidgets(oldWidgets, newWidgets) {
    oldWidgets.map((oldWidget, oldIndex) => {
        newWidgets.map((newWidget, newIndex) => {
            if (oldWidget.id == newWidget.id) {
                if (newWidget.loaded) {

                }
            }
        });
    });
}

function removeWidget(oldWidgets, removeId) {
    return oldWidgets.filter((w) => w.id !== removeId);
}

function updatePositions(oldWidgets, newPositions) {
    const newWidgets = oldWidgets.map((w) => {
        const relevantPosition = newPositions.find((p) => p.id === w.id);
        if (relevantPosition) {
            return {
                ...w,
                pos: {
                    w: relevantPosition.w,
                    h: relevantPosition.h,
                    x: relevantPosition.x,
                    y: relevantPosition.y,
                },
            };
        }
        return w;
    });
    return newWidgets;
}

function setLoading(oldWidgets, widgetId) {
    return oldWidgets.map((w) => {
        if (w.id === widgetId) {
            return {
                ...w,
                loaded: false,
            };
        }
        return w;
    });
}

export {
    updateWidgets,
    removeWidget,
    updatePositions,
    setLoading,
};
