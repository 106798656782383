/* eslint-disable no-case-declarations */
/* eslint-disable no-param-reassign */

import { Record } from 'immutable';

const {
    GET_UNRESOLVED_LOGS_REQUEST,
    GET_UNRESOLVED_LOGS_SUCCESS,
    GET_UNRESOLVED_LOGS_FAILURE,
} = require('./shardLogActions').constants;

const InitialState = Record({
    unresolved: 0,
    error: null,
    isFetching: false,
});

const shardLogInitialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function shardLogReducer(state = shardLogInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = shardLogInitialState.mergeDeep(state);
    switch (type) {
    case GET_UNRESOLVED_LOGS_REQUEST:
        return state.set('isFetching', true);
    case GET_UNRESOLVED_LOGS_SUCCESS:
        return state.set('isFetching', false)
            .set('unresolved', payload.data);
    case GET_UNRESOLVED_LOGS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    default:
        return state;
    }
}

export {
    shardLogReducer,
    shardLogInitialState,
};
