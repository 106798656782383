/* global: localStorage */
import {
    call,
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { saveAs } from 'file-saver';
import { fetchApiAuth } from '../utils/api';

import {
    getMileageReportSuccess,
    getMileageReportFailure,

    getMpgReportSuccess,
    getMpgReportFailure,

    getDrivingTimeReportSuccess,
    getDrivingTimeReportFailure,

    getIdleReportSuccess,
    getIdleReportFailure,

    getPtoReportSuccess,
    getPtoReportFailure,

    getTrueIdleReportSuccess,
    getTrueIdleReportFailure,

    getSpeedReportSuccess,
    getSpeedReportFailure,

    getSpeedingReportSuccess,
    getSpeedingReportFailure,

    getCarbonReportSuccess,
    getCarbonReportFailure,

    getUnauthorisedJourneyReportSuccess,
    getUnauthorisedJourneyReportFailure,

    getEcoScoreReportSuccess,
    getEcoScoreReportFailure,

    getTrueOdometerReportSuccess,
    getTrueOdometerReportFailure,

    getTimesheetReportSuccess,
    getTimesheetReportFailure,

    getTimesheetReportCsvSuccess,
    getTimesheetReportCsvFailure,

    getUtilisationReportSuccess,
    getUtilisationReportFailure,

    getSpeedingReportCsvSuccess,
    getSpeedingReportCsvFailure,

    getNewPtoIdleReportSuccess,
    getNewPtoIdleReportFailure,

    getGeofenceReportSuccess,
    getGeofenceReportFailure,

    getGeofenceReportCsvSuccess,
    getGeofenceReportCsvFailure,

    getEventReportSuccess,
    getEventReportFailure,

    getNewPtoIdleReportCsvSuccess,
    getNewPtoIdleReportCsvFailure,

    getEventReportCsvSuccess,
    getEventReportCsvFailure,

    getOutsideWorkingHoursReportSuccess,
    getOutsideWorkingHoursReportFailure, getNotMovedReportSuccess, getNotMovedReportFailure,
} from './reportActions';

const {
    GET_MILEAGE_REPORT_REQUEST,
    GET_MPG_REPORT_REQUEST,
    GET_DRIVING_TIME_REPORT_REQUEST,
    GET_IDLE_REPORT_REQUEST,
    GET_PTO_REPORT_REQUEST,
    GET_TRUE_IDLE_REPORT_REQUEST,
    GET_SPEED_REPORT_REQUEST,
    GET_SPEEDING_REPORT_REQUEST,
    GET_CARBON_REPORT_REQUEST,
    GET_UNAUTHORISED_JOURNEY_REPORT_REQUEST,
    GET_ECO_SCORE_REPORT_REQUEST,
    GET_TRUE_ODOMETER_REPORT_REQUEST,
    GET_TIMESHEET_REPORT_REQUEST,
    GET_TIMESHEET_REPORT_CSV_REQUEST,
    GET_UTILISATION_REPORT_REQUEST,
    GET_SPEEDING_REPORT_CSV_REQUEST,
    GET_NEW_PTO_IDLE_REPORT_REQUEST,
    GET_NEW_PTO_IDLE_REPORT_CSV_REQUEST,
    GET_GEOFENCE_REPORT_REQUEST,
    GET_GEOFENCE_REPORT_CSV_REQUEST,
    GET_EVENT_REPORT_REQUEST,
    GET_EVENT_REPORT_CSV_REQUEST,
    GET_OUTSIDE_WORKING_HOURS_REPORT_REQUEST,
    GET_NOT_MOVED_REPORT_REQUEST,
} = require('./reportActions').constants;

function* getMileageReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-mileage-report',
            body: action.payload,
        });
        yield put(getMileageReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getMileageReportFailure(e));
    }
}

function* getMpgReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-mpg-report',
            body: action.payload,
        });

        yield put(getMpgReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getMpgReportFailure(e));
    }
}

function* getDrivingTimeReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-driving-time-report',
            body: action.payload,
        });

        yield put(getDrivingTimeReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getDrivingTimeReportFailure(e));
    }
}

function* getOutsideWorkingHoursReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-outside-working-hours-report',
            body: action.payload,
        });

        yield put(getOutsideWorkingHoursReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getOutsideWorkingHoursReportFailure(e));
    }
}

function* getNotMovedReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-not-moved-report',
            body: action.payload,
        });

        yield put(getNotMovedReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getNotMovedReportFailure(e));
    }
}

function* getIdleReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-idle-report',
            body: action.payload,
        });

        yield put(getIdleReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getIdleReportFailure(e));
    }
}

function* getPtoReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-pto-report',
            body: action.payload,
        });

        yield put(getPtoReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getPtoReportFailure(e));
    }
}

function* getTrueIdleReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-true-idle-report',
            body: action.payload,
        });

        yield put(getTrueIdleReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getTrueIdleReportFailure(e));
    }
}

function* getSpeedReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-speed-report',
            body: action.payload,
        });

        yield put(getSpeedReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSpeedReportFailure(e));
    }
}

function* getSpeedingReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-speeding-report',
            params: action.payload,
        });

        yield put(getSpeedingReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSpeedingReportFailure(e));
    }
}

function* getCarbonReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/report/get-carbon-report',
            body: action.payload,
        });

        yield put(getCarbonReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getCarbonReportFailure(e));
    }
}

function* getUnauthorisedJourneyReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-unauthorised-journey-report',
            body: action.payload,
        });

        yield put(getUnauthorisedJourneyReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getUnauthorisedJourneyReportFailure(e));
    }
}

function* getEcoScoreReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'report/get-eco-score-report',
            body: action.payload,
        });

        yield put(getEcoScoreReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getEcoScoreReportFailure(e));
    }
}

function* getTrueOdometerReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'report/get-true-odometer-report',
            params: action.payload,
        });

        yield put(getTrueOdometerReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getTrueOdometerReportFailure(e));
    }
}

function* getTimesheetReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-timesheet-report',
            params: action.payload,
        });

        yield put(getTimesheetReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getTimesheetReportFailure(e));
    }
}

function* getTimesheetReportCsv(action) {
    const params = action.payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-timesheet-report-csv',
            params,
        });
        const blob = new Blob([response.data], { type: 'application/csv' });
        saveAs(blob, 'Timesheet.csv');
        notification.success({
            message: 'Success',
            description: 'File downloaded successfully.',
        });
        yield put(getTimesheetReportCsvSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getTimesheetReportCsvFailure(e));
    }
}

function* getUtilisationReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-utilisation-report',
            params: action.payload,
        });

        yield put(getUtilisationReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getUtilisationReportFailure(e));
    }
}

function* getSpeedingReportCsv(action) {
    const params = action.payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-speeding-report-csv',
            params,
        });
        const blob = new Blob([response.data], { type: 'application/csv' });
        saveAs(blob, 'Speeding Report.csv');
        notification.success({
            message: 'Success',
            description: 'File downloaded successfully.',
        });
        yield put(getSpeedingReportCsvSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSpeedingReportCsvFailure(e));
    }
}

function* getGeofenceReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-geofence-report',
            params: action.payload,
        });

        yield put(getGeofenceReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getGeofenceReportFailure(e));
    }
}

function* getGeofenceReportCsv(action) {
    const params = action.payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-geofence-report-csv',
            params,
        });
        const blob = new Blob([response.data], { type: 'application/csv' });
        saveAs(blob, 'Geofence Report.csv');
        notification.success({
            message: 'Success',
            description: 'File downloaded successfully.',
        });
        yield put(getGeofenceReportCsvSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getGeofenceReportCsvFailure(e));
    }
}

function* getNewPtoIdleReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-new-pto-idle-report',
            params: action.payload,
        });
        yield put(getNewPtoIdleReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getNewPtoIdleReportFailure(e));
    }
}

function* getNewPtoIdleReportCsv(action) {
    const params = action.payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-new-pto-idle-report-csv',
            params,
        });
        const blob = new Blob([response.data], { type: 'application/csv' });
        saveAs(blob, 'New PTO Idle Report.csv');
        notification.success({
            message: 'Success',
            description: 'File downloaded successfully.',
        });
        yield put(getNewPtoIdleReportCsvSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getNewPtoIdleReportCsvFailure(e));
    }
}

function* getEventReport(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-event-report',
            params: action.payload,
        });
        yield put(getEventReportSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getEventReportFailure(e));
    }
}

function* getEventReportCsv(action) {
    const params = action.payload;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/report/get-event-report-csv',
            params,
        });
        const blob = new Blob([response.data], { type: 'application/csv' });
        saveAs(blob, 'Event Report.csv');
        notification.success({
            message: 'Success',
            description: 'File downloaded successfully.',
        });
        yield put(getEventReportCsvSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getEventReportCsvFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* assetSaga() {
    yield all([
        takeEvery(GET_MILEAGE_REPORT_REQUEST, getMileageReport),
        takeEvery(GET_MPG_REPORT_REQUEST, getMpgReport),
        takeEvery(GET_DRIVING_TIME_REPORT_REQUEST, getDrivingTimeReport),
        takeEvery(GET_IDLE_REPORT_REQUEST, getIdleReport),
        takeEvery(GET_PTO_REPORT_REQUEST, getPtoReport),
        takeEvery(GET_TRUE_IDLE_REPORT_REQUEST, getTrueIdleReport),
        takeEvery(GET_SPEED_REPORT_REQUEST, getSpeedReport),
        takeEvery(GET_SPEEDING_REPORT_REQUEST, getSpeedingReport),
        takeEvery(GET_CARBON_REPORT_REQUEST, getCarbonReport),
        takeEvery(GET_UNAUTHORISED_JOURNEY_REPORT_REQUEST, getUnauthorisedJourneyReport),
        takeEvery(GET_ECO_SCORE_REPORT_REQUEST, getEcoScoreReport),
        takeEvery(GET_TRUE_ODOMETER_REPORT_REQUEST, getTrueOdometerReport),
        takeEvery(GET_TIMESHEET_REPORT_REQUEST, getTimesheetReport),
        takeEvery(GET_TIMESHEET_REPORT_CSV_REQUEST, getTimesheetReportCsv),
        takeEvery(GET_UTILISATION_REPORT_REQUEST, getUtilisationReport),
        takeEvery(GET_SPEEDING_REPORT_CSV_REQUEST, getSpeedingReportCsv),
        takeEvery(GET_NEW_PTO_IDLE_REPORT_REQUEST, getNewPtoIdleReport),
        takeEvery(GET_NEW_PTO_IDLE_REPORT_CSV_REQUEST, getNewPtoIdleReportCsv),
        takeEvery(GET_GEOFENCE_REPORT_REQUEST, getGeofenceReport),
        takeEvery(GET_GEOFENCE_REPORT_CSV_REQUEST, getGeofenceReportCsv),
        takeEvery(GET_EVENT_REPORT_REQUEST, getEventReport),
        takeEvery(GET_EVENT_REPORT_CSV_REQUEST, getEventReportCsv),
        takeEvery(GET_OUTSIDE_WORKING_HOURS_REPORT_REQUEST, getOutsideWorkingHoursReport),
        takeEvery(GET_NOT_MOVED_REPORT_REQUEST, getNotMovedReport),
    ]);
}
