import * as React from 'react';
import src from './google-map-icon-green.png';

function GeofenceMapMarkerImg({
    className = '',
    geofenceName = 'Geofence',
}) {
    return (
        <img 
            src={src} 
            alt={`Geofence: ${geofenceName}`}
            className={className} 
        />
    );
}

// eslint-disable-next-line import/prefer-default-export
export default GeofenceMapMarkerImg;
