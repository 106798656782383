import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';

import moment from 'moment';
import { Empty, notification, Spin } from 'antd';
import * as videoActions from '../../../core/video/videoActions';
import * as deviceActions from '../../../core/device/deviceActions';

import Icon from '../../../components/elements/Icon';
import selectionScreen from '../partials/SearchedVideoSelection';
import videoScreen from '../partials/HistoricVideoScreen';
import TrimVideo from '../partials/TrimVideo';
import TrimStitchedVideo from '../partials/TrimStitchedVideo';
import { secondsToHms } from '../../../core/utils/functions';
import appConstants from '../../../core/constants';
import MapAssetPopup from '../../../components/elements/MapAssetPopup';
import { setMarkerPopoverDetails, setMarkerPopoverToLoading } from '../../../core/utils/mapUtils';
import CustomEmpty, { CustomEmptyType } from '../../../components/CustomEmpty';

const { MAP_SLIDER_INTERVAL } = appConstants;

const caps = (string) => string.charAt(0)
    .toUpperCase() + string.slice(1);

class VideoSearchTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: props?.pagination?.currentPage || 1,
            search: {},
            order: {},
            selectedVideoId: null,
            videoFailed: false,
            markerPopover: null,
            loadingDvrStatus: false,
        };
        this.select = selectionScreen;
        this.show = videoScreen;
        this.googleMapAccess = React.createRef();
        props.registerCallback(this.searchVideos);
    }

    async componentDidMount() {
        await this.setState({ loading_dvrs_status: true });
        await this.props.checkDvrStatus();
        this.setState({ loading_dvrs_status: false });
        const {
            actions,
            assetId,
            mapSearchLocationBoundary,
            mapSearchVideoSelectedDate,
            parent,
            mapSearchVideoAssets,
            mapSearchVideoSliderValue,
        } = this.props;
        const { order } = this.state;

        if (mapSearchLocationBoundary?.length > 0) {
            if (assetId === 'location') {
                let startTimeDecrease = 60 * 5;
                let endTimeIncrease = 60 * 5;

                if (mapSearchVideoSelectedDate === new moment().format('YYYY-MM-DD')) {
                    startTimeDecrease = 60 * 60 * 3;
                    endTimeIncrease = 0;
                }

                const startTime = `${secondsToHms(((mapSearchVideoSliderValue * 60) * MAP_SLIDER_INTERVAL) - startTimeDecrease, 'time')}`;
                const endTime = `${secondsToHms(((mapSearchVideoSliderValue * 60) * MAP_SLIDER_INTERVAL) + endTimeIncrease, 'time')}`;

                const searchParams = [];
                searchParams.polygon = mapSearchLocationBoundary;
                searchParams.devices = mapSearchVideoAssets;
                searchParams.date = mapSearchVideoSelectedDate;
                searchParams.time_from = startTime;
                searchParams.time_to = endTime;

                actions.searchHistoricVideosRequest({
                    page: 1,
                    search: searchParams,
                    order,
                });

                parent.setState({
                    videoSearchFiltered: true,
                    showSideBar: true,
                    openSidebar: true,
                    mapOpen: true,
                });
            } else {
                this.removePolygon();
            }
        }
        window.historicVideo = this;
        setMarkerPopoverToLoading(this);
    }

    getMapSearchBoundary = (polygon) => {
        const {
            actions,
            mapSearchVideoAssets,
            mapSearchVideoSliderValue,
            mapSearchVideoSelectedDate,
        } = this.props;

        actions.setMapSearchLocationBoundary({
            polygon,
            devices: mapSearchVideoAssets,
            mapSliderValue: mapSearchVideoSliderValue,
            selectedDate: mapSearchVideoSelectedDate,
        });

        if (polygon && polygon.length > 0) {
            setTimeout(() => {
                this.searchHistoricVideos();
            }, 300);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const {
            latitude,
            longitude,
            geometry,
            mapSearchLocationBoundary,
            assetId,
            mapSearchVideoSelectedDate,
            mapSearchVideoSliderValue,
            mapSearchVideoAssets,
            mapOpen,
            deviceDetailedLocationInfo,
            deviceDetailedLocationFetching,
        } = this.props;
        const { search } = this.state;

        if (assetId === 'location' && mapSearchLocationBoundary.length > 0 && search.polygon !== mapSearchLocationBoundary) {
            let startTimeDecrease = 60 * 5;
            let endTimeIncrease = 60 * 5;

            if (mapSearchVideoSelectedDate === new moment().format('YYYY-MM-DD')) {
                startTimeDecrease = 60 * 60 * 3;
                endTimeIncrease = 0;
            }

            const startTime = `${secondsToHms(((mapSearchVideoSliderValue * 60) * MAP_SLIDER_INTERVAL) - startTimeDecrease, 'time')}`;
            const endTime = `${secondsToHms(((mapSearchVideoSliderValue * 60) * MAP_SLIDER_INTERVAL) + endTimeIncrease, 'time')}`;

            const searchParams = [];
            searchParams.polygon = mapSearchLocationBoundary;
            searchParams.devices = mapSearchVideoAssets;
            searchParams.date = mapSearchVideoSelectedDate;
            searchParams.time_from = startTime;
            searchParams.time_to = endTime;
            this.setState({ search: searchParams });
        }

        if (this.googleMapAccess && this.googleMapAccess.current) {
            if (mapOpen && mapOpen !== prevProps.mapOpen) {
                if (latitude !== null && longitude !== null) {
                    this.googleMapAccess.current.setState({
                        center: {
                            lat: latitude,
                            lng: longitude,
                        },
                    });
                    const { map } = this.googleMapAccess.current.state;
                    if (map) {
                        map.fitBounds(geometry.viewport);
                    }
                }
                this.googleMapAccess.current.toggleInfoBox();
                setTimeout(() => {
                    if (this.googleMapAccess && this.googleMapAccess.current) {
                        this.googleMapAccess.current.executeAutoZoom();
                    }
                }, 300);
            }
        }

        if (deviceDetailedLocationFetching === true && prevProps.deviceDetailedLocationFetching === false) {
            setMarkerPopoverToLoading(this);
        }

        if (deviceDetailedLocationFetching === false && deviceDetailedLocationInfo !== prevProps.deviceDetailedLocationInfo) {
            setMarkerPopoverDetails(this);
        }
    }

    removePolygon() {
        const {
            actions,
            mapSearchVideoAssets,
            mapSearchVideoSliderValue,
            mapSearchVideoSelectedDate,
        } = this.props;
        actions.setMapSearchLocationBoundary({
            polygon: [],
            devices: mapSearchVideoAssets,
            mapSliderValue: mapSearchVideoSliderValue,
            selectedDate: mapSearchVideoSelectedDate,
        });
    }

    searchHistoricVideos = () => {
        const {
            actions,
            pagination,
            assetId,
            mapSearchLocationBoundary,
            mapSearchVideoAssets,
            parent,
        } = this.props;

        const {
            search,
            order,
            page,
        } = this.state;
        const { currentPage: pageReducer } = pagination;

        if (mapSearchLocationBoundary?.length > 0) {
            search.polygon = mapSearchLocationBoundary;
            // search.devices = mapSearchVideoAssets;

            parent.setState({
                mapOpen: true,
            });
        } else {
            delete search.polygon;
            this.setState({ search });
        }

        actions.searchHistoricVideosRequest({
            page,
            search,
            order,
        });
    };

    componentWillUnmount() {
        delete window.historicVideo;
    }

    videoFailed = () => {
        this.setState({ videoFailed: true });
    };

    handleTableChange = (pagination, filters, sorter, extra) => {
        const { search } = this.state;
        const {
            actions,
            pagination: p,
        } = this.props;
        const { currentPage: page } = p;
        const order = [];
        // checking when only order changes
        if (pagination && pagination.current === page) {
            if (!isEmpty(sorter.order)) {
                order[sorter.field] = sorter.order ? sorter.order : 'ascend';
                this.setState({ order });
                setTimeout(() => {
                    this.searchHistoricVideos();
                }, 300);
            }
        }
    };

    cacheVideo = (id) => {
        const { actions } = this.props;
        actions.queueVideoByIdRequest({ id });
    };

    extendVideo = (id) => {
        const { actions } = this.props;
        actions.extendVideoByIdRequest({ id });
    };

    searchVideos = (s) => {
        const {
            actions,
            assetReg,
            mapDate,
            parent,
        } = this.props;
        const {
            order,
            search,
        } = this.state;

        actions.resetSearchHistoricVideosRequest();
        const time_from = s.time_from;
        const time_to = s.time_to;
        const date = s.date && s.date.format('YYYY-MM-DD');
        const newSearch = {
            ...search,
            ...s,
            date,
            time_from,
            time_to,
        };
        this.setState({ search: newSearch });
        setTimeout(() => {
            this.searchHistoricVideos();
        }, 300);

        if (newSearch.polygon && newSearch.polygon.length > 0) {
            parent.setState({ mapOpen: true });
        }
    };

    paginationChange = (page) => {
        const {
            actions,
            pagination,
        } = this.props;
        const { currentPage } = pagination;
        const {
            search,
            order,
        } = this.state;

        if (page !== currentPage) {
            this.setState({ page });
            setTimeout(() => {
                this.searchHistoricVideos();
            }, 300);
        }
    };

    /**
     * Converts seconds since midnight to a formatted MM:SS or HH:MM:SS string
     * @param {*} secondsSinceMidnight Number of seconds since midnight
     */
    convertFromSeconds(secondsSinceMidnight) {
        const t = secondsSinceMidnight;
        const hours = Math.floor(t / 3600);
        const minutes = `${Math.floor((t / 60) % 60)}`.padStart(2, '0');
        const seconds = `${Math.floor(t % 60)}`.padStart(2, '0');
        if (hours !== 0) {
            return `${hours}:${minutes}:${seconds}`;
        }
        return `${minutes}:${seconds}`;
    }

    showStatus(status) {
        return (
            <div className="d-flex align-center">
                <Icon name={`link-${status === 'cached' ? 'cached' : 'uncached'}`}/>
                <span className="ml-1">{status === 'discovered' || isEmpty(status) ? 'Uncached' : caps(status)}</span>
            </div>
        );
    }

    selectVideo(vid) {
        const {
            actions,
            setShouldShowSidebar,
        } = this.props;
        const { search } = this.state;
        const searchChannels = {};

        if (search.date) {
            vid.date = search.date;
        }

        if (search.time_from && search.time_to) {
            vid.time_from = search.time_from;
            vid.time_to = search.time_to;
        }

        if (search.time_from && search.time_to && vid.device_id) {
            searchChannels.time_from = search.time_from;
            searchChannels.time_to = search.time_to;
            searchChannels.device_id = vid.device_id;
        }

        setShouldShowSidebar(false);
        actions.selectSingleSearchedVideoRequest(vid);
        actions.getChannelsAndVideosRequest(searchChannels);
    }

    selectStitchedVideo(vid) {
        const {
            actions,
            setShouldShowSidebar,
        } = this.props;
        const { search } = this.state;
        const searchChannels = {};

        if (search.date) {
            vid.date = search.date;
        }

        if (search.time_from && search.time_to) {
            vid.time_from = search.time_from;
            vid.time_to = search.time_to;
        }

        if (search.time_from && search.time_to && vid.device_id) {
            searchChannels.time_from = search.time_from;
            searchChannels.time_to = search.time_to;
            searchChannels.device_id = vid.device_id;
        }

        setShouldShowSidebar(false);
        actions.selectSingleSearchedVideoRequest(vid);
    }

    render() {
        const { loading_dvrs_status } = this.state;
        const {
            videos,
            videos_loading,
            searchedSingleVideo,
            locationSearch,
            setLocationSearch,
            videoSearchFiltered,
            latitude,
            longitude,
            mapSearchLocationBoundary,
        } = this.props;

        if (!isEmpty(searchedSingleVideo)) {
            if (searchedSingleVideo.stitched_video_exist && searchedSingleVideo.stitched_video_exist === '1') {
                return <TrimStitchedVideo setShouldShowSidebar={this.props.setShouldShowSidebar}/>;
            }
            return <TrimVideo setShouldShowSidebar={this.props.setShouldShowSidebar}/>;
        }

        const loading = videos_loading || loading_dvrs_status;

        if (videoSearchFiltered && !loading_dvrs_status || (videos && videos.length > 0)) {
            return this.select(
                videos,
                loading,
                locationSearch,
                setLocationSearch,
                latitude,
                longitude,
                mapSearchLocationBoundary,
            );
        }

        if (videos_loading) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 30,
                }}>
                    <Spin spinning={videos_loading}></Spin>
                </div>

            );
        }

        if (!videos_loading && !loading_dvrs_status && (videos && videos.length === 0)) {
            return (
                <CustomEmpty
                    type={CustomEmptyType.NO_DATA}
                    title="No videos found"
                    description="No videos found for the selected date and time range."
                    showIcon={true}
                />
            );
        }
    }
}

VideoSearchTab.defaultProps = {
    registerCallback: () => null,
    assetId: 0,
};

VideoSearchTab.propTypes = {
    actions: PropTypes.object.isRequired,
    videos: PropTypes.array.isRequired,
    video_url: PropTypes.object.isRequired,
    videos_loading: PropTypes.bool.isRequired,
    pagination: PropTypes.object.isRequired,
    singleVideo: PropTypes.object.isRequired,
    setMapOpen: PropTypes.func.isRequired,
    deviceDetailedLocationInfo: PropTypes.object.isRequired,
    deviceDetailedLocationFetching: PropTypes.bool.isRequired,
    registerCallback: PropTypes.func,
    setShouldShowSidebar: PropTypes.func,
    assetId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    assetReg: PropTypes.string,
    mapDate: PropTypes.string,
    checkDvrStatus: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.video.isFetching,
        videos: state.video.searchedVideos,
        searchedSingleVideo: state.video.searchedSingleVideo,
        videos_loading: state.video.searchingVideos,
        video_url: state.video.video_url,
        pagination: state.video.videoSearchPagination,
        singleVideo: state.video.singleVideo,
        assetReg: state.global.videoSearchFilters.assetReg,
        mapDate: state.global.mapDate,
        goPreviousPage: state.video.goPreviousPage,
        mapSearchLocationBoundary: state.video.mapSearchLocationBoundary,
        mapSearchVideoSelectedDate: state.video.mapSearchVideoSelectedDate,
        mapSearchVideoAssets: state.video.mapSearchVideoAssets,
        mapSearchVideoSliderValue: state.video.mapSearchVideoSliderValue,
        user: state.user,
        deviceDetailedLocationInfo: state.device.deviceDetailedLocationInfo,
        deviceDetailedLocationFetching: state.device.deviceDetailedLocationFetching,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...videoActions,
            ...deviceActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoSearchTab);
