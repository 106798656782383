import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_RECORD_INFO_REQUEST: null,
    GET_RECORD_INFO_SUCCESS: null,
    GET_RECORD_INFO_FAILURE: null,

    RESET_RECORDS_REQUEST: null,
});

export const {
    getRecordInfoRequest,
    getRecordInfoSuccess,
    getRecordInfoFailure,
} = createActions(
    constants.GET_RECORD_INFO_REQUEST,
    constants.GET_RECORD_INFO_SUCCESS,
    constants.GET_RECORD_INFO_FAILURE,
);

export const {
    resetRecordsRequest,
} = createActions(
    constants.RESET_RECORDS_REQUEST,
);
