/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Tabs, Button } from 'antd';
import { Link } from 'react-router-dom';


import Layout from '../../../../components/layout/Layout';
import FilterSidebar from '../../../../components/FilterSidebar';
import Icon from '../../../../components/elements/Icon';

import ReportFilter, { DATE_MODE_RANGE } from '../../ReportFilter';
import ReportTab from './tabs/ReportTab';

export default function () {
    const {
        eventsTabLoaded,
        eventsTabFilter,
        filterSidebar,
        filters,
        metricUnit,
    } = this.state;
    const { pagination, speedingReportBack, user } = this.props;

    return (
        <Layout
            className="events"
            title={<div>Speeding Report</div>}
            headerTitle={<div>Speeding Report</div>}>
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar displayFilter={filterSidebar} >
                    <ReportFilter
                        speedingReportBack={speedingReportBack}
                        onSubmit={this.onEventsTabFilterChange}
                        filters={filters}
                        dateMode={DATE_MODE_RANGE}
                        showDivision
                    />
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <div className="section__action-bar">
                                    {/* <Button
                                        onClick={() => this.exportCsv()}
                                        className="btn ant-btn-primary ant-btn-xs middlewide">
                                        Export
                                    </Button> */}
                                </div>
                                <ReportTab
                                    toggleFilterSidebar={() => this.setState({ filterSidebar: !filterSidebar })}
                                    pagination={pagination}
                                    metricUnit={metricUnit}
                                    eventsTabFilter={eventsTabFilter}
                                    setLoadedState={this.eventsTabLoaded}
                                    eventsTabLoaded={eventsTabLoaded}
                                    onTableChange={this.onEventsTabSortChange}
                                    onPageChange={this.paginationChange}
                                    onExportCSV={this.onExportCSV}
                                    setUnit={this.setUnit}
                                    speedingReportBack={speedingReportBack}
                                    reportHasBeenFetched={this.state.reportHasBeenFetched}
                                />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
