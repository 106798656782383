import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons';
import { Slider } from 'antd';
import './videoPlayer.scss';

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.state = {
            playing: false,
            duration: 0,
            played: 0,
        };
    }

    handlePlayPause = () => {
        this.setState((oldState) => ({ playing: !oldState.playing }));
    };

    handleProgress = (state) => {
        const { seeking } = state;
        // We only want to update time slider if we are not currently seeking
        if (!seeking) {
            this.setState({ played: state.playedSeconds });
        }
    };

    handleDuration = (duration) => {
        this.setState({ duration });
    };

    renderVideoPlayer = () => {
        const {
            url,
            videoServer,
            controls,
            videoType,
            minHeight,
        } = this.props;
        const { playing, duration, played } = this.state;
        const calculatedPlayed = Math.round(((duration - (duration - played)) / duration) * 100) || 0;

        const height = minHeight !== null ? minHeight : 350;

        switch (videoType) {
        case 'flv': {
            let src = `/video.html?url=${url}`;
            if (videoServer.includes('server2')) {
                src = `/video3.html?url=${url}`;
            }
            return (
                <iframe
                    allowFullScreen
                    title="historic_video_iframe"
                    width="100%"
                    height="inherit"
                    frameBorder={0}
                    scrolling="no"
                    style={{ margin: '0 auto', overflow: 'hidden', minHeight: `${height}px` }}
                    src={src} />
            );
        }
        case 'howen':
            return (
                <iframe
                    allowFullScreen
                    title="howen_video_iframe"
                    width="100%"
                    height="inherit"
                    frameBorder={0}
                    scrolling="no"
                    style={{ margin: '0 auto', overflow: 'hidden', minHeight: `${height}px` }}
                    src={url} />
            );

        case 'react':
            return (
                <>
                    <ReactPlayer
                        ref={this.ref}
                        url={url}
                        playing={playing}
                        onProgress={this.handleProgress}
                        onDuration={this.handleDuration}
                        controls={controls || false} />
                    <div className="video-toolbar">
                        {playing
                            ? <PauseCircleFilled onClick={this.handlePlayPause} />
                            : <PlayCircleFilled onClick={this.handlePlayPause} />}
                        <div className="video-slider">
                            <Slider
                                min={1}
                                max={100}
                                value={calculatedPlayed} />
                        </div>
                        {/* <Icon type="fullscreen" /> */}
                    </div>
                </>
            );

        default:
            return null;
        }
    };

    render() {
        return (
            <div className="video-block">
                {this.renderVideoPlayer()}
            </div>
        );
    }
}

VideoPlayer.defaultProps = {
    controls: false,
    videoType: 'react',
    videoServer: '',
};

VideoPlayer.propTypes = {
    url: PropTypes.string.isRequired,
    videoServer: PropTypes.string,
    controls: PropTypes.bool,
    videoType: PropTypes.string,
};

export default VideoPlayer;
