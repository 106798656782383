/* global: localStorage */
import {
    call,
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getRecordInfoSuccess,
    getRecordInfoFailure,

} from './recordsActions';

const {
    GET_RECORD_INFO_REQUEST,
} = require('./recordsActions').constants;

function* getRecordInfo(action) {
    const { recordId } = action.payload;

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `records/${recordId}/detailed-info`,
            params: action.payload,
        });
        yield put(getRecordInfoSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getRecordInfoFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* recordsSaga() {
    yield all([
        takeEvery(GET_RECORD_INFO_REQUEST, getRecordInfo),
    ]);
}
