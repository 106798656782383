import React from 'react';

function CustomisableWidgetDashboardSVG() {
    return (
        <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
            <g stroke="null">
                <path d="M30.346 34.48v13.232H13.808V34.48h16.538m0-3.308H13.808a3.308 3.308 0 00-3.308 3.308v13.23a3.308 3.308 0 003.308 3.308h16.538a3.308 3.308 0 003.308-3.307V34.48a3.308 3.308 0 00-3.308-3.308zM48.538 16.288v8.27H32v-8.27h16.538m0-3.307H32a3.308 3.308 0 00-3.308 3.307v8.27A3.308 3.308 0 0032 27.865h16.538a3.308 3.308 0 003.308-3.307v-8.27a3.308 3.308 0 00-3.308-3.307zM50.192 34.48v8.27h-8.269v-8.27h8.27m0-3.307h-8.27a3.308 3.308 0 00-3.308 3.308v8.269a3.308 3.308 0 003.308 3.308h8.27A3.308 3.308 0 0053.5 42.75v-8.27a3.308 3.308 0 00-3.308-3.307zM22.077 16.288v8.27h-8.27v-8.27h8.27m0-3.307h-8.27a3.308 3.308 0 00-3.307 3.307v8.27a3.308 3.308 0 003.308 3.307h8.269a3.308 3.308 0 003.308-3.307v-8.27a3.308 3.308 0 00-3.308-3.307z" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default CustomisableWidgetDashboardSVG;
