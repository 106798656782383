import { Record } from 'immutable';
import { findIndex, reject, isArray } from 'underscore';
import { getTodayInServerFormat, getTodayStartInServerFormat } from '../utils/dateUtils';

const {
    GET_DRIVERS_REQUEST,
    GET_DRIVERS_SUCCESS,
    GET_DRIVERS_FAILURE,

    GET_GENERAL_DATA_REQUEST,
    GET_GENERAL_DATA_SUCCESS,
    GET_GENERAL_DATA_FAILURE,

    GET_SINGLE_DRIVER_REQUEST,
    GET_SINGLE_DRIVER_SUCCESS,
    GET_SINGLE_DRIVER_FAILURE,

    UPDATE_DRIVER_REQUEST,
    UPDATE_DRIVER_SUCCESS,
    UPDATE_DRIVER_FAILURE,

    UPLOAD_IMAGE_REQUEST,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,

    ASSIGN_ASSET_REQUEST,
    ASSIGN_ASSET_SUCCESS,
    ASSIGN_ASSET_FAILURE,

    GET_DRIVER_JOURNEY_REQUEST,
    GET_DRIVER_JOURNEY_SUCCESS,
    GET_DRIVER_JOURNEY_FAILURE,

    GET_DRIVER_EVENTS_REQUEST,
    GET_DRIVER_EVENTS_SUCCESS,
    GET_DRIVER_EVENTS_FAILURE,

    GET_DRIVER_SERVICE_ALERTS_REQUEST,
    GET_DRIVER_SERVICE_ALERTS_SUCCESS,
    GET_DRIVER_SERVICE_ALERTS_FAILURE,

    CREATE_DRIVER_SERVICE_ALERT_REQUEST,
    CREATE_DRIVER_SERVICE_ALERT_SUCCESS,
    CREATE_DRIVER_SERVICE_ALERT_FAILURE,

    REQUEST_TAG_REQUEST,
    REQUEST_TAG_SUCCESS,
    REQUEST_TAG_FAILURE,

    TAG_LIMIT_REACHED_REQUEST,
    TAG_LIMIT_REACHED_SUCCESS,
    TAG_LIMIT_REACHED_FAILURE,

    GET_DRIVER_LIST_REQUEST,
    GET_DRIVER_LIST_SUCCESS,
    GET_DRIVER_LIST_FAILURE,

    GET_DRIVER_LIST_STRICT_REQUEST,
    GET_DRIVER_LIST_STRICT_SUCCESS,
    GET_DRIVER_LIST_STRICT_FAILURE,

    DELETE_DRIVER_SERVICE_ALERT_REQUEST,
    DELETE_DRIVER_SERVICE_ALERT_SUCCESS,
    DELETE_DRIVER_SERVICE_ALERT_FAILURE,

    UPDATE_JOURNEY_LOCATION_REQUEST,
    UPDATE_JOURNEY_LOCATION_SUCCESS,
    UPDATE_JOURNEY_LOCATION_FAILURE,

    DELETE_DRIVER_DATA_REQUEST,
    DELETE_DRIVER_DATA_SUCCESS,
    DELETE_DRIVER_DATA_FAILURE,

    SET_DRIVER_JOURNEY_FILTERS,
} = require('./driverActions').constants;

const InitialState = Record({
    error: null,
    isFetching: [],
    assignAssetFetching: false,
    driversFetching: false,
    drivers: [],
    driverList: [],
    driverListStrict: [],
    departments: [],
    pagination: {
        currentPage: 1,
        pageCount: 0,
        perPage: 0,
        totalCount: 0,
    },
    singleDriver: {},
    driverJourney: [],
    driverJourneyPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    driverEvents: [],
    driverEventsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    serviceAlerts: [],
    serviceAlertsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    tagStatus: null,
    licenceTypes: [],
    driverStatusList: [],
    driverJourneyFilters: {
        dateRange: [
            getTodayStartInServerFormat(),
            getTodayInServerFormat(),
        ],
    },
});

const driverInitialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function driverReducer(state = driverInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = driverInitialState.mergeDeep(state);
    const { isFetching } = state;
    const tempType = type.replace('_REQUEST', '').replace('_SUCCESS', '').replace('_FAILURE', '');
    const newObj = {};
    newObj[tempType] = true;
    switch (type) {
    case GET_DRIVERS_REQUEST:
        return state.set('driversFetching', true)
            .set('error', null);

    case GET_GENERAL_DATA_REQUEST:
    case GET_SINGLE_DRIVER_REQUEST:
    case UPDATE_DRIVER_REQUEST:
    case UPLOAD_IMAGE_REQUEST:
    case GET_DRIVER_JOURNEY_REQUEST:
    case GET_DRIVER_EVENTS_REQUEST:
    case GET_DRIVER_SERVICE_ALERTS_REQUEST:
    case REQUEST_TAG_REQUEST:
    case TAG_LIMIT_REACHED_REQUEST:
    case CREATE_DRIVER_SERVICE_ALERT_REQUEST:
    case GET_DRIVER_LIST_REQUEST:
    case GET_DRIVER_LIST_STRICT_REQUEST:
    case DELETE_DRIVER_SERVICE_ALERT_REQUEST:
    case UPDATE_JOURNEY_LOCATION_REQUEST:
    case DELETE_DRIVER_DATA_REQUEST: {
        let newArray = [];
        if (Array.isArray(isFetching)) {
            isFetching.push(newObj);
            newArray = [...isFetching];
        }
        return state.set('isFetching', newArray)
            .set('error', null);
    }

    case ASSIGN_ASSET_REQUEST:
        return state.set('assignAssetFetching', true);

    case GET_DRIVERS_SUCCESS: {
        const { pagination } = state;

        if (payload.headers) {
            if (payload.headers['x-pagination-current-page']) {
                pagination.currentPage = parseInt(payload.headers['x-pagination-current-page'], 10);
            }
            if (payload.headers['x-pagination-page-count']) {
                pagination.pageCount = parseInt(payload.headers['x-pagination-page-count'], 10);
            }
            if (payload.headers['x-pagination-per-page']) {
                pagination.perPage = parseInt(payload.headers['x-pagination-per-page'], 10);
            }
            if (payload.headers['x-pagination-total-count']) {
                pagination.totalCount = parseInt(payload.headers['x-pagination-total-count'], 10);
            }
        }
        return state.set('driversFetching', false)
            .set('drivers', isArray(payload.data) ? payload.data : []);
    }
    case GET_GENERAL_DATA_SUCCESS: {
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('licenceTypes', payload.data.licenceTypes)
            .set('driverStatusList', payload.data.driverStatusList)
            .set('departments', payload.data.departments);
    }
    case GET_SINGLE_DRIVER_SUCCESS:
    case UPDATE_DRIVER_SUCCESS:
    case UPLOAD_IMAGE_SUCCESS: {
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('singleDriver', payload.data);
    }
    case ASSIGN_ASSET_SUCCESS: {
        return state.set('assignAssetFetching', false);
    }
    case GET_DRIVER_JOURNEY_SUCCESS:
        const driverJourneyPagination = {};
        driverJourneyPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        driverJourneyPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        driverJourneyPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        driverJourneyPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('driverJourney', payload.data)
            .set('driverJourneyPagination', driverJourneyPagination);
    case GET_DRIVER_EVENTS_SUCCESS:
        const driverEventsPagination = {};
        driverEventsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        driverEventsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        driverEventsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        driverEventsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('driverEvents', payload.data)
            .set('driverEventsPagination', driverEventsPagination);
    case GET_DRIVER_SERVICE_ALERTS_SUCCESS:
    case CREATE_DRIVER_SERVICE_ALERT_SUCCESS:
    case DELETE_DRIVER_SERVICE_ALERT_SUCCESS:
        const serviceAlertsPagination = {};
        serviceAlertsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        serviceAlertsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        serviceAlertsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        serviceAlertsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('serviceAlerts', payload.data)
            .set('serviceAlertsPagination', serviceAlertsPagination);
    case REQUEST_TAG_SUCCESS:
        return state.set('tagStatus', true)
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));
    case TAG_LIMIT_REACHED_SUCCESS:
        return state.set('tagStatus', payload.data)
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));
    case GET_DRIVER_LIST_SUCCESS:
        return state.set('driverList', payload.data)
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));

    case GET_DRIVER_LIST_STRICT_SUCCESS:
        return state.set('driverListStrict', payload.data)
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));

    case UPDATE_JOURNEY_LOCATION_SUCCESS: {
        const { driverJourney } = state;

        if (payload && payload.payload && payload.payload.id) {
            const driverJourneyIndex = findIndex(driverJourney, (val) => val.id === payload.payload.id);
            if (driverJourneyIndex !== -1) {
                driverJourney[driverJourneyIndex].start_area_address = payload.response.data.start_area_address;
                driverJourney[driverJourneyIndex].end_area_address = payload.response.data.end_area_address;
            }
        }

        return state.set('driverJourney', driverJourney)
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true));
    }

    case DELETE_DRIVER_DATA_SUCCESS:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('singleDriver', {});

    case GET_SINGLE_DRIVER_FAILURE:
        return state.set('singleDriver', {})
            .set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('error', payload);

    case GET_DRIVERS_FAILURE:
        return state.set('driversFetching', false)
            .set('error', payload);

    case GET_GENERAL_DATA_FAILURE:
    case UPDATE_DRIVER_FAILURE:
    case UPLOAD_IMAGE_FAILURE:
    case GET_DRIVER_JOURNEY_FAILURE:
    case GET_DRIVER_EVENTS_FAILURE:
    case GET_DRIVER_SERVICE_ALERTS_FAILURE:
    case REQUEST_TAG_FAILURE:
    case TAG_LIMIT_REACHED_FAILURE:
    case CREATE_DRIVER_SERVICE_ALERT_FAILURE:
    case GET_DRIVER_LIST_FAILURE:
    case GET_DRIVER_LIST_STRICT_FAILURE:
    case DELETE_DRIVER_SERVICE_ALERT_FAILURE:
    case UPDATE_JOURNEY_LOCATION_FAILURE:
    case DELETE_DRIVER_DATA_FAILURE:
        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('error', payload);

    case SET_DRIVER_JOURNEY_FILTERS:
        return state.set('driverJourneyFilters', payload);

    case ASSIGN_ASSET_FAILURE:
        return state.set('assignAssetFetching', false);

    default:
        return state;
    }
}

export {
    driverReducer,
    driverInitialState,
};
