import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_DIVISIONS_GRID_REQUEST: null,
    GET_DIVISIONS_GRID_SUCCESS: null,
    GET_DIVISIONS_GRID_FAILURE: null,

    GET_DIVISION_ASSETS_GRID_REQUEST: null,
    GET_DIVISION_ASSETS_GRID_SUCCESS: null,
    GET_DIVISION_ASSETS_GRID_FAILURE: null,

    ASSIGN_DIVISION_ASSET_REQUEST: null,
    ASSIGN_DIVISION_ASSET_SUCCESS: null,
    ASSIGN_DIVISION_ASSET_FAILURE: null,

    UNASSIGN_DIVISION_ASSET_REQUEST: null,
    UNASSIGN_DIVISION_ASSET_SUCCESS: null,
    UNASSIGN_DIVISION_ASSET_FAILURE: null,

    UPDATE_DIVISION_REQUEST: null,
    UPDATE_DIVISION_SUCCESS: null,
    UPDATE_DIVISION_FAILURE: null,

    GET_OTHER_ASSETS_REQUEST: null,
    GET_OTHER_ASSETS_SUCCESS: null,
    GET_OTHER_ASSETS_FAILURE: null,

    CREATE_DIVISION_REQUEST: null,
    CREATE_DIVISION_SUCCESS: null,
    CREATE_DIVISION_FAILURE: null,

    GET_DIVISION_DETAILS_REQUEST: null,
    GET_DIVISION_DETAILS_SUCCESS: null,
    GET_DIVISION_DETAILS_FAILURE: null,

    GET_DIVISION_OPTIONS_REQUEST: null,
    GET_DIVISION_OPTIONS_SUCCESS: null,
    GET_DIVISION_OPTIONS_FAILURE: null,

    GET_DIVISION_OPTIONS_STRICT_REQUEST: null,
    GET_DIVISION_OPTIONS_STRICT_SUCCESS: null,
    GET_DIVISION_OPTIONS_STRICT_FAILURE: null,

    GET_USER_DIVISIONS_REQUEST: null,
    GET_USER_DIVISIONS_SUCCESS: null,
    GET_USER_DIVISIONS_FAILURE: null,
});

export const {
    getDivisionsGridRequest,
    getDivisionsGridSuccess,
    getDivisionsGridFailure,
} = createActions(
    constants.GET_DIVISIONS_GRID_REQUEST,
    constants.GET_DIVISIONS_GRID_SUCCESS,
    constants.GET_DIVISIONS_GRID_FAILURE,
);

export const {
    getDivisionAssetsGridRequest,
    getDivisionAssetsGridSuccess,
    getDivisionAssetsGridFailure,
} = createActions(
    constants.GET_DIVISION_ASSETS_GRID_REQUEST,
    constants.GET_DIVISION_ASSETS_GRID_SUCCESS,
    constants.GET_DIVISION_ASSETS_GRID_FAILURE,
);

export const {
    getDivisionDetailsRequest,
    getDivisionDetailsSuccess,
    getDivisionDetailsFailure,
} = createActions(
    constants.GET_DIVISION_DETAILS_REQUEST,
    constants.GET_DIVISION_DETAILS_SUCCESS,
    constants.GET_DIVISION_DETAILS_FAILURE,
);

export const {
    assignDivisionAssetRequest,
    assignDivisionAssetSuccess,
    assignDivisionAssetFailure,
} = createActions(
    constants.ASSIGN_DIVISION_ASSET_REQUEST,
    constants.ASSIGN_DIVISION_ASSET_SUCCESS,
    constants.ASSIGN_DIVISION_ASSET_FAILURE,
);

export const {
    unassignDivisionAssetRequest,
    unassignDivisionAssetSuccess,
    unassignDivisionAssetFailure,
} = createActions(
    constants.UNASSIGN_DIVISION_ASSET_REQUEST,
    constants.UNASSIGN_DIVISION_ASSET_SUCCESS,
    constants.UNASSIGN_DIVISION_ASSET_FAILURE,
);

export const {
    updateDivisionRequest,
    updateDivisionSuccess,
    updateDivisionFailure,
} = createActions(
    constants.UPDATE_DIVISION_REQUEST,
    constants.UPDATE_DIVISION_SUCCESS,
    constants.UPDATE_DIVISION_FAILURE,
);

export const {
    getOtherAssetsRequest,
    getOtherAssetsSuccess,
    getOtherAssetsFailure,
} = createActions(
    constants.GET_OTHER_ASSETS_REQUEST,
    constants.GET_OTHER_ASSETS_SUCCESS,
    constants.GET_OTHER_ASSETS_FAILURE,
);

export const {
    createDivisionRequest,
    createDivisionSuccess,
    createDivisionFailure,
} = createActions(
    constants.CREATE_DIVISION_REQUEST,
    constants.CREATE_DIVISION_SUCCESS,
    constants.CREATE_DIVISION_FAILURE,
);

export const {
    getDivisionOptionsRequest,
    getDivisionOptionsSuccess,
    getDivisionOptionsFailure,
} = createActions(
    constants.GET_DIVISION_OPTIONS_REQUEST,
    constants.GET_DIVISION_OPTIONS_SUCCESS,
    constants.GET_DIVISION_OPTIONS_FAILURE,
);

export const {
    getDivisionOptionsStrictRequest,
    getDivisionOptionsStrictSuccess,
    getDivisionOptionsStrictFailure,
} = createActions(
    constants.GET_DIVISION_OPTIONS_STRICT_REQUEST,
    constants.GET_DIVISION_OPTIONS_STRICT_SUCCESS,
    constants.GET_DIVISION_OPTIONS_STRICT_FAILURE,
);

export const {
    getUserDivisionsRequest,
    getUserDivisionsSuccess,
    getUserDivisionsFailure,
} = createActions(
    constants.GET_USER_DIVISIONS_REQUEST,
    constants.GET_USER_DIVISIONS_SUCCESS,
    constants.GET_USER_DIVISIONS_FAILURE,
);
