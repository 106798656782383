import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    TWO_FACTOR_AUTH_REQUEST: null,
    TWO_FACTOR_AUTH_SUCCESS: null,
    TWO_FACTOR_AUTH_FAILURE: null,

    RESET_PASSWORD_REQUEST: null,
    RESET_PASSWORD_SUCCESS: null,
    RESET_PASSWORD_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    GET_COMPANY_USERS_REQUEST: null,
    GET_COMPANY_USERS_SUCCESS: null,
    GET_COMPANY_USERS_FAILURE: null,

    GET_USERS_PAGE_REQUEST: null,
    GET_USERS_DEPARTMENT_REQUEST: null,
    GET_USERS_PAGE_SUCCESS: null,
    GET_USERS_PAGE_FAILURE: null,

    GET_DIVISION_ASSET_PERMISSIONS_GRID_REQUEST: null,
    GET_DIVISION_ASSET_PERMISSIONS_GRID_SUCCESS: null,
    GET_DIVISION_ASSET_PERMISSIONS_GRID_FAILURE: null,

    GET_ASSET_PERMISSIONS_GRID_REQUEST: null,
    GET_ASSET_PERMISSIONS_GRID_SUCCESS: null,
    GET_ASSET_PERMISSIONS_GRID_FAILURE: null,

    GET_DIVISION_DRIVER_PERMISSIONS_GRID_REQUEST: null,
    GET_DIVISION_DRIVER_PERMISSIONS_GRID_SUCCESS: null,
    GET_DIVISION_DRIVER_PERMISSIONS_GRID_FAILURE: null,

    GET_DRIVER_PERMISSIONS_GRID_REQUEST: null,
    GET_DRIVER_PERMISSIONS_GRID_SUCCESS: null,
    GET_DRIVER_PERMISSIONS_GRID_FAILURE: null,

    GET_REPORT_PERMISSIONS_GRID_REQUEST: null,
    GET_REPORT_PERMISSIONS_GRID_FAILURE: null,
    GET_REPORT_PERMISSIONS_GRID_SUCCESS: null,

    GET_REPORT_ASSET_PERMISSIONS_GRID_REQUEST: null,
    GET_REPORT_ASSET_PERMISSIONS_GRID_FAILURE: null,
    GET_REPORT_ASSET_PERMISSIONS_GRID_SUCCESS: null,

    GET_ACCOUNT_PERMISSIONS_GRID_REQUEST: null,
    GET_ACCOUNT_PERMISSIONS_GRID_SUCCESS: null,
    GET_ACCOUNT_PERMISSIONS_GRID_FAILURE: null,

    GET_USER_DETAIL_REQUEST: null,
    GET_USER_DETAIL_SUCCESS: null,
    GET_USER_DETAIL_FAILURE: null,

    SET_USER_DETAIL_REQUEST: null,
    SET_USER_DETAIL_SUCCESS: null,
    SET_USER_DETAIL_FAILURE: null,

    UPDATE_USER_COMPANY_REQUEST: null,
    UPDATE_USER_COMPANY_SUCCESS: null,
    UPDATE_USER_COMPANY_FAILURE: null,

    UPDATE_USER_PICTURE_REQUEST: null,
    UPDATE_USER_PICTURE_SUCCESS: null,
    UPDATE_USER_PICTURE_FAILURE: null,

    GET_PROFILE_DETAILS_REQUEST: null,
    GET_PROFILE_DETAILS_SUCCESS: null,
    GET_PROFILE_DETAILS_FAILURE: null,

    SET_PROFILE_DETAILS_REQUEST: null,
    SET_PROFILE_DETAILS_SUCCESS: null,
    SET_PROFILE_DETAILS_FAILURE: null,

    UPDATE_PROFILE_PICTURE_REQUEST: null,
    UPDATE_PROFILE_PICTURE_SUCCESS: null,
    UPDATE_PROFILE_PICTURE_FAILURE: null,

    CHANGE_USER_ACCESS_REQUEST_REQUEST: null,
    CHANGE_USER_ACCESS_REQUEST_SUCCESS: null,
    CHANGE_USER_ACCESS_REQUEST_FAILURE: null,

    CHANGE_USER_REPORT_ACCESS_REQUEST_REQUEST: null,
    CHANGE_USER_REPORT_ACCESS_REQUEST_SUCCESS: null,
    CHANGE_USER_REPORT_ACCESS_REQUEST_FAILURE: null,

    SAVE_DIVISION_REQUEST: null,
    SAVE_REPORT_REQUEST: null,

    CHANGE_USER_PERMISSION_REQUEST: null,
    CHANGE_USER_PERMISSION_SUCCESS: null,
    CHANGE_USER_PERMISSION_FAILURE: null,

    CHANGE_DRIVER_USER_PERMISSION_REQUEST: null,
    CHANGE_DRIVER_USER_PERMISSION_SUCCESS: null,
    CHANGE_DRIVER_USER_PERMISSION_FAILURE: null,

    UPDATE_USER_PROFILE_REQUEST: null,
    UPDATE_USER_PROFILE_SUCCESS: null,
    UPDATE_USER_PROFILE_FAILURE: null,

    UPDATE_PROFILE_IMAGE_REQUEST: null,
    UPDATE_PROFILE_IMAGE_SUCCESS: null,
    UPDATE_PROFILE_IMAGE_FAILURE: null,

    CHANGE_RESET_PASSWORD_REQUEST: null,
    CHANGE_RESET_PASSWORD_SUCCESS: null,
    CHANGE_PASSWORD_REQUEST: null,
    CHANGE_PASSWORD_SUCCESS: null,
    CHANGE_PASSWORD_FAILURE: null,

    ADD_ACCESS_TO_DIVISION_REQUEST: null,
    ADD_ACCESS_TO_DIVISION_SUCCESS: null,
    ADD_ACCESS_TO_DIVISION_FAILURE: null,

    ADD_REPORT_ACCESS_TO_DIVISION_REQUEST: null,
    ADD_REPORT_ACCESS_TO_DIVISION_SUCCESS: null,
    ADD_REPORT_ACCESS_TO_DIVISION_FAILURE: null,

    CREATE_USER_REQUEST: null,
    CREATE_USER_SUCCESS: null,
    CREATE_USER_FAILURE: null,

    DELETE_USER_REQUEST: null,
    DELETE_USER_SUCCESS: null,
    DELETE_USER_FAILURE: null,

    CLEAR_ASSET_PERMISSIONS_REQUEST: null,
    CLEAR_DRIVER_PERMISSIONS_REQUEST: null,

    UPLOAD_NEW_USER_PICTURE_REQUEST: null,
    UPLOAD_NEW_USER_PICTURE_SUCCESS: null,
    UPLOAD_NEW_USER_PICTURE_FAILURE: null,

    RESET_NEW_USER_PICTURE_REQUEST: null,

    GET_MAP_PREFERENCES_REQUEST: null,
    GET_MAP_PREFERENCES_SUCCESS: null,
    GET_MAP_PREFERENCES_FAILURE: null,

    SET_MAP_PREFERENCES_REQUEST: null,
    SET_MAP_PREFERENCES_SUCCESS: null,
    SET_MAP_PREFERENCES_FAILURE: null,

    VERIFY_RESET_PASSWORD_REQUEST: null,
    VERIFY_RESET_PASSWORD_SUCCESS: null,
    VERIFY_RESET_PASSWORD_FAILURE: null,

    SAVE_USER_SEARCH_PARAMS_REQUEST: null,
    SAVE_USER_ORDER_PARAMS_REQUEST: null,

    CLEAR_USER_VIEW_FORM_REQUEST: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,
} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,
);

export const {
    saveTokenRequest,
    saveTokenSuccess,
} = createActions(
    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,
);

export const {
    loginRequest,
    loginSuccess,
    loginFailure,
} = createActions(
    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,
);

export const {
    logoutRequest,
    logoutSuccess,
    logoutFailure,
} = createActions(
    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,
);

export const {
    twoFactorAuthRequest,
    twoFactorAuthSuccess,
    twoFactorAuthFailure,
} = createActions(
    constants.TWO_FACTOR_AUTH_REQUEST,
    constants.TWO_FACTOR_AUTH_SUCCESS,
    constants.TWO_FACTOR_AUTH_FAILURE,
);

export const {
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
} = createActions(
    constants.RESET_PASSWORD_REQUEST,
    constants.RESET_PASSWORD_SUCCESS,
    constants.RESET_PASSWORD_FAILURE,
);

export const {
    verifyResetPasswordRequest,
    verifyResetPasswordSuccess,
    verifyResetPasswordFailure,
} = createActions(
    constants.VERIFY_RESET_PASSWORD_REQUEST,
    constants.VERIFY_RESET_PASSWORD_SUCCESS,
    constants.VERIFY_RESET_PASSWORD_FAILURE,
);

export const {
    getCompanyUsersRequest,
    getCompanyUsersSuccess,
    getCompanyUsersFailure,
} = createActions(
    constants.GET_COMPANY_USERS_REQUEST,
    constants.GET_COMPANY_USERS_SUCCESS,
    constants.GET_COMPANY_USERS_FAILURE,
);

export const {
    getUsersPageRequest,
    getUsersDepartmentRequest,
    getUsersPageSuccess,
    getUsersPageFailure,
} = createActions(
    constants.GET_USERS_PAGE_REQUEST,
    constants.GET_USERS_DEPARTMENT_REQUEST,
    constants.GET_USERS_PAGE_SUCCESS,
    constants.GET_USERS_PAGE_FAILURE,
);

export const {
    getDivisionAssetPermissionsGridRequest,
    getDivisionAssetPermissionsGridSuccess,
    getDivisionAssetPermissionsGridFailure,
} = createActions(
    constants.GET_DIVISION_ASSET_PERMISSIONS_GRID_REQUEST,
    constants.GET_DIVISION_ASSET_PERMISSIONS_GRID_SUCCESS,
    constants.GET_DIVISION_ASSET_PERMISSIONS_GRID_FAILURE,
);

export const {
    getAssetPermissionsGridRequest,
    getAssetPermissionsGridSuccess,
    getAssetPermissionsGridFailure,
} = createActions(
    constants.GET_ASSET_PERMISSIONS_GRID_REQUEST,
    constants.GET_ASSET_PERMISSIONS_GRID_SUCCESS,
    constants.GET_ASSET_PERMISSIONS_GRID_FAILURE,
);

export const {
    getDivisionDriverPermissionsGridRequest,
    getDivisionDriverPermissionsGridSuccess,
    getDivisionDriverPermissionsGridFailure,
} = createActions(
    constants.GET_DIVISION_DRIVER_PERMISSIONS_GRID_REQUEST,
    constants.GET_DIVISION_DRIVER_PERMISSIONS_GRID_SUCCESS,
    constants.GET_DIVISION_DRIVER_PERMISSIONS_GRID_FAILURE,
);

export const {
    getDriverPermissionsGridRequest,
    getDriverPermissionsGridSuccess,
    getDriverPermissionsGridFailure,
} = createActions(
    constants.GET_DRIVER_PERMISSIONS_GRID_REQUEST,
    constants.GET_DRIVER_PERMISSIONS_GRID_SUCCESS,
    constants.GET_DRIVER_PERMISSIONS_GRID_FAILURE,
);

export const {
    getReportPermissionsGridRequest,
    getReportPermissionsGridSuccess,
    getReportPermissionsGridFailure,
} = createActions(
    constants.GET_REPORT_PERMISSIONS_GRID_REQUEST,
    constants.GET_REPORT_PERMISSIONS_GRID_SUCCESS,
    constants.GET_REPORT_PERMISSIONS_GRID_FAILURE,
);

export const {
    getReportAssetPermissionsGridRequest,
    getReportAssetPermissionsGridSuccess,
    getReportAssetPermissionsGridFailure,
} = createActions(
    constants.GET_REPORT_ASSET_PERMISSIONS_GRID_REQUEST,
    constants.GET_REPORT_ASSET_PERMISSIONS_GRID_SUCCESS,
    constants.GET_REPORT_ASSET_PERMISSIONS_GRID_FAILURE,
);

export const {
    getAccountPermissionsGridRequest,
    getAccountPermissionsGridSuccess,
    getAccountPermissionsGridFailure,
} = createActions(
    constants.GET_ACCOUNT_PERMISSIONS_GRID_REQUEST,
    constants.GET_ACCOUNT_PERMISSIONS_GRID_SUCCESS,
    constants.GET_ACCOUNT_PERMISSIONS_GRID_FAILURE,
);

export const {
    getUserDetailRequest,
    getUserDetailSuccess,
    getUserDetailFailure,
} = createActions(
    constants.GET_USER_DETAIL_REQUEST,
    constants.GET_USER_DETAIL_SUCCESS,
    constants.GET_USER_DETAIL_FAILURE,
);

export const {
    setUserDetailRequest,
    setUserDetailSuccess,
    setUserDetailFailure,
} = createActions(
    constants.SET_USER_DETAIL_REQUEST,
    constants.SET_USER_DETAIL_SUCCESS,
    constants.SET_USER_DETAIL_FAILURE,
);

export const {
    updateUserCompanyRequest,
    updateUserCompanySuccess,
    updateUserCompanyFailure,
} = createActions(
    constants.UPDATE_USER_COMPANY_REQUEST,
    constants.UPDATE_USER_COMPANY_SUCCESS,
    constants.UPDATE_USER_COMPANY_FAILURE,
);

export const {
    updateUserPictureRequest,
    updateUserPictureSuccess,
    updateUserPictureFailure,
} = createActions(
    constants.UPDATE_USER_PICTURE_REQUEST,
    constants.UPDATE_USER_PICTURE_SUCCESS,
    constants.UPDATE_USER_PICTURE_FAILURE,
);

export const {
    getProfileDetailsRequest,
    getProfileDetailsSuccess,
    getProfileDetailsFailure,
} = createActions(
    constants.GET_PROFILE_DETAILS_REQUEST,
    constants.GET_PROFILE_DETAILS_SUCCESS,
    constants.GET_PROFILE_DETAILS_FAILURE,
);

export const {
    setProfileDetailsRequest,
    setProfileDetailsSuccess,
    setProfileDetailsFailure,
} = createActions(
    constants.SET_PROFILE_DETAILS_REQUEST,
    constants.SET_PROFILE_DETAILS_SUCCESS,
    constants.SET_PROFILE_DETAILS_FAILURE,
);

export const {
    updateProfilePictureRequest,
    updateProfilePictureSuccess,
    updateProfilePictureFailure,
} = createActions(
    constants.UPDATE_PROFILE_PICTURE_REQUEST,
    constants.UPDATE_PROFILE_PICTURE_SUCCESS,
    constants.UPDATE_PROFILE_PICTURE_FAILURE,
);

export const {
    changeUserAccessRequestRequest,
    changeUserAccessRequestSuccess,
    changeUserAccessRequestFailure,
} = createActions(
    constants.CHANGE_USER_ACCESS_REQUEST_REQUEST,
    constants.CHANGE_USER_ACCESS_REQUEST_SUCCESS,
    constants.CHANGE_USER_ACCESS_REQUEST_FAILURE,
);

export const {
    changeUserReportAccessRequestRequest,
    changeUserReportAccessRequestSuccess,
    changeUserReportAccessRequestFailure,
} = createActions(
    constants.CHANGE_USER_REPORT_ACCESS_REQUEST_REQUEST,
    constants.CHANGE_USER_REPORT_ACCESS_REQUEST_SUCCESS,
    constants.CHANGE_USER_REPORT_ACCESS_REQUEST_FAILURE,
);

export const {
    saveDivisionRequest,
    saveReportRequest,
} = createActions(
    constants.SAVE_DIVISION_REQUEST,
    constants.SAVE_REPORT_REQUEST,
);

export const {
    changeUserPermissionRequest,
    changeUserPermissionSuccess,
    changeUserPermissionFailure,
} = createActions(
    constants.CHANGE_USER_PERMISSION_REQUEST,
    constants.CHANGE_USER_PERMISSION_SUCCESS,
    constants.CHANGE_USER_PERMISSION_FAILURE,
);

export const {
    changeDriverUserPermissionRequest,
    changeDriverUserPermissionSuccess,
    changeDriverUserPermissionFailure,
} = createActions(
    constants.CHANGE_DRIVER_USER_PERMISSION_REQUEST,
    constants.CHANGE_DRIVER_USER_PERMISSION_SUCCESS,
    constants.CHANGE_DRIVER_USER_PERMISSION_FAILURE,
);

export const {
    updateUserProfileRequest,
    updateUserProfileSuccess,
    updateUserProfileFailure,
} = createActions(
    constants.UPDATE_USER_PROFILE_REQUEST,
    constants.UPDATE_USER_PROFILE_SUCCESS,
    constants.UPDATE_USER_PROFILE_FAILURE,
);

export const {
    updateProfileImageRequest,
    updateProfileImageSuccess,
    updateProfileImageFailure,
} = createActions(
    constants.UPDATE_PROFILE_IMAGE_REQUEST,
    constants.UPDATE_PROFILE_IMAGE_SUCCESS,
    constants.UPDATE_PROFILE_IMAGE_FAILURE,
);

export const {
    changeResetPasswordRequest,
    changePasswordRequest,
    changePasswordSuccess,
    changeResetPasswordSuccess,
    changePasswordFailure,
} = createActions(
    constants.CHANGE_RESET_PASSWORD_REQUEST,
    constants.CHANGE_PASSWORD_REQUEST,
    constants.CHANGE_PASSWORD_SUCCESS,
    constants.CHANGE_RESET_PASSWORD_SUCCESS,
    constants.CHANGE_PASSWORD_FAILURE,
);

export const {
    addAccessToDivisionRequest,
    addAccessToDivisionSuccess,
    addAccessToDivisionFailure,
} = createActions(
    constants.ADD_ACCESS_TO_DIVISION_REQUEST,
    constants.ADD_ACCESS_TO_DIVISION_SUCCESS,
    constants.ADD_ACCESS_TO_DIVISION_FAILURE,
);

export const {
    addReportAccessToDivisionRequest,
    addReportAccessToDivisionSuccess,
    addReportAccessToDivisionFailure,
} = createActions(
    constants.ADD_REPORT_ACCESS_TO_DIVISION_REQUEST,
    constants.ADD_REPORT_ACCESS_TO_DIVISION_SUCCESS,
    constants.ADD_REPORT_ACCESS_TO_DIVISION_FAILURE,
);

export const {
    createUserRequest,
    createUserSuccess,
    createUserFailure,
} = createActions(
    constants.CREATE_USER_REQUEST,
    constants.CREATE_USER_SUCCESS,
    constants.CREATE_USER_FAILURE,
);

export const {
    deleteUserRequest,
    deleteUserSuccess,
    deleteUserFailure,
} = createActions(
    constants.DELETE_USER_REQUEST,
    constants.DELETE_USER_SUCCESS,
    constants.DELETE_USER_FAILURE,
);

export const {
    clearAssetPermissionsRequest,
} = createActions(
    constants.CLEAR_ASSET_PERMISSIONS_REQUEST,
);

export const {
    clearDriverPermissionsRequest,
} = createActions(
    constants.CLEAR_DRIVER_PERMISSIONS_REQUEST,
);

export const {
    uploadNewUserPictureRequest,
    uploadNewUserPictureSuccess,
    uploadNewUserPictureFailure,
} = createActions(
    constants.UPLOAD_NEW_USER_PICTURE_REQUEST,
    constants.UPLOAD_NEW_USER_PICTURE_SUCCESS,
    constants.UPLOAD_NEW_USER_PICTURE_FAILURE,
);

export const {
    resetNewUserPictureRequest,
} = createActions(
    constants.RESET_NEW_USER_PICTURE_REQUEST,
);

export const {
    getMapPreferencesRequest,
    getMapPreferencesSuccess,
    getMapPreferencesFailure,
} = createActions(
    constants.GET_MAP_PREFERENCES_REQUEST,
    constants.GET_MAP_PREFERENCES_SUCCESS,
    constants.GET_MAP_PREFERENCES_FAILURE,
);

export const {
    setMapPreferencesRequest,
    setMapPreferencesSuccess,
    setMapPreferencesFailure,
} = createActions(
    constants.SET_MAP_PREFERENCES_REQUEST,
    constants.SET_MAP_PREFERENCES_SUCCESS,
    constants.SET_MAP_PREFERENCES_FAILURE,
);

export const {
    saveUserSearchParamsRequest,
    saveUserOrderParamsRequest,
} = createActions(
    constants.SAVE_USER_SEARCH_PARAMS_REQUEST,
    constants.SAVE_USER_ORDER_PARAMS_REQUEST,
);

export const {
    clearUserViewFormRequest,
} = createActions(
    constants.CLEAR_USER_VIEW_FORM_REQUEST,
);
