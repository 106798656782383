/* eslint-disable react/no-this-in-sfc */
/* global window:true */
import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Spin, Col } from 'antd';
import moment from 'moment';
import { isEmpty, last, indexOf } from 'underscore';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import Icon from '../../components/elements/Icon';
import EventCustomViewPage from '../../components/EventCustomViewPage';

import './eventsCustomView.scss';
import DetailView from '../../components/DetailView';

export default function () {
    const {
        selectedEvent, match, actions, isFetching, speedingReportBack, user,
    } = this.props;
    const { sliderValue } = this.state;
    const { eventId, deviceId } = match.params;

    let eventTitle = '';

    if (selectedEvent && selectedEvent.info && selectedEvent.info.record_time) {
        eventTitle = moment(selectedEvent.info.record_time).format('D/M/YYYY h:mm');
    }

    if (selectedEvent && selectedEvent.info && selectedEvent.info.registration) {
        eventTitle += ` - ${selectedEvent.info.registration}`;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();
    const params = [];
    for (const pair of entries) {
        params.push(`${pair[0]}=${pair[1]}`);
        // // console.log('---------->', pair[0], pair[1]);
    }
    const backLink = params.length == 0 ? '/events/reload' : `/events?${params.join('&')}`;

    let eventListOrbackReportSpeeding = (
        <Link
            to={backLink}
            className="d-flex align-center">
            <Icon name="arrow-left" />
            <span>{params.length == 0 ? 'All events' : 'Filtered events'}</span>
        </Link>
    );

    if (speedingReportBack.referrer === 'speeding-report') {
        eventListOrbackReportSpeeding = (
            <Link
                to={{
                    pathname: '/reports/speeding-report/',
                }}
                className="d-flex align-center"
                type="primary"
                style={{ float: 'right' }}
                onClick={() => {
                    actions.getSpeedingReportRequest({
                        referrer: '', asset_id: speedingReportBack.asset_id, date_from: speedingReportBack.date_from, date_to: speedingReportBack.date_to, assetId: speedingReportBack.assetId, page: speedingReportBack.page,
                    }); this.setState({ currentTabKey: '1' });
                }}><Icon name="arrow-left" />
                <span>Back to speeding-report</span>
            </Link>
        );
    }
    return (
        <>
            <Layout
                data-test="pages-events-view"
                className="events-view"
                title={<div>Event View</div>}
                headerTitle={<div>Event View Page</div>}
                showFooter={false}>
                <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                    <FilterSidebar />
                    <div className="content">
                        <Spin spinning={isFetching}>
                            <div className="content-inner">
                                <section className="section">
                                    <div className="section__body">
                                        <DetailView
                                            shareReportType="event"
                                            selectedEvent={selectedEvent}
                                            sliderValue={sliderValue}
                                            onForward={this.requestEventInfo}
                                            onRewind={this.requestEventInfo}
                                            onIncreaseOptionClick={this.requestEventInfo}
                                            onReset={this.requestEventInfo}
                                            showStreamingSectionOnLoad />
                                    </div>
                                </section>
                            </div>
                        </Spin>
                    </div>
                </div>
            </Layout>
        </>
    );
}
