import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_LEFT_WIDGET_DATA_REQUEST: null,
    GET_LEFT_WIDGET_DATA_SUCCESS: null,
    GET_LEFT_WIDGET_DATA_FAILURE: null,

    GET_RIGHT_WIDGET_DATA_REQUEST: null,
    GET_RIGHT_WIDGET_DATA_SUCCESS: null,
    GET_RIGHT_WIDGET_DATA_FAILURE: null,

    GET_COMPARE_TARGETS_REQUEST: null,
    GET_COMPARE_TARGETS_SUCCESS: null,
    GET_COMPARE_TARGETS_FAILURE: null,

    REMOVE_COMPARE_WIDGET: null,
    ADD_COMPARE_WIDGET: null,
    UPDATE_COMPARE_WIDGET: null,
    SET_SELECTED: null,

    RESET_COMPARE_ASSETS_TARGETS_REQUEST: null,

    GET_PRINT_PDF_COMPARE_REQUEST: null,
    GET_PRINT_PDF_COMPARE_SUCCESS: null,
    GET_PRINT_PDF_COMPARE_FAILURE: null,

});

export const {
    getPrintPdfCompareRequest,
    getPrintPdfCompareSuccess,
    getPrintPdfCompareFailure,
} = createActions(
    constants.GET_PRINT_PDF_COMPARE_REQUEST,
    constants.GET_PRINT_PDF_COMPARE_SUCCESS,
    constants.GET_PRINT_PDF_COMPARE_FAILURE,
);

export const {
    getLeftWidgetDataRequest,
    getLeftWidgetDataSuccess,
    getLeftWidgetDataFailure,
} = createActions(
    constants.GET_LEFT_WIDGET_DATA_REQUEST,
    constants.GET_LEFT_WIDGET_DATA_SUCCESS,
    constants.GET_LEFT_WIDGET_DATA_FAILURE,
);

export const {
    getRightWidgetDataRequest,
    getRightWidgetDataSuccess,
    getRightWidgetDataFailure,
} = createActions(
    constants.GET_RIGHT_WIDGET_DATA_REQUEST,
    constants.GET_RIGHT_WIDGET_DATA_SUCCESS,
    constants.GET_RIGHT_WIDGET_DATA_FAILURE,
);

export const {
    getCompareTargetsRequest,
    getCompareTargetsSuccess,
    getCompareTargetsFailure,
} = createActions(
    constants.GET_COMPARE_TARGETS_REQUEST,
    constants.GET_COMPARE_TARGETS_SUCCESS,
    constants.GET_COMPARE_TARGETS_FAILURE,
);

export const {
    removeCompareWidget,
} = createActions(
    constants.REMOVE_COMPARE_WIDGET,
);

export const {
    addCompareWidget,
} = createActions(
    constants.ADD_COMPARE_WIDGET,
);

export const {
    updateCompareWidget,
} = createActions(
    constants.UPDATE_COMPARE_WIDGET,
);

export const {
    setSelected,
} = createActions(
    constants.SET_SELECTED,
);

export const {
    resetCompareAssetsTargetsRequest,
} = createActions(
    constants.RESET_COMPARE_ASSETS_TARGETS_REQUEST,
);
