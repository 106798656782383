/* eslint-disable import/prefer-default-export */

function updateWidget(oldWidgets, newWidget) {
    return oldWidgets.map((widget) => {
        if (widget.id === newWidget.id) {
            return newWidget;
        }
        return widget;
    });
}
/**
 * Updates a list of widgets from state with a new widget from backend
 *
 * @param oldWidgets
 * @param newWidget
 */
function addWidget(oldWidgets, newWidget) {
    const widget = newWidget;
    return [...oldWidgets, widget];
}

function removeWidget(oldWidgets, removeId) {
    return oldWidgets.filter((w) => w.id !== removeId);
}

function setLoading(oldWidgets, widgetId, loaded = false) {
    return oldWidgets.map((w) => {
        if (w.id === widgetId) {
            return {
                ...w,
                loaded,
            };
        }
        return w;
    });
}

function addData(oldWidgets, rawData, side) {
    const { id, data } = rawData;
    const widgets = oldWidgets.map((w) => {
        if (`${w.id}` !== `${id}`) {
            return w;
        }
        const widget = w;
        widget.loaded = true;
        widget[`${side}Data`] = data;
        return widget;
    });
    return widgets;
}

export {
    addWidget,
    removeWidget,
    setLoading,
    updateWidget,
    addData,
};
