/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DOM from './usersView';
import './usersView.scss';

import * as userActions from '../../core/user/userActions';

class Profile extends Component {
    constructor(props) {
        super(props);
        const { user } = this.props;
        this.view = DOM;
        this.state = {
            modalOpen: false,
            unit_of_length_preference: user.profile.unit_of_length_preference,
        };
        this.submitUpdateProfileForm = () => null;
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getUserDetailRequest();
    }

    updateProfileImage(data) {
        const { actions } = this.props;
        actions.updateProfileImageRequest({ image: data });
    }

    onChange(checked) {
        this.setState({ unit_of_length_preference: checked });
    }

    render() {
        return this.view();
    }
}

Profile.propTypes = {
    actions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    const driverId = parseInt(ownProps.match.params.driverId, 10);
    return {
        ...ownProps,
        user: state.user,
        driverId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...userActions,
            },
            dispatch,
        ),
    };
}
export const ProfileTest = Profile;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Profile);
