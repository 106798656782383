/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import {
    ClockCircleOutlined,
} from '@ant-design/icons';

import DOM from './mainIconWithSecondaryIconsChart';

class MainIconWithSecondaryIconsChart extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            timeframe: props.timeframe,
        };
    }

    onTimeframeChange(timeframe) {
        const { onTimeframeChange } = this.props;

        this.setState({
            timeframe,
        });

        onTimeframeChange(timeframe);
    }

    render() {
        return this.view();
    }
}

MainIconWithSecondaryIconsChart.defaultProps = {
    onTimeframeChange: () => null,
    rightMenuOnClick: () => null,
    rightMenuItems: [],
    tooltipText: null,
    onFirstOptionChange: () => null,
    onSecondOptionChange: () => null,
    onThirdOptionChange: () => null,
    onFourOptionChange: () => null,
    defaultFirstOptionValue: 'All',
    defaultSecondOptionValue: 'All',
    defaultThirdOptionValue: 'All',
    defaultFourOptionValue: 'All',
    divisions: [],
    drivers: [],
    assets: [],
    data: '',
    unit: 'Miles',
    subtitle: '',
    heightRatio: 1,
    imageUrl: '',
    topIcon: ClockCircleOutlined,
    topTitle: '',
    topTitleColor: '',
    topSubtitle: '',
    bottomIcon: ClockCircleOutlined,
    bottomTitleColor: '',
    bottomSubtitle: '',
};

MainIconWithSecondaryIconsChart.propTypes = {
    timeframe: PropTypes.oneOf(['hourly', 'daily', 'weekly', 'monthly', 'yearly']).isRequired,
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    subtitle: PropTypes.string,
    rightMenuItems: PropTypes.array,
    onTimeframeChange: PropTypes.func,
    rightMenuOnClick: PropTypes.func,
    tooltipText: PropTypes.string,
    onFirstOptionChange: PropTypes.func,
    onSecondOptionChange: PropTypes.func,
    onThirdOptionChange: PropTypes.func,
    onFourOptionChange: PropTypes.func,
    defaultFirstOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultSecondOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultThirdOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultFourOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    divisions: PropTypes.array,
    drivers: PropTypes.array,
    assets: PropTypes.array,
    heightRatio: PropTypes.oneOf([1, 2]),
    imageUrl: PropTypes.string,
    topIcon: PropTypes.object,
    topTitle: PropTypes.string,
    topTitleColor: PropTypes.string,
    topSubtitle: PropTypes.string,
    bottomIcon: PropTypes.object,
    bottomTitle: PropTypes.string,
    bottomTitleColor: PropTypes.string,
    bottomSubtitle: PropTypes.string,
};

export default MainIconWithSecondaryIconsChart;
