import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    ASSET_SEARCH_REQUEST: null,
    ASSET_SEARCH_SUCCESS: null,
    ASSET_SEARCH_FAILURE: null,

    CREATE_ASSET_REQUEST: null,
    CREATE_ASSET_SUCCESS: null,
    CREATE_ASSET_FAILURE: null,

    GET_ASSET_INFO_REQUEST: null,
    GET_ASSET_INFO_SUCCESS: null,
    GET_ASSET_INFO_FAILURE: null,

    DELETE_ASSET_REQUEST: null,
    DELETE_ASSET_SUCCESS: null,
    DELETE_ASSET_FAILURE: null,

    GET_ASSET_INFO_BY_IMEI_AND_DATE_REQUEST: null,
    GET_ASSET_INFO_BY_IMEI_AND_DATE_SUCCESS: null,
    GET_ASSET_INFO_BY_IMEI_AND_DATE_FAILURE: null,

    GET_EVENT_TYPES_REQUEST: null,
    GET_EVENT_TYPES_SUCCESS: null,
    GET_EVENT_TYPES_FAILURE: null,

    FILTER_ASSETS_REQUEST: null,
    FILTER_ASSETS_DIVISION_REQUEST: null,
    FILTER_ASSETS_SUCCESS: null,
    FILTER_ASSETS_FAILURE: null,

    GET_ASSET_JOURNEY_REQUEST: null,
    GET_ASSET_JOURNEY_SUCCESS: null,
    GET_ASSET_JOURNEY_FAILURE: null,

    RESET_ASSETS_REQUEST: null,
    RESET_ASSET_IS_FETCHINGS: null,

    GET_ASSET_LIST_REQUEST: null,
    GET_ASSET_LIST_SUCCESS: null,
    GET_ASSET_LIST_FAILURE: null,

    GET_BASIC_ASSET_LIST_REQUEST: null,
    GET_BASIC_ASSET_LIST_SUCCESS: null,
    GET_BASIC_ASSET_LIST_FAILURE: null,

    GET_ASSET_EVENT_HISTORY_REQUEST: null,
    GET_ASSET_EVENT_HISTORY_SUCCESS: null,
    GET_ASSET_EVENT_HISTORY_FAILURE: null,

    GET_ASSET_SERVICE_ALERTS_REQUEST: null,
    GET_ASSET_SERVICE_ALERTS_SUCCESS: null,
    GET_ASSET_SERVICE_ALERTS_FAILURE: null,

    CREATE_SERVICE_ALERT_REQUEST: null,
    CREATE_SERVICE_ALERT_SUCCESS: null,
    CREATE_SERVICE_ALERT_FAILURE: null,

    DELETE_SERVICE_ALERT_REQUEST: null,
    DELETE_SERVICE_ALERT_SUCCESS: null,
    DELETE_SERVICE_ALERT_FAILURE: null,

    UPLOAD_ASSET_IMAGE_REQUEST: null,
    UPLOAD_ASSET_IMAGE_SUCCESS: null,
    UPLOAD_ASSET_IMAGE_FAILURE: null,

    UPLOAD_NEW_ASSET_IMAGE_REQUEST: null,
    UPLOAD_NEW_ASSET_IMAGE_SUCCESS: null,
    UPLOAD_NEW_ASSET_IMAGE_FAILURE: null,

    GET_ASSET_LIVE_VIDEO_URL_REQUEST: null,
    GET_ASSET_LIVE_VIDEO_URL_SUCCESS: null,
    GET_ASSET_LIVE_VIDEO_URL_FAILURE: null,

    QUEUE_BY_DEVICE_ID_REQUEST: null,
    QUEUE_BY_DEVICE_ID_SUCCESS: null,
    QUEUE_BY_DEVICE_ID_FAILURE: null,

    GET_ASSET_HISTORIC_VIDEO_REQUEST: null,
    GET_ASSET_HISTORIC_VIDEO_SUCCESS: null,
    GET_ASSET_HISTORIC_VIDEO_FAILURE: null,

    UPDATE_ASSET_REQUEST: null,
    UPDATE_ASSET_SUCCESS: null,
    UPDATE_ASSET_FAILURE: null,

    GET_DIVISIONS_REQUEST: null,
    GET_DIVISIONS_SUCCESS: null,
    GET_DIVISIONS_FAILURE: null,

    GET_ALL_ASSET_OPTIONS_REQUEST: null,
    GET_ALL_ASSET_OPTIONS_SUCCESS: null,
    GET_ALL_ASSET_OPTIONS_FAILURE: null,

    GET_ASSET_OPTIONS_REQUEST: null,
    GET_ASSET_OPTIONS_SUCCESS: null,
    GET_ASSET_OPTIONS_FAILURE: null,

    GET_ASSET_OPTIONS_STRICT_REQUEST: null,
    GET_ASSET_OPTIONS_STRICT_SUCCESS: null,
    GET_ASSET_OPTIONS_STRICT_FAILURE: null,

    GET_DIVISION_LIST_REQUEST: null,
    GET_DIVISION_LIST_SUCCESS: null,
    GET_DIVISION_LIST_FAILURE: null,

    GET_VEHICLE_TYPES_REQUEST: null,
    GET_VEHICLE_TYPES_SUCCESS: null,
    GET_VEHICLE_TYPES_FAILURE: null,

    SAVE_ASSET_SEARCH_PARAMS_REQUEST: null,
    SAVE_ASSET_ORDER_PARAMS_REQUEST: null,
    SAVE_ASSET_SEARCH_ID_REQUEST: null,

    TOGGLE_ASSET_SELECTED: null,
    CLEAR_SELECTED_ASSETS: null,

    UPDATE_ASSET_JOURNEY_LOCATION_REQUEST: null,
    UPDATE_ASSET_JOURNEY_LOCATION_SUCCESS: null,
    UPDATE_ASSET_JOURNEY_LOCATION_FAILURE: null,

    GET_VEHICLE_MAKES_REQUEST: null,
    GET_VEHICLE_MAKES_SUCCESS: null,
    GET_VEHICLE_MAKES_FAILURE: null,

    GET_VEHICLE_MAKE_MODELS_REQUEST: null,
    GET_VEHICLE_MAKE_MODELS_SUCCESS: null,
    GET_VEHICLE_MAKE_MODELS_FAILURE: null,

    DELETE_ASSET_DATA_REQUEST: null,
    DELETE_ASSET_DATA_SUCCESS: null,
    DELETE_ASSET_DATA_FAILURE: null,

    GET_CSV_ASSET_RECORDS_BY_DAY_REQUEST: null,
    GET_CSV_ASSET_RECORDS_BY_DAY_SUCCESS: null,
    GET_CSV_ASSET_RECORDS_BY_DAY_FAILURE: null,

    SET_ASSET_JOURNEY_FILTERS: null,
});

export const {
    resetAssetsRequest,
    resetAssetIsFetchings,
} = createActions(
    constants.RESET_ASSETS_REQUEST,
    constants.RESET_ASSET_IS_FETCHINGS,
);

export const {
    assetSearchRequest,
    assetSearchSuccess,
    assetSearchFailure,
} = createActions(
    constants.ASSET_SEARCH_REQUEST,
    constants.ASSET_SEARCH_SUCCESS,
    constants.ASSET_SEARCH_FAILURE,
);

export const {
    createAssetRequest,
    createAssetSuccess,
    createAssetFailure,
} = createActions(
    constants.CREATE_ASSET_REQUEST,
    constants.CREATE_ASSET_SUCCESS,
    constants.CREATE_ASSET_FAILURE,
);

export const {
    getAssetInfoRequest,
    getAssetInfoSuccess,
    getAssetInfoFailure,
} = createActions(
    constants.GET_ASSET_INFO_REQUEST,
    constants.GET_ASSET_INFO_SUCCESS,
    constants.GET_ASSET_INFO_FAILURE,
);

export const {
    deleteAssetRequest,
    deleteAssetSuccess,
    deleteAssetFailure,
} = createActions(
    constants.DELETE_ASSET_REQUEST,
    constants.DELETE_ASSET_SUCCESS,
    constants.DELETE_ASSET_FAILURE,
);

export const {
    getAssetInfoByImeiAndDateRequest,
    getAssetInfoByImeiAndDateSuccess,
    getAssetInfoByImeiAndDateFailure,
} = createActions(
    constants.GET_ASSET_INFO_BY_IMEI_AND_DATE_REQUEST,
    constants.GET_ASSET_INFO_BY_IMEI_AND_DATE_SUCCESS,
    constants.GET_ASSET_INFO_BY_IMEI_AND_DATE_FAILURE,
);

export const {
    filterAssetsRequest,
    filterAssetsDivisionRequest,
    filterAssetsSuccess,
    filterAssetsFailure,
} = createActions(
    constants.FILTER_ASSETS_REQUEST,
    constants.FILTER_ASSETS_DIVISION_REQUEST,
    constants.FILTER_ASSETS_SUCCESS,
    constants.FILTER_ASSETS_FAILURE,
);

export const {
    getEventTypesRequest,
    getEventTypesSuccess,
    getEventTypesFailure,
} = createActions(
    constants.GET_EVENT_TYPES_REQUEST,
    constants.GET_EVENT_TYPES_SUCCESS,
    constants.GET_EVENT_TYPES_FAILURE,
);

export const {
    getAssetJourneyRequest,
    getAssetJourneySuccess,
    getAssetJourneyFailure,
} = createActions(
    constants.GET_ASSET_JOURNEY_REQUEST,
    constants.GET_ASSET_JOURNEY_SUCCESS,
    constants.GET_ASSET_JOURNEY_FAILURE,
);

export const {
    getAssetListRequest,
    getAssetListSuccess,
    getAssetListFailure,
} = createActions(
    constants.GET_ASSET_LIST_REQUEST,
    constants.GET_ASSET_LIST_SUCCESS,
    constants.GET_ASSET_LIST_FAILURE,
);

export const {
    getBasicAssetListRequest,
    getBasicAssetListSuccess,
    getBasicAssetListFailure,
} = createActions(
    constants.GET_BASIC_ASSET_LIST_REQUEST,
    constants.GET_BASIC_ASSET_LIST_SUCCESS,
    constants.GET_BASIC_ASSET_LIST_FAILURE,
);

export const {
    getAssetEventHistoryRequest,
    getAssetEventHistorySuccess,
    getAssetEventHistoryFailure,
} = createActions(
    constants.GET_ASSET_EVENT_HISTORY_REQUEST,
    constants.GET_ASSET_EVENT_HISTORY_SUCCESS,
    constants.GET_ASSET_EVENT_HISTORY_FAILURE,
);

export const {
    getAssetServiceAlertsRequest,
    getAssetServiceAlertsSuccess,
    getAssetServiceAlertsFailure,
} = createActions(
    constants.GET_ASSET_SERVICE_ALERTS_REQUEST,
    constants.GET_ASSET_SERVICE_ALERTS_SUCCESS,
    constants.GET_ASSET_SERVICE_ALERTS_FAILURE,
);

export const {
    createServiceAlertRequest,
    createServiceAlertSuccess,
    createServiceAlertFailure,
} = createActions(
    constants.CREATE_SERVICE_ALERT_REQUEST,
    constants.CREATE_SERVICE_ALERT_SUCCESS,
    constants.CREATE_SERVICE_ALERT_FAILURE,
);

export const {
    deleteServiceAlertRequest,
    deleteServiceAlertSuccess,
    deleteServiceAlertFailure,
} = createActions(
    constants.DELETE_SERVICE_ALERT_REQUEST,
    constants.DELETE_SERVICE_ALERT_SUCCESS,
    constants.DELETE_SERVICE_ALERT_FAILURE,
);

export const {
    uploadAssetImageRequest,
    uploadAssetImageSuccess,
    uploadAssetImageFailure,
} = createActions(
    constants.UPLOAD_ASSET_IMAGE_REQUEST,
    constants.UPLOAD_ASSET_IMAGE_SUCCESS,
    constants.UPLOAD_ASSET_IMAGE_FAILURE,
);

export const {
    uploadNewAssetImageRequest,
    uploadNewAssetImageSuccess,
    uploadNewAssetImageFailure,
} = createActions(
    constants.UPLOAD_NEW_ASSET_IMAGE_REQUEST,
    constants.UPLOAD_NEW_ASSET_IMAGE_SUCCESS,
    constants.UPLOAD_NEW_ASSET_IMAGE_FAILURE,
);

export const {
    getAssetLiveVideoUrlRequest,
    getAssetLiveVideoUrlSuccess,
    getAssetLiveVideoUrlFailure,
} = createActions(
    constants.GET_ASSET_LIVE_VIDEO_URL_REQUEST,
    constants.GET_ASSET_LIVE_VIDEO_URL_SUCCESS,
    constants.GET_ASSET_LIVE_VIDEO_URL_FAILURE,
);

export const {
    queueByDeviceIdRequest,
    queueByDeviceIdSuccess,
    queueByDeviceIdFailure,
} = createActions(
    constants.QUEUE_BY_DEVICE_ID_REQUEST,
    constants.QUEUE_BY_DEVICE_ID_SUCCESS,
    constants.QUEUE_BY_DEVICE_ID_FAILURE,
);

export const {
    getAssetHistoricVideoRequest,
    getAssetHistoricVideoSuccess,
    getAssetHistoricVideoFailure,
} = createActions(
    constants.GET_ASSET_HISTORIC_VIDEO_REQUEST,
    constants.GET_ASSET_HISTORIC_VIDEO_SUCCESS,
    constants.GET_ASSET_HISTORIC_VIDEO_FAILURE,
);

export const {
    updateAssetRequest,
    updateAssetSuccess,
    updateAssetFailure,
} = createActions(
    constants.UPDATE_ASSET_REQUEST,
    constants.UPDATE_ASSET_SUCCESS,
    constants.UPDATE_ASSET_FAILURE,
);

export const {
    getDivisionsRequest,
    getDivisionsSuccess,
    getDivisionsFailure,
} = createActions(
    constants.GET_DIVISIONS_REQUEST,
    constants.GET_DIVISIONS_SUCCESS,
    constants.GET_DIVISIONS_FAILURE,
);

export const {
    getAllAssetOptionsRequest,
    getAllAssetOptionsSuccess,
    getAllAssetOptionsFailure,
} = createActions(
    constants.GET_ALL_ASSET_OPTIONS_REQUEST,
    constants.GET_ALL_ASSET_OPTIONS_SUCCESS,
    constants.GET_ALL_ASSET_OPTIONS_FAILURE,
);

export const {
    getAssetOptionsRequest,
    getAssetOptionsSuccess,
    getAssetOptionsFailure,
} = createActions(
    constants.GET_ASSET_OPTIONS_REQUEST,
    constants.GET_ASSET_OPTIONS_SUCCESS,
    constants.GET_ASSET_OPTIONS_FAILURE,
);

export const {
    getAssetOptionsStrictRequest,
    getAssetOptionsStrictSuccess,
    getAssetOptionsStrictFailure,
} = createActions(
    constants.GET_ASSET_OPTIONS_STRICT_REQUEST,
    constants.GET_ASSET_OPTIONS_STRICT_SUCCESS,
    constants.GET_ASSET_OPTIONS_STRICT_FAILURE,
);

export const {
    getDivisionListRequest,
    getDivisionListSuccess,
    getDivisionListFailure,
} = createActions(
    constants.GET_DIVISION_LIST_REQUEST,
    constants.GET_DIVISION_LIST_SUCCESS,
    constants.GET_DIVISION_LIST_FAILURE,
);

export const {
    getVehicleTypesRequest,
    getVehicleTypesSuccess,
    getVehicleTypesFailure,
} = createActions(
    constants.GET_VEHICLE_TYPES_REQUEST,
    constants.GET_VEHICLE_TYPES_SUCCESS,
    constants.GET_VEHICLE_TYPES_FAILURE,
);

export const {
    saveAssetSearchParamsRequest,
    saveAssetOrderParamsRequest,
    saveAssetSearchIdRequest,
} = createActions(
    constants.SAVE_ASSET_SEARCH_PARAMS_REQUEST,
    constants.SAVE_ASSET_ORDER_PARAMS_REQUEST,
    constants.SAVE_ASSET_SEARCH_ID_REQUEST,
);

export const {
    toggleAssetSelected,
    clearSelectedAssets,
} = createActions(
    constants.TOGGLE_ASSET_SELECTED,
    constants.CLEAR_SELECTED_ASSETS,
);

export const {
    updateAssetJourneyLocationRequest,
    updateAssetJourneyLocationSuccess,
    updateAssetJourneyLocationFailure,
} = createActions(
    constants.UPDATE_ASSET_JOURNEY_LOCATION_REQUEST,
    constants.UPDATE_ASSET_JOURNEY_LOCATION_SUCCESS,
    constants.UPDATE_ASSET_JOURNEY_LOCATION_FAILURE,
);

export const {
    getVehicleMakesRequest,
    getVehicleMakesSuccess,
    getVehicleMakesFailure,
} = createActions(
    constants.GET_VEHICLE_MAKES_REQUEST,
    constants.GET_VEHICLE_MAKES_SUCCESS,
    constants.GET_VEHICLE_MAKES_FAILURE,
);

export const {
    getVehicleMakeModelsRequest,
    getVehicleMakeModelsSuccess,
    getVehicleMakeModelsFailure,
} = createActions(
    constants.GET_VEHICLE_MAKE_MODELS_REQUEST,
    constants.GET_VEHICLE_MAKE_MODELS_SUCCESS,
    constants.GET_VEHICLE_MAKE_MODELS_FAILURE,
);

export const {
    deleteAssetDataRequest,
    deleteAssetDataSuccess,
    deleteAssetDataFailure,
} = createActions(
    constants.DELETE_ASSET_DATA_REQUEST,
    constants.DELETE_ASSET_DATA_SUCCESS,
    constants.DELETE_ASSET_DATA_FAILURE,
);

export const {
    setAssetJourneyFilters,
} = createActions(
    constants.SET_ASSET_JOURNEY_FILTERS,
);

export const {
    getCsvAssetRecordsByDayRequest,
    getCsvAssetRecordsByDaySuccess,
    getCsvAssetRecordsByDayFailure,
} = createActions(
    constants.GET_CSV_ASSET_RECORDS_BY_DAY_REQUEST,
    constants.GET_CSV_ASSET_RECORDS_BY_DAY_SUCCESS,
    constants.GET_CSV_ASSET_RECORDS_BY_DAY_FAILURE,
);
