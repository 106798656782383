import colours from './colours';

export default {
    timeRangeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    timeRangeContainer: {
        boxSizing: 'border-box',
        maxWidth: '1000px',
        height: '100px',
        zIndex: 2,
    },
    timeRangeContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxSizing: 'border-box',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
        height: '100%',
        background: '#FFFFFF',
        boxShadow: '1px 2px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px 8px 8px 0px',
        zIndex: 2,
    },
    rewindButton: {
        marginRight: '5px',
        marginTop: '4px',
        cursor: 'pointer',
    },
    forwardButton: {
        marginLeft: '5px',
        marginTop: '4px',
        cursor: 'pointer',
    },
    buttonFooter: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '-5px',
        marginTop: '0px',
    },
    increaseOptionButton: {
        width: '42px',
        height: '16px',
        backgroundColor: colours.buttonBackground,
        fontFamily: 'Roboto',
        fontSize: '8px',
        lineHeight: '9px',
        color: colours.textColorDefault,
        boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '0px 0px 5px 5px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '5px',
        zIndex: 1,
        cursor: 'pointer',
    },

    selectedIncreaseOption: {
        zIndex: 2,
        color: colours.textColorPrimary,
    },

    resetBtn: {
        fontFamily: 'Roboto',
        fontSize: '9px',
        lineHeight: '9px',
        fontWeight: 600,
        textDecoration: 'underline',
        color: colours.primaryColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '10px',
        cursor: 'pointer',
    },
};
