/* eslint-disable react/prefer-stateless-function */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CaretDownOutlined } from '@ant-design/icons';
import Map from '../Map';
import app from '../../core/constants';

const { GOOGLE_MAP_API_KEY } = app;

class ShowAssetLocation extends PureComponent {
    constructor(props) {
        super(props);
        // this.googleMapAccess = React.createRef();
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        const { asset } = this.props;
        const markers = [{
            title: <span style={{ fontSize: '14px', lineHeight: '14px' }}><CaretDownOutlined /></span>,
            lat: asset.lat,
            lng: asset.lon,
        }];
        const centerMap = {
            lat: asset.lat,
            lng: asset.lon,
        };

        return (
            <div style={{ height: 'inherit' }} data-test="component-show-asset-location">
                {asset.lat !== null && asset.lon !== null
                    ? (
                        <Map
                            showToolbar={false}
                            allowStreetView={false}
                            showFindCenterButton={false}
                            apiKey={GOOGLE_MAP_API_KEY}
                            markers={markers}
                            // zoom={10}
                            // center={centerMap}
                            autoZoom />
                    )
                    : (
                        <div>
                            No GPS coordinates specified
                        </div>
                    )}
            </div>
        );
    }
}

ShowAssetLocation.propTypes = {
    asset: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        lon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
};

export default ShowAssetLocation;
