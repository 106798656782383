/* global: localStorage */
import {
    call, put, takeLatest, takeEvery, all,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { fetchApiAuth, fetchApiAuthCustom } from '../utils/api';

import {
    getDriversSuccess,
    getDriversFailure,

    getGeneralDataSuccess,
    getGeneralDataFailure,

    getSingleDriverSuccess,
    getSingleDriverFailure,

    updateDriverSuccess,
    updateDriverFailure,

    uploadImageSuccess,
    uploadImageFailure,

    assignAssetSuccess,
    assignAssetFailure,

    getDriverJourneySuccess,
    getDriverJourneyFailure,

    getDriverEventsSuccess,
    getDriverEventsFailure,

    getDriverServiceAlertsSuccess,
    getDriverServiceAlertsFailure,

    createDriverServiceAlertSuccess,
    createDriverServiceAlertFailure,

    requestTagSuccess,
    requestTagFailure,

    tagLimitReachedSuccess,
    tagLimitReachedFailure,

    getDriverListSuccess,
    getDriverListFailure,

    getDriverListStrictSuccess,
    getDriverListStrictFailure,

    deleteDriverServiceAlertSuccess,
    deleteDriverServiceAlertFailure,

    updateJourneyLocationSuccess,
    updateJourneyLocationFailure,

    deleteDriverDataSuccess,
    deleteDriverDataFailure,
} from './driverActions';
import history from '../utils/history';

const {
    GET_DRIVERS_REQUEST,
    GET_GENERAL_DATA_REQUEST,
    GET_SINGLE_DRIVER_REQUEST,
    UPDATE_DRIVER_REQUEST,
    UPLOAD_IMAGE_REQUEST,
    ASSIGN_ASSET_REQUEST,
    GET_DRIVER_JOURNEY_REQUEST,
    GET_DRIVER_EVENTS_REQUEST,
    GET_DRIVER_SERVICE_ALERTS_REQUEST,
    REQUEST_TAG_REQUEST,
    TAG_LIMIT_REACHED_REQUEST,
    CREATE_DRIVER_SERVICE_ALERT_REQUEST,
    GET_DRIVER_LIST_REQUEST,
    GET_DRIVER_LIST_STRICT_REQUEST,
    DELETE_DRIVER_SERVICE_ALERT_REQUEST,
    UPDATE_JOURNEY_LOCATION_REQUEST,
    DELETE_DRIVER_DATA_REQUEST,
} = require('./driverActions').constants;

function* getDrivers(action) {
    try {
        const url = action.payload && action.payload.page ? `/driver/get-drivers?page=${action.payload.page}` : '/driver/get-drivers';
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url,
            body: action.payload,
        });

        yield put(getDriversSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getDriversFailure(e));
    }
}

function* getGeneralData() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'driver/get-general-data',
        });

        yield put(getGeneralDataSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getGeneralDataFailure(e));
    }
}

function* getSingleDriver(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'driver/get-single-driver',
            body: action.payload,
        });
        yield put(getSingleDriverSuccess(response));
    } catch (e) {
        // history.push('/drivers');
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSingleDriverFailure(e));
    }
}

function* updateDriver(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `driver/${action.payload.driverId}/update-driver`,
            body: action.payload,
        });

        yield put(updateDriverSuccess(response));
        notification.success({ message: 'Success', description: 'Successfully updated driver!' });
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updateDriverFailure(e));
    }
}

function* uploadImage(action) {
    try {
        const body = new FormData();
        body.append('image', action.payload.image);

        const response = yield call(fetchApiAuthCustom, {
            method: 'POST',
            url: `driver/upload-image/${action.payload.driverId}`,
            body,
        });

        yield put(uploadImageSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(uploadImageFailure(e));
    }
}

function* assignAsset(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'driver/assign-asset',
            body: action.payload,
        });
        notification.success({ message: 'Success', description: 'Succesfully assigned asset!' });
        yield put(assignAssetSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(assignAssetFailure(e));
    }
}

function* getDriverJourney(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/driver/${action.payload.driverId}/journey-history`,
            params: {
                page: (action.payload.page || 1),
            },
            body: action.payload,
        });
        yield put(getDriverJourneySuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getDriverJourneyFailure(e));
    }
}

function* getDriverEvents(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/driver/${action.payload.driverId}/events-history`,
            params: {
                page: (action.payload.page || 1),
            },
            body: action.payload,
        });
        yield put(getDriverEventsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getDriverEventsFailure(e));
    }
}

function* getDriverServiceAlerts(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/driver/${action.payload.driverId}/service-alerts`,
            params: {
                page: (action.payload.page || 1),
            },
            body: action.payload,
        });
        yield put(getDriverServiceAlertsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getDriverServiceAlertsFailure(e));
    }
}

function* createAssetServiceAlert(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/driver/${action.payload.driverId}/create-service-alert`,
            body: action.payload,
        });

        yield put(createDriverServiceAlertSuccess(response));
        notification.success({ message: 'Success', description: 'You\'ve Successfully created management alert' });
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(createDriverServiceAlertFailure(e));
    }
}

function* requestTag(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/tag-request/request-new',
            body: action.payload,
        });

        yield put(requestTagSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(requestTagFailure(e));
    }
}

function* tagLimitReached() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/tag-request/is-limit-reached',
        });

        yield put(tagLimitReachedSuccess(response));
    } catch (e) {
        notification.error(e.response ? e.response.data.message : e);
        yield put(tagLimitReachedFailure(e));
    }
}

function* getDriverList({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'driver/get-driver-list',
            params: payload,
        });

        yield put(getDriverListSuccess(response));
    } catch (e) {
        notification.error(e.response ? e.response.data.message : e);
        yield put(getDriverListFailure(e));
    }
}

function* getDriverListStrict({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'driver/get-driver-list-strict',
            params: payload,
        });

        yield put(getDriverListStrictSuccess(response));
    } catch (e) {
        notification.error(e.response ? e.response.data.message : e);
        yield put(getDriverListStrictFailure(e));
    }
}

function* deleteServiceAlert(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/driver/${action.payload.driverId}/delete-service-alert`,
            body: { alertId: action.payload.alertId },
        });
        notification.success({ message: 'Success', description: 'Service alert deleted successfully.' });
        yield put(deleteDriverServiceAlertSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(deleteDriverServiceAlertFailure(e));
    }
}

function* updateJourneyLocation(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'driver/update-journey-location',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'The location has been successfully updated' });
        yield put(updateJourneyLocationSuccess({ payload: action.payload, response }));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(updateJourneyLocationFailure(e));
    }
}

function* deleteDriverData(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/driver/${action.payload.driverId}/soft-delete-driver-data`,
        });

        notification.success({ message: 'Success', description: 'Driver data successfully deleted' });
        history.push('/drivers');
        yield put(deleteDriverDataSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e });
        yield put(deleteDriverDataFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* driverSaga() {
    yield all([
        takeLatest(GET_DRIVERS_REQUEST, getDrivers),
        takeLatest(GET_GENERAL_DATA_REQUEST, getGeneralData),
        takeLatest(GET_SINGLE_DRIVER_REQUEST, getSingleDriver),
        takeLatest(UPDATE_DRIVER_REQUEST, updateDriver),
        takeLatest(UPLOAD_IMAGE_REQUEST, uploadImage),
        takeLatest(ASSIGN_ASSET_REQUEST, assignAsset),
        takeEvery(GET_DRIVER_JOURNEY_REQUEST, getDriverJourney),
        takeEvery(GET_DRIVER_EVENTS_REQUEST, getDriverEvents),
        takeEvery(GET_DRIVER_SERVICE_ALERTS_REQUEST, getDriverServiceAlerts),
        takeEvery(REQUEST_TAG_REQUEST, requestTag),
        takeEvery(TAG_LIMIT_REACHED_REQUEST, tagLimitReached),
        takeLatest(CREATE_DRIVER_SERVICE_ALERT_REQUEST, createAssetServiceAlert),
        takeEvery(GET_DRIVER_LIST_REQUEST, getDriverList),
        takeEvery(GET_DRIVER_LIST_STRICT_REQUEST, getDriverListStrict),
        takeEvery(DELETE_DRIVER_SERVICE_ALERT_REQUEST, deleteServiceAlert),
        takeEvery(UPDATE_JOURNEY_LOCATION_REQUEST, updateJourneyLocation),
        takeEvery(DELETE_DRIVER_DATA_REQUEST, deleteDriverData),
    ]);
}
