import React from 'react';
import { Alert } from 'antd';

import './companyCurrentAlertBanner.scss';

export default function ({ alert }) {
    return (
        <div className="company-current-alert-banner">
            <Alert 
                message={alert} 
                type="warning" 
                banner 
            />
        </div>
    );
}
