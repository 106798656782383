/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { size } from 'underscore';

import DOM from './chartMultiDropDown';

class ChartMultiDropDown extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        const {
            divisions, userDivisions, drivers, assets,
        } = props;

        divisions.unshift({ id: 'All', name: 'All Divisions' });
        drivers.unshift({ id: 'All', name: 'All Drivers' });
        assets.unshift({ id: 'All', name: 'All Assets' });

        this.state = {
            firstOptionValue: props.defaultFirstOptionValue,
            secondOptionValue: props.defaultSecondOptionValue,
            thirdOptionValue: props.defaultThirdOptionValue,
            secondDropDownOptions: [],
            thirdDropDownOptions: [],
            divisions,
            userDivisions,
            drivers,
            assets,
        };
    }

    componentDidMount() {
        this.setDefaultDropdownValues();
    }

    componentDidUpdate(prevProps) {
        if (
            size(this.props.drivers) !== size(this.state.drivers)
            || size(this.props.assets) !== size(this.state.assets)
            || size(this.props.divisions) !== size(this.state.divisions)
            || size(this.props.userDivisions) !== size(this.state.userDivisions)
        ) {
            this.updateOptionValues();
        }
    }

    setDefaultDropdownValues() {
        const {
            firstOptionValue,
            divisions,
            userDivisions,
            drivers,
            assets,
        } = this.state;

        let {
            secondDropDownOptions, thirdDropDownOptions,
        } = this.state;

        if (firstOptionValue === 'Drivers') {
            secondDropDownOptions = userDivisions;
            thirdDropDownOptions = drivers;
        } else if (firstOptionValue === 'Assets') {
            secondDropDownOptions = divisions;
            thirdDropDownOptions = assets;
        }

        this.setState({
            secondDropDownOptions,
            thirdDropDownOptions,
        });
    }

    updateOptionValues() {
        const {
            divisions, userDivisions, drivers, assets,
        } = this.props;
        const { firstOptionValue } = this.state;
        let { secondDropDownOptions, thirdDropDownOptions } = this.state;

        divisions.unshift({ id: 'All', name: 'All Divisions' });
        userDivisions.unshift({ id: 'All', name: 'All Divisions' });
        drivers.unshift({ id: 'All', name: 'All Drivers' });
        assets.unshift({ id: 'All', name: 'All Assets' });

        if (firstOptionValue === 'Drivers') {
            secondDropDownOptions = userDivisions;
            thirdDropDownOptions = drivers;
        } else if (firstOptionValue === 'Assets') {
            secondDropDownOptions = divisions;
            thirdDropDownOptions = assets;
        }

        this.setState({
            divisions, userDivisions, drivers, assets, secondDropDownOptions, thirdDropDownOptions,
        });
    }

    onFirstOptionChange(value) {
        const {
            onFirstOptionChange, onDataChange,
        } = this.props;
        const {
            divisions, userDivisions, drivers, assets,
        } = this.state;
        let { secondDropDownOptions, thirdDropDownOptions, thirdOptionValue } = this.state;
        onFirstOptionChange(value);

        if (value === 'Drivers') {
            secondDropDownOptions = userDivisions;
            thirdDropDownOptions = drivers;
        } else if (value === 'Assets') {
            secondDropDownOptions = divisions;
            thirdDropDownOptions = assets;
        }

        this.setState({
            firstOptionValue: value,
            secondOptionValue: 'All',
            thirdOptionValue: 'All',
            secondDropDownOptions,
            thirdDropDownOptions,
        });

        onDataChange({
            firstOptionValue: value,
            secondOptionValue: 'All',
            thirdOptionValue: 'All',
        });
    }

    onSecondOptionChange(value) {
        const { onSecondOptionChange, onDataChange } = this.props;
        const {
            firstOptionValue,
        } = this.state;
        const { secondDropDownOptions, thirdOptionValue } = this.state;
        onSecondOptionChange(value);

        this.setState({
            secondOptionValue: value,
            secondDropDownOptions,
        });

        onDataChange({
            firstOptionValue,
            secondOptionValue: value,
            thirdOptionValue,
        });
    }

    onThirdOptionChange(value) {
        const { onThirdOptionChange, onDataChange } = this.props;
        const { drivers, assets } = this.state;
        const {
            firstOptionValue, secondOptionValue, userDivisions, divisions,
        } = this.state;
        onThirdOptionChange(value);

        this.setState({
            thirdOptionValue: value,
        });

        onDataChange({
            firstOptionValue,
            secondOptionValue,
            thirdOptionValue: value,
        });
    }

    render() {
        return this.view();
    }
}

ChartMultiDropDown.defaultProps = {
    onFirstOptionChange: () => null,
    onSecondOptionChange: () => null,
    onThirdOptionChange: () => null,
    onDataChange: () => null,
    defaultFirstOptionValue: 'All',
    defaultSecondOptionValue: 'All',
    defaultThirdOptionValue: 'All',
    divisions: [],
    userDivisions: [],
    drivers: [],
    assets: [],
    onlyDriver: false,
};

ChartMultiDropDown.propTypes = {
    onFirstOptionChange: PropTypes.func,
    onSecondOptionChange: PropTypes.func,
    onThirdOptionChange: PropTypes.func,
    onDataChange: PropTypes.func,
    defaultFirstOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultSecondOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultThirdOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    divisions: PropTypes.array,
    userDivisions: PropTypes.array,
    drivers: PropTypes.array,
    assets: PropTypes.array,
    onlyDriver: PropTypes.bool,
};

export default ChartMultiDropDown;
