/* eslint-disable react/no-this-in-sfc */
import React from 'react';

import { Chart } from 'react-google-charts';
import { Spin } from 'antd';

import ChartHeader from '../ChartHeader';
import ChartMultiDropDown from '../ChartMultiDropDown';
import './vorChart.scss';

export default function () {
    const {
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        onAllOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        divisions,
        userDivisions,
        drivers,
        assets,
        heightRatio,
        leftTitle,
        leftSubtitle,
        chartKey,
        dotOnClick,
        vorChartData,
    } = this.props;

    const { timeframe, colors, datatype } = this.state;

    const displayData = [['Status', 'Count', { role: 'tooltip', type: 'string', p: { html: true } }]];

    let widgetHeight = heightRatio * 200;
    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }

    // check if data is all empty, if yes, show special data which will still show the chart

    for (const key in vorChartData) {
        if (vorChartData[key].length > 0) {
            displayData.push([key, vorChartData[key].length, `${vorChartData[key].length} Vehicles`]);
        }
    }

    // means only have header, no data. Add extra data to keep the chart visible
    if (displayData.length == 1) {
        displayData.push(['0', 1, '0 Vehicle']);
    }

    const inactive_count = (vorChartData && vorChartData.inactive) ? vorChartData.inactive.length : 0;

    let newSubtitle = leftSubtitle;
    if (timeframe === 'current month') {
        newSubtitle = 'vehicles have not been seen for ';
    }

    return (
        <div className="new-chart-widget vor-chart-widget" style={{}}>
            <ChartHeader
                type="vor"
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData, datatype)}
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />
            <div className="vor-chart-content" style={{ width: '100%', height: widgetHeight, overflow: 'hidden' }} id={`modalContent_${chartKey}`}>
                <div className="content-inner">
                    <div className="left-side">
                        <div className="title">{leftTitle}</div>
                        <div className="subtitle">{inactive_count} {newSubtitle} {timeframe}</div>
                    </div>
                    <div>
                        <Chart
                            width="180px"
                            height="180px"
                            chartType="PieChart"
                            loader={<Spin />}
                            data={displayData}
                            options={{
                                // Just add this option
                                is3D: true,
                                colors,
                                legend: { position: 'none' },
                                backgroundColor: 'transparent',
                                chartArea: { backgroundColor: 'red' },
                                pieHole: 0.4,
                                width: '100%',
                                height: '70%',
                            }}
                            rootProps={{ 'data-testid': '2' }}
                            chartEvents={[
                                {
                                    eventName: 'select',
                                    callback: ({ chartWrapper }) => {
                                        const selected = chartWrapper.getChart().getSelection();

                                        if (selected) {
                                            const { row } = selected[0];
                                            if (row === 0) {
                                                this.goToAsset(vorChartData.inactive);
                                            } else {
                                                this.goToAsset(vorChartData.active);
                                            }
                                        }
                                    },
                                },
                            ]} />
                    </div>
                </div>
            </div>

            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    divisions={divisions}
                    userDivisions={userDivisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
