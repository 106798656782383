const colours = {
    railBackground: 'rgba(20, 20, 20, 0.08)',
    borderColor: '#d1d1d1',
    handleBackground: '#FFFFFF',
    trackValid: 'rgba(3, 225, 12, 0.3)',
    trackNotValid: 'rgba(214,0,11,0.3)',
    trackDisabled: '#D8D8D8',
    tickLabel: '#808080',

    buttonBackground: '#FFFFFF',
    textColorDefault: '#8A9094',
    textColorPrimary: '#FF6900',
    textColorSecondary: '#D8D8D8',

    primaryColor: '#FF6900',
    handleLineColor: '#FF6900',
    handleLineColorOverError: '#888888',
};

export default colours;
