export default {
    timeRangeWrapper: {
        marginTop: '5px',
    },
    timeRangeContainer: {
        marginTop: '5px',
        height: '105px',
        backgroundColor: 'transparent',
    },
    timeRangeContent: {
        paddingTop: '5px',
    },
    firstLineDiv: {
        marginLeft: '3px',
    },
    lastLineDiv: {
        marginLeft: '10px',
    },
    forwardButton: {
        marginLeft: '10px',
    },
    rewindButton: {
        marginRight: '10px',
    },
    firstTimeText: {
        marginLeft: '-43px',
    },
};
