/* eslint-disable react/no-this-in-sfc */
import React from 'react';

import { isEmpty } from 'underscore';
import ChartHeader from '../ChartHeader'
import ChartMultiDropDown from '../ChartMultiDropDown'
import './mainIconWithSecondaryIconsChart.scss';

import defaultIcon from '../../../../assets/images/leaf.png';
import medalIcon from '../../../../assets/images/medal.png';

export default function () {
    const {
        data,
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        onAllOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        divisions,
        drivers,
        assets,
        heightRatio,
        imageUrl,
        topIcon,
        topTitle,
        topSubtitle,
        topTitleColor,
        bottomIcon,
        bottomTitleColor,
        bottomSubtitle,
        bottomTitle,
        chartKey,
    } = this.props;

    const { timeframe } = this.state;

    let widgetHeight = heightRatio * 200;
    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }
    const maxImageWidth = 200 * heightRatio;
    const maxImageHeight = 200 * heightRatio;
    const displayImage = !isEmpty(imageUrl) ? imageUrl : defaultIcon;

    return (
        <div className="new-chart-widget" style={{}}>
            <ChartHeader
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData) }
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />
            <div className="main-icon-with-custom-icon-chart-content" style={{ width: '100%', height: widgetHeight, overflow: 'hidden' }} id={'modalContent_'+chartKey}>
                <div className="content-inner">
                    <div className="left-side">
                        <img src={displayImage} alt="chart" className="chart-main-picture" style={{ maxWidth: maxImageWidth, maxHeight: maxImageHeight }} />
                    </div>
                    <div className="right-side">
                        <div className="icon-block">
                            <div className="icon-content">
                                {topIcon}
                            </div>
                            <div className="content-block">
                                <div className="title" style={{ color: topTitleColor }}>{topTitle}</div>
                                <div className="subtitle">{topSubtitle}</div>
                            </div>
                        </div>
                        <div className="icon-block">
                            <div className="icon-content">
                                {bottomIcon}
                            </div>
                            <div className="content-block">
                                <div className="title" style={{ color: bottomTitleColor }}>{bottomTitle}</div>
                                <div className="subtitle">{bottomSubtitle}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    divisions={divisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
