import colours from '../../colours';

export default {
    railOuter: {
        position: 'absolute',
        cursor: 'pointer',
        height: '32px',
        transform: 'translate(0%, -50%)',
    },
    railInner: {
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        width: '100%',
        height: '32px',
        pointerEvents: 'none',
        border: `0.2px solid ${colours.borderColor}`,
        backgroundColor: colours.railBackground,
        boxSizing: 'border-box',
        borderRadius: '8px',
    },
};
