import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    DatePicker, Select, Input, Button, 
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Calendar } from '@carbon/icons-react';

dayjs.extend(customParseFormat);

class AssetServiceAlertsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableFormSubmit: false,

        };
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some((field) => fieldsError[field]);
    }

    render() {
        const {
            form, companyUsers, serviceAlert, clearForm,
        } = this.props;
        const {
            getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
        } = form;
        const alertNameError = isFieldTouched('alert_name') && getFieldError('alert_name');

        return (
            <div className="d-flex dir-column align-center mt-3">
                <Form
                    layout="vertical"
                    style={{
                        width: '100%',
                        maxWidth: 304,
                        paddingBottom: 16,
                    }}
                    onSubmit={this.submitForm}
                >
                    <Form.Item label="Name">
                        {getFieldDecorator('alert_name', {
                            initialValue: (serviceAlert.name || ''),
                            rules: [
                                {
                                    required: true,
                                    message: 'Alert name is required',
                                },
                            ],

                        })(
                            <Input placeholder="Enter Name" />,
                        )}
                    </Form.Item>
                    <Form.Item label="Description">
                        {getFieldDecorator('alert_description', {
                            initialValue: (serviceAlert.description || ''),
                            rules: [
                                {
                                    required: true,
                                    message: 'Alert description is required',
                                },
                            ],
                        })(
                            <Input.TextArea
                                placeholder="Enter Description"
                                style={{ height: 112 }} />,
                        )}
                    </Form.Item>
                    <Form.Item label="Service Date">
                        {getFieldDecorator('service_date', {
                            initialValue: serviceAlert.service_date ? dayjs(serviceAlert.service_date, 'YYYY-MM-DD') : null,
                        })(
                            <DatePicker 
                                format="DD/MM/YYYY" 
                                placeholder="Select Service Date"
                                allowClear={false} 
                                suffixIcon={<Calendar />}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label="Frequency">
                        {getFieldDecorator('alert_frequency', {
                            initialValue: (serviceAlert.frequency || undefined),
                            rules: [
                                {
                                    required: true,
                                    message: 'Alert frequency is required',
                                },
                            ],
                        })(
                            <Select
                                placeholder="Select Frequency"
                            >
                                <Select.Option value={2}>
                                    Monthly
                                </Select.Option>
                                <Select.Option value={1}>
                                    Quarterly
                                </Select.Option>
                                <Select.Option value={0}>
                                    Yearly
                                </Select.Option>
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label="User to Notify">
                        {getFieldDecorator('notify_user', {
                            initialValue: (serviceAlert.user_id || undefined),
                            rules: [
                                {
                                    required: true,
                                    message: 'User to notify is required',
                                },
                            ],
                        })(
                            <Select
                                placeholder="Select User to Notify"
                            >
                                {companyUsers.map((companyUser, companyUserIndex) => <Select.Option key={companyUserIndex} value={companyUser.id}>{companyUser.email}</Select.Option>)}
                            </Select>,
                        )}
                    </Form.Item>
                    <div className="mt-2 mb-1 text-center">
                        <Button
                            htmlType="button"
                            onClick={clearForm}
                            className="btn btn-bordered"
                            style={{ width: 'auto' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            className="btn"
                            disabled={this.hasErrors(getFieldsError())}
                            style={{ marginLeft: '16px' }}
                        >
                            Add
                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

const CreateAssetServiceAlert = Form.create({ name: 'event_filter_form' })(AssetServiceAlertsForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        companyUsers: state.user.companyUsers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateAssetServiceAlert);
