import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Upload, message } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import userImage from '../../../assets/images/placeholder.png';
import app from '../../../core/constants';
import * as smartWidgetActions from '../../../core/smartWidget/smartWidgetActions';

const { API_URL } = app;

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

class Avatar extends React.Component {
    state = {
        loading: false,
        imageUrl: null,
    };

    handleChange = (info) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            const { onChange } = this.props;

            onChange(info.file.originFileObj);
            getBase64(info.file.originFileObj, (imageUrl) => this.setState({
                imageUrl,
                loading: false,
            }));
        }
    };

    render() {
        const uploadButton = (
            <div className="ant-upload-btn">
                <LegacyIcon type={this.state.loading ? 'loading' : 'upload'} />
                <div className="ant-upload-text">Upload new picture</div>
            </div>
        );
        const { imageUrl } = this.state;
        const {
            pictureUrl, placeholder, uploadUrl, actions, resetBase64newImageId, className, user,
        } = this.props;
        const placeholderImage = placeholder == 'car' ? user?.userCompany?.company_image : userImage;

        if (resetBase64newImageId) {
            this.setState({ imageUrl: null });
            actions.resetBase64NewImageIdRequest();
        }

        return (
            <Upload
                name="avatar"
                listType="picture-card"
                className={`avatar-uploader ${className}`}
                showUploadList={false}
                action={`${API_URL}/image-check`} // "https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={this.handleChange}>
                {!imageUrl && pictureUrl ? <img src={pictureUrl} alt="avatar" className="avatar-pic fitted" /> : null}
                {imageUrl ? <img src={imageUrl} alt="avatar" className="avatar-pic fitted" /> : null}
                {(!imageUrl && isEmpty(pictureUrl)) || (!pictureUrl && isEmpty(imageUrl)) ? <img src={placeholderImage} alt="avatar" className="avatar-pic fitted" /> : null}
                {uploadButton}
            </Upload>
        );
    }
}

Avatar.defaultProps = {
    pictureUrl: null,
    placeholder: 'user',
    onChange: () => {},
    uploadUrl: '',
    className: '',
};

Avatar.propTypes = {
    pictureUrl: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    uploadUrl: PropTypes.string,
    className: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        resetBase64newImageId: state.smartWidget.resetBase64newImageId,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...smartWidgetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Avatar);
