/* global: localStorage */
import {
    call, put, takeLatest, takeEvery, all,
} from 'redux-saga/effects';
import { notification } from 'antd';
import { fetchApiAuth, fetchApiAuthCustom } from '../utils/api';

import {
    getSmartWidgetPresetsRequest,
    getSmartWidgetPresetsSuccess,
    getSmartWidgetPresetsFailure,

    updatePresetSuccess,
    updatePresetFailure,

    deletePresetSuccess,
    deletePresetFailure,

    createWidgetFromPresetSuccess,
    createWidgetFromPresetFailure,

    createSmartWidgetSuccess,
    createSmartWidgetFailure,

    updateSmartWidgetSuccess,
    updateSmartWidgetFailure,

    getSmartWidgetInputDataSuccess,
    getSmartWidgetInputDataFailure,

    uploadWidgetImageSuccess,
    uploadWidgetImageFailure,

    uploadWidgetImageAttrSuccess,
    uploadWidgetImageAttrFailure,

    getChartDataSuccess,
    getChartDataFailure,

    getVorChartDataSuccess,
    getVorChartDataFailure,

    getBestDriverChartDataSuccess,
    getBestDriverChartDataFailure,

    getSmartWidgetsRequest,
    getSmartWidgetsSuccess,
    getSmartWidgetsFailure,

    getSmartWidgetDataSuccess,
    getSmartWidgetDataFailure,

    deleteSmartWidgetSuccess,
    deleteSmartWidgetFailure,

    updateSmartWidgetPositionsSuccess,
    updateSmartWidgetPositionsFailure,

    getPresetWidgetSuccess,
    getPresetWidgetFailure,

    getUserWidgetSuccess,
    getUserWidgetFailure,
} from './smartWidgetActions';

const {
    GET_SMART_WIDGET_PRESETS_REQUEST,
    UPDATE_PRESET_REQUEST,
    DELETE_PRESET_REQUEST,
    CREATE_WIDGET_FROM_PRESET_REQUEST,
    CREATE_SMART_WIDGET_REQUEST,
    UPDATE_SMART_WIDGET_REQUEST,
    GET_SMART_WIDGET_INPUT_DATA_REQUEST,
    UPLOAD_WIDGET_IMAGE_REQUEST,
    UPLOAD_WIDGET_IMAGE_ATTR_REQUEST,
    GET_CHART_DATA_REQUEST,
    GET_VOR_CHART_DATA_REQUEST,
    GET_BEST_DRIVER_CHART_DATA_REQUEST,
    GET_SMART_WIDGETS_REQUEST,
    GET_SMART_WIDGET_DATA_REQUEST,
    DELETE_SMART_WIDGET_REQUEST,
    UPDATE_SMART_WIDGET_POSITIONS_REQUEST,
    GET_PRESET_WIDGET_REQUEST,
    GET_USER_WIDGET_REQUEST,
} = require('./smartWidgetActions').constants;

function* getSmartWidgetPresets() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/smart-widget/get-preset-widgets',
        });

        yield put(getSmartWidgetPresetsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSmartWidgetPresetsFailure(e));
    }
}

function* updatePreset(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/update-preset',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Preset has been successfully updated' });
        yield put(updatePresetSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updatePresetFailure(e));
    }
}

function* deletePreset(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/delete-preset',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Preset has been successfully deleted' });
        yield put(deletePresetSuccess(action.payload));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(deletePresetFailure(e));
    }
}

function* createWidgetFromPreset(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/create-widget-from-preset',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Successfully created a new widget' });
        yield put(createWidgetFromPresetSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(createWidgetFromPresetFailure(e));
    }
}

function* createSmartWidget(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/create-smart-widget',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Successfully created a new widget' });
        yield put(getSmartWidgetPresetsRequest());
        yield put(createSmartWidgetSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(createSmartWidgetFailure(e));
    }
}

function* updateSmartWidget(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/update-smart-widget',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Successfully updated a widget' });
        yield put(getSmartWidgetsRequest());
        yield put(getSmartWidgetPresetsRequest());
        yield put(updateSmartWidgetSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updateSmartWidgetFailure(e));
    }
}

function* getPresetWidget(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/smart-widget/get-preset-widget/${action.payload.id}`,
        });

        yield put(getPresetWidgetSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getPresetWidgetFailure(e));
    }
}

function* getSmartWidgetInputData() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/smart-widget/get-smart-widget-input-data',
        });

        yield put(getSmartWidgetInputDataSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSmartWidgetInputDataFailure(e));
    }
}

function* uploadWidgetImage(action) {
    try {
        const body = new FormData();

        if (action.payload.image) {
            body.append('image', action.payload.image);
        }

        const response = yield call(fetchApiAuthCustom, {
            method: 'POST',
            url: '/smart-widget/upload-widget-image',
            body,
        });

        yield put(uploadWidgetImageSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(uploadWidgetImageFailure(e));
    }
}

function* uploadWidgetImageAttr(action) {
    try {
        const body = new FormData();

        if (action.payload.image) {
            body.append('image', action.payload.image);
        }

        const response = yield call(fetchApiAuthCustom, {
            method: 'POST',
            url: '/smart-widget/upload-widget-image',
            body,
        });

        response.attr = action.payload.attr;

        yield put(uploadWidgetImageAttrSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(uploadWidgetImageAttrFailure(e));
    }
}

function* getChartData(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/get-chart-data',
            body: action.payload,
        });

        yield put(getChartDataSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getChartDataFailure(e));
    }
}

function* getVorChartData(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/get-vor-chart-data',
            body: action.payload,
        });

        yield put(getVorChartDataSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getVorChartDataFailure(e));
    }
}

function* getBestDriverChartData(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/get-best-driver-chart-data',
            body: action.payload,
        });

        if (action.payload) {
            if (action.payload.id) {
                response.smartWidgetId = action.payload.id;
            }
        }

        yield put(getBestDriverChartDataSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getBestDriverChartDataFailure(e));
    }
}

function* getSmartWidgets(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/get-smart-widgets',
            body: action.payload,
        });

        yield put(getSmartWidgetsSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSmartWidgetsFailure(e));
    }
}

function* getSmartWidgetData(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/get-smart-widget-data',
            body: action.payload,
        });

        if (action.payload) {
            if (action.payload.id) {
                response.smartWidgetId = action.payload.id;
            }

            if (action.payload.timeframe) {
                response.timeframe = action.payload.timeframe;
            }

            if (action.payload.datatype) {
                response.datatype = action.payload.datatype;
            }

            if (action.payload.chart_type_id) {
                response.chart_type_id = action.payload.chart_type_id;
            }
        }

        yield put(getSmartWidgetDataSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getSmartWidgetDataFailure(e));
    }
}

function* deleteSmartWidget(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/delete-smart-widget',
            body: action.payload,
        });

        notification.success({ message: 'Success', description: 'Successfully deleted widget' });
        yield put(deleteSmartWidgetSuccess(action.payload));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(deleteSmartWidgetFailure(e));
    }
}

function* updateSmartWidgetPositions(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/smart-widget/update-smart-widget-positions',
            body: action.payload,
        });

        yield put(updateSmartWidgetPositionsSuccess(response));
        yield put(getSmartWidgetsRequest());
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(updateSmartWidgetPositionsFailure(e));
    }
}

function* getUserWidget(action) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/smart-widget/get-user-widget/${action.payload.id}`,
        });

        yield put(getUserWidgetSuccess(response));
    } catch (e) {
        notification.error({ message: 'Error', description: e.response ? e.response.data.message : e.message });
        yield put(getUserWidgetFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* driverSaga() {
    yield all([
        takeLatest(GET_PRESET_WIDGET_REQUEST, getPresetWidget),
        takeLatest(GET_SMART_WIDGET_PRESETS_REQUEST, getSmartWidgetPresets),
        takeLatest(UPDATE_PRESET_REQUEST, updatePreset),
        takeLatest(DELETE_PRESET_REQUEST, deletePreset),
        takeLatest(CREATE_WIDGET_FROM_PRESET_REQUEST, createWidgetFromPreset),
        takeLatest(CREATE_SMART_WIDGET_REQUEST, createSmartWidget),
        takeLatest(UPDATE_SMART_WIDGET_REQUEST, updateSmartWidget),
        takeLatest(GET_SMART_WIDGET_INPUT_DATA_REQUEST, getSmartWidgetInputData),
        takeLatest(UPLOAD_WIDGET_IMAGE_REQUEST, uploadWidgetImage),
        takeLatest(UPLOAD_WIDGET_IMAGE_ATTR_REQUEST, uploadWidgetImageAttr),
        takeLatest(GET_CHART_DATA_REQUEST, getChartData),
        takeLatest(GET_VOR_CHART_DATA_REQUEST, getVorChartData),
        takeEvery(GET_BEST_DRIVER_CHART_DATA_REQUEST, getBestDriverChartData),
        takeLatest(GET_SMART_WIDGETS_REQUEST, getSmartWidgets),
        takeEvery(GET_SMART_WIDGET_DATA_REQUEST, getSmartWidgetData),
        takeEvery(DELETE_SMART_WIDGET_REQUEST, deleteSmartWidget),
        takeEvery(UPDATE_SMART_WIDGET_POSITIONS_REQUEST, updateSmartWidgetPositions),
        takeEvery(GET_USER_WIDGET_REQUEST, getUserWidget),
    ]);
}
