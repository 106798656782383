import * as React from 'react';

function AssetArrowSVG(props) {
    return (
        <svg 
            width={14} 
            height={14} 
            xmlns="http://www.w3.org/2000/svg" 
            {...props}
        >
            <path
                stroke="null"
                d="M2.333 4.515a.354.354 0 00.223.334l3.4 1.36 1.361 3.402a.354.354 0 00.33.223h.005A.355.355 0 007.98 9.6l2.834-7.792a.354.354 0 00-.454-.454L2.566 4.187a.355.355 0 00-.233.328z" 
            />
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default AssetArrowSVG;
