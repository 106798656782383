import React from 'react';
import { InfoWindow } from '@vis.gl/react-google-maps';
import AssetPopupContent, { AssetPopupContentProps } from './AssetPopupContent';
import EventPopupContent, { EventPopupContentProps } from './EventPopupContent';
import { PopupType } from '../..';

interface PopupProps {
    anchor: google.maps.marker.AdvancedMarkerElement;
    data?: AssetPopupContentProps | EventPopupContentProps | null;
}

/**
 * Renders a popup window to the map via InfoWindow.
 */
const Popup: React.FC<PopupProps> = ({
    anchor,
    data = null,
}) => {
    if (anchor && data) {
        return (
            <InfoWindow
                anchor={anchor}
                shouldFocus
                headerDisabled
                minWidth={350}
                maxWidth={350}
                style={{ zIndex: 1000 }}
            >
                {data.type === PopupType.Asset ? (
                    <AssetPopupContent
                        type={data.type}
                        assetId={data.assetId}
                        date={data.date}
                    />
                ) : data.type === PopupType.Event ? (
                    <EventPopupContent 
                        type={data.type}
                        speed={data.speed}
                        eventName={data.eventName}
                        time={data.time}
                        driverId={data.driverId}
                        driverName={data.driverName}
                        location={data.location}
                        avlKeys={data.avlKeys}
                    />
                ) : (
                    <></>
                )}
            </InfoWindow>
        );
    }

    return null;
}

export default Popup;