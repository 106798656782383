import colours from '../../colours';

export default {
    tickMarker: {
        position: 'absolute',
        marginTop: '17px',
        width: '1px',
        height: '3px',
        backgroundColor: colours.borderColor,
        zIndex: 2,
    },
    tickMarkerLarge: {
        position: 'absolute',
        width: '1px',
        backgroundColor: colours.borderColor,
        zIndex: 2,
        marginTop: '17px',
        height: '8px',
    },
    tickLabel: {
        position: 'absolute',
        marginTop: '25px',
        marginLeft: '-12px',
        textAlign: 'center',
        zIndex: 2,
        color: colours.tickLabel,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '9px',
        lineHeight: '16px',
        letterSpacing: '1px',
    },
};
