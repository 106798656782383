import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    DatePicker, Select, Input, Button, 
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Calendar } from '@carbon/icons-react';
import * as assetActions from '../../../core/asset/assetActions';

dayjs.extend(customParseFormat);

class DriverManagementAlertsForm extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getAssetListRequest();
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    hasErrors(fieldsError) {
        return Object.keys(fieldsError).some((field) => fieldsError[field]);
    }

    render() {
        const {
            form, assetList, serviceAlert, clearForm,
        } = this.props;
        const {
            getFieldDecorator, getFieldsError, getFieldError, isFieldTouched,
        } = form;

        // means new alert, set frequency to undefined to show the placeholder
        if (serviceAlert.name === null) {
            serviceAlert.frequency = undefined;
        }

        return (
            <div className="d-flex dir-column align-center mt-1">
                <Form
                    layout="vertical"
                    style={{
                        width: '100%',
                        maxWidth: 304,
                        paddingBottom: 16,
                    }}
                    onSubmit={this.submitForm}>
                    <Form.Item label="Name">
                        {getFieldDecorator('alert_name', {
                            initialValue: (serviceAlert.name || ''),
                            rules: [
                                {
                                    required: true,
                                    message: 'Alert name is required',
                                },
                            ],

                        })(
                            <Input placeholder="Enter Name" />,
                        )}
                    </Form.Item>
                    <Form.Item label="Description">
                        {getFieldDecorator('alert_description', {
                            initialValue: (serviceAlert.description || ''),
                            rules: [
                                {
                                    required: true,
                                    message: 'Alert description is required',
                                },
                            ],
                        })(
                            <Input.TextArea
                                placeholder="Enter Description"
                                style={{ height: 112 }} />,
                        )}
                    </Form.Item>
                    <Form.Item label="Service Date">
                        {getFieldDecorator('service_date', {
                            initialValue: serviceAlert.service_date ? dayjs(serviceAlert.service_date, 'DD/MM/YYYY') : null,
                        })(
                            <DatePicker 
                                format="DD/MM/YYYY" 
                                placeholder="Select Service Date" 
                                allowClear={false}
                                suffixIcon={<Calendar />}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label="Frequency">
                        {getFieldDecorator('alert_frequency', {
                            initialValue: serviceAlert.frequency,
                            rules: [
                                {
                                    required: true,
                                    message: 'Alert frequency is required',
                                },
                            ],
                        })(
                            <Select
                                placeholder="Select Frequency"
                            >
                                <Select.Option value={2}>
                                    Monthly
                                </Select.Option>
                                <Select.Option value={1}>
                                    Quarterly
                                </Select.Option>
                                <Select.Option value={0}>
                                    Yearly
                                </Select.Option>
                            </Select>,
                        )}
                    </Form.Item>
                    <div style={{ textAlign: 'center', margintop: '20px' }}>
                        <div>
                            
                            <Button
                                style={{ width: 'auto' }}
                                htmlType="button"
                                onClick={clearForm}
                                className="btn btn-bordered"
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{ marginLeft: '16px' }}
                                htmlType="submit"
                                type="primary"
                                disabled={this.hasErrors(getFieldsError())}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

const CreateManagementAlertForm = Form.create({ name: 'event_filter_form' })(DriverManagementAlertsForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        assetList: state.asset.assetList,
        companyUsers: state.user.companyUsers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateManagementAlertForm);
