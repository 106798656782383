import React from 'react';

function IDClearLogoColourSVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1179.1 396.9"
            width="1em"
            height="1em"
            xmlSpace="preserve">
            <g>
                <path d="M245,115.9c0.9-3.9,1.2-5.1,1.2-6.5c0-3.9-2.3-6.2-7.4-7.1l1.4-5.8h44.7c8.1,0,11.3,2.3,11.3,7.4
		c0,1.4-0.2,3-0.7,5.1l-27.9,121.9c-0.9,3.9-1.2,5.1-1.2,6.5c0,4.1,2.3,6.2,7.4,7.1l-1.4,5.8h-44.7c-7.8,0-11.3-2.3-11.3-7.4
		c0-1.2,0.2-3,0.7-5.1L245,115.9z" />
                <path d="M321.7,115.9c0.9-3.9,1.2-5.1,1.2-6.5c0-3.9-2.3-6.2-7.4-7.1l1.4-5.8h90.3c17.5,0,23.3,1.6,28.8,7.4
		c5.3,5.8,7.4,13.1,7.4,25.1c0,15-3.5,36.2-11.1,64.3c-7.6,28.8-14.3,41.5-25.6,49.5c-8.1,5.5-15.7,7.4-31.1,7.4h-71.2
		c-7.8,0-11.5-2.3-11.5-7.4c0-1.2,0.5-3,0.9-5.1L321.7,115.9z M368,127.2l-20.7,90.8h7.1c11.7,0,14.1-0.5,17.3-3.7
		c4.8-5.1,9-16.6,14.5-40.8c3.9-16.8,5.8-28.1,5.8-34.1c0-8.8-3.5-12-13.1-12L368,127.2z" />
            </g>
            <g>
                <path d="M465.1,300.2h-10.3v-2.3h3.9v-18.1h-3.9v-2.3h10.3v2.3h-3.8v18.1h3.8V300.2z" />
                <path d="M493.3,300.2h-2.9l-8.1-17.9v17.9h-2.6v-22.7h2.9l8.2,17.7v-17.7h2.6V300.2z" />
                <path d="M519.7,300.2h-2.9l-8.1-17.9v17.9h-2.6v-22.7h2.9l8.2,17.7v-17.7h2.6V300.2z" />
                <path d="M539.3,277.1c2.5,0,4.5,1,5.8,3c1.3,2,2,4.9,2,8.8c0,3.8-0.7,6.7-2,8.7c-1.3,2-3.3,3-5.8,3c-2.5,0-4.5-1-5.8-3
		c-1.3-2-2-4.9-2-8.7c0-3.8,0.7-6.7,2-8.8C534.8,278.1,536.7,277.1,539.3,277.1z M539.3,298.3c3.3,0,5-3.1,5-9.4
		c0-6.3-1.7-9.5-5-9.5c-1.6,0-2.9,0.8-3.7,2.4c-0.8,1.6-1.3,4-1.3,7c0,3.1,0.4,5.4,1.3,7C536.4,297.5,537.6,298.3,539.3,298.3z" />
                <path d="M574.7,277.5l-7.4,22.7h-3.2l-7.4-22.7h2.8l6.2,19.6l6.2-19.6H574.7z" />
                <path d="M601.2,300.2h-2.8l-2.5-7.9h-7.4l-2.5,7.9h-2.7l7.4-22.7h3.2L601.2,300.2z M595.1,289.9l-3-9.4l-3,9.4H595.1z" />
                <path d="M626.9,279.6h-7v20.5h-2.6v-20.5h-7v-2.1h16.6V279.6z" />
                <path d="M650,300.2h-10.3v-2.3h3.9v-18.1h-3.9v-2.3H650v2.3h-3.8v18.1h3.8V300.2z" />
                <path d="M680.3,277.5l-7.4,22.7h-3.2l-7.4-22.7h2.8l6.2,19.6l6.2-19.6H680.3z" />
                <path d="M703.9,300.2H692v-22.7h11.7v2.3h-9.1v7h8.8v2.3h-8.8v8.8h9.2V300.2z" />
                <path d="M742.5,280c-1.3-0.5-2.6-0.7-3.8-0.7c-1.4,0-2.5,0.3-3.3,0.9c-0.9,0.6-1.3,1.5-1.3,2.7c0,1.4,1.1,2.6,3.4,3.6
		c1.7,0.8,2.8,1.3,3.3,1.6c0.5,0.3,1.1,0.7,1.6,1.2c0.6,0.5,1,1.1,1.3,1.9c0.3,0.7,0.5,1.5,0.5,2.4c0,2.2-0.7,3.9-2.2,5.1
		c-1.5,1.2-3.2,1.8-5.2,1.8c-1.9,0-3.7-0.4-5.1-1.2l0.5-2.3c1.7,0.8,3.2,1.2,4.5,1.2c1.5,0,2.7-0.4,3.6-1.2c0.9-0.8,1.4-1.8,1.4-3.2
		c0-1-0.3-1.9-0.9-2.6c-0.6-0.7-1.7-1.4-3.3-2.1c-1.6-0.8-2.7-1.3-3.2-1.5c-0.5-0.3-1-0.6-1.4-1.1c-0.5-0.5-0.8-1-1.1-1.6
		c-0.3-0.6-0.4-1.3-0.4-2c0-1.9,0.7-3.3,2-4.4c1.3-1.1,3.1-1.6,5.1-1.6c1.5,0,3,0.2,4.5,0.7L742.5,280z" />
                <path d="M773.1,300.2h-2.8l-2.5-7.9h-7.4l-2.5,7.9h-2.7l7.4-22.7h3.2L773.1,300.2z M767.1,289.9l-3-9.4l-3,9.4H767.1z" />
                <path d="M796.8,279.8h-9.4v7.4h8.5v2.3h-8.5v10.7h-2.6v-22.7h12V279.8z" />
                <path d="M823,300.2h-11.8v-22.7h11.7v2.3h-9.1v7h8.8v2.3h-8.8v8.8h9.2V300.2z" />
                <path d="M851.7,279.6h-7v20.5h-2.6v-20.5h-7v-2.1h16.6V279.6z" />
                <path d="M878.2,277.5l-7.1,13v9.7h-2.6v-9.7l-7.1-13h3l5.4,10.2l5.4-10.2H878.2z" />
            </g>
            <g>
                <g>
                    <path
                        className="st1"
                        d="M492.2,141.2c4.1-11.4,8-14.4,19.1-14.4h38.9c9.8,0,12.1-1.8,14.4-12.1l4.1-18.2h-58.8
			c-34.9,0-46,14.8-59.7,79.3c-3.9,19.1-5.7,31-5.7,40.3c0,13.2,3.9,22.3,12.3,27.8c7.3,4.8,16.4,6.2,43.5,6.2
			c32.6,0,37.6-0.7,43.1-5.2c2.5-2.3,4.6-6.4,6.2-13.2l5.5-24.2h-6.2c-2.5,7.5-5.7,9.1-18,9.1h-32.2c-6.6,0-9.1-0.2-11.6-0.7
			c-5.5-1.4-8.7-6.4-8.7-13.2c0-4.3,1.1-11.2,3.9-23.9C485.6,163.1,489.7,148.1,492.2,141.2z" />
                    <path d="M566.6,98.2l-3.7,16.2c-2.1,9.5-3.7,10.8-12.8,10.8h-38.9c-11.9,0-16.3,3.3-20.7,15.4
			c-2.7,7.4-6.9,22.9-10.1,37.8c-2.9,13.3-3.9,19.9-3.9,24.3c0,7.7,3.7,13.3,10,14.8c2.6,0.5,5.2,0.7,11.9,0.7h32.2
			c11.6,0,16.2-1.4,19.2-9.1h2.9l-5,22.2c-1.4,6.2-3.3,10.2-5.6,12.3c-4.9,4.1-8.7,4.9-42,4.9c-26.4,0-35.6-1.3-42.6-5.9
			c-7.9-5.1-11.6-13.5-11.6-26.4c0-8.7,1.6-19.9,5.7-40c13.5-63.4,24.3-78,58.1-78H566.6 M568.7,96.5h-58.8
			c-34.9,0-46,14.8-59.7,79.3c-3.9,19.1-5.7,31-5.7,40.3c0,13.2,3.9,22.3,12.3,27.8c7.3,4.8,16.4,6.2,43.5,6.2
			c32.6,0,37.6-0.7,43.1-5.2c2.5-2.3,4.6-6.4,6.2-13.2l5.5-24.2h-6.2c-2.5,7.5-5.7,9.1-18,9.1h-32.2c-6.6,0-9.1-0.2-11.6-0.7
			c-5.5-1.4-8.7-6.4-8.7-13.2c0-4.3,1.1-11.2,3.9-23.9c3.4-15.7,7.5-30.8,10-37.6c4.1-11.4,8-14.4,19.1-14.4h38.9
			c9.8,0,12.1-1.8,14.4-12.1L568.7,96.5L568.7,96.5z" />
                </g>
                <g>
                    <path
                        className="st1"
                        d="M906.9,126.2c-4.4,0-6,2.1-7.2,4.2l-4.3,8.3c0.1-0.3,0.2-0.6,0.2-1c0-0.4,0-0.9,0-1.2c0-0.3,0-0.4,0-0.6
			c0-0.1,0-0.3,0-0.4c0-2.9,0-9.3-8.3-9.3h-29l-1.4,6.5c2.6,0,5.5,1.9,3.9,7.7L838.7,241c0,0.1,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5
			c0.1,0.8,0.3,1.5,0.6,2.3c0.3,0.8,0.6,1.4,1,1.9c0.4,0.9,1.2,1.5,2.4,1.9c1.2,0.4,2.6,0.6,4.3,0.6h30.5l3-7.2c0,0-9.6,0-8-7.2
			l16.5-74.2c0.4-1.3,0.8-2.4,1.3-3.3c0.5-0.9,1.1-1.7,1.7-2.2c0.2-0.3,0.5-0.5,0.7-0.7c0.2-0.2,0.4-0.3,0.7-0.5
			c0.2-0.1,0.5-0.3,0.7-0.4c0.2-0.1,0.3-0.3,0.5-0.4c1-0.6,2.1-1.1,3.2-1.3c1.1-0.3,2.7-0.4,4.7-0.4H924c0,0,6.6,0.5,8.8-8.4l4-16.2
			H906.9z" />
                    <path d="M934.7,127.8l-3.5,14.2c-1.7,6.8-6.1,7.2-7,7.2l-0.2,0h-21.8c-2.2,0-3.8,0.1-5.1,0.4
			c-1.3,0.3-2.6,0.8-3.7,1.5l-0.2,0.1l-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.2-0.5,0.3c-0.4,0.2-0.8,0.5-1.1,0.8
			l-0.4,0.4l-0.2,0.2c-0.8,0.6-1.4,1.5-2,2.6c-0.5,1-1,2.3-1.4,3.7l-16.5,74.2c-0.6,2.9,0.3,4.8,1.1,5.9c1.6,2,4.2,2.8,6.1,3.1
			l-1.7,4.2h-29.4c-1.5,0-2.7-0.2-3.8-0.5c-1.1-0.3-1.3-0.8-1.4-1l-0.1-0.2l-0.1-0.2c-0.3-0.4-0.5-0.8-0.7-1.4
			c-0.2-0.6-0.4-1.2-0.5-1.9l0-0.3l0-0.3v0l22.1-100.4c0.9-3.2,0.7-5.7-0.7-7.5c-0.7-0.9-1.7-1.6-2.8-1.9l0.8-3.5h27.7
			c6.1,0,6.6,3.8,6.6,7.6l0,0.2l0,0.2l0,0.5l0,1.2c0,0.2,0,0.3,0,0.3c0,0,0,0,0,0l2.9,1.5l4.2-8.3c1-1.8,2.1-3.3,5.7-3.3H934.7
			 M936.8,126.2h-29.9c-4.4,0-6,2.1-7.2,4.2l-4.3,8.3c0.1-0.3,0.2-0.6,0.2-1c0-0.4,0-0.9,0-1.2c0-0.3,0-0.4,0-0.6c0-0.1,0-0.3,0-0.4
			c0-2.9,0-9.3-8.3-9.3h-29l-1.4,6.5c2.6,0,5.5,1.9,3.9,7.7L838.7,241c0,0.1,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5c0.1,0.8,0.3,1.5,0.6,2.3
			c0.3,0.8,0.6,1.4,1,1.9c0.4,0.9,1.2,1.5,2.4,1.9c1.2,0.4,2.6,0.6,4.3,0.6h30.5l3-7.2c0,0,0,0,0,0c-0.2,0-9.5,0-8-7.2l16.5-74.2
			c0.4-1.3,0.8-2.4,1.3-3.3c0.5-0.9,1.1-1.7,1.7-2.2c0.2-0.3,0.5-0.5,0.7-0.7c0.2-0.2,0.4-0.3,0.7-0.5c0.2-0.1,0.5-0.3,0.7-0.4
			c0.2-0.1,0.3-0.3,0.5-0.4c1-0.6,2.1-1.1,3.2-1.3c1.1-0.3,2.7-0.4,4.7-0.4H924c0,0,0.1,0,0.2,0c1.1,0,6.6-0.4,8.6-8.4L936.8,126.2
			L936.8,126.2z" />
                </g>
                <g>
                    <path
                        className="st1"
                        d="M594.2,234.4l26.6-130.4c0.4-1.9,1.9-7.6-7.8-7.6h-29.1l-1.1,5.6c0,0,7.3-0.2,5.2,8.9l-26.7,128
			c0,0-2.5,10.1,7.5,10.1h28.3l2.4-7.4C592.3,241.5,594.2,234.4,594.2,234.4z" />
                    <path d="M613,97.9c3,0,5,0.6,5.9,1.7c0.9,1.2,0.5,2.9,0.3,4l-26.6,130.4c-0.1,0.4-1,4,1.1,6.7
			c0.9,1.1,2.1,1.9,3.7,2.2l-1.4,4.3h-27.1c-2.4,0-4.1-0.6-5-1.9c-1.8-2.3-0.9-6.1-0.9-6.2l26.7-128c0.8-3.5,0.5-6.3-1.1-8.3
			c-1.1-1.4-2.6-2.1-3.8-2.4l0.5-2.6H613 M613,96.3h-29.1l-1.1,5.6c0,0,0,0,0,0c0.5,0,7.2,0.1,5.1,8.9l-26.7,128
			c0,0-2.5,10.1,7.5,10.1h28.3l2.4-7.4c-7.3,0-5.4-7.1-5.4-7.1l26.6-130.4C621.2,102,622.7,96.3,613,96.3L613,96.3z" />
                </g>
                <g>
                    <path
                        className="st1"
                        d="M712,131.3c-2.5-1.1-6.8-2-12.9-2.7c-6.1-0.6-12.5-1-19.4-1c-0.3,0-0.5,0-0.8,0c-0.3,0-0.6,0-0.8,0
			c-6.5,0-12.6,0.3-18.5,0.9c-5.9,0.6-10.2,1.5-12.9,2.8c-6,2.7-11.6,9.7-16.9,21.1c-5.3,11.4-9.2,23.7-11.6,36.9
			c-0.9,4.4-1.5,8.8-2,13.1c-0.4,4.3-0.7,8.5-0.7,12.6c0,6.3,0.7,12.1,2,17.2c1.3,5.1,3.4,9.1,6.2,11.9c2,1.8,4.7,3.4,7.9,4.2
			c5.1,1.3,13.7,1.7,13.7,1.7l38.8-0.2h9.8c0,0,6.6,0,8.3-7.1l6-25.7l-4.9-0.1c0,0-0.6,2.3-2.6,3.7c-1.3,0.9-2.9,0.9-3.7,0.9h-22.4
			l0,0h-15.7c-0.6,0-1.2,0-1.9,0c-0.6,0-1.2-0.1-1.9-0.2c-1.9-0.1-3.6-0.4-5.1-1c-1.6-0.5-2.7-1.3-3.4-2.5c-0.5-0.6-0.9-1.5-1.1-2.6
			c-0.2-1.1-0.4-2.3-0.4-3.7c0-3.2,0.4-7.1,1.3-11.9c0.4-2.3,0.9-4.7,1.4-7.3h60.9c2.9,0,5-0.3,6.5-1c1.5-0.7,2.3-1.7,2.4-3
			c0.2-0.8,0.6-2,1-3.6c0.4-1.6,0.9-3.6,1.4-5.7c0.6-2.9,1.2-6.2,1.8-9.8c0.6-3.6,0.9-7.3,1-10.9c0-0.4,0-0.7,0-1c0-0.3,0-0.7,0-1
			c0-5.3-0.7-10.3-2.2-15C719,136.7,716.2,133.4,712,131.3z M695.4,167.9c-0.2,1.7-0.6,3.4-1.1,5.1h-41.5c0.2-0.8,0.5-1.5,0.7-2.3
			c2.1-6.7,4.8-12,7.9-15.8c3.2-3.8,8.7-5.7,16.5-5.7c7,0,11.7,1.3,14.2,4c2.5,2.7,3.7,6,3.7,9.9
			C695.8,164.7,695.6,166.3,695.4,167.9z" />
                    <path d="M679.7,129.4c6.7,0,13.2,0.3,19.2,0.9c5.9,0.6,10,1.5,12.3,2.5c3.8,1.8,6.4,4.8,7.7,9.1
			c1.4,4.5,2.2,9.4,2.2,14.5v1l0,1c-0.1,3.6-0.5,7.2-1,10.8c-0.6,3.6-1.1,6.8-1.7,9.7c-0.5,2.1-1,4-1.4,5.7c-0.4,1.7-0.8,2.8-1,3.5
			l-0.1,0.2l0,0.2c0,0.3-0.1,1-1.5,1.6c-1.3,0.6-3.2,0.9-5.8,0.9h-60.9h-1.4l-0.3,1.3c-0.5,2.6-1,5.1-1.4,7.3
			c-0.9,4.8-1.3,8.9-1.3,12.2c0,1.5,0.1,2.9,0.4,4.1c0.3,1.3,0.8,2.4,1.3,3.1c1,1.5,2.4,2.5,4.3,3.1c1.6,0.5,3.5,0.9,5.4,1
			c0.7,0.1,1.4,0.2,2.1,0.2h1.9h15.7l0.7,0H697c1,0,3-0.2,4.6-1.2c1.5-1,2.4-2.4,2.9-3.4l1.7,0l-5.5,23.7c-1.3,5.7-6.5,5.8-6.7,5.8
			H684l-38.7,0.2c-0.1,0-8.5-0.4-13.3-1.6c-2.7-0.7-5.2-2.1-7.2-3.8c-2.6-2.6-4.6-6.3-5.8-11.1c-1.3-5-1.9-10.7-1.9-16.8
			c0-4,0.2-8.2,0.6-12.4c0.4-4.2,1.1-8.6,1.9-13c2.4-13,6.3-25.3,11.5-36.6c5.1-11,10.5-17.8,16.1-20.3c2.6-1.2,6.7-2.1,12.4-2.6
			c5.8-0.6,12-0.8,18.4-0.8h0.8H679.7 M650.5,174.7h2.2h41.5h1.2l0.3-1.2c0.5-1.8,0.9-3.6,1.2-5.3c0.3-1.7,0.4-3.4,0.4-5
			c0-4.3-1.4-8-4.2-11c-2.8-3-7.9-4.5-15.4-4.5c-8.3,0-14.3,2.1-17.7,6.3c-3.3,4-6.1,9.5-8.2,16.3c-0.2,0.7-0.5,1.5-0.7,2.3
			L650.5,174.7 M679.7,127.7c-0.3,0-0.5,0-0.8,0c-0.3,0-0.6,0-0.8,0c-6.5,0-12.6,0.3-18.5,0.9c-5.9,0.6-10.2,1.5-12.9,2.8
			c-6,2.7-11.6,9.7-16.9,21.1c-5.3,11.4-9.2,23.7-11.6,36.9c-0.9,4.4-1.5,8.8-2,13.1c-0.4,4.3-0.7,8.5-0.7,12.6
			c0,6.3,0.7,12.1,2,17.2c1.3,5.1,3.4,9.1,6.2,11.9c2,1.8,4.7,3.4,7.9,4.2c5.1,1.3,13.7,1.7,13.7,1.7l38.8-0.2h9.8c0,0,0,0,0,0
			c0.1,0,6.7,0,8.3-7.1l6-25.7l-4.9-0.1c0,0-0.6,2.3-2.6,3.7c-1.3,0.9-2.9,0.9-3.7,0.9h-22.4l0,0h-15.7c-0.6,0-1.2,0-1.9,0
			c-0.6,0-1.2-0.1-1.9-0.2c-1.9-0.1-3.6-0.4-5.1-1c-1.6-0.5-2.7-1.3-3.4-2.5c-0.5-0.6-0.9-1.5-1.1-2.6c-0.2-1.1-0.4-2.3-0.4-3.7
			c0-3.2,0.4-7.1,1.3-11.9c0.4-2.3,0.9-4.7,1.4-7.3h60.9c2.9,0,5-0.3,6.5-1c1.5-0.7,2.3-1.7,2.4-3c0.2-0.8,0.6-2,1-3.6
			c0.4-1.6,0.9-3.6,1.4-5.7c0.6-2.9,1.2-6.2,1.8-9.8c0.6-3.6,0.9-7.3,1-10.9c0-0.4,0-0.7,0-1c0-0.3,0-0.7,0-1c0-5.3-0.7-10.3-2.2-15
			c-1.5-4.7-4.4-8.1-8.6-10.1c-2.5-1.1-6.8-2-12.9-2.7C693,128.1,686.6,127.7,679.7,127.7L679.7,127.7z M652.7,173.1
			c0.2-0.8,0.5-1.5,0.7-2.3c2.1-6.7,4.8-12,7.9-15.8c3.2-3.8,8.7-5.7,16.5-5.7c7,0,11.7,1.3,14.2,4c2.5,2.7,3.7,6,3.7,9.9
			c0,1.5-0.1,3.1-0.4,4.8c-0.2,1.7-0.6,3.4-1.1,5.1H652.7L652.7,173.1z" />
                </g>
                <g>
                    <path
                        className="st1"
                        d="M836.8,160.4c1.4-5.9,2.1-9.8,2.1-13c0-7.5-3.9-13.9-10.3-17.3c-4.6-2.5-8.2-3-21.7-3H761l-2.6,11.5
			c-0.9,3.2-1.4,5.9-1.4,7.3c0,4.8,3.2,6.6,10.5,6.8h29.4c5.9,0,8,0.5,7.1,4.8l-0.9,3.8c0,0-0.6,4.1-4.1,4.1l-4.4,0h-33.8
			c-8.2,0.1-14.7,1.7-14.7,1.7c-3.1,0.9-5.5,2.5-7.2,4.7c-0.8,0.9-1.5,2-2.3,3.2c-0.8,1.3-1.5,2.6-2.3,4.2
			c-1.9,3.8-3.7,8.2-5.4,13.3c-1.7,5.1-3.1,10.4-4.2,15.8c-0.6,3-1.1,6-1.4,9c-0.3,3-0.5,5.8-0.5,8.4s0.2,5.2,0.6,7.6
			c0.4,2.4,1,4.5,1.9,6.2c1,2,2.3,3.7,3.9,5c1.6,1.3,3.4,2.1,5.2,2.9c1.1,0.5,2,0.8,3,1c1,0.2,1.9,0.3,2.8,0.3c0.3,0,0.5,0,0.7,0
			c0.2,0,0.3,0,0.5,0h24.7c5.8,0,10.4-7.4,10.4-7.4l10-15.1l-3.6,15.1c0,0-2.4,7.4,5.9,7.4h34.5l2.2,0l1.1-5.2
			c-4.3-0.5-6.8-3-6.8-6.6c0-1.6,0.2-3.2,1.1-6.8L836.8,160.4z M787.9,220.4H760c-8.1,0-5.9-11.5-5.9-11.5c0.2-1.3,0.5-2.5,0.8-3.8
			c0.5-1.5,1-3,1.6-4.4c0.6-1.4,1.1-2.8,1.6-4.1c0.6-1.1,1.2-2.2,1.8-3.1c0.6-0.9,1-1.7,1.4-2.2c1.4-1.6,3-2.7,4.8-3.1
			c1.8-0.4,3.8-0.7,5.9-0.7h23.6L787.9,220.4z" />
                    <path d="M806.9,128.7c13.3,0,16.6,0.4,20.9,2.8c5.9,3.2,9.4,9.1,9.4,15.9c0,2.8-0.6,6.4-2,12.6l-15.7,69.5
			c-0.9,3.6-1.2,5.4-1.2,7.2c0,4,2.5,6.9,6.5,7.9l-0.5,2.3l-0.9,0h-34.5c-2,0-3.3-0.5-4-1.4c-0.9-1.2-0.5-3.2-0.3-4l3.6-15.1l-3-1.3
			l-10,15.2c-1.2,1.8-5,6.6-9,6.6h-24.7H741h-0.7c-0.8,0-1.6-0.1-2.5-0.3c-0.8-0.2-1.7-0.5-2.7-1c-1.7-0.8-3.4-1.5-4.7-2.7
			c-1.4-1.2-2.6-2.7-3.5-4.5c-0.8-1.6-1.4-3.5-1.7-5.8c-0.4-2.3-0.5-4.8-0.5-7.3c0-2.6,0.2-5.4,0.5-8.2c0.3-2.9,0.8-5.8,1.4-8.8
			c1.1-5.3,2.5-10.6,4.2-15.6c1.7-5,3.4-9.4,5.3-13.1c0.7-1.4,1.5-2.8,2.2-4c0.7-1.2,1.4-2.2,2.2-3.1c1.4-2,3.5-3.3,6.2-4.1
			c0.1,0,6.4-1.6,14.3-1.7h33.8l4.4,0c3.8,0,5.4-3.6,5.7-5.3l0.9-3.8c0.3-1.4,0.5-3.4-0.7-4.8c-1.5-1.8-4.2-1.9-8-1.9h-29.4
			c-8.2-0.3-8.9-2.6-8.9-5.2c0-1.1,0.4-3.7,1.3-6.9l2.3-10.2H806.9 M760,222.1h27.9h1.3l0.3-1.3l7.7-32.8l0.5-2h-2.1H772
			c-2.3,0-4.4,0.2-6.3,0.7c-2.2,0.5-4.1,1.8-5.7,3.7c-0.4,0.6-0.9,1.3-1.5,2.3c-0.6,1-1.2,2-1.8,3.2l0,0.1l0,0.1
			c-0.5,1.3-1,2.6-1.6,4.1c-0.6,1.5-1.1,3-1.6,4.6c-0.4,1.3-0.7,2.7-0.9,3.9c-0.1,0.3-1.3,7.1,1.8,11
			C755.6,221.2,757.6,222.1,760,222.1 M806.9,127.1H761l-2.6,11.5c-0.9,3.2-1.4,5.9-1.4,7.3c0,4.8,3.2,6.6,10.5,6.8h29.4
			c5.9,0,8,0.5,7.1,4.8l-0.9,3.8c0,0-0.6,4.1-4.1,4.1l-4.4,0h-33.8c-8.2,0.1-14.7,1.7-14.7,1.7c-3.1,0.9-5.5,2.5-7.2,4.7
			c-0.8,0.9-1.5,2-2.3,3.2c-0.8,1.3-1.5,2.6-2.3,4.2c-1.9,3.8-3.7,8.2-5.4,13.3c-1.7,5.1-3.1,10.4-4.2,15.8c-0.6,3-1.1,6-1.4,9
			c-0.3,3-0.5,5.8-0.5,8.4s0.2,5.2,0.6,7.6c0.4,2.4,1,4.5,1.9,6.2c1,2,2.3,3.7,3.9,5c1.6,1.3,3.4,2.1,5.2,2.9c1.1,0.5,2,0.8,3,1
			c1,0.2,1.9,0.3,2.8,0.3c0.3,0,0.5,0,0.7,0c0.2,0,0.3,0,0.5,0h24.7c5.8,0,10.4-7.4,10.4-7.4l10-15.1l-3.6,15.1c0,0-2.4,7.4,5.9,7.4
			h34.5l2.2,0l1.1-5.2c-4.3-0.5-6.8-3-6.8-6.6c0-1.6,0.2-3.2,1.1-6.8l15.7-69.5c1.4-5.9,2.1-9.8,2.1-13c0-7.5-3.9-13.9-10.3-17.3
			C824,127.5,820.4,127.1,806.9,127.1L806.9,127.1z M760,220.4c-8.1,0-5.9-11.5-5.9-11.5c0.2-1.3,0.5-2.5,0.8-3.8
			c0.5-1.5,1-3,1.6-4.4c0.6-1.4,1.1-2.8,1.6-4.1c0.6-1.1,1.2-2.2,1.8-3.1c0.6-0.9,1-1.7,1.4-2.2c1.4-1.6,3-2.7,4.8-3.1
			c1.8-0.4,3.8-0.7,5.9-0.7h23.6l-7.7,32.8H760L760,220.4z" />
                </g>
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default IDClearLogoColourSVG;
