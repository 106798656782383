/* eslint-disable no-case-declarations */

import { Record } from 'immutable';
import { isEmpty, isNull, isUndefined } from 'underscore';
import { notification } from 'antd';
import { ExportToCsv } from 'export-to-csv';

const {
    GET_MILEAGE_REPORT_REQUEST,
    GET_MILEAGE_REPORT_SUCCESS,
    GET_MILEAGE_REPORT_FAILURE,

    GET_MPG_REPORT_REQUEST,
    GET_MPG_REPORT_SUCCESS,
    GET_MPG_REPORT_FAILURE,

    GET_DRIVING_TIME_REPORT_REQUEST,
    GET_DRIVING_TIME_REPORT_SUCCESS,
    GET_DRIVING_TIME_REPORT_FAILURE,

    GET_OUTSIDE_WORKING_HOURS_REPORT_REQUEST,
    GET_OUTSIDE_WORKING_HOURS_REPORT_SUCCESS,
    GET_OUTSIDE_WORKING_HOURS_REPORT_FAILURE,

    GET_NOT_MOVED_REPORT_REQUEST,
    GET_NOT_MOVED_REPORT_SUCCESS,
    GET_NOT_MOVED_REPORT_FAILURE,

    GET_IDLE_REPORT_REQUEST,
    GET_IDLE_REPORT_SUCCESS,
    GET_IDLE_REPORT_FAILURE,

    GET_PTO_REPORT_REQUEST,
    GET_PTO_REPORT_SUCCESS,
    GET_PTO_REPORT_FAILURE,

    GET_TRUE_IDLE_REPORT_REQUEST,
    GET_TRUE_IDLE_REPORT_SUCCESS,
    GET_TRUE_IDLE_REPORT_FAILURE,

    RESET_REPORTS_REQUEST,

    GET_SPEED_REPORT_REQUEST,
    GET_SPEED_REPORT_SUCCESS,
    GET_SPEED_REPORT_FAILURE,

    GET_CARBON_REPORT_REQUEST,
    GET_CARBON_REPORT_SUCCESS,
    GET_CARBON_REPORT_FAILURE,

    GET_SPEEDING_REPORT_REQUEST,
    GET_SPEEDING_REPORT_SUCCESS,
    GET_SPEEDING_REPORT_FAILURE,

    GET_ECO_SCORE_REPORT_REQUEST,
    GET_ECO_SCORE_REPORT_SUCCESS,
    GET_ECO_SCORE_REPORT_FAILURE,

    GET_UNAUTHORISED_JOURNEY_REPORT_REQUEST,
    GET_UNAUTHORISED_JOURNEY_REPORT_SUCCESS,
    GET_UNAUTHORISED_JOURNEY_REPORT_FAILURE,

    GET_TRUE_ODOMETER_REPORT_REQUEST,
    GET_TRUE_ODOMETER_REPORT_SUCCESS,
    GET_TRUE_ODOMETER_REPORT_FAILURE,

    GET_TIMESHEET_REPORT_REQUEST,
    GET_TIMESHEET_REPORT_SUCCESS,
    GET_TIMESHEET_REPORT_FAILURE,

    GET_TIMESHEET_REPORT_CSV_REQUEST,
    GET_TIMESHEET_REPORT_CSV_SUCCESS,
    GET_TIMESHEET_REPORT_CSV_FAILURE,

    GET_UTILISATION_REPORT_REQUEST,
    GET_UTILISATION_REPORT_SUCCESS,
    GET_UTILISATION_REPORT_FAILURE,

    GET_SPEEDING_REPORT_CSV_REQUEST,
    GET_SPEEDING_REPORT_CSV_SUCCESS,
    GET_SPEEDING_REPORT_CSV_FAILURE,

    GET_NEW_PTO_IDLE_REPORT_REQUEST,
    GET_NEW_PTO_IDLE_REPORT_SUCCESS,
    GET_NEW_PTO_IDLE_REPORT_FAILURE,

    GET_GEOFENCE_REPORT_REQUEST,
    GET_GEOFENCE_REPORT_SUCCESS,
    GET_GEOFENCE_REPORT_FAILURE,

    GET_EVENT_REPORT_REQUEST,
    GET_EVENT_REPORT_SUCCESS,
    GET_EVENT_REPORT_FAILURE,

    GET_GEOFENCE_REPORT_CSV_REQUEST,
    GET_GEOFENCE_REPORT_CSV_SUCCESS,
    GET_GEOFENCE_REPORT_CSV_FAILURE,

    GET_EVENT_REPORT_CSV_REQUEST,
    GET_EVENT_REPORT_CSV_SUCCESS,
    GET_EVENT_REPORT_CSV_FAILURE,

    GET_NEW_PTO_IDLE_REPORT_CSV_REQUEST,
    GET_NEW_PTO_IDLE_REPORT_CSV_SUCCESS,
    GET_NEW_PTO_IDLE_REPORT_CSV_FAILURE,

} = require('./reportActions').constants;

const {
    LOGOUT_REQUEST,
} = require('../user/userActions').constants;

const InitialState = Record({
    error: null,
    mileageReport: [],
    mpgReport: [],
    drivingTimeReport: [],
    outsideWorkingHoursReport: [],
    notMovedReport: [],
    idleReport: [],
    ptoReport: [],
    trueIdleReport: [],
    carbonReport: [],
    speedReport: [],
    speedingReport: [],
    speedingReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    speedingReportBack: {
        referrer: '',
        asset_id: [],
        date_from: null,
        date_to: null,
        driver: [],
        division: [],
        assetId: [],
        page: 1,
    },
    isExportingSpeedingReportCsv: false,
    ecoScoreReport: [],
    unauthorisedJourneyReport: [],
    unauthorisedJourneyPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    trueOdometerReport: [],
    timeSheetReport: [],
    timeSheetReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    isExportingTimesheetReportCsv: false,
    isFetching: false,
    utilisationReport: [],
    utilisationReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    newPtoIdleReport: [],
    newPtoIdleReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    geofenceReport: [],
    geofenceReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    isExportingGeofenceReportCsv: false,
    eventReport: [],
    eventReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
    isExportingNewPtoIdleReportCsv: false,
    isExportingEventReportCsv: false,

});

const reportInitialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function reportReducer(state = reportInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = reportInitialState.mergeDeep(state);
    switch (type) {
        case GET_MILEAGE_REPORT_REQUEST:
        case GET_MPG_REPORT_REQUEST:
        case GET_DRIVING_TIME_REPORT_REQUEST:
        case GET_IDLE_REPORT_REQUEST:
        case GET_PTO_REPORT_REQUEST:
        case GET_TRUE_IDLE_REPORT_REQUEST:
        case GET_SPEED_REPORT_REQUEST:
        case GET_SPEEDING_REPORT_REQUEST:
        case GET_UNAUTHORISED_JOURNEY_REPORT_REQUEST:
        case GET_CARBON_REPORT_REQUEST:
        case GET_ECO_SCORE_REPORT_REQUEST:
        case GET_TRUE_ODOMETER_REPORT_REQUEST:
        case GET_UTILISATION_REPORT_REQUEST:
        case GET_NEW_PTO_IDLE_REPORT_REQUEST:
        case GET_GEOFENCE_REPORT_REQUEST:
        case GET_EVENT_REPORT_REQUEST:
            return state.set('isFetching', true)
                .set('speedingReportBack', {
                    asset_id: payload.asset_id,
                    date_from: payload.date_from,
                    date_to: payload.date_to,
                    assetId: payload.assetId,
                    driver: payload.driver,
                    division: payload.division,
                    page: payload.page,
                    referrer: payload.referrer,
                })
                .set('error', null);
        case GET_TIMESHEET_REPORT_REQUEST:
            return state.set('isFetching', true).set('error', null);
        case GET_TIMESHEET_REPORT_CSV_REQUEST:
            return state.set('isExportingTimesheetReportCsv', true);
        case GET_SPEEDING_REPORT_CSV_REQUEST:
            return state.set('isExportingSpeedingReportCsv', true);
        case GET_NEW_PTO_IDLE_REPORT_CSV_REQUEST:
            return state.set('isExportingNewPtoIdleReportCsv', true);
        case GET_GEOFENCE_REPORT_CSV_REQUEST:
            return state.set('isExportingGeofenceReportCsv', true);
        case GET_EVENT_REPORT_CSV_REQUEST:
            return state.set('isExportingEventReportCsv', true);

    case LOGOUT_REQUEST:
    case RESET_REPORTS_REQUEST:
        return state
            .set('trueOdometerReport', [])
            .set('mileageReport', [])
            .set('mpgReport', [])
            .set('drivingTimeReport', [])
            .set('idleReport', [])
            .set('ptoReport', [])
            .set('trueIdleReport', [])
            .set('speedReport', [])
            .set('speedingReport', [])
            .set('unauthorisedJourneyReport', [])
            .set('timeSheetReport', [])
            .set('utilisationReport', [])
            .set('geofenceReport', [])
            .set('eventReport', [])
            .set('isExportingEventReportCsv', false)
            .set('outsideWorkingHoursReport', [])
            .set('notMovedReport', [])
            .set('eventReportPagination', {
                currentPage: 1,
                totalRecords: 0,
                pageCount: 0,
                perPageCount: 10,
            })
            .set('speedingReportPagination', {
                currentPage: 1,
                totalRecords: 0,
                pageCount: 0,
                perPageCount: 10,
            })
            .set('geofenceReportPagination', {
                currentPage: 1,
                totalRecords: 0,
                pageCount: 0,
                perPageCount: 10,
            })
            .set('newPtoIdleReport', [])
            .set('newPtoIdleReportPagination', {
                currentPage: 1,
                totalRecords: 0,
                pageCount: 0,
                perPageCount: 20,
            })
            .set('isExportingNewPtoIdleReportCsv', false);

    case GET_MILEAGE_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('mileageReport', payload.data);

    case GET_MPG_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('mpgReport', payload.data);

    case GET_DRIVING_TIME_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('drivingTimeReport', payload.data);

    case GET_IDLE_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('idleReport', payload.data);

    case GET_PTO_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('ptoReport', payload.data);

    case GET_TRUE_IDLE_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('trueIdleReport', payload.data);

    case GET_SPEED_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('speedReport', payload.data);

    case GET_CARBON_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('carbonReport', payload.data);

    case GET_UNAUTHORISED_JOURNEY_REPORT_SUCCESS:
        const unauthorisedJourneyHeaders = payload.headers;
        const unauthorisedJourneyPagination = {};
        unauthorisedJourneyPagination.currentPage = unauthorisedJourneyHeaders['x-pagination-current-page'] || 1;
        unauthorisedJourneyPagination.totalRecords = unauthorisedJourneyHeaders['x-pagination-total-count'] || 0;
        unauthorisedJourneyPagination.pageCount = unauthorisedJourneyHeaders['x-pagination-page-count'] || 0;
        unauthorisedJourneyPagination.perPageCount = unauthorisedJourneyHeaders['x-pagination-per-page'] || 20;

        return state.set('isFetching', false)
            .set('unauthorisedJourneyPagination', unauthorisedJourneyPagination)
            .set('unauthorisedJourneyReport', payload.data);

    case GET_SPEEDING_REPORT_SUCCESS: {
        if (!isNull(payload.config.params.export) && !isUndefined(payload.config.params.export)) {
            if (isEmpty(payload.data)) {
                notification.warning({ message: 'Warning', description: 'There is no data to export!' });
            } else {
                const options = {
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    showLabels: true,
                    showTitle: true,
                    title: 'Speeding Report (Detailed)',
                    useTextFile: false,
                    useBom: false,
                    useKeysAsHeaders: true,
                    filename: 'Speeding Report',
                    headers: ['Vehicle', 'Group', 'Driver Name', 'Date', 'Speeding Start', 'Speeding End', 'Max Speed Time', 'Max Speed (mph)', 'Speed Limit (mph)', 'Road Speed Limit', '% Above Limit', 'Duration', 'Distance (mi)', 'Avg (mph)', 'Max Speed Location', 'Start Location', 'Stop Location'], // Won't work with useKeysAsHeaders present!
                };

                const csvExporter = new ExportToCsv(options);
                csvExporter.generateCsv(payload.data);
            }
        }

        const { speedingReportPagination } = state;
        if (payload.headers) {
            speedingReportPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
            speedingReportPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
            speedingReportPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
            speedingReportPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        }

        return state.set('speedingReport', payload.data).set('isFetching', false).set('speedingReportingPagination', speedingReportPagination);
    }

    case GET_ECO_SCORE_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('ecoScoreReport', payload.data);

    case GET_TRUE_ODOMETER_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('trueOdometerReport', payload.data);

    case GET_TIMESHEET_REPORT_SUCCESS:
        const timeSheetHeaders = payload.headers;
        const timeSheetReportPagination = {};
        timeSheetReportPagination.currentPage = parseInt(timeSheetHeaders['x-pagination-current-page']) || 1;
        timeSheetReportPagination.totalRecords = parseInt(timeSheetHeaders['x-pagination-total-count']) || 0;
        timeSheetReportPagination.pageCount = parseInt(timeSheetHeaders['x-pagination-page-count']) || 0;
        timeSheetReportPagination.perPageCount = parseInt(timeSheetHeaders['x-pagination-per-page']) || 20;
        return state.set('timeSheetReport', payload.data)
            .set('timeSheetReportPagination', timeSheetReportPagination)
            .set('isFetching', false);

    case GET_TIMESHEET_REPORT_CSV_SUCCESS:
        return state.set('isExportingTimesheetReportCsv', false);

    case GET_SPEEDING_REPORT_CSV_SUCCESS:
        return state.set('isExportingSpeedingReportCsv', false);

    case GET_UTILISATION_REPORT_SUCCESS:
        const utilisationReportHeaders = payload.headers;
        const utilisationReportPagination = {};
        utilisationReportPagination.currentPage = utilisationReportHeaders['x-pagination-current-page'] || 1;
        utilisationReportPagination.totalRecords = utilisationReportHeaders['x-pagination-total-count'] || 0;
        utilisationReportPagination.pageCount = utilisationReportHeaders['x-pagination-page-count'] || 0;
        utilisationReportPagination.perPageCount = utilisationReportHeaders['x-pagination-per-page'] || 20;

        return state.set('isFetching', false)
            .set('utilisationReportPagination', utilisationReportPagination)
            .set('utilisationReport', payload.data);

    case GET_NEW_PTO_IDLE_REPORT_SUCCESS:
        const newPtoIdleReportHeaders = payload.headers;
        const newPtoIdleReportPagination = {};
        newPtoIdleReportPagination.currentPage = newPtoIdleReportHeaders['x-pagination-current-page'] || 1;
        newPtoIdleReportPagination.totalRecords = newPtoIdleReportHeaders['x-pagination-total-count'] || 0;
        newPtoIdleReportPagination.pageCount = newPtoIdleReportHeaders['x-pagination-page-count'] || 0;
        newPtoIdleReportPagination.perPageCount = newPtoIdleReportHeaders['x-pagination-per-page'] || 20;
        return state.set('isFetching', false)
            .set('newPtoIdleReport', payload.data)
            .set('newPtoIdleReportPagination', newPtoIdleReportPagination);

    case GET_GEOFENCE_REPORT_SUCCESS:
        const geofenceReportHeaders = payload.headers;
        const geofenceReportPagination = {};
        geofenceReportPagination.currentPage = parseInt(geofenceReportHeaders['x-pagination-current-page']) || 1;
        geofenceReportPagination.totalRecords = parseInt(geofenceReportHeaders['x-pagination-total-count']) || 0;
        geofenceReportPagination.pageCount = parseInt(geofenceReportHeaders['x-pagination-page-count']) || 0;
        geofenceReportPagination.perPageCount = parseInt(geofenceReportHeaders['x-pagination-per-page']) || 20;
        return state.set('geofenceReport', payload.data)
            .set('geofenceReportPagination', geofenceReportPagination)
            .set('isFetching', false);

    case GET_GEOFENCE_REPORT_CSV_SUCCESS:
        return state.set('isExportingGeofenceReportCsv', false);

    case GET_EVENT_REPORT_SUCCESS:
        const eventReportHeaders = payload.headers;
        const eventReportPagination = {};
        eventReportPagination.currentPage = eventReportHeaders['x-pagination-current-page'] || 1;
        eventReportPagination.totalRecords = eventReportHeaders['x-pagination-total-count'] || 0;
        eventReportPagination.pageCount = eventReportHeaders['x-pagination-page-count'] || 0;
        eventReportPagination.perPageCount = eventReportHeaders['x-pagination-per-page'] || 20;
        return state.set('isFetching', false)
            .set('eventReportPagination', eventReportPagination)
            .set('eventReport', payload.data);

    case GET_NEW_PTO_IDLE_REPORT_CSV_SUCCESS:
        return state.set('isExportingNewPtoIdleReportCsv', false);
    case GET_EVENT_REPORT_CSV_SUCCESS:
        return state.set('isExportingEventReportCsv', false);

    case GET_MILEAGE_REPORT_FAILURE:
    case GET_MPG_REPORT_FAILURE:
    case GET_DRIVING_TIME_REPORT_FAILURE:
    case GET_IDLE_REPORT_FAILURE:
    case GET_PTO_REPORT_FAILURE:
    case GET_TRUE_IDLE_REPORT_FAILURE:
    case GET_SPEED_REPORT_FAILURE:
    case GET_SPEEDING_REPORT_FAILURE:
    case GET_UNAUTHORISED_JOURNEY_REPORT_FAILURE:
    case GET_CARBON_REPORT_FAILURE:
    case GET_ECO_SCORE_REPORT_FAILURE:
    case GET_TRUE_ODOMETER_REPORT_FAILURE:
    case GET_TIMESHEET_REPORT_FAILURE:
    case GET_UTILISATION_REPORT_FAILURE:
    case GET_NEW_PTO_IDLE_REPORT_FAILURE:
    case GET_GEOFENCE_REPORT_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    case GET_TIMESHEET_REPORT_CSV_FAILURE:
        return state.set('isExportingTimesheetReportCsv', false)
            .set('error', payload);
    case GET_EVENT_REPORT_CSV_FAILURE:
        return state.set('isExportingEventReportCsv', false)
            .set('error', payload);
    case GET_SPEEDING_REPORT_CSV_FAILURE:
        return state.set('isExportingSpeedingReportCsv', false)
            .set('error', payload);
    case GET_NEW_PTO_IDLE_REPORT_CSV_FAILURE:
        return state.set('isExportingNewPtoIdleReportCsv', false)
            .set('error', payload);
    case GET_GEOFENCE_REPORT_CSV_FAILURE:
        return state.set('isExportingGeofenceReportCsv', false)
            .set('error', payload);
    case GET_OUTSIDE_WORKING_HOURS_REPORT_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case GET_OUTSIDE_WORKING_HOURS_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('outsideWorkingHoursReport', payload.data);
    case GET_NOT_MOVED_REPORT_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case GET_NOT_MOVED_REPORT_SUCCESS:
        return state.set('isFetching', false)
            .set('notMovedReport', payload.data);
    case GET_NOT_MOVED_REPORT_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    case GET_OUTSIDE_WORKING_HOURS_REPORT_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    default:
        return state;
    }
}

export {
    reportReducer,
    reportInitialState,
};
