/* global window */
import React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { Select, Button } from 'antd';
import { FlagFilled, LocationFilled, NotificationFilled, PlugFilled } from '@carbon/icons-react';

const renderIconComponent = (icon: string) => {
    switch (icon) {
        case 'alert':
        case 'bell':
            return (
                <NotificationFilled 
                    size={16} 
                    style={{ 
                        color: 'var(--red)',
                        verticalAlign: 'middle',
                    }} 
                />
            );
        case 'api':
            return (
                <PlugFilled 
                    size={16} 
                    style={{ 
                        color: 'var(--red)',
                        verticalAlign: 'middle',
                    }}
                />
            );
        case 'environment':
            return (
                <LocationFilled 
                    size={16} 
                    style={{ 
                        color: 'var(--red)',
                        verticalAlign: 'middle',
                    }}
                />
            );
        case 'flag':
            return (
                <FlagFilled 
                    size={16} 
                    style={{ 
                        color: 'var(--red)',
                        verticalAlign: 'middle',
                    }}
                />
            );
        default:
            return (
                <NotificationFilled 
                    size={16} 
                    style={{ 
                        color: 'var(--red)',
                        verticalAlign: 'middle',
                    }} 
                />
            );
    }
}

interface AlertsFilterFormProps {
    onSubmit: (values: any) => void;
    iconOptions?: null | string[];
    form: null | any;
}

const AlertsFilterForm:React.FC<AlertsFilterFormProps> = ({
    onSubmit = () => {},
    iconOptions = null,
    form = null,
}) => {
    const { getFieldDecorator } = form;

    return (
        <Form 
            onSubmit={(e: React.FormEvent) => {
                e.preventDefault();
                form.validateFields((err, values) => {
                    if (!err) {
                        onSubmit(values);
                    }
                });
            }}
            layout="vertical"
        >
            <Form.Item label="Enabled">
                {getFieldDecorator('enabled', { initialValue: -1 })(
                    <Select placeholder="Select Enabled">
                        <Select.Option value={-1}>Both</Select.Option>
                        <Select.Option value={1}>Enabled</Select.Option>
                        <Select.Option value={0}>Disabled</Select.Option>
                    </Select>
                )}
            </Form.Item>
            {iconOptions && (
                <Form.Item label="Icon">
                    {getFieldDecorator('icon', { initialValue: "all" })(
                        <Select placeholder="Select Icon">
                            <Select.Option value="all">All Icons</Select.Option>
                            {iconOptions.map((iconOption, i) => {
                                return (
                                    <Select.Option 
                                        key={`iconOption-${i}`}
                                        value={iconOption}
                                    >
                                        {renderIconComponent(iconOption)}
                                        &ensp;
                                        <span style={{ 
                                            display: 'inline-block',
                                            textTransform: 'capitalize',
                                        }}>
                                            {iconOption}
                                        </span>
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    )}
                </Form.Item>
            )}
            <div className="mt-2 mb-1 text-center">
                <Button
                    htmlType="submit"
                    type="primary"
                >
                    Filter
                </Button>
            </div>
        </Form>
    );
}

const AlertsFilter = Form.create<AlertsFilterFormProps>({ name: 'event_filter_form' })(AlertsFilterForm);

export default AlertsFilter;
