/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import { Spin, Row, Col } from 'antd';
import moment from 'moment';
import Map from '../../components/Map';
import { isEmpty, isNull } from 'underscore';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import './journeyView.scss';
import { requestMarkerDetails, setMarkerPopoverToLoading } from '../../core/utils/mapUtils';

const { GOOGLE_MAP_API_KEY, DEBUG } = require('../../core/constants').default;

export default function () {
    const {
        selectedJourney, match, actions, isFetching, journeyBackLink, eventJourneyFetched, user,
    } = this.props;

    const { sliderValue, centerMap, markerPopover } = this.state;
    const { journeyId } = match.params;
    const assetTitle = `${selectedJourney && selectedJourney.info && (selectedJourney.info.registration || selectedJourney.info.name)}`;
    const mappedDevicePath = this.mapDevicePath();
    const driverImage = (selectedJourney && selectedJourney.info && selectedJourney.info.profile_picture) || user?.userCompany?.company_image;
    const assetImage = (selectedJourney && selectedJourney.info && selectedJourney.info.asset_picture) || user?.userCompany?.company_image;
    let backLink = selectedJourney && selectedJourney.info && selectedJourney.info.asset_id ? `/assets/view/${selectedJourney.info.asset_id}` : '/assets';
    let backLinkText = `Asset: ${assetTitle}`;
    const startArea = (selectedJourney && selectedJourney.info && selectedJourney.info.start_area_address) || null;
    const endArea = (selectedJourney && selectedJourney.info && selectedJourney.info.end_area_address) || null;
    const journeyStart = selectedJourney && selectedJourney.info && selectedJourney.info.start ? moment(selectedJourney.info.start, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '[not-set]';
    const journeyEnd = selectedJourney && selectedJourney.info && selectedJourney.info.end ? moment(selectedJourney.info.end, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '[not-set]';
    if (startArea) {
        const tempStart = startArea.split(',');
        // startArea = `${getAddressFromLatLong(tempStart[0], tempStart[1])} (${startArea})`;
    }
    if (endArea) {
        const tempEnd = endArea.split(',');
        // endArea = `${getAddressFromLatLong(tempEnd[0], tempEnd[1])} (${endArea})`;
    }
    if (!isEmpty(journeyBackLink)) {
        if (journeyBackLink.link) {
            backLink = journeyBackLink.link;
        }
        if (journeyBackLink.text) {
            backLinkText = journeyBackLink.text;
        }
    }
    const { updateSliderValue } = this;
    const mapToolBarButtons = [
        { key: 'side-bar', visible: false },
        { key: 'info-box', visible: true },
        { key: 'traffic', visible: false },
        { key: 'geo-fence', visible: false },
        { key: 'center-map', visible: false },
        { key: 'satellite', visible: true },
        { key: 'heat-map', visible: false },
        { key: 'live-map', visible: false },
        { key: 'video-search', visible: false },
    ];

    const assets = this.getAssetCurrentPin(mappedDevicePath);

    let idleTime = 0;
    let trueIdleTime = 0;
    let score = 0;
    let timeDrivenFormatted = 0;
    let averageSpeed = 0;
    let averageMpg = 0;
    // let fuelLevel = 0;
    let startInterval = 0;
    let endInterval = 0;
    let driverAverageScore = 0;

    if (!isEmpty(selectedJourney)) {
        idleTime = selectedJourney.info.idle_time;
        trueIdleTime = selectedJourney.info.true_idle_time;
        score = selectedJourney.info.score;
        timeDrivenFormatted = selectedJourney.info.time_driven_formatted;
        averageSpeed = selectedJourney.info.average_speed;
        averageMpg = selectedJourney.info.mpg;
        // fuelLevel = selectedJourney.info.fuel_level;
        startInterval = selectedJourney.startInterval;
        endInterval = selectedJourney.endInterval;
        driverAverageScore = selectedJourney.driverAverageScore;
    }

    const chartData = [
        {
            name: 'Idle Time', pv: idleTime || 0,
        },
        {
            name: 'True Idle', pv: trueIdleTime || 0,
        },
    ];

    let profile_picture = '';
    if (selectedJourney && selectedJourney.info) {
        profile_picture = (
            <img
                className="driver-info-image"
                alt={selectedJourney.info.driver || '[not-set]'}
                src={selectedJourney.info.profile_picture || user?.userCompany?.company_image} />
        );
    }

    let asset_picture = '';
    if (selectedJourney && selectedJourney.info) {
        asset_picture = (
            <img
                className="driver-info-image"
                alt={selectedJourney.info.registration || '[not-set]'}
                src={selectedJourney.info.asset_picture || user?.userCompany?.company_image} />
        );
    }

    return (
        <Layout
            data-test="pages-journey-view"
            className="events-view"
            title={<div>Journey View</div>}
            headerTitle={<div>Journey View</div>}
            showFooter={false}>
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar />
                <div className="content">
                    <Spin spinning={isFetching || !selectedJourney || isNull(selectedJourney)}>
                        <div className="content-inner">
                            <section className="section">
                                <div className="section__body">
                                    <Row
                                        gutter={40}
                                        type="flex"
                                        justify="space-between"
                                        className="data-row map-row">
                                        <Col xl={24} style={{ height: 600, width: '100%' }}>
                                            <Map
                                                ref={this.googleMapAccess}
                                                // onChange={this.onMapChange}
                                                markerOnClick={this.markerOnClick}
                                                // onDateChange={this.mapOnDateChange}
                                                // zoom={8}
                                                markerOnMouseEnter={(marker) => { setMarkerPopoverToLoading(this); requestMarkerDetails(marker, this); }}
                                                markerPopover={markerPopover}
                                                mapDate={moment().subtract('1', 'days').format('DD/MM/YYYY')}
                                                apiKey={GOOGLE_MAP_API_KEY}
                                                mapToolBarButtons={mapToolBarButtons}
                                                sliderStep={1}
                                                sliderValue={parseInt(sliderValue, 10)}
                                                sliderMinValue={startInterval}
                                                sliderMaxValue={endInterval}
                                                sliderValueUnit="seconds"
                                                hideDatePicker
                                                center={centerMap}
                                                onSliderChange={this.updateSliderValue}
                                                // heatMap={heatMap}
                                                assets={assets}
                                                // showToolbar={false}
                                                allowStreetView
                                                showFindCenterButton={false}
                                                path={mappedDevicePath} />
                                        </Col>
                                    </Row>
                                    <br />
                                    <br />
                                    <br />
                                    <Row 
                                        gutter={[24, 24]} 
                                        className="data-row event-view-driver-info" 
                                        ref={this.pdfDocumentRef}
                                    >
                                        <Col 
                                            xs={12} 
                                            style={{ verticalAlign: 'top' }}
                                        >
                                            <div className="video-view-block-title">Driver & Asset Information</div>
                                            <div className="video-detail-view">
                                                <Row 
                                                    gutter={[24, 24]} 
                                                    style={{ minHeight: 120 }}
                                                >
                                                    <Col xs={12} sm={4} md={12} lg={10}>
                                                        {profile_picture}
                                                        <p> 
                                                            {selectedJourney && selectedJourney.info && selectedJourney.info.user_id ? (
                                                                <Link
                                                                    to={`/drivers/view/${(selectedJourney && selectedJourney.info && selectedJourney.info.user_id) || null}`}>{(selectedJourney.info && selectedJourney.info.driver) || '[not-set]'}
                                                                </Link>
                                                            ) : (
                                                                selectedJourney && selectedJourney.info && selectedJourney.info.driver
                                                            ) || ''}
                                                        </p>
                                                    </Col>
                                                    <Col xs={12} sm={4} md={12} lg={14}>
                                                        <div className="title">
                                                            {asset_picture}
                                                            <p>
                                                                {selectedJourney && selectedJourney.info && selectedJourney.info.asset_id ? (
                                                                    <Link
                                                                        to={`/assets/view/${(selectedJourney && selectedJourney.info && selectedJourney.info.asset_id) || null}`}>{(selectedJourney.info && selectedJourney.info.registration) || '[not-set]'}
                                                                    </Link>
                                                                ) : (selectedJourney && selectedJourney.info && selectedJourney.info.registration) || ''}
                                                            </p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col 
                                            xs={12} 
                                            style={{ verticalAlign: 'top' }}
                                        >
                                            <div className="data-row-header">Journey Info</div>
                                            <Row className="journey-info-row">
                                                <Col xs={5}><strong>Start Date</strong></Col>
                                                <Col xs={19}>{journeyStart}</Col>
                                            </Row>
                                            <Row className="journey-info-row">
                                                <Col xs={5}><strong>Start Area</strong></Col>
                                                <Col xs={19} style={{ wordBreak: 'break-all' }}>{startArea}</Col>
                                            </Row>
                                            <Row className="journey-info-row">
                                                <Col xs={5}><strong>End Date</strong></Col>
                                                <Col xs={19}>{journeyEnd}</Col>
                                            </Row>
                                            <Row className="journey-info-row">
                                                <Col xs={5}><strong>End Area</strong></Col>
                                                <Col xs={19} style={{ wordBreak: 'break-all' }}>{endArea}</Col>
                                            </Row>
                                            <Row className="journey-info-row">
                                                <Col xs={5}><strong>Journey Score</strong></Col>
                                                <Col xs={19}>{selectedJourney && selectedJourney.info && selectedJourney.info.score}</Col>
                                            </Row>
                                            <Row className="journey-info-row">
                                                <Col xs={5}><strong>Events</strong></Col>
                                                <Col xs={19}>{selectedJourney && selectedJourney.info && selectedJourney.info.events}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </section>
                        </div>
                    </Spin>
                </div>
            </div>
        </Layout>
    );
}
