// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import {
    Switch, Route, Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import lockr from 'lockr';
import { notification } from 'antd';
import { isUndefined } from 'underscore';
import NotFound from '../../pages/NotFound';
import * as globalActions from '../global/globalActions';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES,
} = require('../constants').default;

const onlyAuthorisedAllowed = () => {
    const response = lockr.get(AUTH_KEY);
    if (!isUndefined(response)) {
        const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);
        if (!isUndefined(authDate)) {
            const aDate = new Date(authDate);
            const aNow = new Date();
            const milliseconds = aNow - aDate;
            const difference = Math.floor(milliseconds / 1000 / 60);
            if (difference >= LOCALSTORAGE_EXPIRES) {
                notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
                return false;
            }
        } else {
            notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
            return false;
        }
        return true;
    }
    return false;
};

function DefaultRoute({ exact, path, component }) {
    return <Route exact={exact || false} path={path || '/'} component={component || null} />;
}
function AuthRoute({
    exact, path, component, role = '', allowedOnlyRoles = [], requiredFeaturePermission = '', company,
}) {
    if (requiredFeaturePermission) {
        const enabledFeatureIds = (company?.features || []).map((f) => f.id);
        if (!enabledFeatureIds.includes(requiredFeaturePermission)) {
            notification.error({ message: 'Error', description: 'Your company does not have this feature enabled' });
            return <Redirect to="/no-access" />;
        }
    }
    if (allowedOnlyRoles.length > 0 && !allowedOnlyRoles.includes(role)) {
        notification.error({ message: 'Error', description: 'You are trying to access a page which either doesn\'t exist or you don\'t have sufficient permissions to access.' });
        // @todo Generates error because transitioning in the middle of component life cycle. Need to figure a clean way
        return <Redirect to="/map" />;
    } if (onlyAuthorisedAllowed()) {
        return <Route exact={exact || false} path={path || '/'} component={component} />;
    }
    return <Redirect to="/" />;
}

// eslint-disable-next-line react/prefer-stateless-function
class CoreRouterBlock extends Component {
    render() {
        const { props } = this;
        // eslint-disable-next-line react/jsx-props-no-spreading
        return (
            <Switch>
                {this.props.children}
                <Route path="*" component={NotFound} />
            </Switch>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        breadcrumb: state.global.breadcrumb,
        role: state.user.role,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
        }, dispatch),
    };
}

const CoreRouter = connect(mapStateToProps, mapDispatchToProps)(CoreRouterBlock);

export {
    CoreRouter,
    onlyAuthorisedAllowed,
    DefaultRoute,
    AuthRoute,
};
