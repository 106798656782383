import React from 'react';

function ISSInstallationAndServiceQualityAssuranceSVG() {
    return (
        <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
            <path
                stroke="null"
                d="M24.366 4.417a19.26 19.26 0 00-10.612 3.145L26.33 20.139a4.127 4.127 0 01.393 5.896 4.127 4.127 0 01-5.895-.393L7.858 13.064a19.338 19.338 0 00-3.341 11.202 19.928 19.928 0 0019.849 19.85 21.421 21.421 0 005.11-.59l13.167 13.167A9.826 9.826 0 0056.596 42.74L43.43 29.573a21.421 21.421 0 00.59-5.11A19.653 19.653 0 0024.366 4.417zm15.722 19.85a14.956 14.956 0 01-.59 4.126l-.59 2.162 1.573 1.572 13.167 13.168a5.66 5.66 0 011.769 4.127 5.345 5.345 0 01-1.769 4.127 5.7 5.7 0 01-8.254 0L32.227 40.38l-1.572-1.572-2.162.59a14.956 14.956 0 01-4.127.59 16.253 16.253 0 01-11.202-4.52 14.995 14.995 0 01-4.717-11.203 16.37 16.37 0 01.59-4.323l8.647 8.647a8.136 8.136 0 0011.595.393 8.136 8.136 0 00-.393-11.595L20.24 8.74a12.676 12.676 0 013.93-.59 16.253 16.253 0 0111.202 4.52 16.685 16.685 0 014.717 11.595z" />
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default ISSInstallationAndServiceQualityAssuranceSVG;
