import {
    format,
    differenceInMilliseconds,
    isBefore,
    isAfter,
    addMinutes,
} from 'date-fns';

const getTimelineConfig = (timelineStart, timelineLength) => (date) => {
    const percent = (differenceInMilliseconds(date, timelineStart) / timelineLength) * 100;
    const value = getTimestamp(date);
    return { percent, value };
};

export const getFormattedBlockedIntervals = (blockedDates = [], [startTime, endTime]) => {
    if (!blockedDates.length) return null;

    const timelineLength = differenceInMilliseconds(endTime, startTime);
    const getConfig = getTimelineConfig(startTime, timelineLength);

    const formattedBlockedDates = blockedDates.map((interval, index) => {
        let { start, end } = interval;

        if (isBefore(start, startTime)) start = startTime;
        if (isAfter(end, endTime)) end = endTime;

        const source = getConfig(start);
        const target = getConfig(end);

        return { id: `blocked-track-${index}`, source, target };
    });

    return formattedBlockedDates;
};

export const getNowConfig = ([startTime, endTime]) => {
    const timelineLength = differenceInMilliseconds(endTime, startTime);
    const getConfig = getTimelineConfig(startTime, timelineLength);

    const source = getConfig(new Date());
    const target = getConfig(addMinutes(new Date(), 1));

    return { id: 'now-track', source, target };
};

export const getTimestamp = (date) => Number(format(date, 'T'));
