/* eslint-disable no-case-declarations */

import { Record } from 'immutable';

const {
    GET_RECORD_INFO_REQUEST,
    GET_RECORD_INFO_SUCCESS,
    GET_RECORD_INFO_FAILURE,

    RESET_RECORDS_REQUEST,

} = require('./recordsActions').constants;

const {
    LOGOUT_REQUEST,
} = require('../user/userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    selectedRecord: null,
    recordInfoFetched: false,
});

const recordsInitialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function recordsReducer(state = recordsInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = recordsInitialState.mergeDeep(state);
    switch (type) {
    case LOGOUT_REQUEST:
    case RESET_RECORDS_REQUEST:
        return state.set('error', null)
            .set('isFetching', false)
            .set('selectedRecord', null);

        // REQUEST Triggers.
    case GET_RECORD_INFO_REQUEST:
        return state.set('isFetching', true)
            .set('recordInfoFetched', false)
        // .set('selectedRecord', null)
            .set('error', null);
        // END OF REQUEST Triggers.

        // SUCCESSS Triggers.
    case GET_RECORD_INFO_SUCCESS:
        return state.set('isFetching', false)
            .set('recordInfoFetched', true)
            .set('selectedRecord', payload.data);
        // END OF SUCCESSS Triggers.

        // FAILURE Triggers.
    case GET_RECORD_INFO_FAILURE:
        return state.set('isFetching', false)
            .set('recordInfoFetched', false)
            .set('error', payload);
        // END OF FAILURE Triggers.

    default:
        return state;
    }
}

export {
    recordsReducer,
    recordsInitialState,
};
