import React from 'react';
import { Table, TablePaginationConfig } from 'antd';
import DeviceTimelineHeader from '../../../DeviceTimelineHeader';
import { ColumnsType } from 'antd/es/table';

interface TableViewProps {
    dataSource: any[];
    columns: {
        dataIndex: string;
        exportable: boolean;
        key: string;
        sortDirections: string[];
        sorter: boolean;
        title: string | (() => React.ReactNode);
    }[];
    pagination?: null | false | TablePaginationConfig;
    onChange?: null | ((pagination: any, filters: any, sorter: any, extra: any) => void); // TODO: not sure how to import proper types? see exports in iss-frontend/node_modules/antd/es/table/interface.d.ts
    id?: null | string;
    expandedRowRender?: null | ((record: any, index: number, indent: number, expanded: boolean) => React.ReactNode);
    scroll?: null | { x: string; y: string };
}

const TableView: React.FC<TableViewProps> = ({
    dataSource = [],
    columns = [],
    pagination = null,
    onChange = null,
    id = null,
    expandedRowRender = null,
    scroll = null,
}) => {
    const keyedData = dataSource.map((dataItem, dataItemIndex) => {
        dataItem.key = `scorch-data-row-${dataItemIndex}`;
        return dataItem;
    });
    let actionsIndex = -1;
    let columnsWithTimelineHeader = columns.map((column, i) => {
        if (column.title === 'Timeline') {
            return {
                dataIndex: 'timeline',
                exportable: false,
                key: 'timeline',
                sortDirections: ['descend', 'ascend'],
                sorter: false,
                title: () => (<DeviceTimelineHeader reportLength={keyedData.length} />),
            };
        } else {
            if (column.dataIndex === 'actions') {
                actionsIndex = i;
            }
            return column;
        }
    });
    if (actionsIndex > -1) {
        const actionsCol = columnsWithTimelineHeader[actionsIndex];
        columnsWithTimelineHeader.splice(actionsIndex, 1);
        columnsWithTimelineHeader.unshift(actionsCol);
    }
    columnsWithTimelineHeader = columnsWithTimelineHeader.filter((column) => column.dataIndex !== 'id');
    let actualScroll = scroll;
    if (!actualScroll) {
        actualScroll = {
            x: 'max-content',
            y: pagination ? 'calc(100vh - 320px)' : 'calc(100vh - 280px)',
        };
    }

    return (
        <Table
            id={String(id)}
            onChange={onChange}
            pagination={pagination}
            dataSource={keyedData}
            columns={columnsWithTimelineHeader as ColumnsType<any>}
            expandedRowRender={expandedRowRender}
            scroll={actualScroll}
        />
    );
}

export default TableView;
