/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

function ForwardIcon({ color, width = 15, height = 16 }) {
    return (
        <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.14317 6.21483C7.07867 6.28249 7.0275 6.36286 6.99258 6.45133C6.95767 6.5398 6.9397 6.63464 6.9397 6.73042C6.9397 6.8262 6.95767 6.92104 6.99258 7.00951C7.0275 7.09798 7.07867 7.17834 7.14317 7.246L9.84272 10.0835L7.14317 12.9211C7.01308 13.0578 6.93999 13.2433 6.93999 13.4366C6.93999 13.63 7.01308 13.8155 7.14317 13.9522C7.27326 14.089 7.4497 14.1658 7.63368 14.1658C7.81766 14.1658 7.9941 14.089 8.12419 13.9522L11.3177 10.5955C11.3822 10.5278 11.4334 10.4474 11.4683 10.359C11.5032 10.2705 11.5212 10.1757 11.5212 10.0799C11.5212 9.98409 11.5032 9.88925 11.4683 9.80078C11.4334 9.71231 11.3822 9.63195 11.3177 9.56429L8.12419 6.20752C7.8598 5.92962 7.41452 5.92962 7.14317 6.21483Z" fill={color || '#FF6900'} />
            <path d="M10.622 6.21483C10.5575 6.28249 10.5063 6.36286 10.4714 6.45133C10.4365 6.5398 10.4185 6.63464 10.4185 6.73042C10.4185 6.8262 10.4365 6.92104 10.4714 7.00951C10.5063 7.09798 10.5575 7.17834 10.622 7.246L13.3215 10.0835L10.622 12.9211C10.4919 13.0578 10.4188 13.2433 10.4188 13.4366C10.4188 13.63 10.4919 13.8155 10.622 13.9522C10.7521 14.089 10.9285 14.1658 11.1125 14.1658C11.2965 14.1658 11.4729 14.089 11.603 13.9522L14.7965 10.5955C14.861 10.5278 14.9122 10.4474 14.9471 10.359C14.982 10.2705 15 10.1757 15 10.0799C15 9.98409 14.982 9.88925 14.9471 9.80078C14.9122 9.71231 14.861 9.63195 14.7965 9.56429L11.603 6.20752C11.3386 5.92962 10.8933 5.92962 10.622 6.21483Z" fill={color || '#FF6900'} />
            <rect x="0.5" y="0.5" width="19" height="19.7143" rx="9.5" stroke={color || '#FF6900'} />
        </svg>
    );
}

export default ForwardIcon;
