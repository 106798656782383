/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import DOM from './eventReport';

import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as assetActions from '../../../../core/asset/assetActions';

class EventReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            filterSidebar: true,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, dataEventToReportParams } = this.props;
        actions.resetReportsRequest();

        this.onEventsTabFilterChange({
            date_from: moment().subtract(1, 'day'),
            date_to: moment(),
            asset_or_driver: 'assets',
            page: 1,
        });
    }

    onEventsTabPageChange = (page, pageSize) => {
        const { actions } = this.props;
        const { filters } = this.state;
        filters.page = page;
        actions.getEventReportRequest(filters);
    };

    onEventsTabSortChange = (pagination, x, sorter, extra) => {
        const { actions, eventReportPagination } = this.props;
        const { filters } = this.state;

        // checking when only order changes
        if (pagination && pagination.current.toString() === eventReportPagination.currentPage) {
            const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`, ...filters };
            actions.getEventReportRequest(params);
        }
    };

    onEventsTabFilterChange = (values) => {
        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format('YYYY-MM-DD');
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format('YYYY-MM-DD');
        }
        if (filters.asset_id) {
            filters.asset_info = values.asset_id;
        }
        filters.page = 1;
        this.setState({ filters });
        const { actions } = this.props;
        const params = { ...filters };

        actions.getEventReportRequest(params);
    };

    onExportCSV = () => {
        if (this.props.isExportingEventReportCsv) {
            return false;
        }
        const { actions } = this.props;
        const { filters } = this.state;
        filters.page = 1;
        const params = { ...filters };

        actions.getEventReportCsvRequest(params);
    };

    render() {
        return this.view(
            {
                update: (newVals) => (
                    this.props.actions.UpdateEventNotifications(newVals)
                ),
            },
        );
    }
}

EventReport.propTypes = {
    actions: PropTypes.object.isRequired,
    eventReport: PropTypes.array.isRequired,
};

EventReport.defaultProps = {
    eventReport: [],
    eventReportPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 10,
    },
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        eventReport: state.report.eventReport,
        eventReportPagination: state.report.eventReportPagination,
        events: state.events.companyEvents,
        user: state.user,
        filterSidebar: state.filterSidebar,
        dataEventToReportParams: state.global.dataEventToReportParams,
        isExportingEventReportCsv: state.report.isExportingEventReportCsv,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventReport);
