import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_LIST_COMPANIES_REQUEST: null,
    GET_LIST_COMPANIES_SUCCESS: null,
    GET_LIST_COMPANIES_FAILURE: null,
});

export const {
    getListCompaniesRequest,
    getListCompaniesSuccess,
    getListCompaniesFailure,
} = createActions(
    constants.GET_LIST_COMPANIES_REQUEST,
    constants.GET_LIST_COMPANIES_SUCCESS,
    constants.GET_LIST_COMPANIES_FAILURE,
);
