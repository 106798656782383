/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Row, Col, Card,
} from 'antd';
import moment from 'moment';
import {
    Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip as ToolTipChart,
} from 'recharts';
import Map from '../../../components/Map';
import { isEmpty } from 'underscore';
import ProgressCircle from '../../../components/elements/ProgressCircle';
import journeyScore from '../../../assets/images/journey-score.png';
import journeyTime from '../../../assets/images/clock.png';
import journeySpeed from '../../../assets/images/speed.png';
import cyclearLogo from '../../../assets/images/cyclear-logo.png';
import averageMpgIcon from '../../../assets/images/average-mpg.png';
import idClearLogo from '../../../assets/images/id-clear-no-trademark.jpg';
import reclearLogo from '../../../assets/images/reclear-logo.png';
import { secondsToHms } from '../../../core/utils/functions';
import { requestMarkerDetails, setMarkerPopoverToLoading } from '../../../core/utils/mapUtils';

import './singleJourneyTab.scss';

const { GOOGLE_MAP_API_KEY } = require('../../../core/constants').default;

export default function () {
    const {
        selectedJourney, journeyBackLink, user,
    } = this.props;
    const { sliderValue, centerMap, markerPopover } = this.state;

    const assetTitle = `${selectedJourney && selectedJourney.info && (selectedJourney.info.registration || selectedJourney.info.name)}`;
    const mappedDevicePath = this.mapDevicePath();
    let backLink = selectedJourney && selectedJourney.info && selectedJourney.info.asset_id ? `/assets/view/${selectedJourney.info.asset_id}` : '/assets';
    let backLinkText = `Asset: ${assetTitle}`;
    const startArea = (selectedJourney && selectedJourney.info && selectedJourney.info.start_area_address) || null;
    const endArea = (selectedJourney && selectedJourney.info && selectedJourney.info.end_area_address) || null;
    const journeyStart = selectedJourney && selectedJourney.info && selectedJourney.info.start ? moment(selectedJourney.info.start, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '-';
    const journeyEnd = selectedJourney && selectedJourney.info && selectedJourney.info.end ? moment(selectedJourney.info.end, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss') : '-';
    if (!isEmpty(journeyBackLink)) {
        if (journeyBackLink.link) {
            backLink = journeyBackLink.link;
        }
        if (journeyBackLink.text) {
            backLinkText = journeyBackLink.text;
        }
    }

    const mapToolBarButtons = [
        { key: 'side-bar', visible: false },
        { key: 'info-box', visible: true },
        { key: 'traffic', visible: false },
        { key: 'geo-fence', visible: false },
        { key: 'center-map', visible: false },
        { key: 'satellite', visible: true },
        { key: 'heat-map', visible: false },
        { key: 'live-map', visible: false },
        { key: 'video-search', visible: false },
    ];

    const assets = this.getAssetCurrentPin(mappedDevicePath);

    let idleTime = 0;
    let trueIdleTime = 0;
    let score = 0;
    let timeDrivenFormatted = 0;
    let averageSpeed = 0;
    let averageMpg = 0;
    let startInterval = 0;
    let endInterval = 0;
    let driverAverageScore = 0;
    if (!isEmpty(selectedJourney)) {
        idleTime = selectedJourney.info.idle_time;
        trueIdleTime = selectedJourney.info.true_idle_time;
        score = selectedJourney.info.score;
        timeDrivenFormatted = selectedJourney.info.time_driven_formatted;
        averageSpeed = selectedJourney.info.average_speed;
        averageMpg = selectedJourney.info.mpg;
        startInterval = selectedJourney.startInterval;
        endInterval = selectedJourney.endInterval;
        driverAverageScore = selectedJourney.driverAverageScore;
    }

    let profile_picture = '';
    if (selectedJourney && selectedJourney.info) {
        profile_picture = (
            <img
                className="driver-info-image"
                style={{ width: '100%', objectFit: 'cover' }} 
                alt={selectedJourney.info.driver || '-'}
                src={selectedJourney.info.profile_picture || user?.userCompany?.company_image} 
            />
        );
    }
    let asset_picture = '';
    if (selectedJourney && selectedJourney.info) {
        asset_picture = (
            <img
                className="driver-info-image"
                style={{ width: '100%', objectFit: 'cover' }} 
                alt={selectedJourney.info.registration || '-'}
                src={selectedJourney.info.asset_picture || user?.userCompany?.company_image} 
            />
        );
    }

    return (
        <div className="section__body">
            <div className='video-detail-view'>
                <Row
                    gutter={40}
                    type="flex"
                    justify="space-between"
                    className="data-row map-row"
                >
                    <Col 
                        xl={24} 
                        style={{ 
                            height: 350, 
                            width: '100%',
                        }}
                    >
                        <br />
                        <Map
                            ref={this.googleMapAccess}
                            markerOnClick={this.markerOnClick}
                            markerOnMouseEnter={(marker) => { setMarkerPopoverToLoading(this); requestMarkerDetails(marker, this); }}
                            markerPopover={markerPopover}
                            mapDate={moment().subtract('1', 'days').format('DD/MM/YYYY')}
                            apiKey={GOOGLE_MAP_API_KEY}
                            mapToolBarButtons={mapToolBarButtons}
                            sliderStep={1}
                            sliderValue={parseInt(sliderValue, 10)}
                            sliderMinValue={startInterval}
                            sliderMaxValue={endInterval}
                            sliderValueUnit="seconds"
                            hideDatePicker
                            center={centerMap}
                            onSliderChange={this.updateSliderValue}
                            assets={assets}
                            allowStreetView
                            showFindCenterButton={false}
                            path={mappedDevicePath} 
                        />
                        <br />
                        <br />
                        <Row 
                            gutter={[16, 16]} 
                            className="asset-info-row" 
                            ref={this.pdfDocumentRef}
                        >
                            <Col 
                                xs={12} 
                                style={{ verticalAlign: 'top' }}
                            >
                                <div className="video-view-block-title">Asset & Driver Information</div>
                                <Row 
                                    gutter={[32, 32]} 
                                    style={{ minHeight: 120 }}
                                >
                                    <Col span={12}>
                                        {profile_picture}
                                        <div className="title">
                                            <p> 
                                                {selectedJourney?.info?.user_id ? (
                                                    <Link to={`/drivers/view/${selectedJourney.info.user_id}`}>
                                                        {selectedJourney.info.driver || '-'}
                                                    </Link>
                                                ) : (
                                                    selectedJourney?.info?.driver || '-'
                                                )}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        {asset_picture}
                                        <div className="title">
                                            {selectedJourney?.info?.asset_id ? (
                                                <Link to={`/assets/view/${selectedJourney.info.asset_id}`}>
                                                    {selectedJourney.info.registration || '-'}
                                                </Link>
                                            ) : (
                                                selectedJourney?.info?.registration || '-'
                                            )}
                                        </div>
                                        <div className='mt-1'>
                                            <span style={{
                                                fontSize: '12px',
                                                color: 'var(--cool-gray-60)',
                                                fontWeight: 600,
                                            }}>
                                                Last service:
                                            </span>
                                            &ensp;
                                            {selectedJourney?.info?.last_service || '-'}
                                        </div>
                                        <div className='mt-1'>
                                            <span style={{
                                                fontSize: '12px',
                                                color: 'var(--cool-gray-60)',
                                                fontWeight: 600,
                                            }}>
                                                Registration:
                                            </span>
                                            &ensp;
                                            {selectedJourney?.info?.registration || '-'}
                                        </div>
                                        <br />
                                    </Col>
                                </Row>
                            </Col>
                            <Col 
                                xs={12} 
                                style={{ verticalAlign: 'top' }}
                            >
                                <div className="video-view-block-title">Journey Summary</div>
                                <Row className="journey-info-row">
                                    <Col xs={12}>
                                        <strong>Start Time:</strong>
                                        &ensp;
                                        {journeyStart || '-'}
                                    </Col>
                                    <Col xs={12}>
                                        <strong>End Time:</strong>
                                        &ensp;
                                        {journeyEnd || '-'}
                                    </Col>
                                </Row>
                                <Row className="journey-info-row">
                                    <Col xs={12}>
                                        <strong>Start Area:</strong>
                                        &ensp;
                                        {startArea || '-'}
                                    </Col>
                                    <Col 
                                        xs={12} 
                                        style={{ wordBreak: 'break-all' }}
                                    >   
                                        <strong>End Area:</strong>
                                        &ensp;
                                        {endArea || '-'}
                                    </Col>
                                </Row>
                                <Row className="journey-info-row">
                                    <Col xs={12}>
                                        <strong>Avg. Journey Score:</strong>
                                        &ensp;
                                        {selectedJourney?.info?.score || '-'}
                                    </Col>
                                    <Col xs={12}>
                                        <strong>Events:</strong>
                                        &ensp;
                                        {selectedJourney?.info?.events || '-'}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
