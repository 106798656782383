import Layout from "../../../../components/layout/Layout";
import React from "react";
import ReportFilter from "../../ReportFilter";
import FilterSidebar from "../../../../components/FilterSidebar";
import ReportTab from "./tabs/ReportTab";

export default function () {

    const {
        eventsTabLoaded, eventsTabFilter, filterSidebar, filters,
    } = this.state;
    const { user } = this.props;

    return (
        <Layout
            className={"not-moved-report"}
            title={<div>Not Moved Report</div>}
            headerTitle={<div>Not Moved Report</div>}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar displayFilter={filterSidebar}>
                    <ReportFilter
                        onSubmit={this.onEventsTabFilterChange}
                        filters={filters}
                        showDivision
                        showTimeBackSelection
                        dateMode={null}
                        weekendToggle
                    />
                </FilterSidebar>

                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <div className="section__body">
                                <div className="section__action-bar">
                                </div>
                                <ReportTab
                                    toggleFilterSidebar={() => this.setState({filterSidebar: !filterSidebar})}
                                    eventsTabFilter={eventsTabFilter}
                                    setLoadedState={eventsTabLoaded}
                                    eventsTabLoaded={eventsTabLoaded}
                                />
                            </div>
                        </section>
                    </div>
                </div>

            </div>
        </Layout>
    )
}
