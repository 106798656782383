/* eslint-disable react/no-this-in-sfc */
import React from 'react';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { size } from 'underscore';
import GridView from '../../../components/elements/GridView/index';

export default function () {
    const {
        asset,
        user,
        selectedAssets,
        actions,
    } = this.props;
    const {
        data,
        currentTabKey,
    } = this.state;

    const sortableColumns = data && data[0] ? (Object.keys(data[0])
        .filter((oK) => oK.toLowerCase() !== 'actions' && oK.toLowerCase() !== 'select' && oK.toLowerCase() !== 'action')) : [];

    return (
        <div className='assets-tab'>
            <Spin spinning={size(asset.isFetching) > 0}>
                <div className="section__action-bar">
                    {selectedAssets.length > 0
                        ? (
                            <Link
                                to="#"
                                onClick={() => actions.clearSelectedAssets()}
                                className="btn ant-btn-primary page-header-button"
                                style={{ right: '300px' }}
                            >
                                &ensp;&ensp;Clear selection&ensp;&ensp;
                            </Link>
                        )
                        : null}
                    {selectedAssets.length > 0
                        ? (
                            <Link
                                to={{ pathname: '/map/' }}
                                className="btn ant-btn-primary page-header-button"
                                onClick={() => actions.mapAssetSearchRequest({ search_ids: selectedAssets })}
                                style={{ right: '143px' }}
                            >
                                &ensp;&ensp;View selected&ensp;&ensp;
                            </Link>
                        )
                        : null}
                    {user.permissions.editing_assets === true
                        ? (
                            <Link
                                to="/assets/new"
                                className="btn ant-btn-primary page-header-button"
                                style={{ right: 0 }}
                            >
                                &ensp;&ensp;Add asset&ensp;&ensp;
                            </Link>
                        )
                        : null}
                </div>
                <div className='assets-grid'>
                    {!size(asset.isFetching) > 0 && (
                        <GridView
                            data={data}
                            onChange={this.handleTableChange}
                            sortableColumns={sortableColumns}
                            hiddenColumns={['id']}
                            pagination={{
                                total: asset.pagination.totalCount,
                                pageSize: asset.pagination.perPage,
                                current: asset.pagination.currentPage,
                                showSizeChanger: false,
                                onChange: (page) => this.paginationChange(page),
                            }}
                        />
                    )}
                </div>
            </Spin>
        </div>
    );
}
