import React from 'react';
import { Slider } from 'antd';

import './threeHandleSlider.scss';

type ThreeHandleSliderProps = {
    className?: string;
    min?: number;
    max?: number;
    value?: [number, number, number];
    defaultValue?: [number, number, number];
    step?: number;
    onChange?: (value: [number, number, number]) => void;
    tooltipFormatter?: (value: number) => React.ReactNode;
    showTimingLabels?: boolean;
    sliderWidth?: number;
    style?: React.CSSProperties;
};

/**
 * Wraps the native antd Slider component to provide values with keys that allow us to move handles properly when they overlap.
 */
const ThreeHandleSlider: React.FC<ThreeHandleSliderProps> = ({
    className = '',
    min = 0,
    max = 0,
    value = [0, 0, 0],
    defaultValue = [0, 0, 0],
    step = 1,
    onChange = (value: [number, number, number]) => {},
    tooltipFormatter = (value: number) => (<div>value</div>),
    showTimingLabels = false,
    sliderWidth = 0,
    style = {},
}) => {
    return (
        <div className='three-handle-slider'>
            {showTimingLabels && sliderWidth && (
                <div 
                    className='slider-header' 
                    style={{ width: `${sliderWidth}px` }}
                >
                    <div
                        className='labels-container' 
                        style={{ width: `${sliderWidth + 20}px` }}
                    >
                        <div className='label'>00:00</div>
                        <div 
                            className='label-spacer'
                            style={{ width: `${sliderWidth / 4}px` }} 
                        />
                        <div className='label'>06:00</div>
                        <div 
                            className='label-spacer'
                            style={{ width: `${sliderWidth / 4}px` }} 
                        />
                        <div className='label'>12:00</div>
                        <div 
                            className='label-spacer'
                            style={{ width: `${sliderWidth / 4}px` }} 
                        />
                        <div className='label'>18:00</div>
                        <div 
                            className='label-spacer'
                            style={{ width: `${sliderWidth / 4}px` }} 
                        />
                        <div className='label'>23:59</div>
                    </div>
                    <div
                        className='line-container' 
                        style={{ width: `${sliderWidth}px` }}
                    >
                        <div 
                            className='line'
                            style={{ width: `${sliderWidth / 4}px` }}
                        />
                        <div 
                            className='line'
                            style={{ width: `${sliderWidth / 4}px` }}
                        />
                        <div 
                            className='line'
                            style={{ width: `${sliderWidth / 4}px` }}
                        />
                        <div 
                            className='line-last'
                            style={{ width: `${sliderWidth / 4}px` }}
                        />
                    </div>
                </div>
            )}
            <Slider 
                className={className}
                range 
                min={min}
                max={max}
                value={value}
                defaultValue={defaultValue}
                step={step}
                onChange={onChange}
                tooltip={{ formatter: tooltipFormatter }} 
                style={{
                    marginTop: '-6px',
                    ...style,
                }}
            />
        </div>
        
    );
}

export default ThreeHandleSlider;