import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CreateWidgetForm from '../Forms/CreateWidgetForm';

class AdvancedTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        const {
            actions,
        } = this.props;
    }

    render() {
        const { editWidgetData } = this.props;
        return (
            <CreateWidgetForm editWidgetData={editWidgetData} />
        );
    }
}
AdvancedTab.defaultProps = {
    editWidgetData: {},
};

AdvancedTab.propTypes = {
    actions: PropTypes.object.isRequired,
    editWidgetData: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdvancedTab);
