/* global: localStorage */
import {
    call,
    put,
    takeEvery,
    all,
} from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getAllWidgetsSuccess,
    getAllWidgetsFailure,

    updateOrCreateSuccess,
    updateOrCreateFailure,

    updateWidgetPositionsSuccess,
    updateWidgetPositionsFailure,

    deleteWidgetSuccess,
    deleteWidgetFailure,

    getWidgetEventTypesSuccess,
    getWidgetEventTypesFailure,

    getUserDivisionsSuccess,
    getUserDivisionsFailure,

    getUserDriversSuccess,
    getUserDriversFailure,

    getSingleWidgetSuccess,
    getSingleWidgetFailure,

} from './dashboardActions';

const {
    GET_ALL_WIDGETS_REQUEST,
    UPDATE_OR_CREATE_REQUEST,
    UPDATE_WIDGET_POSITIONS_REQUEST,
    DELETE_WIDGET_REQUEST,
    GET_WIDGET_EVENT_TYPES_REQUEST,
    GET_USER_DIVISIONS_REQUEST,
    GET_USER_DRIVERS_REQUEST,
    GET_SINGLE_WIDGET_REQUEST,
} = require('./dashboardActions').constants;

function* getAllWidgets(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/widget/get-widgets',
            body: actions.payload,
        });

        yield put(getAllWidgetsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getAllWidgetsFailure(e));
    }
}

function* updateOrCreate(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/widget/update-or-create',
            body: actions.payload,
        });

        yield put(updateOrCreateSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateOrCreateFailure(e));
    }
}

function* updateWidgetPositions(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/widget/update-widget-positions',
            body: actions.payload,
        });

        yield put(updateWidgetPositionsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateWidgetPositionsFailure(e));
    }
}

function* deleteWidget(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/widget/delete-widget',
            body: actions.payload,
        });

        yield put(deleteWidgetSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(deleteWidgetFailure(e));
    }
}

function* getWidgetEventTypes(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'widget/get-event-types',
            params: actions.payload,
        });

        yield put(getWidgetEventTypesSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getWidgetEventTypesFailure(e));
    }
}

function* getUserDivisions() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'widget/get-user-divisions',
        });

        yield put(getUserDivisionsSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getUserDivisionsFailure(e));
    }
}

function* getUserDrivers() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'widget/get-user-drivers',
        });

        yield put(getUserDriversSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getUserDriversFailure(e));
    }
}

function* getSingleWidget(actions) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/widget/get-widget',
            body: actions.payload,
        });

        yield put(getSingleWidgetSuccess(response));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getSingleWidgetFailure(e));
    }
}

/**
 * Watch actions
 */
export default function* dashboardSaga() {
    yield all([
        takeEvery(GET_ALL_WIDGETS_REQUEST, getAllWidgets),
        takeEvery(UPDATE_OR_CREATE_REQUEST, updateOrCreate),
        takeEvery(UPDATE_WIDGET_POSITIONS_REQUEST, updateWidgetPositions),
        takeEvery(DELETE_WIDGET_REQUEST, deleteWidget),
        takeEvery(GET_WIDGET_EVENT_TYPES_REQUEST, getWidgetEventTypes),
        takeEvery(GET_USER_DIVISIONS_REQUEST, getUserDivisions),
        takeEvery(GET_USER_DRIVERS_REQUEST, getUserDrivers),
        takeEvery(GET_SINGLE_WIDGET_REQUEST, getSingleWidget),
    ]);
}
