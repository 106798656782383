import React from 'react';

function WebBasedTrackingPlatformSVG() {
    return (
        <svg width={64} height={64} xmlns="http://www.w3.org/2000/svg">
            <g stroke="null">
                <path d="M32 48L19.82 30.8A16.28 16.28 0 0132 4a16.16 16.16 0 0116 16.26 16.4 16.4 0 01-3.6 10.26L32 48zm0-40a12.14 12.14 0 00-12 12.26 12.38 12.38 0 002.98 8L32 41.04 41.26 28A12.48 12.48 0 0044 20.26 12.14 12.14 0 0032 8z" />
                <circle r={4} cy={18} cx={32} />
                <path d="M56 24h-4v4h4v28H8V28h4v-4H8a4 4 0 00-4 4v28a4 4 0 004 4h48a4 4 0 004-4V28a4 4 0 00-4-4z" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default WebBasedTrackingPlatformSVG;
