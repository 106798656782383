import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import './feedbackModal.scss';
import { Button, Checkbox, Form, Input, Modal, notification, Radio, Tooltip } from 'antd';
import { fetchApiAuth } from '../../core/utils/api';
import { isArray } from 'underscore';
import GridView from '../elements/GridView/index';
import { Warning, TrashCan, Renew, Restart, Cognitive } from '@carbon/icons-react';
import { FaceVeryDissatisfied, FaceDissatisfied, FaceNeutral, FaceSatisfied, FaceVerySatisfied, } from '@carbon/pictograms-react';
import { CheckCircleOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

export interface FeedbackModalProps {
    isModalOpen: boolean;
    closeModal: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({
    isModalOpen = null,
    closeModal,
}) => {
    const cleanUp = () => {
        form.resetFields();
    }

    const errorHandler = (exception) => {
        console.error(exception);
    }

    const submitRequest = async (body): Promise<void> => {
        const url = 'user-account/feedback';
        await fetchApiAuth({
            method: 'POST',
            url: url,
            body
        }).catch();
    }

    const submit = async () => {
        setConfirmLoading(true);
        const values = await form.validateFields().catch(() => null);
        if (values) {
            await submitRequest(values).catch(errorHandler);
            setConfirmLoading(false);
            notification.success({ message: 'Success', description: 'Your feedback has been sent.' });
            closeModal();
        } else {
            setConfirmLoading(false);
        }
    }

    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);


    const abortController = new AbortController();
    const reasonOptions = [
        { label: 'Something is not working', value: 'bug' },
        { label: 'I have a great idea', value: 'feature' },
        { label: 'Other', value: 'other' },
    ];

    const radioOptions = [
        { value: "very dissatisfied", content: <FaceVeryDissatisfied /> },
        { value: "dissatisfied", content: <FaceDissatisfied /> },
        { value: "neutral", content: <FaceNeutral /> },
        { value: "satisfied", content: <FaceSatisfied /> },
        { value: "very satisfied", content: <FaceVerySatisfied /> },
    ];
    // , icon: <FaceVeryDissatisfied />

    // useEffect(() => { watchVideoInternalReference() }, [videoInternalReference]);
    // useEffect(() => { watchIsModalOpen() }, [isModalOpen]);

    return (
        <Modal
            title="Help us improve"
            open={isModalOpen}
            onCancel={closeModal}
            afterClose={cleanUp}
            width={350}
            onOk={submit}
            okText="Submit"
            footer={(_, { OkBtn }) => (
                <>
                    <OkBtn />
                </>
            )}
            confirmLoading={confirmLoading}
            className='feedback-modal'
        >
            <div className='main-icon mt-2'>
                {/* <Cognitive size={60} /> */}
            </div>
            <Form form={form} layout="vertical" name="userForm">
                <Form.Item
                    name="reason"
                    label="How can we help?"
                    rules={[
                        {
                            required: true,
                            message: "Please select an option",
                        },
                    ]}
                >
                    <Checkbox.Group options={reasonOptions} />
                </Form.Item>
                <Form.Item
                    name="more_info"
                    label="Tell us more"
                    rules={[
                        {
                            required: true,
                            message: "Please tell us more",
                        },
                    ]}
                >
                    <TextArea className='ant-select-selector--multiple' rows={3} />
                    {/* <Input /> */}
                </Form.Item>
                <Form.Item
                    name="satisfaction"
                    label="How satisfied are you with Fleetclear?"
                    rules={[
                        {
                            required: true,
                            message: "Please select an option",
                        },
                    ]}
                >
                    <Radio.Group className="radio-custom">
                        {radioOptions.map((option) => {
                            return (
                                <Radio value={option.value}>
                                    {option.content}
                                </Radio>
                            )
                        })}
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="comments"
                    label="Any other comments?"
                    rules={[]}
                >
                    <TextArea className='ant-select-selector--multiple' rows={3} />

                    {/* <Input /> */}
                </Form.Item>
            </Form>


            {/* {videoStatusList?.[0]?.download_queue_status} */}
        </Modal>
    );
}

export default FeedbackModal;