function addAsset(assetArray, assetId) {
    if (assetArray.includes(assetId)) {
        return assetArray;
    }
    return [...assetArray, assetId];
}

function removeAsset(assetArray, assetId) {
    if (assetArray.includes(assetId)) {
        return assetArray.filter((id) => id !== assetId);
    }
    return assetArray;
}

export { addAsset, removeAsset };
