import React from 'react';

function RXLiteIntegrationSVG() {
    return (
        <svg
            width={64}
            height={64}
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1">
            <g stroke="null">
                <path d="M44 48h-8v-4h8v-8h4v8a4.004 4.004 0 01-4 4zM20 28h-4v-8a4.004 4.004 0 014-4h8v4h-8v8z" />
                <path d="M56 16h-8V8a4.005 4.005 0 00-4-4H8a4.005 4.005 0 00-4 4v36a4.005 4.005 0 004 4h8v8a4.005 4.005 0 004 4h36a4.005 4.005 0 004-4V20a4.005 4.005 0 00-4-4zm0 40H20v-8h8v-4h-8v-8h-4v8H8V8h36v8h-8v4h8v8h4v-8h8v36z" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default RXLiteIntegrationSVG;
