/* eslint-disable no-case-declarations */

import { Record } from 'immutable';
import { reject, findIndex } from 'underscore';

const {
    GET_DEPARTMENTS_GRID_REQUEST,
    GET_DEPARTMENTS_GRID_SUCCESS,
    GET_DEPARTMENTS_GRID_FAILURE,

    GET_DEPARTMENT_USERS_GRID_REQUEST,
    GET_DEPARTMENT_USERS_GRID_SUCCESS,
    GET_DEPARTMENT_USERS_GRID_FAILURE,

    GET_DEPARTMENT_DETAILS_REQUEST,
    GET_DEPARTMENT_DETAILS_SUCCESS,
    GET_DEPARTMENT_DETAILS_FAILURE,

    ASSIGN_USER_REQUEST,
    ASSIGN_USER_SUCCESS,
    ASSIGN_USER_FAILURE,

    UNASSIGN_USER_REQUEST,
    UNASSIGN_USER_SUCCESS,
    UNASSIGN_USER_FAILURE,

    UPDATE_DEPARTMENT_REQUEST,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAILURE,

    GET_OTHER_USERS_REQUEST,
    GET_OTHER_USERS_SUCCESS,
    GET_OTHER_USERS_FAILURE,

    CREATE_DEPARTMENT_REQUEST,
    CREATE_DEPARTMENT_SUCCESS,
    CREATE_DEPARTMENT_FAILURE,

    FILTER_DEPARTMENT_REQUEST,
    FILTER_DEPARTMENT_SUCCESS,
    FILTER_DEPARTMENT_FAILURE,
    SAVE_DEPARTMENT_SEARCH_PARAMS_REQUEST,

} = require('./departmentActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    departments: [],
    departmentsPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    departmentUsers: [],
    departmentUsersPagination: {
        currentPage: 1,
        totalRecords: 0,
        pageCount: 0,
        perPageCount: 20,
    },
    allUsers: [],
    departmentDetail: {},
    departmentSearchParams: {},
});

const departmentInitialState = new InitialState();

/**
 * ## departmentReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
function departmentReducer(state = departmentInitialState, { payload, type }) {
    if (!(state instanceof InitialState)) state = departmentInitialState.mergeDeep(state);
    const { isFetching } = state;
    const tempType = type.replace('_REQUEST', '').replace('_SUCCESS', '').replace('_FAILURE', '');
    switch (type) {
    case GET_DEPARTMENTS_GRID_REQUEST:
    case GET_DEPARTMENT_USERS_GRID_REQUEST:
    case GET_DEPARTMENT_DETAILS_REQUEST:
    case ASSIGN_USER_REQUEST:
    case UNASSIGN_USER_REQUEST:
    case UPDATE_DEPARTMENT_REQUEST:
    case GET_OTHER_USERS_REQUEST:
    case CREATE_DEPARTMENT_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case SAVE_DEPARTMENT_SEARCH_PARAMS_REQUEST: {
        return state.set('departmentSearchParams', payload);
    }

    case FILTER_DEPARTMENT_REQUEST:
        return state.set({ currentTabKey: '2' })
            .set('departmentSearchParams', payload)
            .set('error', null);

    case FILTER_DEPARTMENT_SUCCESS: {
        const { pagination } = state;
        if (payload.headers) {
            if (payload.headers['x-pagination-current-page']) {
                pagination.currentPage = parseInt(payload.headers['x-pagination-current-page'], 10);
            }
            if (payload.headers['x-pagination-page-count']) {
                pagination.pageCount = parseInt(payload.headers['x-pagination-page-count'], 10);
            }
            if (payload.headers['x-pagination-per-page']) {
                pagination.perPage = parseInt(payload.headers['x-pagination-per-page'], 10);
            }
            if (payload.headers['x-pagination-total-count']) {
                pagination.totalCount = parseInt(payload.headers['x-pagination-total-count'], 10);
            }
        }

        return state.set('isFetching', reject(isFetching, (isFetch) => isFetch[tempType] === true))
            .set('pagination', pagination)
            .set('assets', payload.data);
    }

    case GET_DEPARTMENTS_GRID_SUCCESS: {
        const departmentsPagination = {};
        departmentsPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        departmentsPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        departmentsPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        departmentsPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', false)
            .set('departments', payload.data)
            .set('departmentsPagination', departmentsPagination);
    }
    case GET_DEPARTMENT_USERS_GRID_SUCCESS: {
        const departmentUsersPagination = {};
        departmentUsersPagination.currentPage = payload.headers['x-pagination-current-page'] || 1;
        departmentUsersPagination.totalRecords = payload.headers['x-pagination-total-count'] || 0;
        departmentUsersPagination.pageCount = payload.headers['x-pagination-page-count'] || 0;
        departmentUsersPagination.perPageCount = payload.headers['x-pagination-per-page'] || 20;
        return state.set('isFetching', false)
            .set('departmentUsers', payload.data)
            .set('departmentUsersPagination', departmentUsersPagination);
    }
    case GET_DEPARTMENT_DETAILS_SUCCESS:
        return state.set('isFetching', false)
            .set('departmentDetail', payload.data);

    case ASSIGN_USER_SUCCESS: {
        const { allUsers, departmentUsers } = state;
        const userIndex = findIndex(allUsers, (val) => val.id === payload.user_id);
        if (userIndex !== -1) {
            departmentUsers.push(allUsers[userIndex]);
            allUsers.splice(userIndex, 1);
        }

        return state.set('departmentUsers', departmentUsers)
            .set('allUsers', allUsers)
            .set('isFetching', false);
    }

    case UNASSIGN_USER_SUCCESS: {
        const { departmentUsers, allUsers } = state;
        let newUser = {};

        const userIndex = findIndex(departmentUsers, (val) => val.id === payload.user_id);
        if (userIndex !== -1) {
            // adding removed user to all users list
            newUser = {
                id: departmentUsers[userIndex].id,
                full_name: departmentUsers[userIndex].full_name,
                name: departmentUsers[userIndex].name,
                email: departmentUsers[userIndex].email,
                role: departmentUsers[userIndex].role,
                status: departmentUsers[userIndex].status,
            };
            allUsers.push(newUser);
            departmentUsers.splice(userIndex, 1);
        }

        // console.log(allUsers);
        return state.set('departmentUsers', departmentUsers)
            .set('allUsers', allUsers)
            .set('isFetching', false);
    }

    case UPDATE_DEPARTMENT_SUCCESS: {
        return state.set('isFetching', false)
            .set('departmentDetail', payload.data.department);
    }

    case GET_OTHER_USERS_SUCCESS: {
        return state.set('allUsers', payload.data)
            .set('isFetching', false);
    }

    case CREATE_DEPARTMENT_SUCCESS:
        return state.set('isFetching', false);

    case GET_DEPARTMENTS_GRID_FAILURE:
    case GET_DEPARTMENT_USERS_GRID_FAILURE:
    case GET_DEPARTMENT_DETAILS_FAILURE:
    case ASSIGN_USER_FAILURE:
    case UNASSIGN_USER_FAILURE:
    case UPDATE_DEPARTMENT_FAILURE:
    case GET_OTHER_USERS_FAILURE:
    case CREATE_DEPARTMENT_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}

export {
    departmentReducer,
    departmentInitialState,
};
