import colours from '../../colours';

export default {
    handleWrapper: {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 6,
        width: '24px',
        height: '24px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
    },
    firstLineDiv: {
        position: 'absolute',
        margin: 'auto',
        marginTop: '-30px',
        marginLeft: '2px',
        borderTop: `1px solid ${colours.borderColor}`,
        borderRight: `1px solid ${colours.borderColor}`,
        width: '10px',
        height: '26px',
    },
    lastLineDiv: {
        position: 'absolute',
        margin: 'auto',
        marginTop: '-30px',
        marginLeft: '12px',
        borderTop: `1px solid ${colours.borderColor}`,
        borderLeft: `1px solid ${colours.borderColor}`,
        width: '10px',
        height: '26px',
    },
    polygon: {
        position: 'absolute',
        marginTop: '-19px',
        marginLeft: '-0.6px',
    },

    firstTimeText: {
        position: 'absolute',
        marginTop: '-35px',
        marginLeft: '-40px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '11px',
        lineHeight: '12px',
        color: colours.textColorDefault,
    },
    lastTimeText: {
        position: 'absolute',
        marginTop: '-35px',
        marginLeft: '24px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '11px',
        lineHeight: '12px',
        color: colours.textColorDefault,
    },
    timePrimary: {
        position: 'absolute',
        marginTop: '-35px',
        marginLeft: '-10px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '12px',
        color: colours.textColorPrimary,
    },
    timeTextSecondary: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        color: colours.textColorSecondary,
    },
    handleContainer: {
        position: 'absolute',
        display: 'flex',
        transform: 'translate(-50%, -50%)',
        zIndex: 4,
        width: '14px',
        height: '16px',
        border: `0.2px solid ${colours.borderColor}`,
        borderRadius: '8px',
        backgroundColor: colours.handleBackground,
    },
    handleContainerDisabled: {
        position: 'absolute',
        display: 'flex',
        transform: 'translate(-50%, -50%)',
        zIndex: 4,
        width: '14px',
        height: '16px',
        border: `0.2px solid ${colours.borderColor}`,
        borderRadius: '8px',
    },
    handleMarker: {
        width: '2px',
        height: '12px',
        margin: 'auto',
        borderRadius: '2px',
        backgroundColor: colours.primaryColor,
        transition: 'background-color .2s ease',
    },
    handleMarkerError: {
        width: '2px',
        height: '12px',
        margin: 'auto',
        borderRadius: '2px',
        transition: 'background-color .2s ease',
        backgroundColor: colours.primaryColor,
    },
};
