/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import DOM from './chartHeader';

class ChartHeader extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            timeframe: props.timeframe,
        };
    }

    onTimeframeChange(timeframe) {
        const { onTimeframeChange } = this.props;

        this.setState({
            timeframe,
        });

        onTimeframeChange(timeframe);
    }

    render() {
        return this.view();
    }
}

ChartHeader.defaultProps = {
    onTimeframeChange: () => null,
    rightMenuOnClick: () => null,
    rightMenuItems: [],
    tooltipText: null,
    hideTimeframe: false,
};

ChartHeader.propTypes = {
    // timeframe: PropTypes.oneOf(['hourly', 'daily', 'weekly', 'monthly', 'yearly']).isRequired,
    rightMenuItems: PropTypes.array,
    onTimeframeChange: PropTypes.func,
    rightMenuOnClick: PropTypes.func,
    tooltipText: PropTypes.string,
    hideTimeframe: PropTypes.bool,
};

export default ChartHeader;
