import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import { DatePicker, Input, Button, Row, Col } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Calendar } from '@carbon/icons-react';
import DateRangePicker from '../../../components/DateRangePicker';

dayjs.extend(customParseFormat);

class assetJourneyHistoryFilterForm extends Component {
    constructor(props) {
        super(props);
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit } = this.props;
        this.props.form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const { form, telematicsRetentionDays } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        
        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <DateRangePicker
                    getFieldDecorator={getFieldDecorator}
                    getFieldValue={getFieldValue}
                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                />
                <Form.Item label="Current Location">
                    {getFieldDecorator('current_location', {

                    })(
                        <Input placeholder="Enter Current Location" />,
                    )}
                </Form.Item>
                <div className="mt-2 mb-1 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                    >
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

const assetJourneyHistoryFilter = Form.create({ name: 'event_filter_form' })(assetJourneyHistoryFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(assetJourneyHistoryFilter);
