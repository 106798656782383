/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

function RewindIcon({ color, width = 15, height = 16 }) {
    return (
        <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8568 6.21483C12.9213 6.28249 12.9725 6.36286 13.0074 6.45133C13.0423 6.5398 13.0603 6.63464 13.0603 6.73042C13.0603 6.8262 13.0423 6.92104 13.0074 7.00951C12.9725 7.09798 12.9213 7.17834 12.8568 7.246L10.1573 10.0835L12.8568 12.9211C12.9869 13.0578 13.06 13.2433 13.06 13.4366C13.06 13.63 12.9869 13.8155 12.8568 13.9522C12.7267 14.089 12.5503 14.1658 12.3663 14.1658C12.1823 14.1658 12.0059 14.089 11.8758 13.9522L8.68226 10.5955C8.61776 10.5278 8.56659 10.4474 8.53168 10.359C8.49676 10.2705 8.47879 10.1757 8.47879 10.0799C8.47879 9.98409 8.49676 9.88925 8.53168 9.80078C8.56659 9.71231 8.61776 9.63195 8.68226 9.56429L11.8758 6.20752C12.1402 5.92962 12.5855 5.92962 12.8568 6.21483Z" fill={color || '#FF6900'} />
            <path d="M9.37804 6.21483C9.44254 6.28249 9.49371 6.36286 9.52863 6.45133C9.56354 6.5398 9.58151 6.63464 9.58151 6.73042C9.58151 6.8262 9.56354 6.92104 9.52863 7.00951C9.49371 7.09798 9.44254 7.17834 9.37804 7.246L6.67849 10.0835L9.37804 12.9211C9.50813 13.0578 9.58122 13.2433 9.58122 13.4366C9.58122 13.63 9.50813 13.8155 9.37804 13.9522C9.24795 14.089 9.07151 14.1658 8.88753 14.1658C8.70355 14.1658 8.52711 14.089 8.39702 13.9522L5.20347 10.5955C5.13897 10.5278 5.0878 10.4474 5.05289 10.359C5.01797 10.2705 5 10.1757 5 10.0799C5 9.98409 5.01797 9.88925 5.05289 9.80078C5.0878 9.71231 5.13897 9.63195 5.20347 9.56429L8.39702 6.20752C8.66141 5.92962 9.10669 5.92962 9.37804 6.21483Z" fill={color || '#FF6900'} />
            <rect x="-0.5" y="0.5" width="19" height="19.7143" rx="9.5" transform="matrix(-1 0 0 1 19 0)" stroke={color || '#FF6900'} />
        </svg>
    );
}

export default RewindIcon;
