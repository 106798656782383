/* global document */
/* global window:true */
/* global flvjs:true */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import flvjs from './flv.min';

class FlvPlayer extends Component {
    constructor(props) {
        super(props);

        this.videosRefs = [];
        this.player = null;
    }

    componentDidMount() {
        const { url, id, segments } = this.props;

        const element = this.videosRefs[id];
        const mediaDataSource = {
            type: 'flv',
            isLive: true,
            withCredentials: false,
            hasAudio: true,
            hasVideo: true,
            enableStashBuffer: true,
        };
        if (segments.length == 0) {
            mediaDataSource.url = url;
        } else {
            mediaDataSource.segments = segments;
        }

        // Create FLV player
        this.player = flvjs.createPlayer(mediaDataSource, {
            enableWorker: false,
            enableStashBuffer: true,
            stashInitialSize: 256,
            lazyLoadMaxDuration: 5 * 60,
            lazyLoadRecoverDuration: 30,
            seekType: 'range',
        });

        this.player.on(flvjs.Events.ERROR, (errorType, errorDetail) => {
            console.error('Error Type: ', errorType);
            console.error('Error Details: ', errorDetail);
            if (errorType === 'NetworkError') {
                console.warn('Network error occurred');
                // this.player.unload();
                // this.player.load();
            }
        });

        this.player.attachMediaElement(element);
        this.player.load();
    }

    componentWillUnmount() {
        const self = this;
        Object.keys(this.videosRefs).forEach((video_id) => {
            if (self?.props?.onRemoved) {
                self.props.onRemoved(video_id);
            }
        });

        this.player.unload();
        this.player.detachMediaElement();
        this.player.destroy();
        this.player = null;
    }

    createVideoRef = (ref, video_id) => {
        if (!this.videosRefs[video_id]) {
            this.videosRefs[video_id] = ref;
            const self = this;

            ref.addEventListener('canplaythrough', () => {
                if (self?.props?.onReady) {
                    self.props.onReady(video_id);
                }
            }, { once: true });
        }
    };

    render() {
        const { id, refs, videoKey, channelKey, } = this.props;
        return (
            <div className="mainContainer" style={{ height: '100%' }}>
                <video
                    ref={(ref) => {
                        this.createVideoRef(ref, id);
                        refs[channelKey] = { key: videoKey, element: ref };
                    }}
                    className="centeredVideo"
                    id={id}
                    autoPlay={false}
                    style={{ width: '100%', height: '100%', minHeight: '175px' }}
                >
                    Your browser is too old which doesn't support HTML5 video.
                </video>
            </div>
        );
    }
}

FlvPlayer.defaultProps = {
    id: 'customVideo',
    segments: [],
    refs: [],
    videoKey: '',
    channelKey: '',
};

FlvPlayer.propTypes = {
    url: PropTypes.string.isRequired,
    id: PropTypes.string,
    segments: PropTypes.array,
    refs: PropTypes.array,
    videoKey: PropTypes.string,
    channelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {

            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FlvPlayer);
