/* global localStorage */
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { message } from 'antd';
import { globalReducer as global, globalInitialState } from './global/globalReducer';
import { userReducer as user, userInitialState } from './user/userReducer';
import { deviceReducer as device, deviceInitialState } from './device/deviceReducer';
import { eventsReducer as events, eventInitialState } from './events/eventsReducer';
import { assetReducer as asset, assetInitialState } from './asset/assetReducer';
import { dashboardReducer as dashboard, dashboardInitialState } from './dashboard/dashboardReducer';
import { driverReducer as driver, driverInitialState } from './driver/driverReducer';
import { videoReducer as video, videoInitialState } from './video/videoReducer';
import { comparisonReducer as comparison, comparisonInitialState } from './comparison/comparisonReducer';
import { divisionReducer as division, divisionInitialState } from './division/divisionReducer';
import { departmentReducer as department, departmentInitialState } from './department/departmentReducer';
import { reportReducer as report, reportInitialState } from './report/reportReducer';
import { smartWidgetReducer as smartWidget, smartWidgetInitialState } from './smartWidget/smartWidgetReducer';
import { companyReducer as company, companyInitialState } from './company/companyReducer';
import { shardLogReducer as shardLog, shardLogInitialState } from './shardLog/shardLogReducer';
import { recordsReducer as records, recordsInitialState } from './records/recordsReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    user,
    device,
    events,
    asset,
    dashboard,
    driver,
    video,
    comparison,
    department,
    division,
    report,
    smartWidget,
    form: formReducer,
    company,
    shardLog,
    records,
});

const initialStates = {
    global: globalInitialState,
    user: userInitialState,
    device: deviceInitialState,
    events: eventInitialState,
    asset: assetInitialState,
    dashboard: dashboardInitialState,
    driver: driverInitialState,
    video: videoInitialState,
    comparison: comparisonInitialState,
    department: departmentInitialState,
    division: divisionInitialState,
    report: reportInitialState,
    smartWidget: smartWidgetInitialState,
    company: companyInitialState,
    shardLog: shardLogInitialState,
    records: recordsInitialState,
};

const rootReducer = (state, action) => {
    if (state && state.global && state.global.activeTimestamp) {
        // console.log('###############', state.global);
        // state.global.set('activeTimestamp', Date.now());
    }
    if ((action.type).indexOf('_FAILURE') !== -1) {
        const statusCode = (action && action.payload && action.payload.response && action.payload.response.status) || 404;
        const name = (action && action.payload && action.payload.response && action.payload.response.name) || '';
        const responseMessage = (action && action.payload && action.payload.response && action.payload.response.message) || '';
        const type = (action && action.payload && action.payload.response && action.payload.response.type) || '';
        if (
            statusCode == 401
            || name.toLowerCase().includes('unauthorized')
            || responseMessage.toLowerCase().includes('invalid credentials')
            || type.toLowerCase().includes('unauthorizedhttpexception')
        ) {
            // Clear the storage
            storage.removeItem('persist:root');

            // Display the messages immediately
            message.error('You have been logged out, Please log in again.', 5);

            // Redirect to login page after a short delay
            setTimeout(() => {
                window.location.href = '/';
            }, 1000);

            return appReducers(undefined, action);
        }
    }
    return appReducers(state, action);
};

export default rootReducer;
