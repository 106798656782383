import React from 'react';

function GeoFencingSVG() {
    return (
        <svg width={64} height={64} transform="scale(2)" xmlns="http://www.w3.org/2000/svg">
            <path
                stroke="null"
                d="M32 5.5A26.5 26.5 0 1058.5 32 26.53 26.53 0 0032 5.5zM9.286 32a22.639 22.639 0 017.571-16.882v3.632a3.786 3.786 0 003.786 3.786h6.401a3.786 3.786 0 003.387-2.094l.846-1.692h4.509v7.571h-9.019a3.786 3.786 0 00-1.691.4l-6.126 3.063a3.786 3.786 0 00-2.093 3.385v7.014a3.786 3.786 0 002.38 3.515l7.285 2.915 1.283 7.697A22.74 22.74 0 019.285 32zm22.422 22.7l-1.801-10.813-9.264-3.704V33.17l6.125-3.063H39.57V14.964H28.937l-1.893 3.786h-6.401v-6.391a22.634 22.634 0 0132.746 12.07H43.357V32l1.746 7.218-3.639 5.459v7.945A22.54 22.54 0 0132 54.714c-.098 0-.194-.014-.292-.014zM45.25 50.41v-4.588l3.002-4.503a3.79 3.79 0 00.524-3.02l-1.633-6.533v-3.553h7.23a22.413 22.413 0 01-9.123 22.197z" />
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default GeoFencingSVG;
