/* eslint-disable react/no-this-in-sfc */
import React from 'react';

import { isEmpty } from 'underscore';
import { Chart } from 'react-google-charts';
import { Spin } from 'antd';

import ChartHeader from '../ChartHeader';
import ChartMultiDropDown from '../ChartMultiDropDown';
import './pieChart.scss';
import { convertFromSeconds } from '../../../../core/utils/functions';

export default function () {
    const {
        rightMenuItems,
        rightMenuOnClick,
        tooltipText,
        onFirstOptionChange,
        onSecondOptionChange,
        onThirdOptionChange,
        onFourOptionChange,
        onAllOptionChange,
        defaultFirstOptionValue,
        defaultSecondOptionValue,
        defaultThirdOptionValue,
        defaultFourOptionValue,
        divisions,
        userDivisions,
        drivers,
        assets,
        unit,
        subtitle,
        heightRatio,
        imageUrl,
        leftDataColor,
        leftData,
        leftTitle,
        leftUnit,
        leftSubtitle,
        leftDataClass,
        rightDataColor,
        rightTitle,
        rightUnit,
        rightData,
        rightSubtitle,
        rightDataClass,
        bottomTitle,
        bottomSubtitle,
        bottomDataClass,
        data,
        chartKey,
        dotOnClick,
    } = this.props;

    const { timeframe } = this.state;

    let widgetHeight = heightRatio * 200;
    if (heightRatio > 1) {
        widgetHeight += ((heightRatio - 1) * 95);
    }

    let leftMainLabel = leftTitle;
    let leftSecondaryLabel = leftSubtitle;
    let leftColor = leftDataColor;
    let leftDataValue = leftData;
    let leftUnitValue = leftUnit;

    let rightMainLabel = rightTitle;
    let rightSecondaryLabel = rightSubtitle;
    let rightColor = rightDataColor;
    let rightDataValue = rightData;
    let rightUnitValue = rightUnit;

    if (data[0]) {
        if (data[0].unit) {
            leftUnitValue = data[0].unit;
        }

        if (data[0].value) {
            leftDataValue = data[0].value;
            if (leftUnitValue == 'h:m:s') {
                leftMainLabel = convertFromSeconds(data[0].value);
            } else {
                leftMainLabel = `${data[0].value}${leftUnitValue}`;
            }
        }

        if (data[0].main_label_text) {
            leftMainLabel += ` ${data[0].main_label_text}`;
        }

        if (data[0].name) {
            leftSecondaryLabel = data[0].name;
        }

        if (data[0].secondary_label_text) {
            leftSecondaryLabel = data[0].secondary_label_text;
        }

        if (data[0].color) {
            leftColor = data[0].color;
        }
    }

    if (data[1]) {
        if (data[1].unit) {
            rightUnitValue = data[1].unit;
        }

        if (data[1].value) {
            rightDataValue = data[1].value;
            if (rightUnitValue == 'h:m:s') {
                rightMainLabel = convertFromSeconds(data[1].value);
            } else {
                rightMainLabel = `${data[1].value}${rightUnitValue}`;
            }
            rightMainLabel = `${data[1].value}${rightUnitValue}`;
        }

        if (data[1].main_label_text) {
            rightMainLabel += ` ${data[1].main_label_text}`;
        }

        if (data[1].name) {
            rightSecondaryLabel = data[1].name;
        }

        if (data[1].secondary_label_text) {
            rightSecondaryLabel = data[1].secondary_label_text;
        }

        if (data[1].color) {
            rightColor = data[1].color;
        }
    }

    return (
        <div className="new-chart-widget" style={{}}>
            <ChartHeader
                timeframe={timeframe}
                onTimeframeChange={(timeframeData) => this.onTimeframeChange(timeframeData)}
                rightMenuOnClick={(rightMenuData) => rightMenuOnClick(rightMenuData)}
                rightMenuItems={rightMenuItems}
                tooltipText={tooltipText} />
            <div className="pie-chart-content" style={{ width: '100%', height: widgetHeight, overflow: 'hidden' }} id={`modalContent_${chartKey}`}>
                <div className="content-inner">
                    <div className="left-side">
                        <div className="title" style={{ color: leftColor }}>{leftMainLabel}</div>
                        <div className="subtitle">{leftSecondaryLabel}</div>
                    </div>
                    <div className="middle">
                        <Chart
                            width="200px"
                            height="200px"
                            chartType="PieChart"
                            loader={<Spin />}
                            data={[
                                ['Task', 'Hours per Day', { role: 'tooltip', type: 'string', p: { html: true } }],
                                [leftMainLabel, leftDataValue, leftDataValue + leftUnitValue],
                                [rightMainLabel, rightDataValue, rightDataValue + rightUnitValue],
                            ]}
                            options={{
                                // Just add this option
                                is3D: true,
                                colors: [leftColor, rightColor],
                                legend: { position: 'none' },
                                backgroundColor: '#fbfbfb',
                                chartArea: { width: '100%', height: '80%' },
                            }}
                            rootProps={{ 'data-testid': '2' }}
                            chartEvents={[
                                {
                                    eventName: 'select',
                                    callback: ({ chartWrapper }) => {
                                        const selected = chartWrapper.getChart().getSelection();
                                        const { row } = selected[0];
                                        dotOnClick(timeframe, row);
                                    },
                                },
                            ]} />
                    </div>
                    <div className="right-side">
                        <div className="title" style={{ color: rightColor }}>{rightMainLabel}</div>
                        <div className="subtitle">{rightSecondaryLabel}</div>
                    </div>
                </div>
                <div className="pie-chart-bottom-line">
                    <span className="title">{bottomTitle}</span> <span className={`data ${bottomDataClass}`}>{bottomSubtitle}</span>
                </div>
            </div>

            <div className="new-chart-widget-footer">
                <ChartMultiDropDown
                    onFirstOptionChange={(firstOption) => onFirstOptionChange(firstOption)}
                    onSecondOptionChange={(secondOption) => onSecondOptionChange(secondOption)}
                    onThirdOptionChange={(thirdOption) => onThirdOptionChange(thirdOption)}
                    onFourOptionChange={(fourOption) => onFourOptionChange(fourOption)}
                    onDataChange={(optionsData) => onAllOptionChange(optionsData)}
                    defaultFirstOptionValue={defaultFirstOptionValue}
                    defaultSecondOptionValue={defaultSecondOptionValue}
                    defaultThirdOptionValue={defaultThirdOptionValue}
                    defaultFourOptionValue={defaultFourOptionValue}
                    divisions={divisions}
                    userDivisions={userDivisions}
                    drivers={drivers}
                    assets={assets} />
            </div>
        </div>
    );
}
