import React from 'react';
import { Tabs } from 'antd';
import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import VideoStreamingSidebar from '../../components/VideoStreamingSidebar';
import LiveVideosTab from './tabs/LiveVideosTab';
import CachedVideoTab from './tabs/CachedVideoTab';
import VideoSearchTab from './tabs/VideoSearchTab';
import CachedVideoFilter from './forms/CachedVideoFilter';
import LiveVideoFilter from './forms/LiveVideoFilter';
import VideoSearchFilter from './forms/VideoSearchFilter';

import './video.scss';

const { TabPane } = Tabs;

export default function () {
    const {
        match, assetId, user, assetReg,
    } = this.props;
    const {
        filterSidebarIsVisible, selectedDate, channelList, selectedDvrIsOnline,
        videoSearchIsFetching, videoSearchData, timelineSegments, geofenceTimelineSegments,
        videoStreamingSidebarIsVisible, selectedDvrId,
        selectedChannels, videoSearchDateFrom, videoSearchDateTo, videoRequestLength,
        selectedDivisionId, selectedAssetImei, videoSearchSidebarIsVisible,
    } = this.state;
    const videoMain = this;
    const { params } = match;
    let filterSideBar = (
        <VideoSearchFilter
            parent={videoMain}
            params={params}
            onSubmit={this.handleVideoSearchFilter}
            selectedDivisionId={selectedDivisionId}
            onChangeSelectedDivisionId={this.onChangeSelectedDivisionId}
            selectedAssetImei={selectedAssetImei}
            onChangeSelectedAssetImei={this.onChangeSelectedAssetImei}
            selectedDate={selectedDate}
            onChangeSelectedDate={this.onChangeSelectedDate}
        />
    );
    let selectedTab = params?.selectedTab;
    if (user.permissions.historic_video_downloading !== true && user.permissions.live_video === true) {
        selectedTab = 'live-videos';
    }
    if (selectedTab === 'live-videos') {
        filterSideBar = (<LiveVideoFilter onSubmit={this.liveVideoFilterSubmit} />);
    }
    if (selectedTab === 'cached-videos') {
        filterSideBar = (<CachedVideoFilter onSubmit={this.handleRequestedFilter} />);
    }
    let destroyFilter = false;
    if (!filterSidebarIsVisible) {
        destroyFilter = true;
        filterSideBar = null;
    }

    return (
        <Layout
            data-test="pages-video"
            className="video-page"
            title={<div>Video</div>}
            headerTitle={<div>Video Page</div>}
            menuPlacement="right"
            showFooter={false}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                <FilterSidebar
                    registerCloseSidebar={(f) => { this.closeSidebar = f; }}
                    displayFilter={filterSidebarIsVisible}
                    onToggleFilterSidebar={this.toggleVideoSearchSidebar}
                >
                    {filterSideBar}
                </FilterSidebar>
                <div className="content">
                    <div className="content-inner">
                        <section className="section">
                            <Tabs
                                activeKey={selectedTab}
                                onTabClick={this.handleTabClick}
                                defaultActiveKey={selectedTab || 'cached-videos'}
                                animated={false}
                                className="tabs-restyled"
                            >
                                {user.permissions.historic_video_downloading === true && (
                                    <TabPane 
                                        tab="video search" 
                                        key="video-search"
                                    >
                                        <div className="section__body">
                                            <VideoSearchTab
                                                videoSearchIsFetching={videoSearchIsFetching}
                                                videoSearchData={videoSearchData}
                                                selectedDate={selectedDate}
                                                timelineSegments={timelineSegments}
                                                geofenceTimelineSegments={geofenceTimelineSegments}
                                                channelList={channelList}
                                                selectedDvrIsOnline={selectedDvrIsOnline}
                                                showVideoStreamingSidebar={this.showVideoStreamingSidebar}
                                                selectedChannels={selectedChannels}
                                                toggleSelectedChannel={this.toggleSelectedChannel}
                                                onChangeSliderValue={this.onChangeSliderValue}
                                                videoRequestLength={videoRequestLength}
                                                onChangeVideoRequestLength = {this.onChangeVideoRequestLength}
                                                videoStreamingSidebarIsVisible={videoStreamingSidebarIsVisible}
                                                videoSearchSidebarIsVisible={videoSearchSidebarIsVisible}
                                            />
                                        </div>
                                    </TabPane>
                                )}
                                {/* {user.permissions.historic_video_downloading === true && (
                                    <TabPane 
                                        tab="cached video" 
                                        key="cached-videos"
                                    >
                                        <div className="section__body">
                                            {selectedTab === 'cached-videos' && (
                                                <CachedVideoTab 
                                                    checkDvrStatus={this.checkDvrStatus.bind(this)} 
                                                    setShouldShowSidebar={this.setShouldShowSidebar} 
                                                    registerCallback={this.registerOnRequestFilterCallback} 
                                                    assetId={assetId} 
                                                />
                                            )}
                                        </div>
                                    </TabPane>
                                )}
                                {user.permissions.live_video === true && (
                                    <TabPane 
                                        tab="live video" 
                                        key="live-videos"
                                    >
                                        <div className="section__body">
                                            <LiveVideosTab 
                                                checkDvrStatus={this.checkDvrStatus.bind(this)} 
                                                params={params} 
                                                setShouldShowSidebar={this.setShouldShowSidebar} 
                                            />
                                        </div>
                                    </TabPane>
                                )} */}
                            </Tabs>
                        </section>
                    </div>
                </div>
                {selectedTab === 'video-search' && (
                    <VideoStreamingSidebar 
                        dvrId={selectedDvrId}
                        channelList={channelList}
                        selectedChannels={selectedChannels}
                        isVisible={videoStreamingSidebarIsVisible} 
                        closeSidebar={() => { this.setState({ videoStreamingSidebarIsVisible: false }); }}
                        dateFrom={videoSearchDateFrom}
                        dateTo={videoSearchDateTo}
                        queueCachedVideo={this.queueCachedVideo}
                    />
                )}
            </div>
        </Layout>
    );
}
