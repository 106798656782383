/* global window */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    DatePicker, Select, Input, Button, Row, Col,Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formatEventTypes } from '../../../core/utils/functions';
import { Erase, Calendar } from '@carbon/icons-react';
import * as eventsActions from '../../../core/events/eventsActions';
import * as assetActions from '../../../core/asset/assetActions';
import * as driverActions from '../../../core/driver/driverActions';
import * as globalActions from '../../../core/global/globalActions';
import DateRangePicker from '../../../components/DateRangePicker';

dayjs.extend(customParseFormat);

class EventFilterForm extends Component {
    componentDidMount() {
        const {
            actions,
            driverList,
        } = this.props;
        actions.getEventTypesRequest({ is_event: true });
        actions.getAssetOptionsRequest();
        actions.getDriverListRequest();
        actions.getDivisionsRequest();

        const {
            form,
            eventsTabFilter,
        } = this.props;

        const dateFrom = dayjs(eventsTabFilter.date_from);
        const dateTo = dayjs(eventsTabFilter.date_to);

        // If we have no start_time and end_time then set it to now -1 hour
        if (!eventsTabFilter.start_hour && !eventsTabFilter.end_hour) {
            const now = dayjs();
            const oneHourAgo = now.subtract(1, 'hour');
            form.setFieldsValue({
                start_hour: oneHourAgo.format('HH:mm'),
                end_hour: now.format('HH:mm'),
            });
        }

        form.setFieldsValue({
            date_from: dateFrom,
            date_to: dateTo,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { form, eventsTabFilter } = this.props;
        const { date_from, date_to } = eventsTabFilter;
        const { date_from: old_date_from, date_to: old_date_to } = prevProps.eventsTabFilter;

        if (date_from !== old_date_from || date_to !== old_date_to) {
            const dateFrom = dayjs(eventsTabFilter.date_from);
            const dateTo = dayjs(eventsTabFilter.date_to);

            form.setFieldsValue({
                date_from: dateFrom,
                date_to: dateTo,
            });
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        const { onSubmit, form } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    render() {
        const {
            form,
            event_types,
            assetOptions,
            driverList,
            divisionList,
            role,
            eventsTabFilter,
            assetId,
            dateFrom,
            dateTo,
            mapDate,
            telematicsRetentionDays,
        } = this.props;
        const { getFieldDecorator, getFieldValue } = form;

        const params = eventsTabFilter;
        let assetInfoInitialVal = params && params.asset_info ? { initialValue: parseInt(params.asset_info, 10) } : {};
        const eventTypeInitialVal = params && params.event_types ? { initialValue: params.event_types } : {};
        const driverInitialVal = params && params.driver ? { initialValue: params.driver } : {};
        const divisionInitialVal = params && params.division ? { initialValue: params.division } : {};
        const postcodeInitialVal = params && params.postcode ? { initialValue: params.postcode } : {};
        const radiusInitialVal = params && params.miles ? { initialValue: params.miles } : {};
        const startHourInitialVal = params && params.start_hour ? { initialValue: params.start_hour } : {};
        const endHourInitialVal = params && params.end_hour ? { initialValue: params.end_hour } : {};

        if (window.location.pathname.includes('search')) {
            if (assetId) {
                assetInfoInitialVal = { initialValue: parseInt(assetId, 10) };
            }
        }
        const updatedEventTypes = formatEventTypes(event_types);

        return (
            <Form onSubmit={this.submitForm} layout="vertical">
                <Form.Item label="Asset">
                    {getFieldDecorator('asset_info', assetInfoInitialVal)(
                        <Select
                            showSearch
                            mode="multiple"
                            allowClear={<Erase size={12} />}
                            filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                            placeholder="Select Asset"
                            placement='topRight'
                        >
                            {assetOptions.map((a) => {

                                if (a.act_as_tracker) {
                                    const toolTipTitle = (
                                        <span>Unavailable for this Asset - <a href="https://www.fleetclear.com/contact" target="_blank" rel="noreferrer">Click here</a> to find out more</span>
                                    );

                                    return (
                                        <Select.Option key={a.id} value={a.id} disabled style={{backgroundColor: '#f3f2f2', borderRadius: 0, marginBottom: '1px'}}>
                                            <Tooltip title={toolTipTitle}>
                                                <div>
                                                    {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                                </div>
                                            </Tooltip>
                                        </Select.Option>
                                    );
                                } else {
                                    return (
                                        <Select.Option key={a.id} value={a.id}>
                                            {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                        </Select.Option>
                                    );
                                }
                            })}
                        </Select>,
                    )}
                </Form.Item>
                <DateRangePicker
                    getFieldDecorator={getFieldDecorator}
                    getFieldValue={getFieldValue}
                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                />

                <Row>
                    <Col span={12}>
                        <Form.Item label="Start Hour">
                            {getFieldDecorator('start_hour', {
                                ...startHourInitialVal,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input start hour',
                                    },
                                    {
                                        pattern: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                                        message: 'Time format should be HH:MM',
                                    }
                                ],
                            })(
                                <Input
                                    type='text'
                                    placeholder="HH:MM"
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="End Hour">
                            {getFieldDecorator('end_hour', {
                                ...endHourInitialVal,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input end hour',
                                    },
                                ],
                            })(
                                <Input
                                    type='text'
                                    placeholder="HH:MM"
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Event type">
                    {getFieldDecorator('event_types', eventTypeInitialVal)(
                        <Select
                            mode="multiple"
                            placeholder="Select Event Type"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            showSearch
                            allowClear={<Erase size={12} />}
                        >
                            {updatedEventTypes.map((eventType) => <Select.Option key={eventType.id} value={eventType.id}>{eventType.key}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                { /* option.props.children.toLowerCase() */}
                <Form.Item label="Driver">
                    {getFieldDecorator('driver', driverInitialVal)(
                        <Select
                            mode="multiple"
                            placeholder="Select Driver"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            {driverList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Division">
                    {getFieldDecorator('division', divisionInitialVal)(
                        <Select
                            mode="multiple"
                            placeholder="Select Division"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            allowClear={<Erase size={12} />}
                            showSearch
                        >
                            {divisionList.map((dl) => <Select.Option key={dl.id} value={dl.id}>{dl.name}</Select.Option>)}
                        </Select>,
                    )}
                </Form.Item>
                <Row>
                    <Col span={12}>
                        <Form.Item label="Postcode">
                            {getFieldDecorator('postcode', {
                                ...postcodeInitialVal,
                                rules: [
                                    {
                                        pattern: /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
                                        message: 'Please enter a valid UK postcode.',
                                    },
                                ],
                            })(
                                <Input placeholder="Enter Postcode" />,
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Radius">
                            {getFieldDecorator('miles', {
                                ...radiusInitialVal,
                                rules: [{
                                    pattern: /^[0-9]*$/,
                                    message: ' Radius must be a valid number\n',
                                }, {
                                    max: 5,
                                    message: 'Radius must be no more than 99999',
                                }]
                            })(
                                <Input placeholder="Enter Miles" />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <div className="mt-2 mb-1 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                    >
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

EventFilterForm.propTypes = {
    form: PropTypes.object.isRequired,
    event_types: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    assetOptions: PropTypes.array.isRequired,
    driverList: PropTypes.array.isRequired,
    divisionList: PropTypes.array.isRequired,
    eventsTabFilter: PropTypes.object.isRequired,
    assetId: PropTypes.string,
    dateFrom: PropTypes.string,
    mapDate: PropTypes.string,
};

const EventFilter = Form.create({ name: 'event_filter_form' })(EventFilterForm);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
        assetOptions: state.asset.options,
        driverList: state.driver.driverList,
        divisionList: state.asset.divisions,
        role: state.user.role,
        assetId: state.global.eventSearchFilters.assetId,
        mapDate: state.global.mapDate,
        dateFrom: state.global.eventSearchFilters.dateFrom,
        dateTo: state.global.eventSearchFilters.dateTo,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventFilter);
