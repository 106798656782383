import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon, { UserOutlined } from '@ant-design/icons';

import placeholder from '../../../../assets/images/report_placeholder.png';
import './style.scss';

function Report({
    title,
    description,
    link,
    image,
    icon,
    clearParams
}) {
    const ReportIcon = (props) => <Icon component={icon} {...props} />;

    const handleClick = (e) => {
        clearParams();
        window.scrollTo(0, 0);
    }

    return (
        <Link className={`report-link ${link}`} onClick={handleClick} to={`/reports/${link}`}>
            <div className="report-link-inner">
                <span className="image-border">
                    {icon
                        ? (
                            <ReportIcon className="report-icon" />
                        )
                        : <img className="image" src={image} alt={`${title} representation`} />}
                </span>
                <div style={{
                    width: 'calc(100% - 32px)',
                    margin: '0 16px',
                }}>
                    <h4 className="title">{title}</h4>
                    <p className="description">{description}</p>
                </div>
            </div>
        </Link>
    );
}

Report.defaultProps = {
    title: '',
    description: '',
    link: '',
    image: placeholder,
    icon: null,
};

Report.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.any,
    icon: PropTypes.any,
};

export default Report;
