import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { PopupType } from '../../..';
import Icon from '../../../../elements/Icon';
import SpeedSign from '../../../../SpeedSign';
import { AvlKey, UnitOfMeasurementPreference } from '../AssetPopupContent';
import { Location } from '../../..';

import './eventPopupContent.scss';

export interface EventPopupContentProps {
    type: PopupType.Event;
    speed: number;
    eventName: string;
    time: string;
    driverId: number;
    driverName: string;
    location: Location;
    avlKeys: AvlKey[];
}

/**
 * Renders the content of an event popup.
 */
const EventPopupContent: React.FC<EventPopupContentProps> = ({
    speed,
    eventName,
    time,
    driverId,
    driverName,
    location,
    avlKeys,
}) => {
    const user = useSelector((state: any) => state.user);

    return (
        <div className='event-popup'>
            <Row>
                <Col xs={3}>
                    <SpeedSign 
                        speed={speed} 
                        isMetric={user?.profile?.unit_of_measurement_preference === UnitOfMeasurementPreference.Metric || false}
                    />
                </Col>
                <Col xs={21}>
                    <h3 className='name'>{eventName} Event</h3>
                </Col>
                <Col xs={20}>
                    <div className='metadata'>
                        <div className='time'>
                            <Icon name='clock' />
                            &ensp;
                            {time}
                        </div>
                        <div className='driver-name'>
                            <Icon name='person' />
                            &ensp;
                            <a 
                                href={`/drivers/view/${driverId}`} 
                                target="_blank"
                                rel="noreferrer"
                            >
                                {driverName}
                            </a>
                        </div>
                        <div className='lat-lng'>
                            <Icon name='location' />
                            &ensp;
                            <div>{location.lat}, {location.lng}</div>
                        </div>
                        <div className='address'>
                            <Icon name='road' />
                            &ensp;
                            {location.address}
                        </div>
                    </div>
                </Col>
                <Col xs={24}>
                    <div className='avl-table-container'>
                        <table>
                            <tbody>
                                {avlKeys.map((avlKey: AvlKey) => (
                                    <tr key={avlKey.value}>
                                        <td>&ensp;{avlKey.description}</td>
                                        <td>{avlKey.value}&ensp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default EventPopupContent;