/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import PropTypes from 'prop-types';
import DOM from './pieChart';

class PieChart extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.state = {
            timeframe: props.timeframe,
        };
    }

    onTimeframeChange(timeframe) {
        const { onTimeframeChange } = this.props;

        this.setState({
            timeframe,
        });

        onTimeframeChange(timeframe);
    }

    render() {
        return this.view();
    }
}

PieChart.defaultProps = {
    onTimeframeChange: () => null,
    rightMenuOnClick: () => null,
    rightMenuItems: [],
    tooltipText: null,
    onFirstOptionChange: () => null,
    onSecondOptionChange: () => null,
    onThirdOptionChange: () => null,
    onFourOptionChange: () => null,
    defaultFirstOptionValue: 'All',
    defaultSecondOptionValue: 'All',
    defaultThirdOptionValue: 'All',
    defaultFourOptionValue: 'All',
    divisions: [],
    userDivisions: [],
    drivers: [],
    assets: [],
    data: '',
    unit: 'Miles',
    subtitle: '',
    heightRatio: 1,
    imageUrl: '',
    leftSubtitle: 'AVERAGE DRIVER SCORE',
    leftTitle: '',
    leftData: '',
    leftDataColor: '#faad14',
    leftDataClass: 'success',
    leftUnit: '',
    rightTitle: '',
    rightData: '',
    rightSubtitle: '',
    rightDataClass: 'success',
    rightDataColor: '#52c41a',
    rightUnit: '',
    bottomTitle: '',
    bottomSubtitle: '',
    bottomDataClass: 'warning',
};

PieChart.propTypes = {
    timeframe: PropTypes.oneOf(['hourly', 'daily', 'weekly', 'monthly', 'yearly']).isRequired,
    // data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    unit: PropTypes.string,
    subtitle: PropTypes.string,
    rightMenuItems: PropTypes.array,
    onTimeframeChange: PropTypes.func,
    rightMenuOnClick: PropTypes.func,
    tooltipText: PropTypes.string,
    onFirstOptionChange: PropTypes.func,
    onSecondOptionChange: PropTypes.func,
    onThirdOptionChange: PropTypes.func,
    onFourOptionChange: PropTypes.func,
    defaultFirstOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultSecondOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultThirdOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultFourOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    divisions: PropTypes.array,
    userDivisions: PropTypes.array,
    drivers: PropTypes.array,
    assets: PropTypes.array,
    heightRatio: PropTypes.oneOf([1, 2]),
    imageUrl: PropTypes.string,
    leftSubtitle: PropTypes.string,
    leftData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    leftDataColor: PropTypes.string,
    leftTitle: PropTypes.string,
    leftUnit: PropTypes.string,
    rightSubtitle: PropTypes.string,
    rightTitle: PropTypes.string,
    rightUnit: PropTypes.string,
    rightData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rightDataColor: PropTypes.string,
    leftDataClass: PropTypes.oneOf(['success', 'error', 'warning']),
    rightDataClass: PropTypes.oneOf(['success', 'error', 'warning']),
    bottomTitle: PropTypes.string,
    bottomSubtitle: PropTypes.string,
    bottomDataClass: PropTypes.oneOf(['success', 'error', 'warning']),
};

export default PieChart;
