import React from 'react';
import './pathMarkerContent.scss';

export enum PathMarkerPosition {
    Start = 'start',
    End = 'end',
}

type PathMarkerContentProps = {
    position?: PathMarkerPosition;
};

const PathMarkerContent: React.FC<PathMarkerContentProps> = ({
    position = PathMarkerPosition.Start,
}) => {
    return (
        <div className='path-marker-content'>
            <div className='path-marker'>
                <div className='path-marker-text'>
                    {position}
                </div>
            </div>
        </div>
    );
}

export default PathMarkerContent;