import React from 'react';

function MapAndAssetDisplaySVG() {
    return (
        <svg
            width={64}
            height={64}
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1">
            <g stroke="null">
                <path d="M32 14.818l3.65 7 7.805.967-5.728 5.256 1.146 7.777L32 31.443l-6.873 4.375 1.146-7.777-5.728-5.256 8.019-.966 3.436-7z" />
                <path d="M32 58.727L15.896 39.734c-.092-.109-.665-.862-.665-.862A20.788 20.788 0 0111 26.272a21 21 0 0142 0 20.78 20.78 0 01-4.228 12.596l-.003.004s-.573.753-.658.854L32 58.727zM18.278 36.572c.002.002.446.589.548.715L32 52.824l13.192-15.559c.084-.105.531-.697.532-.698a16.993 16.993 0 003.458-10.294 17.182 17.182 0 00-34.364 0 17.001 17.001 0 003.46 10.3z" />
            </g>
        </svg>
    );
}

// eslint-disable-next-line import/prefer-default-export
export default MapAndAssetDisplaySVG;
